import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  value: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: theme.palette.custom.red,
  },
  success: {
    color: theme.palette.custom.green,
  },
  warning: {
    color: theme.palette.custom.yellow,
  },
  stale: {
    color: theme.palette.text.disabled,
  },
  label: {
    color: theme.palette.text.disabled,
    marginLeft: '.5rem',
  },
  valueIcon: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    top: 'calc((100% - 10px) / 2)',
    // -10px width, -3px margin
    left: '-13px',
    display: 'inline-flex',
    '& > svg': {
      fontSize: '1rem',
    },
    '&.hover:hover': {
      backgroundColor: theme.palette.divider,
      cursor: 'pointer',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.divider,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
