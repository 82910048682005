import React, { SyntheticEvent, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Avatar, Box, Button, Card, CardActions, CardHeader, Collapse } from '@mui/material';

import { DownloadIcon, Expand2Icon, VideoIcon } from 'assets/icons';
import { Message } from 'models/messaging';
import { useUserAnalytics } from 'hooks/useUserAnalytics';

import useStyles from './styles';

interface VideoPlayerProps {
  url: string;
  message: Partial<Message>;
}

const VideoPlayer = ({ url, message }: VideoPlayerProps) => {
  const classes = useStyles();
  const videoNode = useRef<HTMLVideoElement>(null);
  const { onCreateEvent } = useUserAnalytics();
  const [showCard, setShowCard] = useState(false);

  const handleVideoActions = (type: string) => (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    onCreateEvent(event, `<video type="${type}" />`, '<LinkPreview />');
  };

  const onVideoPlay = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    const videoMessages = document.getElementsByClassName('chat-video-message');
    for (let i = 0; i < videoMessages.length; i++) {
      const videoMessage = videoMessages[i] as HTMLVideoElement;
      if (videoMessage !== event.target) {
        videoMessage.pause();
      }
    }

    // Stop all audios
    const audioMessages = document.getElementsByClassName('chat-audio-message');
    for (let i = 0; i < audioMessages.length; i++) {
      const audioMessage = audioMessages[i] as HTMLAudioElement;
      audioMessage.pause();
    }

    handleVideoActions('onPlay')(event);
  };

  const handleFullScreen = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLVideoElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (!videoNode.current) {
      return;
    }
    if (isFullScreen(videoNode.current)) {
      try {
        await document.exitFullscreen();
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await requestFullScreen(videoNode.current);
      } catch (err) {
        const error = err as Error;
        console.error(
          `Error attempting to enable fullscreen mode: ${error.message} (${error.name})`
        );
      }
    }
  };

  const handleOnMouseEnter = async () => {
    if (!videoNode.current || isFullScreen(videoNode.current)) {
      return;
    }

    setShowCard(true);
    try {
      await videoNode.current.play();
    } catch (err) {
      const error = err as Error;
      console.error(`Error attempting to play video: ${error.message} (${error.name})`);
    }
  };

  const handleOnMouseLeave = async () => {
    if (!videoNode.current || isFullScreen(videoNode.current)) {
      return;
    }

    setShowCard(false);
    videoNode.current.pause();
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    window.location.href = url;
  };

  return (
    <Box onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <div className={classes.videoContainer}>
        <video
          className={['chat-video-message', classes.video].join(' ')}
          muted={true}
          onPlay={onVideoPlay}
          onPause={handleVideoActions('onPause')}
          onEnded={handleVideoActions('onEnded')}
          onSeeked={handleVideoActions('onSeeked')}
          onClick={(e) => handleFullScreen(e)}
          ref={videoNode}
          preload="metadata"
          loop
          src={url}
        />
      </div>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar} aria-label="recipe">
              <VideoIcon />
            </Avatar>
          }
          title={message?.name}
          subheader={
            <>
              <div>{message?._sender?.nickname}</div>
              {message?.createdAt && <div>{format(message?.createdAt, 'yyyy-MM-dd')}</div>}
            </>
          }
        />
        <Collapse in={showCard} timeout={{ enter: 1000, exit: 1000, appear: 1000 }} unmountOnExit>
          <CardActions className={classes.cardActions}>
            <Button
              size="medium"
              fullWidth
              startIcon={<Expand2Icon />}
              onClick={(e) => handleFullScreen(e)}
            >
              FULL SCREEN
            </Button>
            <Button
              size="medium"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={(e) => handleDownload(e)}
            >
              DOWNLOAD
            </Button>
          </CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default VideoPlayer;

function requestFullScreen(element: HTMLElement) {
  const methodName = [
    'requestFullscreen',
    'webkitRequestFullscreen',
    'mozRequestFullScreen',
    'msRequestFullscreen',
  ].find((name) => name in element);
  if (!methodName) {
    return Promise.reject(new Error('This browser does not support full screen mode'));
  }
  return (element as any)[methodName]();
}

function isFullScreen(element: HTMLElement) {
  const propertyNames = [
    'fullscreenElement',
    'webkitFullscreenElement',
    'mozFullScreenElement',
    'msFullscreenElement',
  ] as Array<keyof Document>;
  for (const propertyName of propertyNames) {
    if (propertyName in document && document[propertyName] === element) {
      return true;
    }
  }
  return false;
}
