import { AnalyticsEvent } from 'lib/analytics/models/analytics';
import { UserInfo } from 'lib/analytics/models/userInfo';
import {
  EVENT_APP_NAME,
  EVENT_CATEGORY,
  EVENT_DATA_SOURCE,
  EVENT_NAME,
  EVENT_SUBCATEGORY,
  EVENT_VERSION,
} from 'lib/analytics/constants/analyticsEvent';

const getPatientIdFrom = (pageLocation: string) => {
  let patientId = undefined;

  if (pageLocation.includes('patient')) {
    const paths = pageLocation.split('/');
    patientId = paths[2];
  }

  return patientId;
};

export const generateAnalyticsEvent = (userInfo: UserInfo, currentPage: string) => {
  const event: AnalyticsEvent = {
    v: EVENT_VERSION,
    tid: userInfo.sessionId,
    ds: EVENT_DATA_SOURCE,
    uid: userInfo.userId,
    te: userInfo.tenantId,
    et: Math.floor(Date.now() / 1000),
    en: EVENT_NAME.pageViewStart,
    ep: {
      category: EVENT_CATEGORY,
      subcategory: EVENT_SUBCATEGORY,
      app_name: EVENT_APP_NAME,
      session_id: userInfo.sessionId,
      visit: currentPage,
      patient_id: getPatientIdFrom(currentPage),
    },
  };

  return event;
};
