import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { selectSnackbarMessage, clearSnackbarMessage } from 'store/snackbar';

export default function SnackbarProvider({ children }: React.PropsWithChildren<{}>) {
  const dispatch = useDispatch();
  const message = useSelector(selectSnackbarMessage);

  const renderMessage = () => {
    if (message === null) {
      return null;
    }
    return (
      <Snackbar
        open={true}
        autoHideDuration={message.dismissAfterMs}
        action={message.action}
        onClose={() => dispatch(clearSnackbarMessage())}
      >
        <Alert severity={message.variant} sx={{ width: '100%' }}>
          {message.text}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderMessage()}
      {children}
    </>
  );
}
