import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import type { AuthOption, DirectCall } from 'sendbird-calls';
import SendbirdCall, { LoggerLevel } from 'sendbird-calls';

import CallContext, { initialContext } from './context';
import type { ContextType } from './context';
import { statefyDirectCall } from './statefy';
import { useAppDispatch, useAppSelector } from 'store/index';
import {
  auth as authCall,
  deauth as deauthCall,
  callsStateSelector,
  callsSelector,
  clearCalls as clearSbCalls,
  updateAudioInputDeviceInfo,
  updateAudioOutputDeviceInfo,
  updateVideoInputDeviceInfo,
  addCall,
} from 'store/calls';
import { StatefulDirectCall } from 'models/calls';

const SbCallsProvider = ({
  appId,
  children,
}: {
  appId: string;
  children: ReactElement;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(callsStateSelector);
  const calls = useAppSelector(callsSelector);
  const currentCall = useMemo(
    () => calls.find((call: StatefulDirectCall) => !call.isEnded),
    [calls]
  );
  const isBusy = useMemo(() => calls.some((call: StatefulDirectCall) => !call.isEnded), [calls]);

  const init = useCallback<ContextType['init']>(
    (nAppId) => {
      const listenerId = 'device-change-listener';
      try {
        SendbirdCall.removeListener(listenerId);
      } catch (error) {}

      SendbirdCall.init(nAppId);
      SendbirdCall.setLoggerLevel(
        process.env.NODE_ENV === 'development' ? LoggerLevel.INFO : LoggerLevel.NONE
      );
      SendbirdCall.addListener(listenerId, {
        onRinging: (call: DirectCall) => {},
        onAudioInputDeviceChanged: (current, available) => {
          dispatch(updateAudioInputDeviceInfo({ current, available }));
        },
        onAudioOutputDeviceChanged: (current, available) => {
          dispatch(updateAudioOutputDeviceInfo({ current, available }));
        },
        onVideoInputDeviceChanged: (current, available) => {
          dispatch(updateVideoInputDeviceInfo({ current, available }));
        },
      });
      SendbirdCall.updateMediaDevices({ audio: true, video: true });
    },
    [dispatch]
  );

  useEffect(() => {
    if (appId) {
      init(appId);
    }
  }, [appId, init]);

  const auth = useCallback(
    async (authOption: AuthOption) => {
      try {
        const user = await SendbirdCall.authenticate(authOption);
        await SendbirdCall.connectWebSocket();
        dispatch(authCall(user));
        return user;
      } catch (error) {}
    },
    [dispatch]
  );

  const deauth = useCallback<ContextType['deauth']>(() => {
    if (state.user) {
      SendbirdCall.deauthenticate();
    }
    dispatch(deauthCall());
  }, [dispatch, state.user]);

  /*
    Media Device Control
   */
  const updateMediaDevices = useCallback<ContextType['updateMediaDevices']>((constraints) => {
    SendbirdCall.updateMediaDevices(constraints);
  }, []);

  const selectAudioInputDevice = useCallback<ContextType['selectAudioInputDevice']>(
    (mediaInfo: MediaDeviceInfo) => {
      SendbirdCall.selectAudioInputDevice(mediaInfo);
      dispatch(updateAudioInputDeviceInfo({ current: mediaInfo }));
    },
    [dispatch]
  );

  const selectAudioOutputDevice = useCallback<ContextType['selectAudioOutputDevice']>(
    (mediaInfo: MediaDeviceInfo) => {
      SendbirdCall.selectAudioOutputDevice(mediaInfo);
      dispatch(updateAudioOutputDeviceInfo({ current: mediaInfo }));
    },
    [dispatch]
  );

  const selectVideoInputDevice = useCallback<ContextType['selectVideoInputDevice']>(
    (mediaInfo: MediaDeviceInfo) => {
      SendbirdCall.selectVideoInputDevice(mediaInfo);
      dispatch(updateVideoInputDeviceInfo({ current: mediaInfo }));
    },
    [dispatch]
  );

  /*
    Direct Calls
   */
  const dial = useCallback<ContextType['dial']>(
    (params) =>
      new Promise((response, reject) => {
        SendbirdCall.dial(params, (call, error) => {
          if (error) {
            reject(error);
            return;
          }
          const statefulCall = statefyDirectCall(call as DirectCall, dispatch);
          dispatch(addCall(statefulCall));
          response(statefulCall);
        });
      }),
    [dispatch]
  );

  const clearCalls = useCallback(() => {
    dispatch(clearSbCalls());
  }, [dispatch]);

  const callContext: ContextType = {
    ...initialContext,
    ...state,
    init,
    auth,
    deauth,
    isAuthenticated: !!state.user,

    // Media Device Control
    updateMediaDevices,
    selectAudioInputDevice,
    selectAudioOutputDevice,
    selectVideoInputDevice,

    // Direct Calls
    currentCall,
    isBusy,
    dial,
    addDirectCallSound: SendbirdCall.addDirectCallSound,
    clearCalls,
  };

  return <CallContext.Provider value={callContext}>{children}</CallContext.Provider>;
};

export default SbCallsProvider;
