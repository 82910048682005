import { createSlice, PayloadAction, Slice, SliceCaseReducers } from '@reduxjs/toolkit';

import {
  BandStatusReportRawData,
  BillingCareMemberRawData,
  ClinicalReportPatientData,
  EngagementCareMemberRawData,
  EngagementReportRawData,
  PeriodItem,
} from 'models/reports';
import { userReportsApi } from 'services/userReports';
import { adminReportsApi } from 'services/userReports/adminReports';
import { RootState } from 'store/rootReducer';

export interface InitialReportStateProps {
  openSummary: boolean;
  periodSelected?: PeriodItem;
  billing: {
    items: BillingCareMemberRawData[];
    totalRecords: number;
    ctmTotalSecondsSpent: number;
    careTeam: BillingCareMemberRawData[];
    engagementSummaryData?: EngagementCareMemberRawData;
    careTeamSummaryData: Partial<BillingCareMemberRawData>[];
  };
  clinical: {
    currentMemberReport?: ClinicalReportPatientData;
    selectedReport: number;
  };
  engagement: {
    items: EngagementReportRawData[];
  };
  bandStatus: {
    items: BandStatusReportRawData[];
  };
}

const initialState: InitialReportStateProps = {
  openSummary: false,
  periodSelected: undefined,
  billing: {
    items: [],
    totalRecords: 0,
    ctmTotalSecondsSpent: 0,
    careTeam: [],
    careTeamSummaryData: [],
  },
  clinical: {
    selectedReport: 0,
  },
  engagement: {
    items: [],
  },
  bandStatus: {
    items: [],
  },
};

const reportSlice: Slice<
  InitialReportStateProps,
  SliceCaseReducers<InitialReportStateProps>,
  'reports'
> = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearClinicianReport: (state) => {
      state.openSummary = false;
      state.periodSelected = undefined;
      state.clinical = { ...initialState.clinical };
    },
    setReportPeriod: (state, { payload }: PayloadAction<PeriodItem | undefined>) => {
      state.periodSelected = payload;
    },
    setOpenSummary: (state, { payload }: PayloadAction<boolean>) => {
      state.openSummary = payload;
    },
    setSelectedClinicalReport: (state, { payload }: PayloadAction<number>) => {
      state.clinical.selectedReport = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminReportsApi.endpoints.fetchCareTeamTimeTrackingReport.matchFulfilled,
      (state, { payload }) => {
        state.billing.totalRecords = payload.groupedData.length;
        state.billing.items = payload.groupedData;
        state.billing.engagementSummaryData = payload.engagementSummaryData;
        state.billing.careTeamSummaryData = payload.careTeamSummaryData;
        state.billing.ctmTotalSecondsSpent = payload.groupedData.reduce(
          (sum, r) => (r.careMemberTotalSecondsSpent ? sum + r.careMemberTotalSecondsSpent : sum),
          0
        );
        state.billing.careTeam = payload.groupedData.filter(
          (report) => !!report.careMemberTotalSecondsSpent
        );
      }
    );
    builder.addMatcher(
      userReportsApi.endpoints.fetchClinicianRiskReport.matchFulfilled,
      (state, { payload }) => {
        state.clinical.currentMemberReport = payload;
      }
    );
  },
});

export const { setOpenSummary, setReportPeriod, clearClinicianReport, setSelectedClinicalReport } =
  reportSlice.actions;

export const openReportSummarySelector = (state: RootState) => state.reports.openSummary;
export const periodReportSelector = (state: RootState) => state.reports.periodSelected;
export const billingReportSelector = (state: RootState) => state.reports.billing;
export const clinicalReportSelector = (state: RootState) => state.reports.clinical;

export default reportSlice.reducer;
