import React from 'react';
import { Fade, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import type { CustomColors } from 'styles/theme';

export interface SimpleTooltipProps {
  title: TooltipProps['title'];
  children: TooltipProps['children'];
  placement: TooltipProps['placement'];
  isWithArrow: TooltipProps['arrow'];
  className?: TooltipProps['className'];
  color: CustomColors;
}

const SimpleTooltip = styled(
  ({ className, title, isWithArrow, placement, children }: SimpleTooltipProps) => (
    <Tooltip
      title={title}
      arrow={isWithArrow}
      placement={isWithArrow ? placement : undefined}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      classes={{ popper: className }}
    >
      {children}
    </Tooltip>
  )
)(({ theme, color }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.custom[color],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.custom[color],
    color: theme.palette.getContrastText(theme.palette.custom[color]),
    borderRadius: '6px',
  },
}));

export default SimpleTooltip;
