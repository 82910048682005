import {
  SystemSurvey,
  SystemSurveyMultiChoiceQuestion,
  SystemSurveyQuestion,
  SystemSurveySingleChoiceQuestion,
  SystemSurveyLongTextQuestion,
  SystemSurveyTextQuestion,
} from 'models/systemSurvey';

export interface SystemSurveyData {
  id: string;
  name: string;
  questions: SystemSurveyQuestionData[];
  tenant_id: string;
}

export interface SystemSurveyQuestionData {
  id: string;
  name: string;
  type: number | string;
  position: number;
  text: string;
  options: SystemSurveyQuestionOptionData[];
  display_conditions: SystemSurveyQuestionDisplayConditionData[];
}

export interface SystemSurveyQuestionDisplayConditionData {
  question_name: string;
  answer_indices: number[];
}

export type SystemSurveyQuestionOptionData = {
  id: string;
  position: number;
  text: string;
  value: number | null;
} & (
  | { input_type: 'default' }
  | { input_type: 'text'; text: string; input_default_text: string | null }
  | { input_type: 'keypad'; input_min: number; input_max: number; input_unit: string | null }
  | { input_type: 'time'; input_is_24_hr: number; input_default: number }
  | {
      input_type: 'picker';
      input_min: number;
      input_max: number;
      input_increment: number;
      input_unit: string | null;
    }
  | { input_type: 'date'; input_date: string | null }
);

export function systemSurveyFromSystemSurveyData(data: SystemSurveyData): SystemSurvey {
  return {
    id: data.id,
    name: data.name,
    tenantId: data.tenant_id,
    questions: data.questions
      .map((q) => {
        const converter = QuestionTypeConvertersMap[q.type];
        if (!converter) {
          throw new Error(`Unexpected question type ${q.type}`);
        }
        return converter(q);
      })
      .sort((a, b) => a.position - b.position),
  };
}

const QuestionTypeConvertersMap: {
  [serverQuestionType: string]: (serverQuestion: SystemSurveyQuestionData) => SystemSurveyQuestion;
} = {
  text: textQuestionConverter,
  long_text: longTextQuestionConverter,
  multiple_choice: singleChoiceQuestionConverter,
  checkbox: multiChoiceQuestionConverter,
  0: singleChoiceQuestionConverter,
  1: textQuestionConverter,
  2: longTextQuestionConverter,
  3: multiChoiceQuestionConverter,
};

function baselineQuestionConverter(
  q: SystemSurveyQuestionData
): Pick<SystemSurveyQuestion, 'name' | 'text' | 'position' | 'displayConditions'> {
  return {
    name: q.name,
    text: q.text,
    position: q.position,
    displayConditions: (q.display_conditions || []).map((dc) => ({
      questionName: dc.question_name,
      answerIndices: dc.answer_indices,
    })),
  };
}

function longTextQuestionConverter(q: SystemSurveyQuestionData): SystemSurveyLongTextQuestion {
  return {
    ...baselineQuestionConverter(q),
    type: 'long-text',
  };
}

function singleChoiceQuestionConverter(
  q: SystemSurveyQuestionData
): SystemSurveySingleChoiceQuestion {
  return {
    ...baselineQuestionConverter(q),
    type: 'single-choice',
    options: q.options
      .map((o, index) => ({
        text: o.text,
        score: o.value,
        position: o.position,
        index,
      }))
      .sort((a, b) => a.position - b.position),
  };
}

function multiChoiceQuestionConverter(
  q: SystemSurveyQuestionData
): SystemSurveyMultiChoiceQuestion {
  return {
    ...baselineQuestionConverter(q),
    type: 'multi-choice',
    options: q.options
      .map((o, index) => ({
        text: o.text,
        score: o.value,
        position: o.position,
        index,
      }))
      .sort((a, b) => a.position - b.position),
  };
}

function textQuestionConverter(q: SystemSurveyQuestionData): SystemSurveyTextQuestion {
  const singleOption = q.options[0] || { input_type: 'default' };
  let inputProps;
  switch (singleOption.input_type) {
    case 'text':
      inputProps = {
        type: 'text' as const,
        placeholder: singleOption.text,
        defaultText: singleOption.input_default_text,
      };
      break;
    case 'keypad':
      inputProps = {
        type: 'keypad' as const,
        min: singleOption.input_min,
        max: singleOption.input_max,
        unit: singleOption.input_unit,
      };
      break;
    case 'picker':
      inputProps = {
        type: 'picker' as const,
        values: makeRange(
          singleOption.input_min,
          singleOption.input_max,
          singleOption.input_increment
        ),
        unit: singleOption.input_unit,
      };
      break;
    case 'time':
      inputProps = {
        type: 'time' as const,
        is24Hr: Boolean(singleOption.input_is_24_hr),
      };
      break;
    case 'date':
      inputProps = { type: 'date' as const };

      break;
    case 'default':
    // fallthrough
    default:
      inputProps = { type: 'default' as const };
      break;
  }

  return {
    ...baselineQuestionConverter(q),
    type: 'text',
    inputProps,
  };
}

function makeRange(start: number, end: number, increment: number) {
  const result = [];
  for (let i = start; i <= end; i += increment) {
    result.push(i);
  }
  return result;
}
