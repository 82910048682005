const audios: Map<string, HTMLAudioElement> = new Map();

export function playSound(src: string): Promise<void> {
  if (audios.has(src)) {
    const audio = audios.get(src)!;
    audio.currentTime = 0;
    return audio?.play();
  }

  const audio = new Audio(src);
  audio.volume = 0.75;
  audios.set(src, audio);
  return audio.play();
}
