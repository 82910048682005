import flatMap from 'lodash/flatMap';
import { CohortFilter } from 'models/cohorts';
import { UnitSystem } from 'utils/unitSystem';
import { diseasesListMap } from './analysis';

export const RECORDED_DATE_FORMAT = 'MMM d, yyyy';

export const diagnosesListMap: Record<string, string> = {
  anemia: 'Anemia',
  angina: 'Angina',
  atrial_fibrilation: 'Atrial Fibrillation',
  benign_prostatic_hyperplasia: 'Benign Prostatic Hyperplasia',
  bronchial_asthma: 'Bronchial Asthma',
  celiac_disease: 'Celiac Disease',
  chlamydia: 'Chlamydia',
  cholelithiasis: 'Cholelithiasis',
  chronic_liver_disease: 'Chronic Liver Disease',
  chronic_obstructive_pulmonary_disease: 'Chronic Obstructive Pulmonary Disease',
  coronary_heart_disease: 'Coronary Heart Disease',
  covid_19: 'Covid-19',
  depression: 'Depression',
  endometriosis: 'Endometriosis',
  generalised_anxiety_disorder: 'Generalised Anxiety Disorder',
  gestational_diabetes: 'Gestational Diabetes',
  gout: 'Gout',
  heart_failure: 'Heart Failure',
  hepatitis_b_c_d: 'Hepatitis [B, C, D]',
  hiv: 'HIV',
  hpv: 'HPV',
  hypercholesterolemia: 'Hypercholesterolemia',
  hypertension: 'Hypertension',
  hypotension: 'Hypotension',
  hypothyroidism: 'Hypothyroidism',
  inflammatory_bowel_disease: 'Inflammatory Bowel Disease',
  irritable_bowel_syndrome: 'Irritable Bowel Syndrome',
  kidney_disease_with_dialysis: 'Kidney disease',
  menopause: 'Menopause',
  obstructive_sleep_apnea: 'Obstructive Sleep Apnea',
  osteoporosis: 'Osteoporosis',
  panic_disorder: 'Panic Disorder',
  polycystic_ovarian_syndrome: 'Polycystic Ovarian Syndrome',
  previous_myocardial_infarction: 'Heart attack',
  psoriasis: 'Psoriasis',
  seasonal_allergies: 'Seasonal Allergies',
  stroke: 'Stroke',
  syphilis: 'Syphilis',
  type_2_diabetes: 'Type 2 Diabetes',
  uterine_fibroids: 'Uterine Fibroids',
  valvular_heart_disease: 'Valvular Heart Disease',
  vitamin_d_deficiency: 'Vitamin D Deficiency',
};

export const getDemographicFacts = (unitSystem: UnitSystem): CohortFilter[] =>
  [
    {
      base: 'age',
      columnName: 'Age',
      column: 'age',
      name: 'age',
      displayName: 'Age',
      type: 'measure' as const,
      range: [18, 120],
      precision: 1,
      units: 'y',
    },
    {
      base: 'sex',
      columnName: 'Sex',
      column: 'sex',
      name: 'sex',
      displayName: 'Sex: Female',
      type: 'value' as const,
      value: 'female',
    },
    {
      base: 'sex',
      columnName: 'Sex',
      column: 'sex',
      name: 'sex',
      displayName: 'Sex: Male',
      type: 'value' as const,
      value: 'male',
    },
    unitSystem.systemName === 'metric'
      ? {
          base: 'weight',
          columnName: 'Weight',
          column: 'weight',
          name: 'weight',
          displayName: 'Weight',
          type: 'measure' as const,
          range: [3, 500],
          precision: 1,
          units: 'kg',
          system: unitSystem.systemName,
        }
      : {
          base: 'weight',
          columnName: 'Weight',
          column: 'weight',
          name: 'weight',
          displayName: 'Weight',
          type: 'measure' as const,
          range: [5, 1100],
          precision: 5,
          units: 'lbs',
          system: unitSystem.systemName,
        },
    {
      base: 'bmi',
      columnName: 'BMI',
      column: 'bmi',
      name: 'bmi',
      displayName: 'BMI (Body Mass Index)',
      type: 'measure' as const,
      range: [5, 80],
      precision: 1,
    },
  ].map((it) => ({ ...it, category: 'Demographics' }));

export const getBiometricFacts = (unitSystem: UnitSystem): CohortFilter[] =>
  [
    {
      name: 'cardiovascular',
      displayName: 'Cardiovascular',
      nestedList: [
        {
          base: 'heart_rate',
          columnName: 'HR',
          column: 'heart_rate',
          name: 'heart_rate_mean_7d',
          displayName: 'Heart Rate 7d avg',
          type: 'measure' as const,
          range: [0, 300],
          precision: 1,
          category: 'Biometrics',
        },
        {
          base: 'rhr',
          columnName: 'RHR',
          column: 'rhr',
          name: 'rhr_mean_7d',
          displayName: 'RestingHR 7d avg',
          type: 'measure' as const,
          range: [0, 300],
          precision: 1,
          category: 'Biometrics',
        },
        {
          base: 'rmssd',
          columnName: 'HRV',
          column: 'rmssd',
          name: 'rmssd_mean_7d',
          displayName: 'HRV 7d avg',
          type: 'measure' as const,
          range: [0, 300],
          precision: 1,
          category: 'Biometrics',
        },
      ],
    },
    {
      name: 'movement',
      displayName: 'Movement',
      nestedList: [
        {
          base: 'steps',
          columnName: 'Steps',
          column: 'steps_sum_1d',
          name: 'steps_mean_7d',
          displayName: 'Step Count 7d avg',
          type: 'measure' as const,
          range: [0, 30000],
          precision: 100,
          category: 'Biometrics',
        },
        {
          base: 'distance',
          columnName: 'Distance',
          column: 'distance_sum_1d',
          name: 'distance_mean_7d',
          displayName: 'Distance 7d avg',
          type: 'measure' as const,
          range: [0, 10000],
          precision: 1,
          category: 'Biometrics',
          units: 'm',
        },
        {
          base: 'calories',
          columnName: 'Calories',
          column: 'calories_sum_1d',
          name: 'calories_mean_7d',
          displayName: 'Caloric Burn 7d avg',
          type: 'measure' as const,
          range: [0, 6000],
          precision: 1,
          category: 'Biometrics',
          units: 'cal',
        },
        {
          base: 'active_time',
          columnName: 'Active Time',
          column: 'active_time_sum_1d',
          name: 'active_time_mean_7d',
          displayName: 'Active Time 7d avg',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'longest_idle',
          columnName: 'Longest Idle 30d',
          column: 'longest_idle_30d',
          name: 'longest_idle_30d',
          displayName: 'Longest Idle 30d',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
      ],
    },
    {
      name: 'respiration',
      displayName: 'Respiration',
      nestedList: [
        {
          base: 'resp_rate',
          columnName: 'RR',
          column: 'resp_rate',
          name: 'resp_rate_mean_7d',
          displayName: 'Respiratory Rate 7d avg',
          type: 'measure' as const,
          range: [0, 40],
          precision: 1,
          category: 'Biometrics',
        },
        {
          base: 'spo2',
          columnName: 'Spo2',
          column: 'spo2',
          name: 'spo2_mean_7d',
          displayName: 'Oxygen Saturation 7d avg',
          type: 'measure' as const,
          range: [0, 1],
          precision: 0.01,
          category: 'Biometrics',
          units: '%',
        },
      ],
    },
    {
      name: 'skin_contact',
      displayName: 'Skin Contact',
      nestedList: [
        {
          base: 'gsr',
          columnName: 'GSR',
          column: 'gsr_last_90d',
          name: 'gsr_mean_7d',
          displayName: 'GSR 7d avg',
          type: 'measure' as const,
          range: [0, 15],
          precision: 0.5,
          category: 'Biometrics',
        },
        unitSystem.systemName === 'metric'
          ? {
              base: 'skin_temp',
              columnName: 'Skin Temp',
              column: 'skin_temp',
              name: 'skin_temp_mean_7d',
              displayName: 'Temperature 7d avg',
              type: 'measure' as const,
              range: [20, 43],
              precision: 1,
              category: 'Biometrics',
              units: '°C',
              system: unitSystem.systemName,
            }
          : {
              base: 'skin_temp',
              columnName: 'Skin Temp',
              column: 'skin_temp',
              name: 'skin_temp_mean_7d',
              displayName: 'Temperature 7d avg',
              type: 'measure' as const,
              range: [68, 110],
              precision: 1,
              category: 'Biometrics',
              units: '°F',
              system: unitSystem.systemName,
            },
      ],
    },
    {
      name: 'sleep_consistency',
      displayName: 'Sleep Consistency',
      nestedList: [
        {
          base: 'sleep_time_onset',
          columnName: 'Sleep Start',
          column: 'sleep_time_onset_24h',
          name: 'sleep_time_onset_30d',
          displayName: 'Sleep Start 30d avg',
          type: 'measure' as const,
          range: [0, 86340],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'sleep_time_final_awekening',
          columnName: 'Sleep End',
          column: 'sleep_time_final_awekening_24h',
          name: 'sleep_time_final_awekening_30d',
          displayName: 'Sleep End 30d avg',
          type: 'measure' as const,
          range: [0, 86340],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'sleep_awakenings',
          columnName: 'Sleep Awakenings',
          column: 'sleep_awakenings_mean_24h',
          name: 'sleep_awakenings_mean_30d',
          displayName: 'Awakenings 30d avg',
          type: 'measure' as const,
          range: [0, 20],
          precision: 1,
          category: 'Biometrics',
        },
        {
          base: 'sleep_time_to_sleep',
          columnName: 'Sleep Latency',
          column: 'sleep_time_to_sleep_mean_24h',
          name: 'sleep_time_to_sleep_mean_30d',
          displayName: 'Sleep Latency 30d avg',
          type: 'measure' as const,
          range: [0, 3600],
          precision: 60,
          category: 'Biometrics',
        },
        {
          base: 'sleep_efficiency',
          columnName: 'Sleep %',
          column: 'sleep_efficiency_last_14d',
          name: 'sleep_efficiency_mean_30d',
          displayName: 'Sleep Efficiency 30d avg',
          type: 'measure' as const,
          range: [0, 100],
          precision: 1,
          category: 'Biometrics',
          units: '%',
        },
      ],
    },
    {
      name: 'sleep_duration',
      displayName: 'Sleep Duration',
      nestedList: [
        {
          base: 'sleep_duration',
          columnName: 'Sleep',
          column: 'sleep_duration_mean_24h',
          name: 'sleep_duration_mean_7d',
          displayName: 'Sleep Total 7d avg',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'sleep_rem',
          columnName: 'Sleep REM',
          column: 'sleep_rem_mean_24h',
          name: 'sleep_rem_mean_30d',
          displayName: 'Sleep REM 30d avg',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'sleep_deep',
          columnName: 'Sleep Deep',
          column: 'sleep_deep_mean_24h',
          name: 'sleep_deep_mean_30d',
          displayName: 'Sleep Deep 30d avg',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'sleep_light',
          columnName: 'Sleep Light',
          column: 'sleep_light_mean_24h',
          name: 'sleep_light_mean_30d',
          displayName: 'Sleep Light 30d avg',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
        {
          base: 'sleep_awake',
          columnName: 'Sleep Awake',
          column: 'sleep_awake_mean_24h',
          name: 'sleep_awake_mean_30d',
          displayName: 'Sleep Awake 30d avg',
          type: 'measure' as const,
          range: [0, 86400],
          precision: 1800,
          category: 'Biometrics',
        },
      ],
    },
  ].map((it) => ({ ...it, category: 'Biometrics' }));

export const DIAGNOSE_FACTS: CohortFilter[] = Object.entries(diagnosesListMap).map(
  ([key, value]) => ({
    name: `e_${key}`,
    displayName: value,
    type: 'value' as const,
    value: 'yes',
    category: 'Diagnoses',
  })
);

export const DISEASE_RISK_FACTS: CohortFilter[] = Object.entries(diseasesListMap).map(
  ([key, value]) => ({
    name: `relative_population_${key}`,
    displayName: value,
    type: 'measure' as const,
    range: [0.1, 10],
    precision: 0.1,
    category: 'Disease Risk',
    units: 'x',
  })
);

export const getFilterFacts = (unitSystem: UnitSystem) => ({
  DEMOGRAPHIC_FACTS: getDemographicFacts(unitSystem),
  BIOMETRIC_FACTS: getBiometricFacts(unitSystem),
  DIAGNOSE_FACTS,
  DISEASE_RISK_FACTS,
});

export const getAllFacts = (unitSystem: UnitSystem) =>
  [
    ...getDemographicFacts(unitSystem),
    ...flatMap(
      getBiometricFacts(unitSystem).map((categoryFact: CohortFilter) => categoryFact.nestedList)
    ),
    ...DIAGNOSE_FACTS,
    ...DISEASE_RISK_FACTS,
  ] as CohortFilter[];

export const SIDE_BAR_PATIENT_FACTS = [
  'account_age_day', //needed to get compliance correctly
  'heart_rate_last_12h',
  'heart_rate_max_12h',
  'heart_rate_min_12h',
  'heart_rate_last_state',
  'heart_rate_last_30d',
  'heart_rate_max_30d',
  'heart_rate_min_30d',
  'heart_rate_updated_30d',

  'sleep_rmssd_last_3d',
  'sleep_rmssd_min_3d',
  'sleep_rmssd_max_3d',
  'sleep_rmssd_last_state',
  'sleep_rmssd_last_30d',
  'sleep_rmssd_min_30d',
  'sleep_rmssd_max_30d',
  'sleep_rmssd_updated_30d',

  'rmssd_last_3d',
  'rmssd_min_3d',
  'rmssd_max_3d',
  'rmssd_last_state',
  'rmssd_last_30d',
  'rmssd_min_30d',
  'rmssd_max_30d',
  'rmssd_updated_30d',

  'rhr_last_7d',
  'rhr_min_7d',
  'rhr_max_7d',
  'rhr_last_30d',
  'rhr_min_30d',
  'rhr_max_30d',
  'rhr_last_state',
  'rhr_updated_30d',

  'sleep_efficiency_last_1d',
  'sleep_efficiency_min_1d',
  'sleep_efficiency_max_1d',
  'sleep_efficiency_last_30d',
  'sleep_efficiency_min_30d',
  'sleep_efficiency_max_30d',
  'sleep_efficiency_last_state',
  'sleep_efficiency_updated_30d',

  'skin_temp_last_12h',
  'skin_temp_min_12h',
  'skin_temp_max_12h',
  'skin_temp_last_30d',
  'skin_temp_updated_30d',
  'skin_temp_last_state',
  'skin_temp_min_30d',
  'skin_temp_max_30d',

  'sleep_spo2_last_3d',
  'sleep_spo2_last_30d',
  'sleep_spo2_updated_30d',
  'sleep_spo2_last_state',
  'sleep_spo2_min_3d',
  'sleep_spo2_max_3d',
  'sleep_spo2_min_30d',
  'sleep_spo2_max_30d',

  'spo2_last_3d',
  'spo2_last_30d',
  'spo2_updated_30d',
  'spo2_last_state',
  'spo2_min_3d',
  'spo2_max_3d',
  'spo2_min_30d',
  'spo2_max_30d',

  'sleep_resp_rate_last_12h',
  'sleep_resp_rate_min_12h',
  'sleep_resp_rate_max_12h',
  'sleep_resp_rate_last_30d',
  'sleep_resp_rate_updated_30d',
  'sleep_resp_rate_state',
  'sleep_resp_rate_min_30d',
  'sleep_resp_rate_max_30d',

  'resp_rate_last_12h',
  'resp_rate_min_12h',
  'resp_rate_max_12h',
  'resp_rate_last_30d',
  'resp_rate_updated_30d',
  'resp_rate_state',
  'resp_rate_min_30d',
  'resp_rate_max_30d',

  'steps_sum_1d',
  'steps_mean_30d',
  'active_time_mean_1d',
  'active_time_mean_30d',
  'steps_updated_30d',

  'systolic_last_90d',
  'systolic_last_180d',
  'systolic_updated_90d',
  'systolic_updated_180d',
  'diastolic_last_90d',
  'diastolic_last_180d',
  'diastolic_updated_90d',
  'diastolic_updated_180d',
  'blood_pressure_last_state',

  'glucose_last_1d',
  'glucose_last_30d',
  'glucose_mean_1d',
  'glucose_mean_30d',
  'glucose_last_state',
  'glucose_updated_30d',
];
