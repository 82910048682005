export const loadState = (stateName: string, type: 'STRING' | 'OBJECT' = 'OBJECT') => {
  try {
    const serializedState = localStorage.getItem(stateName);
    if (serializedState === null) {
      return undefined;
    }
    if (type === 'STRING') {
      return serializedState;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const saveState = (stateName: string, state: unknown) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(stateName, serializedState);
  } catch (error) {
    console.error(error);
  }
};
