import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  menu: {
    maxWidth: '30rem',
    maxHeight: '80vh',
    zIndex: theme.zIndex.tooltip,
    '& .MuiPaper-root': {
      minWidth: 'unset',
      color: theme.palette.custom.greyE,
      backgroundColor: theme.palette.custom.black,
      borderRadius: 'unset',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      padding: 'unset',
      [theme.breakpoints.up('md')]: {
        minWidth: '200px',
      },
    },
  },
  button: {
    color: theme.palette.custom.white40,
    '&:hover': {
      backgroundColor: theme.palette.custom.black,
      color: theme.palette.custom.greyE,
    },
    '&.active': {
      backgroundColor: theme.palette.custom.greyD,
      color: theme.palette.custom.blackFill,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '200px',
    },
  },
  icon: {
    fontSize: '30px!important',
    color: theme.palette.custom.white40,
    marginTop: '-4px',
  },
  menuItem: {
    width: '100%',
    color: theme.palette.custom.white40,
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'inherit!important',
    },
  },
  openMenu: {
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.white40,
    [theme.breakpoints.up('md')]: {
      minWidth: '200px',
    },
  },
}));

export default useStyles;
