import { combineReducers } from '@reduxjs/toolkit';

import accountReducer from './account';
import adminReducer from './admin';
import authReducer from './auth';
import callsReducer from './calls';
import messagingReducer from './messaging';
import notesReducer from './notes';
import notificationsReducer from './notifications';
import panelReducer from './panel';
import patientReducer from './patient';
import patientTabsReducer from './patientTabs';
import snackbarReducer from './snackbar';
import cohortsReducer from './cohorts';
import programsReducer from './programs';
import reportsReducer from './reports';

import { activitiesApi } from 'services/activities';
import { adminApi } from 'services/admin';
import { analyticsApi } from 'services/analytics';
import { calendarApi } from 'services/calendar';
import { configuratorApi } from 'services/configurator';
import { eventsProcessorApi } from 'services/eventsProcessor';
import { factuaryApi } from 'services/factuary';
import { filesApi } from 'services/files';
import { foodloggingApi } from 'services/foodlogging';
import { loginApi } from 'services/login';
import { notesApi } from 'services/notes';
import { panelApi } from 'services/panel';
import { registryApi } from 'services/registry/index';
import { userApi } from 'services/user';
import { userReportsApi } from 'services/userReports';
import { cohortApi } from 'services/cohorts';
import { fhirApi } from 'services/fhir';

const rootReducer = combineReducers({
  [activitiesApi.reducerPath]: activitiesApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [configuratorApi.reducerPath]: configuratorApi.reducer,
  [eventsProcessorApi.reducerPath]: eventsProcessorApi.reducer,
  [factuaryApi.reducerPath]: factuaryApi.reducer,
  [cohortApi.reducerPath]: cohortApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [foodloggingApi.reducerPath]: foodloggingApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [notesApi.reducerPath]: notesApi.reducer,
  [panelApi.reducerPath]: panelApi.reducer,
  [registryApi.reducerPath]: registryApi.reducer,
  [userReportsApi.reducerPath]: userReportsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [fhirApi.reducerPath]: fhirApi.reducer,
  account: accountReducer,
  admin: adminReducer,
  auth: authReducer,
  calls: callsReducer,
  messaging: messagingReducer,
  notes: notesReducer,
  notifications: notificationsReducer,
  panel: panelReducer,
  patientTabs: patientTabsReducer,
  patient: patientReducer,
  snackbar: snackbarReducer,
  cohorts: cohortsReducer,
  programs: programsReducer,
  reports: reportsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
