import {
  BandRemovedTypes,
  DateRange,
  Metric,
  MetricChartType,
  RequestMetric,
  SleepDepthTypes,
} from 'models/generatedData';
import { getCurrentUnitSystem } from 'utils/staticUnitSystem';

export const factsMetrics: Partial<Record<Metric, string[]>> = {
  sleep: ['sleep_efficiency_mean'],
  steps: ['steps_mean', 'active_time_mean'],
  heart_rate: ['heart_rate_mean', 'heart_rate_min', 'heart_rate_max'],
  rhr: ['rhr_mean', 'rhr_min', 'rhr_max'],
  rmssd: ['rmssd_mean', 'rmssd_min', 'rmssd_max'],
  resp_rate: ['resp_rate_mean', 'resp_rate_min', 'resp_rate_max'],
  temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  glucose: ['glucose_mean', 'glucose_min', 'glucose_max'],
  weight: ['weight_mean', 'weight_min', 'weight_max'],
  spo2: ['spo2_mean', 'spo2_min', 'spo2_max'],
  gsr: ['gsr_mean', 'gsr_min', 'gsr_max'],
  insulin: ['bolus_mean', 'basal_mean'],
  blood_pressure: ['systolic_mean', 'diastolic_mean'],
  battery_level: ['battery_level_mean', 'battery_level_min', 'battery_level_max'],
  calories: ['calories_mean'],
};

export const factsAvgMetrics: Partial<Record<Metric, string[]>> = {
  sleep: ['sleep_efficiency_mean'],
  steps: ['steps_mean'],
  heart_rate: ['heart_rate_mean'],
  rhr: ['rhr_mean'],
  rmssd: ['rmssd_mean'],
  resp_rate: ['resp_rate_mean'],
  temp: ['skin_temp_mean'],
  glucose: ['glucose_mean'],
  weight: ['weight_mean'],
  spo2: ['spo2_mean'],
  gsr: ['gsr_mean'],
  insulin: ['bolus_mean', 'basal_mean'],
  blood_pressure: ['systolic_mean', 'diastolic_mean'],
  battery_level: ['battery_level_mean'],
  calories: ['calories_mean'],
};

export const factsCore: Partial<Record<Metric, string[]>> = {
  sleep: ['sleep_efficiency'],
  steps: ['steps', 'active_time'],
  heart_rate: ['heart_rate'],
  rhr: ['rhr'],
  rmssd: ['rmssd'],
  resp_rate: ['resp_rate'],
  temp: ['skin_temp'],
  glucose: ['glucose'],
  weight: ['weight'],
  spo2: ['spo2'],
  gsr: ['gsr'],
  insulin: ['bolus', 'basal'],
  blood_pressure: ['systolic', 'diastolic'],
  battery_level: ['battery_level'],
  calories: ['calories'],
};

export const factsSuffix: Record<DateRange, string> = {
  1: '1d',
  7: '7d',
  30: '30d',
  365: '1y',
};

export const simpleRequestMetrics: Record<Metric, RequestMetric[]> = {
  sleep: ['sleep'],
  steps: ['steps'],
  heart_rate: ['heart_rate'],
  rmssd: ['rmssd'],
  rhr: ['rhr'],
  resp_rate: ['resp_rate'],
  temp: ['ambient_temp', 'skin_temp'],
  glucose: ['glucose'],
  weight: ['weight'],
  spo2: ['spo2'],
  gsr: ['gsr'],
  band_removed: ['band_removed', 'band_charge', 'band_wearing'],
  insulin: ['bolus', 'basal'],
  blood_pressure: ['systolic', 'diastolic'],
  meals: ['meals'],
  workouts: ['workouts'],
  medications: ['medications'],
  battery_level: ['battery_level'],
  calories: ['calories'],
};

export const requestMetrics: Record<RequestMetric, string[]> = {
  sleep: ['sleep_efficiency_mean'],
  steps: ['steps_sum'],
  heart_rate: ['heart_rate_mean', 'heart_rate_min', 'heart_rate_max'],
  rhr: ['rhr_mean'],
  rmssd: ['rmssd_mean', 'rmssd_min', 'rmssd_max'],
  resp_rate: ['resp_rate_mean', 'resp_rate_min', 'resp_rate_max'],
  ambient_temp: ['ambient_temp_mean', 'ambient_temp_min', 'ambient_temp_max'],
  skin_temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  glucose: ['glucose_mean', 'glucose_min', 'glucose_max'],
  weight: ['weight_mean', 'weight_min', 'weight_max'],
  spo2: ['spo2_mean', 'spo2_min', 'spo2_max'],
  gsr: ['gsr_mean', 'gsr_min', 'gsr_max'],
  band_removed: ['band_removed_time'],
  band_charge: ['band_charge_time'],
  band_wearing: ['band_wearing_time'],
  bolus: ['bolus_sum'],
  basal: ['basal_sum'],
  systolic: ['systolic_mean', 'systolic_min', 'systolic_max'],
  diastolic: ['diastolic_mean', 'diastolic_min', 'diastolic_max'],
  systolic_mean: ['systolic_mean', 'systolic_min', 'systolic_max'],
  diastolic_mean: ['diastolic_mean', 'diastolic_min', 'diastolic_max'],
  meals: ['meals'],
  workouts: ['workouts'],
  medications: ['medications'],
  battery_level: ['battery_level_mean', 'battery_level_min', 'battery_level_max'],
  calories: ['calories_sum'],
};

export const requestYearMetrics: Record<RequestMetric, string[]> = {
  sleep: ['sleep_efficiency_mean'],
  steps: ['steps_mean'],
  heart_rate: ['heart_rate_mean', 'heart_rate_min', 'heart_rate_max'],
  rhr: ['rhr_mean'],
  rmssd: ['rmssd_mean', 'rmssd_min', 'rmssd_max'],
  resp_rate: ['resp_rate_mean', 'resp_rate_min', 'resp_rate_max'],
  ambient_temp: ['ambient_temp_mean', 'ambient_temp_min', 'ambient_temp_max'],
  skin_temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  glucose: ['glucose_mean', 'glucose_min', 'glucose_max'],
  weight: ['weight_mean', 'weight_min', 'weight_max'],
  spo2: ['spo2_mean', 'spo2_min', 'spo2_max'],
  gsr: ['gsr_mean', 'gsr_min', 'gsr_max'],
  band_removed: ['band_removed_time_mean'],
  band_charge: ['band_charge_time_mean'],
  band_wearing: ['band_wearing_time_mean'],
  bolus: ['bolus_mean'],
  basal: ['basal_mean'],
  systolic: ['systolic_mean', 'systolic_min', 'systolic_max'],
  diastolic: ['diastolic_mean', 'diastolic_min', 'diastolic_max'],
  systolic_mean: ['systolic_mean', 'systolic_min', 'systolic_max'],
  diastolic_mean: ['diastolic_mean', 'diastolic_min', 'diastolic_max'],
  meals: ['meals'],
  workouts: ['workouts'],
  medications: ['medications'],
  battery_level: ['battery_level_mean', 'battery_level_min', 'battery_level_max'],
  calories: ['calories_mean'],
};

export const composedCharts: any = {
  temp: ['ambient_temp', 'skin_temp'],
  insulin: ['bolus', 'basal'],
  band_removed: ['band_removed', 'band_charge', 'band_wearing'],
};

export const parentMetric: any = {
  ambient_temp: 'temp',
  skin_temp: 'temp',
  bolus: 'insulin',
  basal: 'insulin',
  band_removed: 'band_removed',
  band_wearing: 'band_removed',
  band_charge: 'band_removed',
};

export const metricChartType: MetricChartType = {
  sleep: 'sleepDepth',
  steps: 'basicBar',
  heart_rate: 'basicLine',
  rhr: 'basicLine',
  rmssd: 'basicLine',
  resp_rate: 'basicLine',
  temp: 'composedLine',
  glucose: 'basicLine',
  weight: 'basicLine',
  spo2: 'basicLine',
  gsr: 'basicLine',
  band_removed: 'bandLine',
  insulin: 'composedBar',
  blood_pressure: 'bloodPressure',
  meals: 'mealsDots',
  workouts: 'logDots',
  medications: 'logDots',
  battery_level: 'basicLine',
  calories: 'basicBar',
};

export const metricsColor: any = {
  sleep: '#7169E0',
  sleep_efficiency: '#7169E0',
  steps: '#F57558',
  active_time: '#F57558',
  heart_rate: '#E35B88',
  rmssd: '#DB4FB6',
  rhr: '#A04DDB',
  resp_rate: '#00B5D1',
  ambient_temp: '#F5AF00',
  skin_temp: '#DB8F37',
  temp: '#DB8F37',
  glucose: '#1692C7',
  weight: '#00A189',
  bmi: '#00A189',
  spo2: '#0090C9',
  gsr: '#DB7337',
  band_removed: '#E66067',
  band_charge: '#F0A0CC',
  band_wearing: '#00C4BB',
  insulin: '#02C0C7',
  bolus: '#00A68A',
  basal: '#02C0C7',
  blood_pressure: '#6D54D1',
  systolic: '#6D54D1',
  diastolic: '#6D54D1',
  meals: '#30BA61',
  workouts: '#9ABB9D',
  medications: '#BACCF1',
  battery_level: '#B3965C',
  calories: '#C8102E',
  sleep_deep: '#7765C2',
  sleep_rem: '#3889C7',
  sleep_light: '#00B9D1',
  sleep_awake: '#FCB500',
};

export const dotColors: any = {
  sleep_deep_mean: '#7765C2',
  sleep_rem_mean: '#3889C7',
  sleep_light_mean: '#00B9D1',
  sleep_awake_mean: '#FCB500',
  band_removed_time_mean: '#E66067',
  band_wearing_time_mean: '#00C4BB',
  band_charge_time_mean: '#F0A0CC',
};

export const sleepStagesColors: any = {
  0: '#3882C7',
  1: '#389EC7',
  2: '#10B7C9',
  3: '#FAC919',
};

export const tooltipSleepStagesColors: any = {
  1: '#FAC919',
  2: '#389EC7',
  3: '#10B7C9',
  4: '#3882C7',
};

export const bandRemovedColors: any = {
  1: '#E66067',
  2: '#F0A0CC',
  3: '#00C4BB',
};

export const getMetricsUnits = (): Record<string, string> => {
  const unitSystem = getCurrentUnitSystem();
  return {
    sleep: '',
    steps: 'steps',
    heart_rate: 'bpm',
    rhr: 'bpm',
    rmssd: 'ms',
    resp_rate: 'br/min',
    temp: unitSystem.temperatureSymbol,
    ambient_temp: unitSystem.temperatureSymbol,
    skin_temp: unitSystem.temperatureSymbol,
    glucose: 'mg/dL',
    weight: unitSystem.weightSymbol,
    spo2: '%',
    gsr: 'µS',
    band_removed: '',
    band_wearing: '',
    band_charge: '',
    insulin: 'U',
    bolus: 'U',
    basal: 'U',
    blood_pressure: 'mmHg',
    meals: '',
    workouts: '',
    medications: '',
    battery_level: '%',
    calories: 'cal',
  };
};

export const metricName: Record<Metric, string> = {
  sleep: 'Sleep',
  steps: 'Movement',
  heart_rate: 'Heart Rate',
  rhr: 'Resting Heart Rate',
  rmssd: 'Heart Rate Variability',
  resp_rate: 'Respiration Rate',
  temp: 'Temperature',
  glucose: 'Blood Glucose',
  weight: 'Weight',
  spo2: 'SpO2',
  gsr: 'GSR',
  band_removed: 'Band Status',
  insulin: 'Insulin',
  blood_pressure: 'Blood Pressure',
  meals: 'Meals',
  workouts: 'Workouts',
  medications: 'Medications',
  battery_level: 'Battery Level',
  calories: 'Calories',
};

export const tooltipMetricName: Record<string, string> = {
  sleep: 'Sleep Efficiency',
  sleep_efficiency: 'Sleep Efficiency',
  steps: 'Steps',
  heart_rate: 'HR',
  rhr: 'RHR',
  rmssd: 'HRV',
  resp_rate: 'RR',
  ambient_temp: 'Ambient Temp',
  skin_temp: 'Skin Temp',
  glucose: 'BG',
  weight: 'Weight',
  spo2: 'SpO2',
  gsr: 'GSR',
  band_removed: 'Removed',
  band_wearing: 'Wearing',
  band_charge: 'Charging',
  bolus: 'Bolus',
  basal: 'Basal',
  systolic: 'SYS',
  diastolic: 'DIAS',
  meals: 'Meals',
  workouts: 'Workouts',
  medications: 'Medications',
  battery_level: 'Battery Level',
  calories: 'Calories',
};

export const dateRangePagination: Record<DateRange, number> = {
  1: 1,
  7: 1,
  30: 7,
  365: 30,
};

export const dateRangePaginationTitle: Record<DateRange, string> = {
  1: '1 day',
  7: '1 day',
  30: '1 week',
  365: '1 month',
};

export const bandStates: BandRemovedTypes = {
  1: 'Removed',
  2: 'Charging',
  3: 'Wearing',
};

export const sleepDepthLevels: SleepDepthTypes = {
  1: 'Awake',
  2: 'Light',
  3: 'REM',
  4: 'Deep',
};

export const factsGranularity: Record<DateRange, string> = {
  1: 'HOURLY',
  7: 'DAILY',
  30: 'DAILY',
  365: 'MONTHLY',
};

export const xAxisLabelFormat: Record<DateRange, string> = {
  1: 'haaa',
  7: 'MMM dd',
  30: 'MMM dd',
  365: 'MMM',
};

export const minMetricValue: Partial<Record<Metric, number>> = {
  sleep: 60,
  steps: 0,
  heart_rate: 35,
  rhr: 30,
  rmssd: 20,
  resp_rate: 10,
  temp: 30,
  glucose: 80,
  weight: 100,
  spo2: 50,
  gsr: 2,
  band_removed: 0,
  insulin: 0,
  blood_pressure: 40,
  battery_level: 50,
  calories: 0,
};

export const maxMetricValue: Partial<Record<Metric, number>> = {
  sleep: 100,
  steps: 1000,
  heart_rate: 230,
  rhr: 120,
  rmssd: 250,
  resp_rate: 40,
  temp: 44,
  glucose: 180,
  weight: 260,
  spo2: 100,
  gsr: 5,
  band_removed: 86400,
  insulin: 100,
  blood_pressure: 180,
  battery_level: 100,
  calories: 1000,
};

export const mealDefaultTime: Record<string, string> = {
  BREAKFAST: '09:00',
  LUNCH: '12:00',
  DINNER: '19:00',
  SNACK: '16:00',
  DESSERT: '16:00',
  BEVERAGE: '16:00',
};

export const mealsTypes: Record<string, string> = {
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
  SNACK: 'SNACK',
  DESSERT: 'SNACK',
  BEVERAGE: 'SNACK',
  JACKET: 'SNACK',
};

export const medicationPeriod: Record<string, string> = {
  oncePerDay: '1x per day',
  twicePerDay: '2x per day',
  threeTimes: '3x per day',
  asNeeded: 'As needed',
  other: 'Other',
};
