import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { AxiosRequestConfig } from 'axios';
import { RadiusApiError } from 'services/base';
import { AccountStatus } from 'models/user';
import { MemberListResponse } from 'models/memberList';

type baseQueryType = (
  arg: AxiosRequestConfig
) => MaybePromise<QueryReturnValue<unknown, RadiusApiError, {}>>;

interface AppComplianceRequestData {
  queryParams: {
    isClinician?: boolean;
    userIds?: string[];
    userStatus?: AccountStatus;
  };
  baseQuery: baseQueryType;
}

const INITIAL_REQUEST_SIZE = 1;

const fetchUsers = async ({ queryParams, baseQuery }: AppComplianceRequestData) => {
  const params = new URLSearchParams();
  const size = queryParams.userIds ? queryParams.userIds.length : INITIAL_REQUEST_SIZE;
  params.append('teamType', queryParams.isClinician ? 'CLINICIAN' : 'PATIENT');
  params.append('size', `${size}`);

  if (queryParams.userIds?.length) {
    params.append('userIds', `${queryParams.userIds.join(',')}`);
  }

  if (queryParams.userStatus) {
    params.append('userStatus', `${queryParams.userStatus}`);
  }

  const usersResponse = await baseQuery({
    url: `/registry/api/v1/users?${params.toString()}`,
    method: 'GET',
  });

  if (usersResponse?.data) {
    const users = usersResponse?.data as MemberListResponse;

    if (users?.totalElements > size) {
      params.delete('size');
      params.append('size', `${users.totalElements}`);
      const allUsersResponse = await baseQuery({
        url: `/registry/api/v1/users?${params.toString()}`,
        method: 'GET',
      });
      if (allUsersResponse?.data) {
        const allUsers = allUsersResponse?.data as MemberListResponse;
        return allUsers.content;
      }
      return [];
    }

    return users.content;
  }
  return [];
};

export default fetchUsers;
