import { createTheme, Theme } from '@mui/material/styles';

export const configureMenus = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              padding: '12px',
              minWidth: '240px',
              borderRadius: '6px',
              boxShadow: '0px 12px 24px -4px rgba(80, 86, 97, 0.2)',
              '& .MuiMenu-list': {
                padding: 0,
              },
              '& .MuiMenuItem-root': {
                ...theme.typography.para12,
                padding: '9px',
                borderRadius: '3px',
                color: theme.palette.text.primary,
                '& > svg': {
                  marginRight: '7px',
                  color: theme.palette.text.primary,
                },
                '&:hover': {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.action.hover,
                },
                '&:active, &.Mui-selected': {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.action.selected,
                },
              },
            },
          },
        },
      },
    },
  });
