import { FeatureGroup } from './context';
import useFeatureFlags from './useFeatureFlags';

function useFeatureFlag(name: string): boolean | FeatureGroup {
  const features = useFeatureFlags();
  if (Array.isArray(features)) return features.includes(name);
  if (typeof features[name] === 'boolean') return features[name];
  return name.split('/').reduce<FeatureGroup | boolean>((featureGroup, featureName: string) => {
    if (typeof featureGroup === 'boolean') return featureGroup;
    if (featureGroup[featureName] === undefined) return false;
    return featureGroup[featureName];
  }, features);
}

export default useFeatureFlag;
