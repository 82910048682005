import { createApi } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';

import { Activities, ActivityDay } from 'models/activities';
import { apiBaseQuery } from 'services/base';

export interface QueryParams {
  userId: string;
  startDate: string;
  endDate: string;
}

export interface TicksQueryParams {
  userId: string;
  tz: string;
  from: string;
  to: string;
  time: string;
  weight: number;
}

export const activitiesApi = createApi({
  reducerPath: 'activitiesApi',
  baseQuery: apiBaseQuery('/activities/api/v1'),
  tagTypes: ['Activities'],
  endpoints(builder) {
    return {
      fetchPatientActivitiesCompliance: builder.query<ActivityDay[], QueryParams>({
        query: (queryParams) => ({
          url:
            `/activities/${queryParams.userId}/?from=${queryParams?.startDate}&to=${queryParams?.endDate}` +
            '&activityTypes=LOGGING_MEDICATION,LOGGING_FOOD&size=28',
          method: 'GET',
        }),
        providesTags: ['Activities'],
        transformResponse: (data: Activities) => {
          return data.content.filter((item: ActivityDay) => {
            return !isEmpty(item.activities);
          });
        },
      }),
      logWeightActivity: builder.mutation<string, TicksQueryParams>({
        query: (queryParams) => ({
          method: 'POST',
          url: `/${queryParams.userId}/activities?from=${queryParams.from}&to=${queryParams.to}&tz=${queryParams.tz}`,
          data: {
            status: 'DONE',
            contentType: 'ACTIVITY',
            activityType: 'LOGGING_WEIGHT',
            schedule: 'ONCE',
            activityDetails: { weight: queryParams.weight },
            title: 'Weight',
            when: { dayPart: 'SPECIFIC_TIME', time: queryParams.time },
          },
        }),
      }),
    };
  },
});

export const {
  useFetchPatientActivitiesComplianceQuery,
  useLazyFetchPatientActivitiesComplianceQuery,
  useLogWeightActivityMutation,
} = activitiesApi;
