import { PaletteOptions, PaletteMode } from '@mui/material';
import { LIGHT_MODE } from '@constants/global';
import variables from './variables.module.scss';

export const createAppPalette: (mode: PaletteMode) => PaletteOptions = (mode) => {
  return {
    mode,
    primary: {
      main: variables.cyan,
      light: variables.lightCyan,
    },
    secondary: {
      main: variables.red,
      light: variables.lightRed,
    },
    ...(mode === LIGHT_MODE
      ? {
          text: {
            primary: variables.black,
            secondary: variables.black60,
            disabled: variables.black40,
          },
          background: {
            default: variables.white,
            paper: variables.white,
          },
          action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            selected: 'rgba(0, 0, 0, 0.08)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
          },
          divider: variables.greyC,
          customBackground: {
            aside: variables.greyA,
            input: variables.greyB,
          },
        }
      : {
          text: {
            primary: variables.white,
            secondary: variables.white60,
            disabled: variables.white40,
          },
          background: {
            default: variables.blackFill,
            paper: variables.blackFill,
          },
          action: {
            active: variables.white,
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
          },
          divider: 'rgba(255, 255, 255, 0.12)',
          customBackground: {
            aside: variables.darkBlackFill,
            input: 'rgba(255, 255, 255, 0.12)',
          },
        }),
    custom: {
      cyan: variables.cyan,
      red: variables.red,
      white: variables.white,
      white20: variables.white20,
      white40: variables.white40,
      white60: variables.white60,
      white80: variables.white80,
      black: variables.black,
      black80: variables.black80,
      black60: variables.black60,
      black40: variables.black40,
      black20: variables.black20,
      black10: variables.black10,
      blackFill: variables.blackFill,
      blue: variables.blue,
      brown: variables.brown,
      cerulean: variables.cerulean,
      gold: variables.gold,
      green: variables.green,
      orange: variables.orange,
      magenta: variables.magenta,
      pink: variables.pink,
      purple: variables.purple,
      yellow: variables.yellow,
      gradientBlack: variables.gradientBlack,
      gradientBlue: variables.gradientBlue,
      gradientCyan: variables.gradientCyan,
      gradientGold: variables.gradientGold,
      gradientGreen: variables.gradientGreen,
      gradientRed: variables.gradientRed,
      greyA: variables.greyA,
      greyB: variables.greyB,
      greyC: variables.greyC,
      greyD: variables.greyD,
      greyE: variables.greyE,
      greyF: variables.greyF,
      lightBlue: variables.lightBlue,
      lightBrown: variables.lightBrown,
      lightCerulean: variables.lightCerulean,
      lightCyan: variables.lightCyan,
      lightGold: variables.lightGold,
      lightGreen: variables.lightGreen,
      lightOrange: variables.lightOrange,
      lightMagenta: variables.lightMagenta,
      lightPink: variables.lightPink,
      lightPurple: variables.lightPurple,
      lightRed: variables.lightRed,
      lightYellow: variables.lightYellow,
      darkLightCyan: variables.darkLightCyan,
      cyan15: variables.cyan15,
      cyan20: variables.cyan20,
      cyan40: variables.cyan40,
      gold20: variables.gold20,
      green20: variables.green20,
      green40: variables.green40,
      red5: variables.red5,
      red20: variables.red20,
      red40: variables.red40,
      purple5: variables.purple5,
      purple20: variables.purple20,
      purple40: variables.purple40,
      magenta5: variables.magenta5,
      blue5: variables.blue5,
    },
  };
};
