import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSbCalls } from 'hooks/sbCalls';
import { StatefulDirectCall } from 'models/calls';
import { useMediaPortalContext } from './MediaPortalContext';

const RemoteAudioPortal: React.FC = () => {
  const { remoteAudioPortalDestination } = useMediaPortalContext();
  const { calls } = useSbCalls();
  const ongoingCall = calls.find((call) => call.isOngoing) as StatefulDirectCall | undefined;

  const remoteAudioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (ongoingCall) {
      ongoingCall.setRemoteMediaView(remoteAudioRef.current as HTMLAudioElement);
    }

    return () => {
      if (ongoingCall) {
        ongoingCall.setRemoteMediaView(null as any);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingCall?.callId]);

  if (!ongoingCall || ongoingCall.isVideoCall) {
    return null;
  }

  const portalDestination =
    remoteAudioPortalDestination || document.getElementById('default-remote-audio-container');
  if (!portalDestination) return null;

  return ReactDOM.createPortal(
    <audio ref={remoteAudioRef} autoPlay playsInline muted={false} style={{ display: 'block' }} />,
    portalDestination
  );
};

export default RemoteAudioPortal;
