import React, { createContext, useContext, useState } from 'react';

interface MediaPortalContextProps {
  localVideoPortalDestination: HTMLElement | null;
  setLocalVideoPortalDestination: (element: HTMLElement | null) => void;

  remoteVideoPortalDestination: HTMLElement | null;
  setRemoteVideoPortalDestination: (element: HTMLElement | null) => void;

  localAudioPortalDestination: HTMLElement | null;
  setLocalAudioPortalDestination: (element: HTMLElement | null) => void;

  remoteAudioPortalDestination: HTMLElement | null;
  setRemoteAudioPortalDestination: (element: HTMLElement | null) => void;
}

const MediaPortalContext = createContext<MediaPortalContextProps>({
  localVideoPortalDestination: null,
  setLocalVideoPortalDestination: () => {},

  remoteVideoPortalDestination: null,
  setRemoteVideoPortalDestination: () => {},

  localAudioPortalDestination: null,
  setLocalAudioPortalDestination: () => {},

  remoteAudioPortalDestination: null,
  setRemoteAudioPortalDestination: () => {},
});

export const useMediaPortalContext = () => useContext(MediaPortalContext);

export const MediaPortalProvider: React.FC = ({ children }) => {
  const [localVideoPortalDestination, setLocalVideoPortalDestination] =
    useState<HTMLElement | null>(null);
  const [remoteVideoPortalDestination, setRemoteVideoPortalDestination] =
    useState<HTMLElement | null>(null);
  const [localAudioPortalDestination, setLocalAudioPortalDestination] =
    useState<HTMLElement | null>(null);
  const [remoteAudioPortalDestination, setRemoteAudioPortalDestination] =
    useState<HTMLElement | null>(null);

  return (
    <MediaPortalContext.Provider
      value={{
        localVideoPortalDestination,
        setLocalVideoPortalDestination,

        remoteVideoPortalDestination,
        setRemoteVideoPortalDestination,

        localAudioPortalDestination,
        setLocalAudioPortalDestination,

        remoteAudioPortalDestination,
        setRemoteAudioPortalDestination,
      }}
    >
      {children}
    </MediaPortalContext.Provider>
  );
};
