import React, { forwardRef } from 'react';
import { Theme } from '@mui/material/styles';
import { IconButtonProps } from '@mui/material/IconButton';
import HeaderIconButton from 'components/HeaderIconButton';

export interface ToggleIconButtonProps extends IconButtonProps {
  isActive: boolean;
}

function ToggleIconButton(props: ToggleIconButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const { isActive, children, ...rest } = props;
  const overrides = isActive
    ? {
        color: (theme: Theme) => theme.palette.text.primary,
        background: (theme: Theme) => theme.palette.action.selected,
        '&:active': {
          color: (theme: Theme) => theme.palette.text.primary,
          background: (theme: Theme) => theme.palette.action.selected,
        },
      }
    : {
        '&:hover': {
          color: (theme: Theme) => theme.palette.text.primary,
        },
      };
  return (
    <HeaderIconButton ref={ref} sx={overrides} {...rest}>
      {children}
    </HeaderIconButton>
  );
}

export default forwardRef(ToggleIconButton);
