export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
export const SLEEP_EVENT_FIELDS = [
  'id',
  'bid',
  'device_type',
  'time_started',
  'time_completed',
  'awakenings',
  'awake',
  'light',
  'rem',
  'deep',
  'sound',
  'tacit',
  'metrics.short_disturbances',
  'metrics.time_sleep_onset',
  'metrics.time_final_awakening',
  'metrics.dur_sleep_onset_latency',
  'metrics.dur_rem_onset_latency',
  'metrics.dur_deep_onsetL_latency',
  'metrics.num_intra_sleep_awakenings',
  'metrics.dur_total_sleep_time',
  'metrics.dur_aake_after_sleep_onset',
  'metrics.dur_wake_after_final_awakening',
  'metrics.dur_sleep_episode',
  'metrics.algo_sleep_efficiency',
  'metrics.sleep_efficiency',
  'metrics.pct_wake_of_dse',
  'metrics.pct_light_of_dse',
  'metrics.pct_rem_of_dse',
  'metrics.pct_deep_of_dse',
  'metrics.pct_light_of_tst',
  'metrics.pct_rem_of_tst',
  'metrics.pct_deep_of_tst',
  'metrics.score',
  'data.spo2_last',
  'data.resp_rate_mean',
  'data.resp_rate_median',
  'data.rmssd_mean',
  'data.rmssd_count',
  'data.resp_rate_last',
  'data.rmssd_last',
  'data.spo2_mean',
  'data.resp_rate_count',
  'data.spo2_median',
  'data.rmssd_median',
  'data.spo2_count',
];

export const SLEEP_EVENT_TIMESTAMP_FIELDS = [
  'time_started',
  'time_completed',
  'metrics.time_sleep_onset',
  'metrics.time_final_awakening',
];

export const TEMP_COLUMNS = [
  'ambient_temp',
  'skin_temp',
  'ambient_temp_mean',
  'ambient_temp_min',
  'ambient_temp_max',
  'skin_temp_mean',
  'skin_temp_min',
  'skin_temp_max',
];
export const SPO2_COLUMNS = ['spo2', 'spo2_mean', 'spo2_min', 'spo2_max'];
export const WEIGHT_COLUMNS = ['weight', 'weight_mean', 'weight_min', 'weight_max'];
export const OTHER_METRIC_COLUMNS = [
  'resp_rate',
  'resp_rate_mean',
  'resp_rate_min',
  'resp_rate_max',
  'battery_level',
  'battery_level_mean',
  'battery_level_min',
  'battery_level_max',
  'calories_sum',
  'gsr',
  'gsr_mean',
  'gsr_min',
  'gsr_max',
  'heart_rate',
  'heart_rate_mean',
  'heart_rate_min',
  'heart_rate_max',
  'rmssd',
  'rmssd_mean',
  'rmssd_min',
  'rmssd_max',
  'rhr',
  'rhr_mean',
  'sleep_efficiency',
  'sleep_efficiency_mean',
  'glucose',
  'glucose_mean',
  'glucose_min',
  'glucose_max',
  'systolic',
  'systolic_mean',
  'systolic_min',
  'systolic_max',
  'diastolic',
  'diastolic_mean',
  'diastolic_min',
  'diastolic_max',
];
