import { createApi } from '@reduxjs/toolkit/query/react';
import { Dictionary } from 'lodash';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import round from 'lodash/round';
import maxBy from 'lodash/maxBy';

import { apiBaseQuery } from 'services/base';
import { Insight, Pledge, ScreenerResponse, Survey, SurveyQuestion } from 'models/content';
import {
  ModuleStep,
  Program,
  ProgramAction,
  ProgramAggregation,
  ProgramAggregations,
  ProgramDetails,
} from 'models/program';
import {
  Action,
  ActionResponse,
  ActionsData,
  ActionSpec,
  AssignActionRequest,
  BaselineResponse,
  CreateActionRequest,
} from 'models/action';
import { Patient } from 'models/user';
import { RootState } from 'store/rootReducer';

interface FetchActionsQueryParams {
  page: number;
  perPage: number;
  categories?: any[];
  status?: any[];
  userId?: string;
}

interface QueryParams {
  userId?: string;
  programId?: string;
  moduleId?: string;
  actionId?: string;
  tenantId?: string;
  page?: number;
  programAction?: ProgramAction;
  surveyId?: number;
  surveyAnswers?: any;
  refresh?: boolean;
  pledgesNextPage?: number | null;
  insightsNextPage?: number | null;
}

interface BaselineQueryParams {
  surveyId: number;
  numberAnswers?: number;
}

export const factuaryApi = createApi({
  reducerPath: 'factuaryApi',
  baseQuery: apiBaseQuery('/factuary/api'),
  tagTypes: [
    'CurrentContent',
    'Surveys',
    'Diagnoses',
    'Programs',
    'Actions',
    'ActionSpecs',
    'UserFacts',
    'Baseline',
  ],
  endpoints(builder) {
    return {
      fetchCurrentContent: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const pledgesResponse = await baseQuery({
            url: `v1/users/${queryParams.userId}/userpledges?per_page=200&status=created|delivered|opted|achieved|expired&skip_date=True&order_by=date_desc`,
            method: 'GET',
          });

          const insightsResponse = await baseQuery({
            url: `v1/users/${queryParams.userId}/userinsights?per_page=200&skip_rated=False&skip_date=True&status=created|delivered|read|rated|expired&order_by=date_desc`,
            method: 'GET',
          });

          if (pledgesResponse.error || insightsResponse.error) {
            return {
              error: pledgesResponse.error || insightsResponse.error,
            };
          }

          const pledgesData = pledgesResponse.data as {
            data: { pledges: Pledge[] };
            meta: { next_page: number | null };
          };
          const insightsData = insightsResponse.data as {
            data: Insight[];
            meta: { next_page: number | null };
          };
          const pledgesNextPage = pledgesData?.meta?.next_page;
          const insightsNextPage = insightsData?.meta?.next_page;

          const pledges = pledgesData.data.pledges.map((pledge) => ({
            ...pledge,
            content_type: 'pledge',
            dateToOrder: pledge.time_created,
            lastInteraction: pledge.time_created,
          }));
          const insights = insightsData.data.map((insight) => ({
            ...insight,
            content_type: 'insight',
            dateToOrder: insight.time_created,
            lastInteraction: insight.time_created,
          }));

          const data = orderBy([...pledges, ...insights], 'dateToOrder', 'desc');

          return {
            data: { content: data, pledgesNextPage, insightsNextPage },
          };
        },
        providesTags: ['CurrentContent'],
      }),
      fetchMoreContent: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          let pledgesResponse: any = {};
          let insightsResponse: any = {};

          if (queryParams.pledgesNextPage) {
            pledgesResponse = await baseQuery({
              url: `v1/users/${queryParams.userId}/userpledges?per_page=200&page=${queryParams.pledgesNextPage}&status=created|delivered|opted|achieved|expired&skip_date=True&order_by=date_desc`,
              method: 'GET',
            });
          }

          if (queryParams.insightsNextPage) {
            insightsResponse = await baseQuery({
              url: `v1/users/${queryParams.userId}/userinsights?per_page=200&page=${queryParams.insightsNextPage}&skip_rated=False&skip_date=True&status=created|delivered|read|rated|expired&order_by=date_desc`,
              method: 'GET',
            });
          }

          if (pledgesResponse.error || insightsResponse.error) {
            return {
              error: pledgesResponse.error || insightsResponse.error,
            };
          }

          const pledgesData =
            (pledgesResponse.data as {
              data: { pledges: Pledge[] };
              meta: { next_page: number | null };
            }) ?? {};
          const insightsData =
            (insightsResponse.data as { data: Insight[]; meta: { next_page: number | null } }) ??
            {};
          const pledgesNextPage = pledgesData?.meta?.next_page ?? null;
          const insightsNextPage = insightsData?.meta?.next_page ?? null;

          const pledges =
            pledgesData?.data?.pledges?.map((pledge) => ({
              ...pledge,
              content_type: 'pledge',
              dateToOrder: pledge.time_created,
              lastInteraction: pledge.time_created,
            })) ?? [];
          const insights =
            insightsData?.data?.map((insight) => ({
              ...insight,
              content_type: 'insight',
              dateToOrder: insight.time_created,
              lastInteraction: insight.time_created,
            })) ?? [];

          const data = orderBy([...pledges, ...insights], 'dateToOrder', 'desc');

          return {
            data: { content: data, pledgesNextPage, insightsNextPage },
          };
        },
      }),
      fetchSurveys: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const surveysResponse = await baseQuery({
            url: `v1/users/${queryParams.userId}/surveys?per_page=200&skip_date=True&order_by=time_modified_status_desc`,
            method: 'GET',
          });

          if (surveysResponse.error) {
            return { error: surveysResponse.error };
          }

          const surveysData = surveysResponse.data as {
            surveys: Survey[];
            meta: { next_page?: number };
          };
          const nextPage = surveysData?.meta?.next_page;

          const surveysQuestions = flatMap(
            (surveysData?.surveys || [])
              .filter((survey) => survey.status === 'completed')
              .map((survey) => survey.questions)
          );
          const surveysEditors = uniq(
            surveysQuestions.map((question) => question.ctm_uuid).filter((i) => i)
          );

          if (surveysEditors?.length) {
            const clinicianProfiles = await apiBaseQuery('/registry/api/v1')(
              {
                url: [
                  '/users/?userIds=',
                  surveysEditors.join(','),
                  `&size=${surveysEditors.length}`,
                ].join(''),
                method: 'GET',
              },
              queryApi,
              extraOptions
            );

            if (clinicianProfiles.error) {
              return { error: clinicianProfiles.error };
            }

            const clinicianProfilesData = clinicianProfiles.data as { content: Patient[] };
            const clinicianProfilesMap = keyBy(clinicianProfilesData.content, 'id');
            const surveys = (surveysData?.surveys || []).map((survey) =>
              mapSurveysFunc(survey, clinicianProfilesMap)
            );

            return {
              data: { content: surveys, nextPage },
            };
          }

          const surveys = (surveysData?.surveys || []).map((survey) => mapSurveysFunc(survey, {}));

          return {
            data: { content: surveys, nextPage },
          };
        },
        providesTags: ['Surveys'],
      }),
      fetchMoreSurveys: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const surveysResponse = await baseQuery({
            url: `v1/users/${queryParams.userId}/surveys?per_page=200&page=${queryParams?.page}&skip_date=True&order_by=time_modified_status_desc`,
            method: 'GET',
          });

          if (surveysResponse.error) {
            return { error: surveysResponse.error };
          }

          const surveysData = surveysResponse.data as {
            surveys: Survey[];
            meta: { next_page?: number };
          };
          const nextPage = surveysData?.meta?.next_page;

          const surveysQuestions = flatMap(
            (surveysData?.surveys || [])
              .filter((survey) => survey.status === 'completed')
              .map((survey) => survey.questions)
          );
          const surveysEditors = uniq(
            surveysQuestions.map((question) => question.ctm_uuid).filter((i) => i)
          );

          if (surveysEditors?.length) {
            const clinicianProfiles = await apiBaseQuery('/registry/api/v1')(
              {
                url: [
                  '/users/?userIds=',
                  surveysEditors.join(','),
                  `&size=${surveysEditors.length}`,
                ].join(''),
                method: 'GET',
              },
              queryApi,
              extraOptions
            );

            if (clinicianProfiles.error) {
              return { error: clinicianProfiles.error };
            }

            const clinicianProfilesData = clinicianProfiles.data as { content: Patient[] };
            const clinicianProfilesMap = keyBy(clinicianProfilesData.content, 'id');
            const surveys = (surveysData?.surveys || []).map((survey) =>
              mapSurveysFunc(survey, clinicianProfilesMap)
            );

            return {
              data: { content: surveys, nextPage },
            };
          }
          const surveys = (surveysData?.surveys || []).map((survey) => mapSurveysFunc(survey, {}));

          return {
            data: { content: surveys, nextPage },
          };
        },
      }),
      editSurvey: builder.mutation<any, QueryParams>({
        query: (queryParams) => ({
          url: `/v1/users/${queryParams.userId}/surveys/${queryParams.surveyId}/answers`,
          method: 'POST',
          data: { ...queryParams?.surveyAnswers },
        }),
        invalidatesTags: ['Surveys'],
      }),

      fetchDiagnoses: builder.query<string[], QueryParams>({
        query: (queryParams) => ({
          url: `v1/screener/${queryParams.userId}/screen?deduplicate=true`,
          method: 'GET',
        }),
        providesTags: ['Diagnoses'],
        transformResponse: (data: ScreenerResponse[]) => {
          let diagnosesSet: Set<string> = new Set();
          for (const patientAnswers of data) {
            for (const answers of patientAnswers.patient) {
              const answerValues = answers.answer_text.split(', ');
              answerValues.forEach(diagnosesSet.add, diagnosesSet);
            }
          }
          const diagnosesArray = [...Array.from(diagnosesSet)].sort();
          return diagnosesArray;
        },
      }),
      fetchPrograms: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const [detailsResponse, programsResponse] = await Promise.all([
            baseQuery({
              url: `v2/users/${queryParams.userId}/programs/detail`,
              method: 'GET',
            }),
            baseQuery({
              url: `/v2/programs/filter?status=live&type=regular&outdated=no&page=1&per_page=20&tenants=${queryParams.tenantId}`,
              method: 'GET',
            }),
          ]);

          if (detailsResponse.error || programsResponse.error) {
            return { error: detailsResponse.error || programsResponse.error };
          }

          const detailsData = detailsResponse.data as { data: ProgramDetails[] };
          const programsData = detailsData.data?.filter(
            (program) => program?.program_status !== 'canceled'
          );

          const unassignedProgramsData = programsResponse.data as {
            programs: Program[];
            meta: any;
          };
          const assignedIds =
            programsData?.map((program) => ({
              id: program.program_id,
              tenantId: program.tenant_id,
            })) || [];
          const unassignedPrograms = formatUnassignedPrograms(
            unassignedProgramsData?.programs,
            assignedIds
          );
          let assignedPrograms: Program[] = [];

          if (!isEmpty(programsData)) {
            const detailsModules = flatMap(programsData.map((program) => program.program_details));
            const surveyIds = detailsModules.map((module) => module.user_survey_id);
            const responseIds = surveyIds.filter((id) => !isNil(id) && id !== 'None').join('|');

            const [aggregationsResponse, surveysResponse] = await Promise.all([
              baseQuery({
                url: `v2/users/${queryParams.userId}/programs/aggregations`,
                method: 'GET',
              }),
              !isEmpty(responseIds) &&
                baseQuery({
                  url: `v1/users/${queryParams.userId}/surveys?user_survey_id=${responseIds}`,
                  method: 'GET',
                }),
            ]);

            if (aggregationsResponse.error) {
              return { error: aggregationsResponse.error };
            }

            let aggregationsData = aggregationsResponse.data as { data: ProgramAggregations[] };

            const aggregationsByProgram = keyBy(aggregationsData.data, 'program_id');

            const surveysData = surveysResponse
              ? (surveysResponse?.data as { surveys: Survey[] })
              : { surveys: [] };
            const surveyByUserId = keyBy(surveysData.surveys, 'user_survey_id');

            assignedPrograms = programsData.map((program) => {
              const aggregations =
                aggregationsByProgram[program.program_id]?.program_aggregations || [];
              const modules = Object.values(groupBy(program?.program_details, 'module_id'))
                .map((module: ModuleStep[], index: number) => {
                  const steps = [...module]
                    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                    .map((step: ModuleStep, index: number) => ({
                      ...step,
                      index: index + 1,
                      time_delivered: step.time_delivered * 1000,
                      type: step.type.toLowerCase(),
                      survey:
                        surveyByUserId[step?.user_survey_id]?.questions?.map((q) => ({
                          ...q,
                          parsedAnswer: parseSurveyQuestionAnswer(q),
                        })) || null,
                    }));

                  return {
                    index: index + 1,
                    programId: module[0].program_id,
                    id: module[0].module_id,
                    name: module[0].module_name,
                    status: module[0].module_status,
                    steps,
                  };
                })
                .sort(
                  (a, b) =>
                    a.steps?.[a.steps.length - 1]?.time_delivered -
                    b.steps?.[b.steps.length - 1]?.time_delivered
                );

              return {
                id: program.program_id,
                tenant_id: program.tenant_id,
                name: program.program_name,
                status: program.program_status,
                modules,
                aggregations: getAggregations(aggregations),
                isAssigned: true,
              };
            });
          }

          const currentPrograms = assignedPrograms.filter(
            (p) => p.status !== 'completed' && p.status !== 'finished'
          );
          const allPrograms = [...assignedPrograms, ...unassignedPrograms];

          return {
            data: {
              assigned: currentPrograms,
              all: allPrograms,
              nextPage: unassignedProgramsData?.meta?.next_page,
            },
          };
        },
        providesTags: ['Programs'],
      }),
      fetchUnassignedPrograms: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const assignedPrograms = (queryApi.getState() as RootState).programs.assigned;
          const programsResponse = await baseQuery({
            url: `/v2/programs/filter?status=live&type=regular&outdated=no&page=${queryParams.page}&per_page=20&tenants=${queryParams.tenantId}`,
            method: 'GET',
          });

          if (programsResponse.error) {
            return { error: programsResponse.error };
          }

          const unassignedProgramsData = programsResponse.data as {
            programs: Program[];
            meta: any;
          };
          const assignedIds =
            assignedPrograms?.map((program) => ({ id: program.id, tenantId: program.tenant_id })) ||
            [];
          const unassignedPrograms = formatUnassignedPrograms(
            unassignedProgramsData?.programs,
            assignedIds
          );

          return {
            data: {
              programs: unassignedPrograms,
              nextPage: unassignedProgramsData?.meta?.next_page,
            },
          };
        },
        providesTags: ['Programs'],
      }),
      fetchModule: builder.query<any, QueryParams>({
        query: (queryParams) => ({
          url: `/v2/modules/${queryParams?.moduleId}?tenants=${queryParams?.tenantId}`,
          method: 'GET',
        }),
      }),
      updateProgram: builder.mutation<any, QueryParams>({
        query: (queryParams) => ({
          url: `/v2/users/${queryParams?.userId}/programs`,
          method: 'PATCH',
          data: { ...queryParams?.programAction },
        }),
        invalidatesTags: (_, error, arg) => (!error && arg.refresh ? ['Programs'] : []),
      }),
      fetchPatientPrograms: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const [programDetailResponse, programsAggregationsResponse] = await Promise.all([
            baseQuery({
              url: `v2/users/${queryParams.userId}/programs/detail`,
              method: 'GET',
            }),
            baseQuery({
              url: `v2/users/${queryParams.userId}/programs/aggregations`,
              method: 'GET',
            }),
          ]);

          if (programDetailResponse.error || programsAggregationsResponse.error) {
            return { error: programDetailResponse.error || programsAggregationsResponse.error };
          }

          let assignedPrograms: Program[] = [];
          const aggregationsData = programsAggregationsResponse.data as {
            data: ProgramAggregations[];
          };
          const aggregationsByProgram = keyBy(aggregationsData.data, 'program_id');
          const detailsData = programDetailResponse.data as { data: ProgramDetails[] };
          const programsData = detailsData.data?.filter(
            (program) => program?.program_status !== 'canceled'
          );
          assignedPrograms = programsData.map((program) => {
            const aggregations =
              aggregationsByProgram[program.program_id].program_aggregations || [];

            return {
              id: program.program_id,
              tenant_id: program.tenant_id,
              name: program.program_name,
              status: program.program_status,
              modules: [],
              aggregations: getAggregations(aggregations),
              isAssigned: true,
            };
          });

          return { data: assignedPrograms };
        },
      }),

      //Actions
      fetchActionById: builder.query<
        { action: Action; parentAction: Action | null },
        QueryParams | void
      >({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const actionResponse = await baseQuery({
            url: `/v2/users/actions/${queryParams?.actionId}`,
            method: 'GET',
          });

          if (actionResponse.error) {
            return { error: actionResponse.error };
          }

          const action = (actionResponse.data as { data: Action }).data;

          let parentAction = null;
          if (action.action_category === 'PATIENT_FOLLOWUP' && action.status.parent_id) {
            const parentActionResponse = await baseQuery({
              url: `/v2/users/actions/${action.status.parent_id}`,
              method: 'GET',
            });
            parentAction = (parentActionResponse.data as { data: Action }).data ?? null;
          }

          return {
            data: { action, parentAction },
          };
        },
        providesTags: ['Actions'],
      }),
      createAction: builder.mutation<Action, CreateActionRequest>({
        query: (body) => ({
          url: `/v2/users/actions`,
          method: 'post',
          data: { ...body },
        }),
        invalidatesTags: ['Actions'],
        transformResponse: (response: { data: Action }) => response.data,
      }),
      updateAction: builder.mutation<Action, Partial<Action>>({
        query: (body) => ({
          url: `/v2/users/actions`,
          method: 'put',
          data: { ...body },
        }),
        invalidatesTags: ['Actions'],
      }),
      fetchActions: builder.query<ActionResponse, FetchActionsQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const params = new URLSearchParams();
          queryParams?.userId && params.append('user_id', queryParams?.userId);
          params.append('page', queryParams.page.toString());
          params.append('per_page', queryParams.perPage.toString());
          params.append('only_active_users', 'true'); // will return actions only for active users

          if (queryParams.status?.length) {
            params.append('status', queryParams.status?.join('|'));
          }

          if (queryParams.categories?.length) {
            params.append('category', queryParams.categories?.join('|'));
          }

          const actions = await baseQuery({
            url: `/v2/users/actions?${params.toString()}`,
            method: 'GET',
          });

          if (actions.error) {
            return { error: actions.error };
          }

          let { data: actionsData, meta: pagination } = actions.data as ActionsData;

          // TODO: remove when patient info comes inside each action
          if (actionsData.length) {
            const patientIds = uniq(actionsData.map((action) => action.user_id));
            const patientProfiles = await apiBaseQuery('/registry/api/v1')(
              {
                url: ['/users/?userIds=', patientIds.join(','), `&size=${patientIds.length}`].join(
                  ''
                ),
                method: 'GET',
              },
              queryApi,
              extraOptions
            );

            if (patientProfiles.error) {
              return { error: patientProfiles.error };
            }

            const patientProfilesData = patientProfiles.data as { content: Patient[] };
            const patientProfilesMap = keyBy(patientProfilesData.content, 'id');
            actionsData = actionsData
              .map((action) => ({
                ...action,
                user: patientProfilesMap[action.user_id],
              }))
              .filter((action) => action.user);
          }

          return {
            data: { pagination, response: actionsData },
          };
        },
        providesTags: ['Actions'],
      }),
      fetchPanelActionSpec: builder.query<ActionSpec, void>({
        query: () => ({
          url: `/v2/action/spec/category/panel`,
          method: 'GET',
        }),
        providesTags: ['ActionSpecs'],
        transformResponse: (response: { data: ActionSpec }) => response.data,
      }),
      submitActionIntervention: builder.mutation<any, any>({
        query: (body) => ({
          url: `/v2/clinicians/actions/intervenes`,
          method: 'post',
          data: { ...body },
        }),
        invalidatesTags: ['Actions'],
      }),

      assignAction: builder.mutation<any, AssignActionRequest>({
        query: (body) => ({
          url: `/v2/clinicians/actions/${body.action_id}/assign`,
          method: 'POST',
          data: {
            op: body.op,
            assignee_id: body.assignee_id,
            assignee_role: body.assignee_role,
          },
        }),
        invalidatesTags: ['Actions'],
      }),

      //facts for disease risks
      fetchUserFacts: builder.query<any, QueryParams | void>({
        query: (queryParams) => ({
          url: `/v1/user_facts/${queryParams?.userId}`,
          method: 'GET',
        }),
        providesTags: ['UserFacts'],
        transformResponse: (response: { facts: any }) => response.facts,
      }),

      // Baseline
      fetchBaseline: builder.query<BaselineResponse, BaselineQueryParams>({
        query: (queryParams) => ({
          url: `/v2/baseline/${queryParams.surveyId}`,
          method: 'GET',
        }),
        providesTags: ['Baseline'],
        transformResponse: (response: BaselineResponse) => ({
          ...response,
          baseline_count: response.length,
        }),
      }),
      setBaseline: builder.mutation<any, BaselineQueryParams>({
        query: (body) => ({
          url: `/v2/baseline/${body.surveyId}/${body.numberAnswers}`,
          method: 'POST',
        }),
        invalidatesTags: ['Baseline'],
      }),
    };
  },
});

export const {
  useFetchCurrentContentQuery,
  useFetchSurveysQuery,
  useEditSurveyMutation,
  useFetchDiagnosesQuery,
  useFetchProgramsQuery,
  useUpdateProgramMutation,
  useFetchActionByIdQuery,
  useFetchActionsQuery,
  useCreateActionMutation,
  useUpdateActionMutation,
  useFetchPanelActionSpecQuery,
  useSubmitActionInterventionMutation,
  useAssignActionMutation,
  useFetchUserFactsQuery,
  useLazyFetchPatientProgramsQuery,
  useLazyFetchBaselineQuery,
  useSetBaselineMutation,
  useLazyFetchDiagnosesQuery,
  useLazyFetchMoreSurveysQuery,
} = factuaryApi;

function parseSurveyQuestionAnswer(question: SurveyQuestion): SurveyQuestion['parsedAnswer'] {
  if (question.ctm_answer_selected) {
    return JSON.parse(question.ctm_answer_selected);
  }
  if (question.answer_selected) {
    return JSON.parse(question.answer_selected);
  }
  return undefined;
}

function mapSurveysFunc(survey: Survey, clinicianProfilesMap: Dictionary<Patient>) {
  let editor;
  const dateToOrder = survey.time_created;
  const questions = survey.questions?.map((q) => ({
    ...q,
    parsedAnswer: parseSurveyQuestionAnswer(q),
    isEdited: Boolean('ctm_answer_selected' in q && 'answer_selected' in q),
  }));
  const editedQuestions = questions?.filter((q) => q.isEdited);
  const latestQuestion = maxBy(editedQuestions, 'ctm_answer_time');
  if (latestQuestion) {
    editor = {
      id: latestQuestion?.ctm_uuid,
      timestamp: latestQuestion?.ctm_answer_time ?? dateToOrder,
      user: clinicianProfilesMap[latestQuestion?.ctm_uuid ?? ''],
    };
  }
  const lastInteraction = Math.max(dateToOrder || 0, editor?.timestamp || 0);
  const isEdited = Boolean(editedQuestions?.length);
  return {
    ...survey,
    content_type: 'survey',
    dateToOrder,
    id: survey.user_survey_id,
    questions,
    isEdited,
    lastInteraction,
    editor,
  };
}

function getAggregations(aggregations: ProgramAggregation[]) {
  const modules = aggregations?.find(
    (item: ProgramAggregation) => item.type === 'modules_all'
  ) as ProgramAggregation;
  const insights = aggregations?.find(
    (item: ProgramAggregation) => item.type === 'insights_all'
  ) as ProgramAggregation;
  const pledges = aggregations?.find(
    (item: ProgramAggregation) => item.type === 'pledges_all'
  ) as ProgramAggregation;
  const surveys = aggregations?.find(
    (item: ProgramAggregation) => item.type === 'surveys_all'
  ) as ProgramAggregation;
  const completed = insights?.completed + pledges?.completed + surveys?.completed;
  const total = insights?.count + pledges?.count + surveys?.count;
  const progress = total ? round((completed / total) * 100) : 0;

  return { modules, insights, pledges, surveys, completed, total, progress };
}

function formatUnassignedPrograms(
  programs: Program[],
  assignedIds: { id: string; tenantId: string }[]
) {
  return programs
    ?.filter(
      (program) =>
        !assignedIds.find(
          (assigned) => assigned.id === program.id && assigned.tenantId === program.tenant_id
        )
    )
    ?.map((program) => ({
      id: program.id,
      name: program.name,
      modules: program.modules,
      isAssigned: false,
      tenant_id: program.tenant_id,
    }));
}
