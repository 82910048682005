const ROUTES = {
  auth: '/auth/tenant',
  main: '/dashboard/today',
  dashboard: '/dashboard',
  patient: '/patient/:patientId',
  theme: '/theme',
  today: '/dashboard/today',
  panel: '/dashboard/panel',
  calendar: '/dashboard/calendar',
  library: '/dashboard/library',
  support: '/dashboard/support',
  reports: '/dashboard/reports',
  account: '/dashboard/account',
  team: '/dashboard/team',
  summary: 'summary',
  timeline: 'timeline',
  generatedData: 'data',
  analysis: 'analysis',
  content: 'content',
  patientTeam: 'team',
  patientReports: 'reports',
};

export const ADMIN_ROUTES = {
  admin: '/admin',
  members: '/admin/members',
  staff: '/admin/staff',
};

export default ROUTES;
