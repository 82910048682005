/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilledIconButton from 'components/FilledIconButton';
import HeaderIconButton from 'components/HeaderIconButton';
import ToggleIconButton from 'components/ToggleIconButton';
import Dropdown from 'components/Dropdown';
import MenuRenderer from 'components/MenuRenderer';
import DefinitionList, { Definition, DefinitionValue } from 'components/DefinitionList';
import makeStyles from '@mui/styles/makeStyles';
import * as icons from 'assets/icons';

const ThemePreview: React.FC = () => (
  <Box mt="64px" ml="32px" overflow="scroll" height="calc(100vh - 64px)">
    <Texts />
    <Divider sx={{ margin: '16px 0' }} />
    <Colors />
    <Divider sx={{ margin: '16px 0' }} />
    <Icons />
    <Divider sx={{ margin: '16px 0' }} />
    <Buttons />
    <Divider sx={{ margin: '16px 0' }} />
    <Dropdowns />
    <Divider sx={{ margin: '16px 0' }} />
    <Definitions />
  </Box>
);

const Texts = () => (
  <Box>
    <Typography variant="header72" component="div" gutterBottom>
      Header 72
    </Typography>
    <Typography variant="header56" gutterBottom component="div">
      Header 56
    </Typography>
    <Typography variant="header48" gutterBottom component="div">
      Header 48
    </Typography>
    <Typography variant="header36" gutterBottom component="div">
      Header 36
    </Typography>
    <Typography variant="header30" gutterBottom component="div">
      Header 30
    </Typography>
    <Typography variant="header24" gutterBottom component="div">
      Header 24
    </Typography>
    <Typography variant="header19" gutterBottom component="div">
      Header 19
    </Typography>
    <Typography variant="header15" gutterBottom component="div">
      Header 15
    </Typography>
    <Typography variant="header12" gutterBottom component="div">
      Header 12
    </Typography>
    <Typography variant="header11" gutterBottom component="div">
      Header 11
    </Typography>
    <Typography variant="header10" gutterBottom component="div">
      Header 10
    </Typography>
    <Typography variant="para12" gutterBottom component="div">
      Para12. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
    </Typography>
    <Typography variant="para11" gutterBottom component="div">
      Para11. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
    </Typography>
    <Typography variant="para10" gutterBottom component="div">
      Para10. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
    </Typography>
  </Box>
);

const useColorStyles = makeStyles(() => ({
  colorBox: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    padding: '6px 6px 6px 60px',
    height: '60px',
    width: '240px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '6px',
      top: '6px',
      width: '48px',
      height: '48px',
      borderRadius: '50%',
    },
  },
}));

const Colors = () => {
  const styles = useColorStyles();
  return (
    <Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.primary.main },
        }}
      >
        primary.main
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.secondary.main },
        }}
      >
        secondary.main
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.text.primary },
        }}
      >
        text.primary
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.text.secondary },
        }}
      >
        text.secondary
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.cyan },
        }}
      >
        custom.cyan
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.black },
        }}
      >
        custom.black
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.black80 },
        }}
      >
        custom.black80
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.black60 },
        }}
      >
        custom.black60
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.black40 },
        }}
      >
        custom.black40
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.blackFill,
          },
        }}
      >
        custom.blackFill
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.blue },
        }}
      >
        custom.blue
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.brown },
        }}
      >
        custom.brown
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.cerulean },
        }}
      >
        custom.cerulean
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.gold },
        }}
      >
        custom.gold
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.green },
        }}
      >
        custom.green
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.orange },
        }}
      >
        custom.orange
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.magenta },
        }}
      >
        custom.magenta
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.pink },
        }}
      >
        custom.pink
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.purple },
        }}
      >
        custom.purple
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.yellow },
        }}
      >
        custom.yellow
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.gradientBlack,
          },
        }}
      >
        custom.gradientBlack
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.gradientBlue,
          },
        }}
      >
        custom.gradientBlue
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.gradientCyan,
          },
        }}
      >
        custom.gradientCyan
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.gradientGold,
          },
        }}
      >
        custom.gradientGold
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.gradientGreen,
          },
        }}
      >
        custom.gradientGreen
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.gradientRed,
          },
        }}
      >
        custom.gradientRed
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.greyA },
        }}
      >
        custom.greyA
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.greyB },
        }}
      >
        custom.greyB
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.greyC },
        }}
      >
        custom.greyC
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.greyD },
        }}
      >
        custom.greyD
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.greyE },
        }}
      >
        custom.greyE
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightBlue,
          },
        }}
      >
        custom.lightBlue
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightBrown,
          },
        }}
      >
        custom.lightBrown
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightCerulean,
          },
        }}
      >
        custom.lightCerulean
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightCyan,
          },
        }}
      >
        custom.lightCyan
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightGold,
          },
        }}
      >
        custom.lightGold
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightGreen,
          },
        }}
      >
        custom.lightGreen
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightOrange,
          },
        }}
      >
        custom.lightOrange
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightMagenta,
          },
        }}
      >
        custom.lightMagenta
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightPink,
          },
        }}
      >
        custom.lightPink
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightPurple,
          },
        }}
      >
        custom.lightPurple
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': { background: (theme) => theme.palette.custom.lightRed },
        }}
      >
        custom.lightRed
      </Box>
      <Box
        className={styles.colorBox}
        sx={{
          '&::before': {
            background: (theme) => theme.palette.custom.lightYellow,
          },
        }}
      >
        custom.lightYellow
      </Box>
    </Box>
  );
};

const Icons = () => {
  const [selectedName, setSelectedName] = useState('');
  return (
    <Box>
      <Box mt={2} mb={2}>
        <Typography variant="para12">
          {selectedName
            ? `import { ${selectedName} } from 'assets/icons';`
            : 'Click any icon to see how to import it in code'}
        </Typography>
      </Box>
      {Object.entries(icons).map(([name, Icon]) => (
        <Icon
          aria-label={name}
          style={{ margin: 2, cursor: 'pointer' }}
          key={name}
          onClick={() => setSelectedName(name)}
        />
      ))}
    </Box>
  );
};

const Buttons = () => (
  <Box mt={2} mb={2}>
    <Stack mb={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
      <Button variant="cta" color="red">
        CTA: Red
      </Button>
      <Button variant="cta" color="black">
        CTA: Black
      </Button>
      <Button variant="cta" color="white">
        CTA: White
      </Button>
      <Button variant="cta" color="grey">
        CTA: Grey
      </Button>

      <Button variant="cta" color="red" disabled>
        CTA: Red Disabled
      </Button>
      <Button variant="cta" color="black" disabled>
        CTA: Black Disabled
      </Button>
      <Button variant="cta" color="white" disabled>
        CTA: White Disabled
      </Button>
    </Stack>

    <Stack mb={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
      <Button variant="cta" color="red" startIcon={<icons.ClockIcon />}>
        Icon + Text
      </Button>
      <Button variant="cta" color="black" endIcon={<icons.HomeIcon />}>
        Text + Icon
      </Button>
      <Button
        variant="cta"
        color="white"
        startIcon={<icons.MicrophoneIcon />}
        endIcon={<icons.StarIcon />}
      >
        Icon + Text + Icon
      </Button>
    </Stack>

    <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
      <IconButton>
        <icons.MenuHamburgerIcon />
      </IconButton>
      <HeaderIconButton>
        <icons.MenuHamburgerIcon />
      </HeaderIconButton>
      <IconButton disabled>
        <icons.MenuHamburgerIcon />
      </IconButton>
      <HeaderIconButton disabled>
        <icons.MenuHamburgerIcon />
      </HeaderIconButton>
      <FilledIconButton>
        <icons.PlusIcon />
      </FilledIconButton>
      <FilledIconButton disabled>
        <icons.PlusIcon />
      </FilledIconButton>
      <Fab color="cyan">
        <icons.PencilIcon />
      </Fab>
      <Fab color="gold">
        <icons.PencilIcon />
      </Fab>
      <Fab color="cyan" disabled>
        <icons.PencilIcon />
      </Fab>
      <Fab color="gold" disabled>
        <icons.PencilIcon />
      </Fab>
    </Stack>
  </Box>
);

const Dropdowns = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const options = [
    { value: '1', text: 'Option One' },
    { value: '2', text: 'Option Two' },
    { value: '3', text: 'Option Three' },
    { value: '4', text: 'Option Four' },
  ];

  return (
    <Box mt={2} mb={2}>
      <Stack spacing={4} direction="row" alignItems="center" justifyContent="center">
        <Dropdown
          value={selectedValue}
          onChange={setSelectedValue}
          options={options}
          placeholder="Please select..."
          placement="bottom-end"
        />
        <Dropdown
          value={selectedValue}
          onChange={setSelectedValue}
          options={options}
          placeholder="Please select..."
          placement="bottom-start"
          variant="header"
        />
      </Stack>
      <Stack spacing={4} mt={2} direction="row" alignItems="center" justifyContent="center">
        <MenuRenderer
          trigger={(props, { isOpen }) => (
            <ToggleIconButton {...props} isActive={isOpen}>
              <icons.MenuHamburgerIcon />
            </ToggleIconButton>
          )}
        >
          {(props, { isOpen, close }) => (
            <Menu {...props} open={isOpen} onClose={close}>
              <MenuItem onClick={close}>
                <icons.AvatarIcon />
                Profile
              </MenuItem>
              <MenuItem onClick={close}>
                <icons.ActionOwnerIcon />
                My account
              </MenuItem>
              <MenuItem onClick={close}>
                <icons.LogoutIcon />
                Logout
              </MenuItem>
            </Menu>
          )}
        </MenuRenderer>
        <MenuRenderer
          placement="left-end"
          trigger={(props, { isOpen }) => (
            <ToggleIconButton {...props} isActive={isOpen}>
              <icons.MenuHamburgerIcon />
            </ToggleIconButton>
          )}
        >
          {(props, { isOpen, close }) => (
            <Menu {...props} open={isOpen} onClose={close}>
              <MenuItem onClick={close}>
                <icons.AvatarIcon />
                Profile
              </MenuItem>
              <MenuItem onClick={close}>
                <icons.ActionOwnerIcon />
                My account
              </MenuItem>
              <MenuItem onClick={close}>
                <icons.LogoutIcon />
                Logout
              </MenuItem>
            </Menu>
          )}
        </MenuRenderer>
      </Stack>
      <Box sx={{ height: '200px' }}></Box>
    </Box>
  );
};

const Definitions = () => {
  return (
    <Box mt={2} mb={2}>
      <Stack spacing={4} direction="row" alignItems="center" justifyContent="center">
        <DefinitionList title="Dx: Type 2 Diabetes" isAccented={true}>
          <Definition name="Weight c">
            <DefinitionValue>224 lbs</DefinitionValue>
          </Definition>
          <Definition name="Weight i">
            <DefinitionValue>252 lbs</DefinitionValue>
          </Definition>
          <Definition name="BMI c">
            <DefinitionValue variant="error" prefixIcon={<icons.ThumbsDownIcon />}>
              34.5
            </DefinitionValue>
          </Definition>
          <Definition name="BMI i">
            <DefinitionValue>35.2</DefinitionValue>
          </Definition>
        </DefinitionList>

        <DefinitionList title="Contact Information" variant="single-column">
          <Definition name="Email" isAccented>
            <DefinitionValue>cgreeny@gmail.com</DefinitionValue>
          </Definition>
          <Definition name="Phone" color="purple">
            <DefinitionValue>+1 (555) 555-5555</DefinitionValue>
          </Definition>
          <Definition name="Address">
            <DefinitionValue>2180 Long Windy Road</DefinitionValue>
            <DefinitionValue>San Francisco, CA 94109</DefinitionValue>
          </Definition>
          <Definition name="Emergency Contact">
            <DefinitionValue label="Wife">Stephanie Green</DefinitionValue>
            <DefinitionValue>(555) 555-5555</DefinitionValue>
          </Definition>
        </DefinitionList>
      </Stack>
    </Box>
  );
};

export default ThemePreview;
