import { ActivityDay } from 'models/activities';

export const getActivityCompliance = (data: ActivityDay[], type: string, activityDays: number) => {
  const daysArray = data.filter((item: ActivityDay) => {
    return item.activities.some((activity) => activity.activityType === type);
  });
  return {
    complianceDays: daysArray.length,
    compliancePercentage: `${Math.round((daysArray.length / activityDays) * 100)}%`,
  };
};
