import { Gender, Sex } from 'models/accountInvitation';
import { GenderType, SexType } from 'models/admin';

export const SEX_LABEL: Record<string, SexType> = {
  m: 'male',
  M: 'male',
  F: 'female',
  f: 'female',
  i: 'intersex',
  I: 'intersex',
  male: 'male',
  female: 'female',
  intersex: 'intersex',
};

export const GENDER_LABEL: Record<string, GenderType> = {
  m: 'male',
  M: 'male',
  F: 'female',
  f: 'female',
  i: 'intersex',
  I: 'intersex',
  nb: 'non-binary',
  NB: 'non-binary',
  t: 'transgender',
  T: 'transgender',
  male: 'male',
  female: 'female',
  intersex: 'intersex',
  'non-binary': 'non-binary',
  transgender: 'transgender',
  other: 'unknown',
};

export const USER_STATUS: Record<string, string> = {
  ACTIVE: 'ACTIVE',
  CREATED: 'CREATED',
  HOLDING: 'HOLDING',
  INACTIVE: 'INACTIVE',
  REMOVED: 'REMOVED',
  DISCHARGED: 'DISCHARGED',
};

export const PER_PAGE = 20;
export const ROWS_PER_PAGE_OPTIONS = [20, 30, 40, 50, 100];

export const SexValues: { value: Sex; label: string }[] = [
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'intersex',
    label: 'Intersex',
  },
  {
    value: 'male',
    label: 'Male',
  },
];

export const GenderValues: { value: Gender; label: string }[] = [
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'intersex',
    label: 'Intersex',
  },
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'non-binary',
    label: 'Non-Binary',
  },
  {
    value: 'transgender',
    label: 'Transgender',
  },
  {
    value: 'unknown',
    label: 'Prefer Not to Say',
  },
];
