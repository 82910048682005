import React, { SyntheticEvent } from 'react';
import { ActionIcon, CalendarIcon, MessageIcon } from 'assets/icons';
import { Notification, NotificationType } from 'models/notification';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { CustomColors } from 'styles/theme';
import NotificationItem from '../NotificationItem';
import StyledAccordion from '../../styledComponents/StyledAccordion';
import StyledAccordionDetails from '../../styledComponents/StyledAccordionDetails';
import StyledAccordionSummary from '../../styledComponents/StyledAccordionSummary';
import Typography from '@mui/material/Typography';

const CALENDAR = 'calendar';

interface NotificationTitleItemProps {
  badge?: number;
  title: string;
  type: NotificationType;
  list?: Notification[];
  handleChange: (
    panel: string
  ) => (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanded?: string | boolean;
  handleClose: () => void;
}

const NotificationColors: Record<NotificationType, CustomColors> = {
  calendar: 'blue',
  messages: 'cyan',
  actions: 'red',
};

const getNotificationIcon = (type: NotificationType) => {
  switch (type) {
    case CALENDAR:
      return <CalendarIcon className="notificationIcon" />;
    case 'messages':
      return <MessageIcon className="notificationIcon" />;
    default:
      return <ActionIcon className="notificationIcon" />;
  }
};

const NotificationListItems = ({
  badge,
  type,
  title,
  list,
  expanded,
  handleChange,
  handleClose,
}: NotificationTitleItemProps) => {
  const color = NotificationColors[type];

  const renderNotificationItem = (event: Notification) => {
    if (event.clicked === true && type === CALENDAR) {
      return null;
    } else {
      return (
        <NotificationItem
          key={event.id}
          color={color}
          event={event}
          type={type}
          handleClose={handleClose}
        />
      );
    }
  };

  return (
    <StyledAccordion
      key={type}
      disableGutters
      expanded={expanded === `${type}`}
      onChange={handleChange(`${type}`)}
      sx={{ padding: 0 }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <StyledAccordionSummary
        aria-controls={`${type}-content`}
        id={`${type}-header`}
        color={color}
        isTitle
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getNotificationIcon(type)}
          <Typography variant="header12">{title}</Typography>
          <Badge badgeContent={badge} />
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box
          sx={{
            maxHeight: 212,
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {list?.map((event) => renderNotificationItem(event))}
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default NotificationListItems;
