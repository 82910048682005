import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  notificationButton: {
    borderRadius: 0,
    borderColor: theme.palette.custom.blackFill,
    color: theme.palette.custom.greyC,
    height: 48,
    width: 48,
    '&.active': {
      backgroundColor: theme.palette.custom.black,
      '& .MuiBadge-badge': { borderColor: theme.palette.custom.black },
    },
  },
  popper: {
    zIndex: theme.zIndex.snackbar,
    '&[data-popper-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  notificationContainer: {
    borderRadius: '0 0 6px 6px',
    boxShadow: '0px 18px 24px -6px rgba(80, 86, 97, 0.2)',
    width: 396,
    maxHeight: 737,
  },
}));

export default useStyles;
