import React from 'react';

export type FeatureGroup = {
  [featureName: string]: boolean;
};

export type FeatureFlags = string[] | FeatureGroup;

const FeatureFlagsContext = React.createContext<FeatureGroup>({});

export default FeatureFlagsContext;
