import { createTheme, Theme } from '@mui/material/styles';
import { DARK_MODE } from '@constants/global';

export const configureAlerts = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiAlert: {
        variants: [
          {
            props: { severity: 'error' },
            style: {
              ...(theme.palette.mode === DARK_MODE && {
                backgroundColor: theme.palette.customBackground.aside,
              }),
            },
          },
          {
            props: { severity: 'info' },
            style: {
              ...(theme.palette.mode === DARK_MODE && {
                backgroundColor: theme.palette.customBackground.aside,
              }),
            },
          },
          {
            props: { severity: 'success' },
            style: {
              ...(theme.palette.mode === DARK_MODE && {
                backgroundColor: theme.palette.customBackground.aside,
              }),
            },
          },
          {
            props: { severity: 'warning' },
            style: {
              ...(theme.palette.mode === DARK_MODE && {
                backgroundColor: theme.palette.customBackground.aside,
              }),
            },
          },
        ],
      },
    },
  });
