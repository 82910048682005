import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

// global MSAL instance
let msalInstance: PublicClientApplication | null = null;

export const setMsalInstance = (instance: PublicClientApplication) => {
  msalInstance = instance;
};

export const getMsalInstance = (): PublicClientApplication | null => {
  return msalInstance;
};

const getMsalFlow = (config: any) => {
  if (config.isMagicFlow) {
    return config?.azure_ad_b2c?.magic_link_flow;
  }

  const host = window.location.host;
  const type = host.split('.')[0];

  if (type.includes('localhost') || type.includes('radius')) {
    return config?.azure_ad_b2c?.clinician_flow;
  }

  return config?.azure_ad_b2c?.[`${type}_flow`];
};

// MSAL configuration
export const getMsalConfig = (config: any): Configuration => {
  const flow = getMsalFlow(config);
  return {
    auth: {
      clientId: config?.azure_ad_b2c?.clientId ?? '',
      authority:
        config?.azure_ad_b2c?.authority && config?.azure_ad_b2c?.tenantName
          ? `https://${config?.azure_ad_b2c?.authority}/${config?.azure_ad_b2c?.tenantName}/${flow}`
          : '',
      knownAuthorities: config?.azure_ad_b2c?.authority ? [config?.azure_ad_b2c?.authority] : [],
      redirectUri: `${window.location.origin}/`,
      postLogoutRedirectUri: `${window.location.origin}/`,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };
};

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};

export const clearSession = () => {
  const notifications = localStorage.getItem('notifications');
  clearCookies();
  sessionStorage.clear();
  localStorage.clear();
  if (notifications) {
    localStorage.setItem('notifications', notifications);
  }
};
