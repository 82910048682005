import { ActionStatusType } from 'models/action';
import { ActionAssignmentRole, ActionAssignmentType } from 'models/actionAssignment';
import { UserRole } from 'models/user';

export const ESCALATE_ROLES: UserRole[] = ['nurse', 'coach'];
export const DEESCALATE_ROLES: UserRole[] = ['nurse', 'md'];
export const REASSIGN_ROLES: UserRole[] = ['nurse', 'md', 'coach'];

export const REASSIGNED_STATUS: ActionStatusType[] = ['DEESCALATED', 'ESCALATED', 'REASSIGNED'];

export const ASSIGNMENT_LABELS: Record<ActionAssignmentType, string> = {
  deescalate: 'De-escalate',
  escalate: 'Escalate',
  reassign: 'Reassign',
};

export const ASSIGNMENT_BUTTON_LABEL: Record<ActionAssignmentType, string> = {
  deescalate: 'De-escalate',
  escalate: 'Escalate',
  reassign: 'Reassign',
};

export const ASSIGNMENT_ROLES: ActionAssignmentRole[] = [
  {
    role: 'md',
    label: 'Provider',
  },
  {
    role: 'nurse',
    label: 'Nurse',
  },
  {
    role: 'coach',
    label: 'Coach',
  },
];
