import React, {
  ReactEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Typography, Slider, Stack, IconButton } from '@mui/material';
import { PauseIcon, RunFillIcon, SpeakerIcon, SpeakerMutedIcon } from 'assets/icons';

import useStyles from './styles';

interface AudioPlayerProps {
  src: string;
  className?: string;
  onPlay?: ReactEventHandler<HTMLAudioElement>;
  onPause?: ReactEventHandler<HTMLAudioElement>;
  onEnded?: ReactEventHandler<HTMLAudioElement>;
  onSeeked?: ReactEventHandler<HTMLAudioElement>;
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const classes = useStyles();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1.0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const { onEnded } = props;

  const onAudioEnded = useCallback(
    (event: SyntheticEvent<HTMLAudioElement>) => {
      setIsPlaying(false);
      if (typeof onEnded === 'function') {
        onEnded(event);
      }
    },
    [onEnded]
  );

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    if (audio.readyState >= HTMLMediaElement.HAVE_METADATA) {
      setDuration(audio.duration);
    }
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }
    isPlaying ? audio.play() : audio.pause();
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }
    audio.muted = isMuted;
  }, [isMuted]);

  return (
    <Stack direction="row" gap="4px" alignItems="center" className={classes.root}>
      <audio
        ref={audioRef}
        src={props.src}
        onEnded={onAudioEnded}
        onVolumeChange={(event) => setVolume(event.currentTarget.volume)}
        onTimeUpdate={(event) => setCurrentTime(event.currentTarget.currentTime)}
        onLoadedMetadata={(event) => setDuration(event.currentTarget.duration)}
        className={props.className}
        onSeeked={props.onSeeked}
        onPlay={props.onPlay}
        onPause={props.onPause}
      />
      <IconButton
        className={classes.button}
        aria-label={isPlaying ? 'pause' : 'play'}
        onClick={() => setIsPlaying((current) => !current)}
      >
        {isPlaying ? <PauseIcon /> : <RunFillIcon />}
      </IconButton>
      <Stack direction="row" gap="4px" className={classes.timeContainer}>
        <Typography variant="para10" className={classes.time}>
          {formatTime(currentTime)}
        </Typography>
        <Typography variant="para10">/</Typography>
        <Typography variant="para10" className={classes.time}>
          {formatTime(duration)}
        </Typography>
      </Stack>
      <Slider
        aria-label="time-indicator"
        size="small"
        value={audioRef.current?.currentTime ?? 0}
        min={0}
        step={1}
        max={duration ?? 100}
        className={classes.slider}
        onChange={(_, value) => {
          if (audioRef.current) {
            audioRef.current.currentTime = Number(value);
          }
        }}
      />
      <IconButton
        aria-label="mute"
        className={classes.button}
        onClick={() => setIsMuted((current) => !current)}
      >
        {isMuted ? (
          <SpeakerMutedIcon className={classes.speakerIcon} />
        ) : (
          <SpeakerIcon className={classes.speakerIcon} />
        )}
      </IconButton>
      <Slider
        aria-label="volume-indicator"
        size="small"
        value={volume}
        min={0}
        step={0.01}
        max={1}
        className={classes.slider}
        onChange={(_, value) => {
          if (audioRef.current) {
            audioRef.current.volume = Number(value);
          }
        }}
      />
    </Stack>
  );
};

function formatTime(timeInSeconds: number | null) {
  if (timeInSeconds === null) {
    return '--:--';
  }

  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

export default AudioPlayer;
