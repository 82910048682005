import { createSlice, PayloadAction, Slice, SliceCaseReducers } from '@reduxjs/toolkit';

import { PanelSet } from 'models/panel';
import { eventsProcessorApi } from 'services/eventsProcessor';
import { panelApi } from 'services/panel';
import { RootState } from 'store/rootReducer';

export interface InitialPanelStateProps {
  panelList: PanelSet[];
  panelSelected: PanelSet | null;
  totalPatients: number;
}

const initialState: InitialPanelStateProps = {
  panelList: [],
  panelSelected: null,
  totalPatients: 0,
};

const panelSlice: Slice<
  InitialPanelStateProps,
  SliceCaseReducers<InitialPanelStateProps>,
  'panel'
> = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    setPanel: (state, action: PayloadAction<PanelSet>) => ({
      ...state,
      panelSelected: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(panelApi.endpoints.getAllPanelSets.matchFulfilled, (state, { payload }) => {
      const defaultPanel = {
        id: 'defaultPanel',
        measures: [
          { measureId: 'age', measureName: 'Age' },
          { measureId: 'gender', measureName: 'Gender' },
          { measureId: 'weight', measureName: 'Weight' },
        ],
        name: 'Default Panel',
      };
      state.panelList = [defaultPanel, ...payload];

      if (state.panelSelected && state.panelSelected.id) {
        const panelSelectedId = state.panelSelected.id;
        const currentPanelSelected = payload.findIndex((panel) => panel.id === panelSelectedId);
        if (currentPanelSelected === -1) {
          state.panelSelected = defaultPanel;
        }
      } else {
        state.panelSelected = defaultPanel;
      }
    });

    builder.addMatcher(panelApi.endpoints.createPanelSet.matchFulfilled, (state, { payload }) => {
      if (payload) {
        state.panelList = [...state.panelList, payload];
        state.panelSelected = payload;
      }
    });

    builder.addMatcher(panelApi.endpoints.updatePanelSet.matchFulfilled, (state, { payload }) => {
      if (payload) {
        const newPanelList = state.panelList.map((panel) => {
          if (panel.id === payload.id) {
            return payload;
          }
          return panel;
        });
        state.panelList = [...newPanelList];
        state.panelSelected = payload;
      }
    });

    builder.addMatcher(
      eventsProcessorApi.endpoints.fetchPatientsByMeasure.matchFulfilled,
      (state, { payload }) => {
        state.totalPatients = payload.totalElements;
      }
    );
  },
});

export const { setPanel } = panelSlice.actions;
export const panelListSelector = (state: RootState) => state.panel.panelList;
export const panelSelectedSelector = (state: RootState) => state.panel.panelSelected;
export const totalPatientsSelector = (state: RootState) => state.panel.totalPatients;

export default panelSlice.reducer;
