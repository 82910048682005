import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.8rem',
    width: '21.6rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.6rem',
    marginBottom: '0.6rem',
    paddingTop: '0.3rem',
    borderTop: `1px solid ${theme.palette.custom.white20}`,
  },
  alertIcon: {
    color: theme.palette.custom.red,
    fontSize: '1.6rem',
    marginRight: '0.7rem',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0.3rem',
    paddingTop: '0.3rem',
    borderTop: `1px solid ${theme.palette.custom.white20}`,
    whiteSpace: 'nowrap',
  },
  detailFooter: {
    marginTop: '1.2rem',
  },
}));

export default useStyles;
