import { createApi } from '@reduxjs/toolkit/query/react';

import { Meal } from 'models/meal';
import { apiBaseQuery } from 'services/base';

export interface QueryParams {
  userId: string;
  startTime: string;
  endTime: string;
}

export const foodloggingApi = createApi({
  reducerPath: 'foodloggingApi',
  baseQuery: apiBaseQuery('/foodlogging/api/v1'),
  tagTypes: ['FoodLogging'],
  endpoints(builder) {
    return {
      fetchPatientMealsByDates: builder.query<Meal[], QueryParams>({
        query: queryParams => ({
          url: `/users/${queryParams.userId}/food-logs/date?start_date=${queryParams?.startTime}&end_date=${queryParams?.endTime}`,
          method: 'GET',
        }),
        providesTags: ['FoodLogging'],
      }),
    };
  },
});

export const { useFetchPatientMealsByDatesQuery } = foodloggingApi;
