import React from 'react';
import { DocumentViewer } from 'react-documents';
import { Alert, Avatar, Box, Card, CardActionArea, CardHeader, Tooltip } from '@mui/material';

import { CardInsightIcon } from 'assets/icons';
import { ClassNameMap } from '@mui/styles';
import {
  filterFilesByAcceptedFileTypeForPreviewer,
  filterFilesBySizeForPreviewer,
} from 'utils/files';
import useStyles from './styles';

interface FilesPreviewProps {
  name?: string;
  url: string;
  fileSize: string;
  classes: ClassNameMap<string>;
  provider?: 'google' | 'office';
  action: (event: any, component: string, parent: string) => void;
  type: string;
}

const FilesPreview = ({
  name,
  url,
  fileSize,
  provider = 'google',
  action,
  type,
}: FilesPreviewProps) => {
  const classes = useStyles();
  const showDocumentViewer = filterFilesByAcceptedFileTypeForPreviewer(type);
  const isAcceptedFileSize = filterFilesBySizeForPreviewer(fileSize);

  return (
    <>
      {showDocumentViewer && isAcceptedFileSize && (
        <div className={classes.documentViewerContainer}>
          <DocumentViewer
            viewer={provider}
            url={url}
            className={classes.documentViewer}
            googleCheckContentLoaded={true}
            googleCheckInterval={3000}
            googleMaxChecks={5}
          ></DocumentViewer>
        </div>
      )}
      {!isAcceptedFileSize && (
        <Box sx={{ pb: '6px' }}>
          <Alert severity="info">This file is too large to preview</Alert>
        </Box>
      )}
      <Tooltip title={name || 'File'} arrow>
        <Card className={classes.card}>
          <CardActionArea
            className={classes.cardActionArea}
            href={url}
            download
            onClick={(ev) => action(ev, '<CardActionArea />', '<LinkPreview />')}
          >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <CardInsightIcon />
                </Avatar>
              }
              title={name}
              subheader={fileSize}
              classes={{
                title: classes.cardFileTitle,
                content: classes.cardFileContent,
              }}
            />
          </CardActionArea>
        </Card>
      </Tooltip>
    </>
  );
};

export default FilesPreview;
