import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

interface AccountImportState {
  submissionProgress: number;
}

const initialState: AccountImportState = {
  submissionProgress: 0,
};

const accountImportSlice = createSlice({
  name: 'accountImport',
  initialState,
  reducers: {
    setSubmissionProgress: (state, action: PayloadAction<number>) => {
      state.submissionProgress = action.payload;
    },
  },
});

export const selectSubmissionProgressState = (state: RootState) =>
  state.admin.accountImport.submissionProgress;
export const { setSubmissionProgress } = accountImportSlice.actions;
export default accountImportSlice.reducer;
