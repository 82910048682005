import { createApi } from '@reduxjs/toolkit/query/react';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { isArray } from 'lodash';

import {
  CLINICAL_DEPRECATED_FACTS,
  CLINICAL_DEPRECATED_MONTH_METRICS,
  CLINICAL_FACTS,
  CLINICAL_MONTH_METRICS,
  CLINICAL_SLEEP_FACTS,
  CLINICAL_WEEK_METRICS,
} from '@constants/reportClinical';
import { apiBaseQuery, RadiusApiError } from 'services/base';
import { User } from 'models/user';
import {
  getClinicalFacts,
  getTimezoneDate,
  getTimezoneMonthDate,
  queryParamsReportsByPeriod,
  reportFromBillingData,
  reportFromFactsData,
} from 'utils/reports';
import {
  BandStatusReportRawData,
  BillingReportPatient,
  BillingReportRawData,
  BillingReportResponse,
  ClinicalReportPatientData,
  PeriodItem,
  ReportResponse,
} from 'models/reports';
import { reportFromBandData } from 'utils/reportsBandStatus';
import { UnitSystem } from 'utils/unitSystem';

export interface QueryParams {
  patients: User[];
  start: Date;
  end: Date;
  source?: string;
  clinicianId?: string;
  unitSystem?: UnitSystem;
}

export interface ClinicianReportParams {
  period: PeriodItem;
  clinicianId?: string;
  patientId?: string;
  unitSystem?: UnitSystem;
  pageNumber?: number;
}

const eventsProcessorAPI = 'events-processor/api/v1';
const ticksStoreAPI = 'ticks-store/api/v1';

export const userReportsApi = createApi({
  reducerPath: 'userReportsApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 60,
  baseQuery: apiBaseQuery('/'),
  tagTypes: ['DeprecatedReports', 'ClinicianReports', 'MemberReports', 'AdminReports', 'Reports'],
  endpoints(builder) {
    return {
      // Deprecated Reports
      fetchClinicalReportData: builder.query<ClinicalReportPatientData[], QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          let reportData: ClinicalReportPatientData[] = [];

          for (let patient of queryParams?.patients) {
            const tz =
              patient.languageRegion?.timezone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone ||
              'America/Los_Angeles';

            const { startTime, endTime } = getTimezoneDate(queryParams.end, tz);

            const facts = CLINICAL_DEPRECATED_FACTS.map((fact: string) => `${fact}_at_${endTime}`);
            const monthMetrics = CLINICAL_DEPRECATED_MONTH_METRICS;
            const weekMetrics = CLINICAL_WEEK_METRICS;

            const [
              factsResponse,
              monthFactsResponse,
              weekFactsResponse,
              dailyFactsResponse,
              primaryTeamResponse,
              supportTeamResponse,
            ] = await Promise.all([
              baseQuery({
                url: `${eventsProcessorAPI}/users/${patient.id}/facts?facts=${facts},height`,
                method: 'GET',
              }),
              baseQuery({
                url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=MONTHLY`,
                method: 'GET',
              }),
              baseQuery({
                url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${weekMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=WEEKLY`,
                method: 'GET',
              }),
              baseQuery({
                url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=DAILY`,
                method: 'GET',
              }),
              apiBaseQuery('/registry/api/v1')(
                {
                  url: `/users/${patient.id}/clinicians?teamTypes=PRIMARY`,
                  method: 'GET',
                },
                queryApi,
                extraOptions
              ),
              apiBaseQuery('/registry/api/v1')(
                {
                  url: `/users/${patient.id}/clinicians?teamTypes=SUPPORT`,
                  method: 'GET',
                },
                queryApi,
                extraOptions
              ),
            ]);

            if (
              factsResponse.error ||
              monthFactsResponse.error ||
              weekFactsResponse.error ||
              dailyFactsResponse.error ||
              primaryTeamResponse.error ||
              supportTeamResponse.error
            ) {
              return {
                error: (factsResponse.error ||
                  monthFactsResponse.error ||
                  weekFactsResponse.error ||
                  dailyFactsResponse.error ||
                  primaryTeamResponse.error ||
                  supportTeamResponse.error) as RadiusApiError,
              };
            }

            const teams = ((primaryTeamResponse.data ?? []) as User[]).concat(
              (supportTeamResponse.data ?? []) as User[]
            );

            reportData.push(
              reportFromFactsData(
                patient,
                tz,
                startTime,
                endTime,
                (factsResponse.data as any)?.data || {},
                monthFactsResponse.data,
                weekFactsResponse.data,
                dailyFactsResponse.data,
                {},
                teams,
                queryParams.unitSystem
              )
            );
          }

          return { data: reportData };
        },
        providesTags: ['DeprecatedReports'],
      }),
      fetchBillingReport: builder.query<BillingReportRawData[], QueryParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          const from = format(startOfMonth(queryParams.start), 'yyyy-MM-dd');
          const to = format(endOfMonth(queryParams.end), 'yyyy-MM-dd');
          let data: BillingReportPatient[] = [];
          let isNext = true;
          let pageNumber = 0;
          let patients = '';

          if (queryParams?.source === 'panel') {
            const patientsResponse = await baseQuery({
              url: `/registry/api/v1/users/${queryParams?.clinicianId}/patients`,
              method: 'GET',
            });

            if (patientsResponse.error) {
              return { error: patientsResponse.error };
            }

            const patientIds = (patientsResponse.data as User[])
              .map((patient) => patient.id)
              .join(',');
            patients = `&patientIds=${patientIds}`;
          } else if (queryParams?.source === 'patient') {
            const patientIds = queryParams.patients.map((patient) => patient.id).join(',');
            patients = `&patientIds=${patientIds}`;
          }

          while (isNext) {
            const reportsResponse = await baseQuery({
              url: `/datalake-service/reports/rpm?reportFrom=${from}&reportTo=${to}&pageNumber=${pageNumber}${patients}`,
              method: 'GET',
            });

            if (reportsResponse.error) {
              return { error: reportsResponse.error };
            }

            const responseData = reportsResponse.data as BillingReportResponse;
            const reportList = responseData?._embedded?.rPMReportList || [];

            isNext = !!responseData?._links?.next;
            data = [...data, ...reportList];
            pageNumber++;
          }

          const reportsData: BillingReportRawData[] =
            isArray(data) && data.length > 0 ? reportFromBillingData(data) : [];

          return { data: reportsData };
        },
        providesTags: ['DeprecatedReports'],
      }),

      // Clinician Reports
      fetchClinicianBandStatusReport: builder.query<
        BandStatusReportRawData[],
        ClinicianReportParams
      >({
        async queryFn(queryParams, _, __, baseQuery) {
          const params = queryParamsReportsByPeriod(queryParams.period);
          let data: BandStatusReportRawData[] = [];
          let isNext = true;
          let pageNumber = 0;

          const patientsResponse = await baseQuery({
            url: `/registry/api/v1/users/${queryParams?.clinicianId}/patients`,
            method: 'GET',
          });

          if (patientsResponse.error) {
            return { error: patientsResponse.error };
          }

          const patientIds = (patientsResponse.data as User[])
            .map((patient) => patient.id)
            .join(',');
          params.append('patientIds', patientIds);

          while (isNext) {
            params.append('pageNumber', `${pageNumber}`);
            const reportsResponse = await baseQuery({
              url: `/datalake-service/v1/reports/engagement-band/${
                queryParams.period.period
              }?${params.toString()}`,
              method: 'GET',
            });

            if (reportsResponse.error) {
              return { error: reportsResponse.error };
            }

            const responseData = reportsResponse.data as ReportResponse;
            const reportList =
              ((responseData?._embedded?.engagementBandDailyReportList ||
                responseData?._embedded?.engagementBandMonthlyReportList ||
                responseData?._embedded
                  ?.engagementBandWeeklyReportList) as BandStatusReportRawData[]) || [];

            isNext = !!responseData?._links?.next;
            data = [...data, ...reportList];
            pageNumber++;
          }

          const reportsData: BandStatusReportRawData[] =
            isArray(data) && data.length > 0 ? reportFromBandData(data) : [];

          return { data: reportsData };
        },
        providesTags: ['ClinicianReports'],
      }),
      fetchClinicianRiskReport: builder.query<ClinicalReportPatientData, ClinicianReportParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const patientResponse = await baseQuery({
            url: `/registry/api/v1/users/${queryParams.clinicianId}/patients/${queryParams.patientId}`,
            method: 'GET',
          });

          if (patientResponse.error) {
            return { error: patientResponse.error };
          }

          const patient = patientResponse.data as User;

          const tz =
            patient.languageRegion?.timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/Los_Angeles';

          const { startTime, endTime } = getTimezoneMonthDate(queryParams.period.value, tz);

          const facts = getClinicalFacts(CLINICAL_FACTS, startTime, endTime);
          const sleepFacts = getClinicalFacts(CLINICAL_SLEEP_FACTS, startTime, endTime);
          const monthMetrics = CLINICAL_MONTH_METRICS;

          const [
            factsResponse,
            sleepFactsResponse,
            monthFactsResponse,
            dailyFactsResponse,
            aggHistResponse,
            primaryTeamResponse,
            supportTeamResponse,
          ] = await Promise.all([
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts?facts=${facts},height`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts?facts=${sleepFacts}`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=MONTHLY`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=DAILY`,
              method: 'GET',
            }),
            baseQuery({
              url: `${ticksStoreAPI}/users/${patient.id}/aggregate?start_time=${startTime}&end_time=${endTime}&metrics=heart_rate&reduce=hist`,
              method: 'GET',
            }),
            apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${patient.id}/clinicians?teamTypes=PRIMARY`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${patient.id}/clinicians?teamTypes=SUPPORT`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
          ]);

          if (
            factsResponse.error ||
            sleepFactsResponse.error ||
            monthFactsResponse.error ||
            dailyFactsResponse.error ||
            aggHistResponse.error ||
            primaryTeamResponse.error ||
            supportTeamResponse.error
          ) {
            return {
              error: (factsResponse.error ||
                sleepFactsResponse.error ||
                monthFactsResponse.error ||
                dailyFactsResponse.error ||
                aggHistResponse.error ||
                primaryTeamResponse.error ||
                supportTeamResponse.error) as RadiusApiError,
            };
          }

          const teams = ((primaryTeamResponse.data ?? []) as User[]).concat(
            (supportTeamResponse.data ?? []) as User[]
          );

          const reportData = reportFromFactsData(
            patient,
            tz,
            startTime,
            endTime,
            {
              ...((factsResponse.data as any)?.data || {}),
              ...((sleepFactsResponse.data as any)?.data || {}),
            },
            monthFactsResponse.data,
            [],
            dailyFactsResponse.data,
            aggHistResponse.data,
            teams,
            queryParams.unitSystem
          );

          return { data: reportData };
        },
        providesTags: ['ClinicianReports'],
      }),
    };
  },
});

export const { useLazyFetchClinicalReportDataQuery, useLazyFetchClinicianBandStatusReportQuery } =
  userReportsApi;
