import React, { MouseEvent, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/Button';
import PatientTab from 'components/Header/components/PatientTab';
import { PatientTab as PatientTabModel } from 'models/patientTab';
import useStyles from './styles';

interface SubHeaderProps {
  tabs: PatientTabModel[];
}

const PatientsMenu = ({ tabs }: SubHeaderProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const matchesUpXS = useMediaQuery(theme.breakpoints.up('xs'));

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        endIcon={
          open ? (
            <ArrowDropUpIcon className={classes.icon} />
          ) : (
            <ArrowDropDownIcon className={classes.icon} />
          )
        }
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={open ? classes.openMenu : classes.button}
      >
        <div>Patients {matchesUpXS && 'Menu'}</div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        classes={{ root: classes.menu }}
      >
        {tabs?.map((tab, index) => (
          <MenuItem onClick={handleClose} key={index} className={classes.menuItem}>
            <PatientTab tab={tab} tabs={tabs} isDropDownMenu={true} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const defaultProps = {
  tabs: [],
};

PatientsMenu.defaultProps = defaultProps;

export default PatientsMenu;
