import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import type { CustomColors } from 'styles/theme';
import { Theme } from '@mui/material/styles';

export interface FilledIconButtonProps extends IconButtonProps {
  fillColor?: CustomColors;
  hoverFillColor?: CustomColors;
  textColor?: CustomColors;
  theme?: Theme;
}

const FilledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => ['fillColor', 'hoverFillColor', 'textColor'].includes(prop.toString()) === false,
})(({ theme, fillColor, hoverFillColor, textColor }: FilledIconButtonProps) => ({
  color: theme?.palette.custom[textColor as CustomColors] || theme?.palette.custom.white,
  background: theme?.palette.custom[fillColor as CustomColors] || theme?.palette.custom.black,
  '&:hover': {
    background: theme?.palette.custom[hoverFillColor as CustomColors] || theme?.palette.custom.cyan,
  },
  '&.Mui-disabled': {
    color: theme?.palette.custom.black40,
    background: theme?.palette.custom.greyB,
  },
}));

export default FilledIconButton;
