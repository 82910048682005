import { round } from 'lodash';
import { ProgramAggregation } from 'models/programCompletionReport';
import { ExternalIdColumn } from 'models/reports';

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
}

export const getCsvHeaders = ({ externalIdColumns }: CsvHeaderProps) => {
  return [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Patient ID', key: 'userId' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'userStatus' },
    { label: 'Account Created', key: 'createdDate' },
    { label: 'Member Since', key: 'onboardedDate' },
    { label: 'Cohorts', key: 'cohorts' },
    { label: 'Program ID', key: 'programId' },
    { label: 'Program Name', key: 'programName' },
    { label: 'Program Started', key: 'programCreatedDate' },
    { label: 'Program Completion %', key: 'programCompletion' },
    { label: '# Modules', key: 'modulesCount' },
    { label: '# Modules Completed', key: 'modulesCompleted' },
    { label: '# Insights', key: 'insightsCount' },
    { label: '# Insights Completed', key: 'insightsCompleted' },
    { label: '# Pledges', key: 'pledgesCount' },
    { label: '# Pledges Completed', key: 'pledgesCompleted' },
    { label: '# Surveys', key: 'surveysCount' },
    { label: '# Surveys Completed', key: 'surveysCompleted' },
    { label: 'Program Completed', key: 'programCompletedDate' },
  ];
};

export const reportFromProgramAggregations = (programsAggregations: ProgramAggregation[]) => {
  const totals = programsAggregations.reduce(
    (prev, aggregation) => {
      if (aggregation.type === 'modules_all') {
        return {
          ...prev,
          modulesCount: aggregation.count,
          modulesCompleted: aggregation.completed,
        };
      }

      if (aggregation.type === 'insights_all') {
        return {
          ...prev,
          programStepsCount: prev.programStepsCount + aggregation.count,
          programStepsCompleted: prev.programStepsCompleted + aggregation.completed,
          insightsCompleted: aggregation.completed,
          insightsCount: aggregation.count,
        };
      }
      if (aggregation.type === 'pledges_all') {
        return {
          ...prev,
          programStepsCount: prev.programStepsCount + aggregation.count,
          programStepsCompleted: prev.programStepsCompleted + aggregation.completed,
          pledgesCompleted: aggregation.completed,
          pledgesCount: aggregation.count,
        };
      }
      if (aggregation.type === 'surveys_all') {
        return {
          ...prev,
          programStepsCount: prev.programStepsCount + aggregation.count,
          programStepsCompleted: prev.programStepsCompleted + aggregation.completed,
          surveysCompleted: aggregation.completed,
          surveysCount: aggregation.count,
        };
      }
      return prev;
    },
    {
      programStepsCount: 0,
      programStepsCompleted: 0,
      modulesCount: 0,
      modulesCompleted: 0,
      insightsCount: 0,
      insightsCompleted: 0,
      pledgesCount: 0,
      pledgesCompleted: 0,
      surveysCount: 0,
      surveysCompleted: 0,
    }
  );
  return {
    ...totals,
    programCompletion: round((totals.programStepsCompleted / totals.programStepsCount) * 100),
  };
};
