import { formatInTimeZone } from 'date-fns-tz';
import { get, round } from 'lodash';
import { ChartCsvExportRow, DateRange, SleepDepthOption } from 'models/generatedData';
import { sleepDepthLevels } from '@constants/generatedData';
import { getConvertedValue } from './generatedData';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  OTHER_METRIC_COLUMNS,
  SLEEP_EVENT_FIELDS,
  SLEEP_EVENT_TIMESTAMP_FIELDS,
  SPO2_COLUMNS,
  TEMP_COLUMNS,
  WEIGHT_COLUMNS,
} from '@constants/csvGeneratedData';

type TicksData = Record<string, Array<[number, number, number]>>;
type FactsOverTimeData = Array<{
  data: Record<string, string | number | null>;
  timestamp: number;
}>;
type SleepEventData = Record<string, any>;

export class ChartCsvDataBuilder {
  private columns: string[] = [];
  private rows: Map<string, ChartCsvExportRow> = new Map();
  private additionalContent: string[][] = [];

  constructor(
    private readonly dateRange: DateRange,
    private readonly tz: string = 'America/Los_Angeles'
  ) {
    this.columns.push('timestamp');
  }

  addTicks(ticksData: TicksData) {
    const columns = Object.keys(ticksData);
    this.appendColumns(columns);

    for (const [tickName, ticks] of Object.entries(ticksData)) {
      for (const tick of ticks) {
        this.upsertRow(tick[0], { [tickName]: tick[1] });
      }
    }
  }

  addFactsOverTime(factNames: string[], factsOverTimeData: FactsOverTimeData) {
    if (factsOverTimeData.length === 0) {
      return;
    }

    this.appendColumns(factNames);

    for (const { timestamp, data } of factsOverTimeData) {
      this.upsertRow(timestamp, data);
    }
  }

  addSleepEvents(sleepEvents: SleepEventData[]) {
    for (const sleepEvent of sleepEvents) {
      this.additionalContent.push(['sleep event field', 'value']);
      for (const key of SLEEP_EVENT_FIELDS) {
        let value = get(sleepEvent, key, '');
        if (SLEEP_EVENT_TIMESTAMP_FIELDS.includes(key)) {
          value = formatInTimeZone(value * 1000, this.tz, DATE_TIME_FORMAT);
        }
        this.additionalContent.push([key, value ? value.toString() : '']);
      }
      this.additionalContent.push(['---']);
    }
  }

  addSleepStages(sleepStages: [number, number, number][]) {
    if (sleepStages.length === 0) {
      return;
    }

    this.appendColumns(['start', 'end', 'depth', 'phase']);

    for (const [start, end, depth] of sleepStages) {
      this.upsertRow(start, {
        start: formatInTimeZone(start * 1000, this.tz, DATE_TIME_FORMAT),
        end: formatInTimeZone(end * 1000, this.tz, DATE_TIME_FORMAT),
        depth,
        phase: sleepDepthLevels[depth as SleepDepthOption],
      });
    }
  }

  getCsvData() {
    if (this.rows.size === 0) {
      return [];
    }

    const result: Array<Array<string | number | null | undefined>> = [];
    if (this.additionalContent.length > 0) {
      result.push(...this.additionalContent);
    }

    const headers = [...this.columns];
    const rows = [...this.rows.values()].sort((a, b) => a.timestamp - b.timestamp);

    result.push(headers);
    for (const row of rows) {
      result.push(
        this.columns.map((column) => {
          if (column === 'timestamp') {
            return this.formatTimestamp(row.timestamp);
          }
          if (TEMP_COLUMNS.includes(column) && row[column]) {
            return round(getConvertedValue(Number(row[column]), 'temp'), 2);
          }
          if (SPO2_COLUMNS.includes(column) && row[column]) {
            return round(getConvertedValue(Number(row[column]), 'spo2'), 2);
          }
          if (WEIGHT_COLUMNS.includes(column) && row[column]) {
            return round(getConvertedValue(Number(row[column]), 'weight'), 2);
          }
          if (OTHER_METRIC_COLUMNS.includes(column) && row[column]) {
            return round(Number(row[column]), 2);
          }
          return row[column];
        })
      );
    }
    return result;
  }

  private appendColumns(columns: string[]) {
    this.columns.push(...columns);
  }

  private upsertRow(timestamp: number, data: Partial<ChartCsvExportRow>) {
    const key = this.getRowKeyFromTimestamp(timestamp);
    if (this.rows.has(key)) {
      const existingRow = this.rows.get(key)!;
      Object.assign(existingRow, data);
    } else {
      this.rows.set(key, { timestamp, ...data });
    }
  }

  private formatTimestamp(timestamp: number) {
    const format = this.dateRange === 1 ? DATE_TIME_FORMAT : DATE_FORMAT;
    return formatInTimeZone(timestamp * 1000, this.tz, format);
  }

  private getRowKeyFromTimestamp(timestamp: number) {
    const format = {
      1: 'MMM dd h:mmaaa',
      7: 'MMM dd',
      30: 'MMM dd',
      365: 'MMM yyyy',
    }[this.dateRange];

    return formatInTimeZone(timestamp * 1000, this.tz, format);
  }
}
