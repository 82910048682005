import React, { Ref, ReactNode, useState, useRef, useCallback, MouseEvent } from 'react';
import { PopperPlacementType } from '@mui/material/Popper';
import { MenuProps as MuiMenuProps } from '@mui/material/Menu';

type AnchorOrigin = MuiMenuProps['anchorOrigin'];
type TransformOrigin = MuiMenuProps['transformOrigin'];

interface TriggerProps {
  'aria-haspopup': boolean;
  'aria-expanded': boolean;
  role: string;
  tabIndex: number;
  ref: Ref<HTMLButtonElement>;
  onClick: (event: MouseEvent) => void;
}

interface TriggerState {
  isOpen: boolean;
}

interface MenuProps {
  anchorEl: HTMLElement | null;
  anchorOrigin?: AnchorOrigin;
  transformOrigin?: TransformOrigin;
  role: string;
}

interface MenuState {
  isOpen: boolean;
  close: () => void;
}

type MenuPlacement = PopperPlacementType;

type Edge = 'left' | 'top' | 'right' | 'bottom';
type Alignment = 'start' | 'end' | undefined;

export interface MenuRendererProps {
  trigger: (props: TriggerProps, state: TriggerState) => ReactNode;
  children: (props: MenuProps, state: MenuState) => ReactNode;
  placement?: MenuPlacement;
  onOpen?: (event: MouseEvent) => void;
}

function MenuRenderer({ trigger, children, placement, onOpen }: MenuRendererProps) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const triggerProps: TriggerProps = {
    'aria-haspopup': true,
    'aria-expanded': isOpen,
    role: 'button',
    tabIndex: 0,
    ref: triggerRef,
    onClick: (event: MouseEvent) => {
      setIsOpen((curIsOpen) => !curIsOpen);
      if (typeof onOpen === 'function' && !isOpen) {
        onOpen(event);
      }
    },
  };

  const menuProps: MenuProps = {
    role: 'menu',
    anchorEl: triggerRef.current,
    anchorOrigin: placement && anchorOriginFromPlacement(placement),
    transformOrigin: placement && transformOriginFromPlacement(placement),
  };

  return (
    <>
      {trigger(triggerProps, { isOpen })}
      {children(menuProps, { isOpen, close })}
    </>
  );
}

function anchorOriginFromPlacement(placement: MenuPlacement): AnchorOrigin {
  const [edge, alignment] = placement.split('-') as [Edge, Alignment];
  let vertical: 'top' | 'bottom' | 'center';
  let horizontal: 'left' | 'right' | 'center';
  switch (edge) {
    case 'top':
      vertical = 'top';
      horizontal = asHorizontalAlignment(alignment);
      break;
    case 'bottom':
      vertical = 'bottom';
      horizontal = asHorizontalAlignment(alignment);
      break;
    case 'left':
      horizontal = 'left';
      vertical = asVerticalAlignment(alignment);
      break;
    case 'right':
      horizontal = 'right';
      vertical = asVerticalAlignment(alignment);
      break;
  }
  return { vertical, horizontal };
}

function transformOriginFromPlacement(placement: MenuPlacement): TransformOrigin {
  const [edge, alignment] = placement.split('-') as [Edge, Alignment];
  let vertical: 'top' | 'bottom' | 'center';
  let horizontal: 'left' | 'right' | 'center';
  switch (edge) {
    case 'top':
      vertical = 'bottom';
      horizontal = asHorizontalAlignment(alignment);
      break;
    case 'bottom':
      vertical = 'top';
      horizontal = asHorizontalAlignment(alignment);
      break;
    case 'left':
      horizontal = 'right';
      vertical = asVerticalAlignment(alignment);
      break;
    case 'right':
      horizontal = 'left';
      vertical = asVerticalAlignment(alignment);
      break;
  }
  return { vertical, horizontal };
}

function asHorizontalAlignment(alignment: Alignment): 'left' | 'right' | 'center' {
  if (!alignment) {
    return 'center' as const;
  }

  return alignment === 'start' ? ('left' as const) : ('right' as const);
}

function asVerticalAlignment(alignment: Alignment): 'top' | 'bottom' | 'center' {
  if (!alignment) {
    return 'center' as const;
  }

  return alignment === 'start' ? ('top' as const) : ('bottom' as const);
}

MenuRenderer.defaultProps = {
  placement: 'right-start',
};

export default MenuRenderer;
