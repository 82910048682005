export const BAND_STATUS: Record<number, string> = {
  0: 'wearing',
  1: 'removed',
  2: 'charging',
};

export const BAND_STATUS_TITLE: Record<string, string> = {
  wearing: 'Band on wrist',
  removed: 'Band off wrist',
  charging: 'Band charging',
  unknown: 'Band status unknown',
};
