import { format } from 'date-fns';
import isNaN from 'lodash/isNaN';
import { Address, FullName, UserRole } from 'models/user';
import { UnitSystem } from './unitSystem';
import { ReportExternalId } from 'services/configurator';

// Height (METERS)
export const formatHeight = (
  heightMeters: number,
  unitSystem: UnitSystem,
  format?: 'long' | 'short' | 'narrow'
) => {
  return unitSystem.formatLocaleHeight(unitSystem.localeHeight(heightMeters), format);
};

// Weight (KILOGRAMS)
export const formatWeight = (weightKg: number, unitSystem: UnitSystem) => {
  return unitSystem.formatLocaleWeight(unitSystem.localeWeight(weightKg));
};

export const formatSkinTemp = (tempC: number, unitSystem: UnitSystem) => {
  return unitSystem.formatLocaleTemperature(unitSystem.localeTemperature(tempC));
};

// Height (METERS), Weight (KILOGRAMS)
export const calculateBMI = (height: number, weight: number) => {
  const bodyMassIndex = weight / (height * height);
  if (isNaN(bodyMassIndex)) {
    return null;
  }
  return Number(bodyMassIndex.toFixed(1));
};

export const formatDate = (date: string) => {
  const newDate = new Date(date);
  return format(newDate, 'MM/dd/yyyy');
};

export const fullName = (user: FullName | undefined | null, separator: string = ' ') => {
  if (!user) {
    return '';
  }

  if (user.middleName) {
    return [user.firstName, user.middleName, user.lastName].join(separator);
  }
  return [user.firstName, user.lastName].join(separator);
};

export const formatAddress = (address?: Address | null) => {
  if (!address || (!address.city && !address.state && !address.zip)) {
    return null;
  }

  return `${address?.city && `${address?.city}, `} ${address?.state} ${address?.zip}`;
};

export const formatMainName = (user: FullName | undefined | null) => {
  if (!user) {
    return '';
  }

  const lastName = user.lastName?.trim();
  const firstName = user.firstName?.trim();

  if (lastName && firstName) {
    return [user.lastName, user.firstName].join(', ');
  }

  return '';
};

export const formatRoles = (roles: UserRole[]) => {
  if (roles?.length) {
    if (roles.length === 1 && roles[0] === 'clinician') {
      return ['Provider'];
    }

    const formattedRoles = roles
      .filter((role) => role !== 'clinician')
      .map((role) => (role === 'md' ? 'Provider' : role));

    return formattedRoles;
  }
  return [];
};

export function nameMatches(fullName: FullName, term: string): boolean {
  const normalizedTerm = term.trim().toLocaleUpperCase();
  const firstLast = `${fullName.firstName} ${fullName.lastName}`.toLocaleUpperCase();
  const lastFirst = `${fullName.lastName} ${fullName.firstName}`.toLocaleUpperCase();
  return firstLast.includes(normalizedTerm) || lastFirst.includes(normalizedTerm);
}

export function formatExternalId(str: string, externalIdsConfig: ReportExternalId[]) {
  const externalId = externalIdsConfig.find((item: ReportExternalId) => item.externalId === str);
  if (externalId?.displayName) {
    return externalId.displayName;
  }

  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
