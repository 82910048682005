import makeStyles from '@mui/styles/makeStyles';
import variables from 'styles/variables.module.scss';
import { DARK_MODE } from '@constants/global';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontSize: '1.2rem',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.custom.blackFill,
    ...(theme.palette.mode === DARK_MODE
      ? { borderBottom: `1px solid ${theme.palette.custom.white20}` }
      : {}),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      columnGap: 'unset',
    },
  },
  toolbarRoot: {
    minHeight: variables.headerSize,
    gridTemplateColumns: '25vw 50vw 25vw',
    padding: '0',
    width: '100%',
    display: 'inline-grid',
    border: 'none',
  },
  leftSide: {
    alignItems: 'center',
    display: 'flex',
  },
  linkPatientsContainer: {
    width: 'auto',
    display: 'flex',
  },
  link: {
    color: 'white',
    display: 'flex',
    margin: '0 1.5rem',
    [theme.breakpoints.down('lg')]: {
      marginRight: 'unset',
    },
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      backgroundColor: theme.palette.custom.blackFill,
      '& .MuiBadge-badge': { borderColor: theme.palette.custom.blackFill },
      '&:hover': {
        borderColor: theme.palette.custom.black,
        backgroundColor: theme.palette.custom.black,
        '& .MuiBadge-badge': { borderColor: theme.palette.custom.black },
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '25vw',
    },
  },
  notificationsIcon: {
    color: 'white',
  },
  phoneBadge: {
    background: 'white',
  },
  phoneIcon: {
    color: theme.palette.custom.green,
  },
}));

export default useStyles;
