export const diseasesListMap: Record<string, string> = {
  acute_respiratory_infection: 'Acute Respiratory Infection',
  anemia: 'Anemia',
  angina: 'Angina',
  ankylosing_spondylitis: 'Ankylosing Spondylitis',
  atrial_fibrilation: 'Atrial Fibrillation',
  benign_prostatic_hyperplasia: 'Benign Prostatic Hyperplasia',
  bronchial_asthma: 'Bronchial Asthma',
  celiac_disease: 'Celiac Disease',
  chlamydia: 'Chlamydia',
  cholelithiasis: 'Cholelithiasis',
  chronic_liver_disease: 'Chronic Liver Disease',
  chronic_obstructive_pulmonary_disease: 'Chronic Obstructive Pulmonary Disease',
  coronary_heart_disease: 'Coronary Heart Disease',
  covid_19: 'Covid-19',
  covid_19_severe: 'Covid-19 Severe',
  depression: 'Depression',
  end_stage_renal_disease: 'End Stage Renal Disease',
  endometriosis: 'Endometriosis',
  gastroenteritis: 'Gastroenteritis',
  generalised_anxiety_disorder: 'Generalised Anxiety Disorder',
  glaucoma: 'Glaucoma',
  gout: 'Gout',
  heart_failure: 'Heart Failure',
  hepatitis_b_c_d: 'Hepatitis [B, C, D]',
  hiv: 'HIV',
  hpv: 'HPV',
  hypercholesterolemia: 'Hypercholesterolemia',
  hypertension: 'Hypertension',
  hypothyroidism: 'Hypothyroidism',
  inflammatory_bowel_disease: 'Inflammatory Bowel Disease',
  insomnia: 'Insomnia',
  irritable_bowel_syndrome: 'Irritable Bowel Syndrome',
  lower_respiratory_infection: 'Lower Respiratory Infection',
  menopause: 'Menopause',
  nephrolithiasis: 'Nephrolithiasis',
  obstructive_sleep_apnea: 'Obstructive Sleep Apnea',
  osteoarthritis: 'Osteoarthritis',
  osteoporosis: 'Osteoporosis',
  panic_disorder: 'Panic Disorder',
  polycystic_ovarian_syndrome: 'Polycystic Ovarian Syndrome',
  presbycusis: 'Presbycusis',
  psoriasis: 'Psoriasis',
  restless_legs_syndrome: 'Restless Legs Syndrome',
  rheumatoid_arthritis: 'Rheumatoid Arthritis',
  seasonal_allergies: 'Seasonal Allergies',
  stroke: 'Stroke',
  syphilis: 'Syphilis',
  tinnitus: 'Tinnitus',
  tuberculosis: 'Tuberculosis',
  type_2_diabetes: 'Type 2 Diabetes',
  upper_respiratory_infection: 'Upper Respiratory Infection',
  urinary_incontinence: 'Urinary Incontinence',
  uterine_fibroids: 'Uterine Fibroids',
  valvular_heart_disease: 'Valvular Heart Disease',
  vitamin_d_deficiency: 'Vitamin D Deficiency',
};

export const diseasesDetails: any = {
  acute_respiratory_infection: {
    description:
      'Infection of the respiratory system caused by a virus or bacteria that inflames the air sacs in one or both lungs, causing a productive cough with phlegm or pus, fever, chills, or breathing difficulties.',
    externalLink: '',
  },
  anemia: {
    description:
      "Condition characterized by a deficiency of red blood cells or hemoglobin, leading to reduced oxygen transport to the body's tissues and organs. It can have various causes, including iron deficiency, vitamin deficiencies, chronic diseases, or inherited disorders.",
    externalLink: '',
  },
  angina: {
    description:
      'Chest pain or discomfort caused by reduced oxygenation to the heart. Can be triggered by physical exertion such as walking up stairs.',
    externalLink: '',
  },
  ankylosing_spondylitis: {
    description:
      'A type of arthritis that causes inflammation in the joints and ligamnets of the spine, causing pain and stiffness.',
    externalLink: '',
  },
  atrial_fibrilation: {
    description:
      'Occurs when the upper chambers and the lower chambers of the heart are not coordinated, often causing an irregular and rapid arrhythmia that can lead to blood clots in the heart.',
    externalLink: '',
  },
  benign_prostatic_hyperplasia: {
    description:
      'Condition where the prostate gland enlarges and is the most common benign tumor found in men typically over the age of 50.',
    externalLink: '',
  },
  bronchial_asthma: {
    description:
      'Chronic inflammatory disease of the respiratory tract, where the airways narrow and swell and may produce extra mucus. Results in difficulty breathing, coughing, wheezing, and shortness of breath.',
    externalLink: '',
  },
  celiac_disease: {
    description:
      'Autoimmune disease that causes the immune system to attack gluten in the small intestine, causing malabsorption of nutrients and symptoms such as stomach pain, bloating, and fatty stools.',
    externalLink: '',
  },
  chlamydia: {
    description:
      "Common sexually transmitted infection (STI) affecting both men and women, often asymptomatic yet can damage a woman's reproductive system leading to infertility and ectopic pregnancy.",
    externalLink: '',
  },
  cholelithiasis: {
    description:
      'A condition characterized by the presence of gallstones (consisting of cholesterol or bilirubin) within the gallbladder or bile ducts. It can cause inflammation, infection, and obstruction of the biliary system leading to abdominal pain and discomfort.',
    externalLink: '',
  },
  chronic_liver_disease: {
    description:
      'A progressive deterioration of liver functions leading to fibrosis and cirrhosis, commonly caused by hepatitis, other viruses, and alcohol abuse.',
    externalLink: '',
  },
  chronic_obstructive_pulmonary_disease: {
    description:
      'Chronic inflammatory lung diease that causes obstructed airflow from the lungs resulting in breathing difficulty, cough, sputum production, and wheezing. Majority of cases are caused by smoking.',
    externalLink: '',
  },
  coronary_heart_disease: {
    description:
      'A type of heart disease where plaque builds up in the arteries and reduces delivery of oxygen-rich blood to the heart, leading to clots, chest pain, and heart attack.',
    externalLink:
      'https://www.nhlbi.nih.gov/resources/know-differences-cardiovascular-disease-heart-disease-coronary-heart-disease',
  },
  covid_19: {
    description:
      "The condition caused by the SARS-CoV-2 virus that is spread from an infected person's mouth or nose in small liquid particles when they cough, sneeze, speak, sing, or breathe.",
    externalLink: '',
  },
  covid_19_severe: {
    description:
      'A more advanced case of COVID-19 that causes significant breathing problems and requires hospitalization, oxygen therapy, or a breathing tube.',
    externalLink: '',
  },
  depression: {
    description:
      'A mood disorder that causes a persistent feeling of sadness and loss of interest, and can lead to a variety of emotional and physical problems.',
    externalLink: '',
  },
  end_stage_renal_disease: {
    description:
      'A condition where the kidneys stop functioning properly, leading to dangerous levels of fluid, electrolytes and waste build up. Long-term dialysis or a kidney transplant is required for survival.',
    externalLink: '',
  },
  endometriosis: {
    description:
      'A condition in which endometrial tissue grows outside the uterus, causing pain, heavy menstrual bleeding, and/or infertility.',
    externalLink: '',
  },
  gastroenteritis: {
    description:
      'Acute intestinal infection caused by a bacteria or virus that causes symptoms such as diarrhea, nausea or vomiting, and sometimes fever.',
    externalLink: '',
  },
  generalised_anxiety_disorder: {
    description:
      'A condition of persistant and excessive worry about everyday issues and situations that interferes with living life.',
    externalLink: '',
  },
  glaucoma: {
    description:
      'An eye disease characterized by optic nerve damage due to elevated intraocular pressure and may lead to vision loss or blindness. It is a leading cause of irreversible blindness worldwide, and early detection and treatment are crucial for preventing vision loss.',
    externalLink: '',
  },
  gout: {
    description:
      'A form of arthritis where urate crystals accumulate in the joint, causing sudden severe attacks of pain, swelling, and tenderness in one or more joints, most often in the big toe.',
    externalLink:
      'https://www.mayoclinic.org/diseases-conditions/gout/symptoms-causes/syc-20372897',
  },
  heart_failure: {
    description:
      "Condition where the heart doesn't pump blood effectively. Causes blood to back up and fluid to pool up in the lungs causing shortness of breath.",
    externalLink: '',
  },
  hepatitis_b_c_d: {
    description:
      'Inflammation of the liver caused by a virus that can be a short-term infection or become chronic, which increases risk of developing liver failure, liver cancer, or cirrhosis.',
    externalLink: '',
  },
  hiv: {
    description:
      'Infection caused by the Human Immunodeficiency Virus (HIV) which destroys white blood cells and requires management with antiretroviral therapy (ART) to delay further immune suppresion, and can lead to AIDS (Acquired Immunodeficiency Syndrome) if left untreated.',
    externalLink: '',
  },
  hpv: {
    description:
      'A firus and the most common sexually transmitted infection (STI) that can cause warts and is associated with the development of certain types of cancer, including cervical, anal, and oropharyngeal cancer.',
    externalLink: '',
  },
  hypercholesterolemia: {
    description:
      'Disorder where there are abnormally high levels of cholesterol, leading to atherosclerosis and increased chance for heart attack and stroke.',
    externalLink: '',
  },
  hypertension: {
    description:
      'Condition where blood pressure is consistently above 130/80 mm Hg, and is a major risk factor for stroke, heart disease, and kidney dysfunction.',
    externalLink: '',
  },
  hypothyroidism: {
    description:
      "Condition where the thyroid gland doesn't make enough thyroid hormones, leading to obesity, joint pain, infertility, and heart disease.",
    externalLink: '',
  },
  inflammatory_bowel_disease: {
    description:
      "Chronic disorders involving inflammation of the digestive tract. Includes ulcerative colitis and Crohn's disease.",
    externalLink: '',
  },
  insomnia: {
    description:
      'A common sleep disorder that makes it hard to fall asleep, stay asleep, or wake up too early and not be able to get back to sleep.',
    externalLink:
      'https://www.mayoclinic.org/diseases-conditions/insomnia/symptoms-causes/syc-20355167',
  },
  irritable_bowel_syndrome: {
    description:
      'A chronic, functional gastrointestinal disorder characterized by abdominal pain, discomfort, bloating, and altered bowel habits (diarrhea or constipation) in the absence of any structural abnormalities or inflammation.',
    externalLink: '',
  },
  lower_respiratory_infection: {
    description:
      'An infection caused by bacteria or viruses that affect the airways below the voicebox, including the trachea, bronchi, and the alveolar sacs. Common infections are pneumonia, bronchitis, and influenza.',
    externalLink: '',
  },
  menopause: {
    description:
      "A natural decline in reproductive hormones in which a woman's menstrual cycle ends, typicall occuring between the ages of 45 and 55.",
    externalLink: '',
  },
  nephrolithiasis: {
    description:
      'A condition where stones (made of mineral and acid salts) are formed in the kidneys or urinary tract. These stones can cause severe pain, obstruction, and sometimes lead to infection or kidney damage.',
    externalLink: '',
  },
  obstructive_sleep_apnea: {
    description:
      'Sleep disorder characterized by episodes of complete or partial collapse of the airway with an associated arousal from sleep.',
    externalLink: 'https://www.ncbi.nlm.nih.gov/books/NBK459252/',
  },
  osteoarthritis: {
    description:
      'The most common form of arthritis, occuring when the protective cartilage cushioning the ends of the bones wears down over time. Most commonly affects joints in hands, knees, hips, and spine.',
    externalLink:
      'https://www.mayoclinic.org/diseases-conditions/osteoarthritis/symptoms-causes/syc-20351925',
  },
  osteoporosis: {
    description:
      'A bone disease that develops when bone mineral density and bone mass decreases, or quality or structure of bone changes, leading to an increase risk of fractures.',
    externalLink: '',
  },
  panic_disorder: {
    description:
      'An anxiety disorder characterized by unexpected and recurrent episodes of intense fear accompanied by physical symptoms that may include chest pain, palpitations, shortness of breath, or a feeling of impending doom.',
    externalLink: 'https://www.nimh.nih.gov/health/statistics/panic-disorder',
  },
  peptic_ulcer_disease: {
    description:
      'A condition characterized by mucosal erosion in the lining of the stomach, duodenum, or esophagus. It is most commonly caused by infection with Helicobacter pylori, long-term use of nonsteroidal anti-inflammatory drugs (NSAIDs), or excessive acid production in the stomach.',
    externalLink: '',
  },
  pernicious_anemia: {
    description: 'A type of anemia casued by the lack of vitamin B12.',
    externalLink: '',
  },
  polycystic_ovarian_syndrome: {
    description:
      'A condition in which the ovaries produce an abnormal amount of male sex hormones called androgens, causing irregular menstrual periods, excess hair growth, acne, and infertility.',
    externalLink: '',
  },
  presbycusis: {
    description:
      'Age-related, progressive, and permanant hearing loss that is due to changes in the inner ear or auditory nerve.',
    externalLink: '',
  },
  psoriasis: {
    description:
      'A common and chronic skin disease that causes a rash with itchy, scaly patches, most commonly on the knees, elbows, trunk, and scalp.',
    externalLink: '',
  },
  restless_legs_syndrome: {
    description:
      'A neurological disorder characterized by unpleseant or uncomfortable sensation in the legs and an irresistible urge to move them. Sensations can be described as creeping, itching, pulling, crawling, thorbbing, or burning.',
    externalLink: '',
  },
  rheumatoid_arthritis: {
    description:
      'An autoimmune and inflammatory disease that commonly attacks the joint, causing painful swelling in the affected parts of the body. The joints in the hands, wrist, and knees are commonly affected.',
    externalLink: 'https://www.cdc.gov/arthritis/basics/rheumatoid-arthritis.html',
  },
  seasonal_allergies: {
    description:
      'Also known as hay fever or allergic rhinitis, is an immune-mediated response to airborne allergens that affects the upper respiratory system. Symptoms include sneezing, rhinorrhea, itchy eyes, and congestion.',
    externalLink: '',
  },
  stroke: {
    description:
      'Commonly called a brain attack, occurs when the blood supply to a part of the brain is interrupted or reduced, either by a blockage or a bleed.',
    externalLink: '',
  },
  syphilis: {
    description:
      'A sexually transmitted infection (STI) caused by the bacterium Treponema pallidum that results in serious health problems without treatment, where infection develops in stages with different signs and symptoms.',
    externalLink: '',
  },
  tinnitus: {
    description:
      'A condition characterized by the perception of sound, such as ringing or buzzing, without an external sound source. It can also be experienced as hissing, buzzing, or other sounds, and can cause significant distress and impairment of quality of life.',
    externalLink: '',
  },
  tuberculosis: {
    description:
      'Infectitious bacterial disease caused by Mycobacterium tuberculosis that primarily affects the lungs, causing cough, fever, chest pain, and night sweats.',
    externalLink: '',
  },
  type_2_diabetes: {
    description:
      "The most common type of diabetes, which occurs because the body doesn't make enough insulin or doesn't use well, resulting in too much glucose that stays in the blood and not reaching the cells.",
    externalLink: '',
  },
  upper_respiratory_infection: {
    description:
      'An infection of the the nose, sinuses, pharynx, or larynx that is typically caused by viruses and results in cold symptoms of sore throat, runy nose, congestion, and fever.',
    externalLink: '',
  },
  urinary_incontinence: {
    description:
      'Involuntary leakage of urine from the bladder, that may be brought upon by sneezing or coughing.',
    externalLink: '',
  },
  uterine_fibroids: {
    description:
      'A type of noncancerous (benign) growth made up of muscle and connective tissue that can grow in and on your uterus.',
    externalLink: '',
  },
  valvular_heart_disease: {
    description:
      'Condition where any valve in the heart has damage or is diseased, caused by conditions like rheumatic disease, endocarditis, hypertension, ischemic heart disease, or heart failure.',
    externalLink: '',
  },
  vitamin_d_deficiency: {
    description:
      'A common condition characterized by insufficient vitamin D levels, which can lead to a wide range of health problems, including bone loss and fractures, muscle weakness, and an increased risk of diseases such as diabetes, hypertension, and infection.',
    externalLink: '',
  },
};

export const ANOMALY_LABELS: Record<string, string> = {
  score: 'Anomaly Score',
  background_hr: 'Resting Heart \nRate (bpm)',
  rmssd: 'Heart Rate \nVariability (ms)',
  resp_rate: 'Respiration \nRate (br/min)',
  skin_temp: 'Skin Temp \n(°C)',
};
