import { combineReducers } from '@reduxjs/toolkit';

import sdkReducer from 'store/messaging/sdk';
import channelReducer from 'store/messaging/channel';

const messagingReducer = combineReducers({
  sdk: sdkReducer,
  channel: channelReducer,
});

export default messagingReducer;
