import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from 'services/base';

interface AnalyticsEventData {
  metadata: {
    eventType: string;
    userId?: string;
    tenantId?: string;
    sessionId?: string;
    timestamp: string;
    client: string;
    clientVersion: string;
    userAgent: string;
    operatingSystem: string;
  };
  event: {
    componentName: string;
    parentComponent: string;
    type: string;
  };
}

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: apiBaseQuery('/analytics/event_populator/api/v1'),
  tagTypes: ['AnalyticsEvent'],
  endpoints(builder) {
    return {
      createAnalyticsEvent: builder.mutation<string, AnalyticsEventData | void>({
        query: (event) => ({
          url: '/event',
          method: 'POST',
          data: event,
        }),
        invalidatesTags: ['AnalyticsEvent'],
      }),
    };
  },
});

export const { useCreateAnalyticsEventMutation } = analyticsApi;
