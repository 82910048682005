import ROUTES, { ADMIN_ROUTES } from '@constants/routes';

interface UserRouteItem {
  link: string;
  text: string;
}

export const CARE_ROUTE_ITEMS: UserRouteItem[] = [
  {
    link: ROUTES.main,
    text: 'Today',
  },
  {
    link: ROUTES.panel,
    text: 'Panel',
  },
  {
    link: ROUTES.calendar,
    text: 'Calendar',
  },
  {
    link: ROUTES.account,
    text: 'Account',
  },
  {
    link: ROUTES.support,
    text: 'Support',
  },
  {
    link: ROUTES.reports,
    text: 'Reports',
  },
  {
    link: ROUTES.team,
    text: 'Team',
  },
];

export const ADMIN_ROUTE_ITEMS: UserRouteItem[] = [
  {
    link: ADMIN_ROUTES.members,
    text: 'Members',
  },
  {
    link: ADMIN_ROUTES.staff,
    text: 'Staff',
  },
];
