import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

import { CustomColors } from 'styles/theme';
import { styled } from '@mui/material/styles';

interface StyledListItemProps extends ListItemButtonProps {
  color: CustomColors;
  isTitle?: boolean;
  isCalendarItem?: boolean;
}

const StyledListItem = styled(({ isTitle, isCalendarItem, ...props }: StyledListItemProps) => (
  <ListItemButton {...props} />
))(({ theme, color, isTitle, isCalendarItem }) => ({
  padding: '1.2rem 5rem 1.2rem 0rem',
  ...(isTitle && {
    paddingTop: '4rem',
  }),
  ...(isCalendarItem && {
    paddingLeft: '1.2rem',
  }),
  '& .MuiListItemSecondaryAction-root': {
    color: theme.palette.custom[color],
    right: '2.4rem',
    ...(isTitle && {
      top: '5rem',
    }),
  },
  '& .notificationIcon': {
    color: theme.palette.custom[color],
    marginRight: '1rem',
  },
  '& .MuiBadge-badge': {
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom[color],
    minWidth: '18px',
    width: '18px',
    height: '18px',
    right: '-20px',
  },
  '& .eventTitle': {
    color: theme.palette.custom[color],
  },
  '& .eventTime': {
    color: theme.palette.text.disabled,
  },
  '& .eventAction': {
    visibility: 'hidden',
    height: '18px',
  },
  '&:hover .eventAction': {
    visibility: 'visible',
  },
}));

export default StyledListItem;
