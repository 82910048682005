import { FeatureGroup } from '../hooks/featureFlag';

const featureFlags: FeatureGroup = {
  content: true,
  memberReport: true,
  adminReport: true,
  clinicianReport: true,
};

export default featureFlags;
