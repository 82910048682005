import { createApi } from '@reduxjs/toolkit/query/react';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';

import { Note } from 'models/note';
import { User } from 'models/user';
import { apiBaseQuery } from 'services/base';

interface QueryParams {
  tenantId: string;
  patientId: string;
}

export const notesApi = createApi({
  reducerPath: 'notesApi',
  baseQuery: apiBaseQuery('/notary/api/v1'),
  tagTypes: ['Note'],
  endpoints(builder) {
    return {
      fetchNotesHistory: builder.query<Note[], QueryParams | void>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const notes = await baseQuery({
            url: `/tenant/${queryParams?.tenantId}/patient/${queryParams?.patientId}/notes/history`,
            method: 'GET',
          });

          if (notes.error) {
            return { error: notes.error };
          }

          let notesData = notes.data as Note[];

          if (notesData.length) {
            const clinicianIds = uniq(notesData.map((note: Note) => note.clinicianId));
            const clinicianProfiles = await apiBaseQuery('/registry/api/v1')(
              {
                url: [
                  '/users/?userIds=',
                  clinicianIds.join(','),
                  `&size=${clinicianIds.length}`,
                ].join(''),
                method: 'GET',
              },
              queryApi,
              extraOptions
            );

            if (clinicianProfiles.error) {
              return { error: clinicianProfiles.error };
            }

            const clinicianProfilesData = clinicianProfiles.data as { content: User[] };
            const clinicianProfilesMap = keyBy(clinicianProfilesData.content, 'id');
            notesData = notesData.map((note: Note) => ({
              ...note,
              user: clinicianProfilesMap[note.clinicianId] || null,
            }));

            return { data: notesData };
          }

          return { data: [] };
        },
        providesTags: ['Note'],
      }),
      fetchLastNoteInstance: builder.query<Note, QueryParams | void>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const note = await baseQuery({
            url: `/tenant/${queryParams?.tenantId}/patient/${queryParams?.patientId}/note/last`,
            method: 'GET',
          });

          if (note.error) {
            return { error: note.error };
          }

          let noteData = note.data as Note;
          if (noteData) {
            const clinicianProfile = await apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${noteData.clinicianId}`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            );

            if (clinicianProfile.error) {
              return { error: clinicianProfile.error };
            }

            const clinicianData = clinicianProfile.data as User;
            noteData = {
              ...noteData,
              user: clinicianData || null,
            };
          }

          return { data: noteData };
        },
        providesTags: ['Note'],
      }),
      createNoteInstance: builder.mutation<Note, Partial<Note>>({
        query: (body) => ({
          url: `/note`,
          method: 'post',
          data: { ...body },
        }),
        invalidatesTags: ['Note'],
      }),
    };
  },
});

export const {
  useFetchNotesHistoryQuery,
  useFetchLastNoteInstanceQuery,
  useCreateNoteInstanceMutation,
} = notesApi;
