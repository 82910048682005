import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { AxiosRequestConfig } from 'axios';
import { RadiusApiError } from 'services/base';
import { format } from 'date-fns';
import { entries, keyBy, map } from 'lodash';
import { ActionSpecPDFChartData, ActionSpecReport } from 'models/actionSpecReport';
import { PeriodItem } from 'models/reports';
import { User } from 'models/user';
import { formatSeconds } from 'utils/reports';

interface RequestParams {
  period: PeriodItem;
  members?: User[];
  clinicianId?: string;
}

type baseQueryType = (
  arg: AxiosRequestConfig
) => MaybePromise<QueryReturnValue<unknown, RadiusApiError, {}>>;

interface AppComplianceRequestData {
  queryParams: RequestParams;
  baseQuery: baseQueryType;
}

interface ContentInteraction {
  interactions: number;
}

interface ActionInteraction {
  data: ActionSpecReport[];
  count: number;
}

interface FactuaryCounterData {
  tenant_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  counters: {
    action?: {
      remain_opened?: ActionInteraction;
      expired?: ActionInteraction;
      closed?: ActionInteraction;
      completed?: ActionInteraction;
      escalated?: ActionInteraction;
      deescalated?: ActionInteraction;
      reassigned?: ActionInteraction;
    };
    insight?: ContentInteraction;
    pledge?: ContentInteraction;
    survey?: ContentInteraction;
  };
}

export interface FactuaryCounter {
  uid: string;
  firstName: string;
  lastName: string;
  insights?: number;
  surveys?: number;
  pledges?: number;
  actionsOpened?: number;
  actionsClosed?: number;
  actionsExpired?: number;
  actionsCompleted?: number;
  actionsEscalated?: number;
  actionReassigned?: number;
  actionDeescalated?: number;
  actions: ActionSpecReport[];
}

const ACTION_DATE_FORMAT = 'MMM d, yyy hh:mm:ss aa';

const fetchFactuaryCounters = async ({ queryParams, baseQuery }: AppComplianceRequestData) => {
  const fromDate = format(queryParams.period.value.start, 'yyyyMMdd');
  const toDate = format(queryParams.period.value.end, 'yyyyMMdd');

  const response = await baseQuery({
    url: `/factuary/api/v2/report${
      !!queryParams.clinicianId ? '/by-clinician' : ''
    }?from_date=${fromDate}&to_date=${toDate}`,
    method: 'GET',
  });

  if (response?.data) {
    const countersData = {
      ...(response.data as { data: FactuaryCounterData[] }),
    };
    let openedCount = 0;
    let closedCount = 0;
    let completedCount = 0;
    let expiredCount = 0;
    let escalatedCount = 0;
    let deescalatedCount = 0;
    let reassignedCount = 0;
    let actionGroup: Record<string, ActionSpecPDFChartData> = {};
    const patientsListAmount = countersData.data?.length;
    let patientsSet = new Set<string>();
    const countersByUser: Record<string, FactuaryCounter> = keyBy(
      countersData.data.map((counter) => {
        const open: ActionSpecReport[] = counter.counters.action?.remain_opened
          ? counter.counters.action?.remain_opened?.data.map((action) => {
              if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                if (actionGroup[action.title]) {
                  actionGroup[action.title].remain_opened += 1;
                } else {
                  actionGroup[action.title] = {
                    remain_opened: 1,
                    closed: 0,
                    completed: 0,
                    expired: 0,
                    escalated: 0,
                    deescalated: 0,
                    reassigned: 0,
                  };
                }
              }

              if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                patientsSet.add(action.patientId);
              }

              return {
                ...action,
                status: 'OPEN',
                timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                class:
                  action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                    ? 'PRIORITY'
                    : 'STANDARD',
              };
            })
          : [];
        const closed: ActionSpecReport[] = counter.counters.action?.closed
          ? counter.counters.action?.closed?.data.map((action) => {
              if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                if (actionGroup[action.title]) {
                  actionGroup[action.title].closed += 1;
                } else {
                  actionGroup[action.title] = {
                    remain_opened: 0,
                    closed: 1,
                    completed: 0,
                    expired: 0,
                    escalated: 0,
                    deescalated: 0,
                    reassigned: 0,
                  };
                }
              }

              if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                patientsSet.add(action.patientId);
              }
              return {
                ...action,
                status: 'CLOSED',
                delta_time_formatted: action.delta_time ? formatSeconds(action.delta_time) : '',
                timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                statusAt: action.time_closed,
                statusDate: action.time_closed
                  ? format(action.time_closed * 1000, ACTION_DATE_FORMAT)
                  : undefined,
                class:
                  action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                    ? 'PRIORITY'
                    : 'STANDARD',
              };
            })
          : [];
        const completed: ActionSpecReport[] = counter.counters.action?.completed
          ? counter.counters.action?.completed?.data.map((action) => {
              if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                if (actionGroup[action.title]) {
                  actionGroup[action.title].completed += 1;
                } else {
                  actionGroup[action.title] = {
                    remain_opened: 0,
                    closed: 0,
                    completed: 1,
                    expired: 0,
                    escalated: 0,
                    deescalated: 0,
                    reassigned: 0,
                  };
                }
              }

              if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                patientsSet.add(action.patientId);
              }
              return {
                ...action,
                status: 'INTERVENED',
                delta_time_formatted: action.delta_time ? formatSeconds(action.delta_time) : '',
                timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                statusAt: action.time_completed,
                statusDate: action.time_completed
                  ? format(action.time_completed * 1000, ACTION_DATE_FORMAT)
                  : undefined,
                class:
                  action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                    ? 'PRIORITY'
                    : 'STANDARD',
              };
            })
          : [];
        const expired: ActionSpecReport[] = counter.counters.action?.expired
          ? counter.counters.action?.expired?.data.map((action) => {
              if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                if (actionGroup[action.title]) {
                  actionGroup[action.title].expired += 1;
                } else {
                  actionGroup[action.title] = {
                    remain_opened: 0,
                    closed: 0,
                    completed: 0,
                    expired: 1,
                    escalated: 0,
                    deescalated: 0,
                    reassigned: 0,
                  };
                }
              }

              if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                patientsSet.add(action.patientId);
              }

              return {
                ...action,
                status: 'EXPIRED',
                delta_time_formatted: action.delta_time ? formatSeconds(action.delta_time) : '',
                timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                statusAt: action.time_expired,
                statusDate: action.time_expired
                  ? format(action.time_expired * 1000, ACTION_DATE_FORMAT)
                  : undefined,
                class:
                  action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                    ? 'PRIORITY'
                    : 'STANDARD',
              };
            })
          : [];
        const escalated: ActionSpecReport[] =
          queryParams.clinicianId && counter.counters.action?.escalated
            ? counter.counters.action?.escalated?.data.map((action) => {
                if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                  if (actionGroup[action.title]) {
                    actionGroup[action.title].escalated += 1;
                  } else {
                    actionGroup[action.title] = {
                      remain_opened: 0,
                      closed: 0,
                      completed: 0,
                      expired: 0,
                      escalated: 1,
                      deescalated: 0,
                      reassigned: 0,
                    };
                  }
                }

                if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                  patientsSet.add(action.patientId);
                }

                return {
                  ...action,
                  status: 'ESCALATED',
                  delta_time_formatted: action.delta_time ? formatSeconds(action.delta_time) : '',
                  timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                  statusAt: action.time_escalated,
                  statusDate: action.time_escalated
                    ? format(action.time_escalated * 1000, ACTION_DATE_FORMAT)
                    : undefined,
                  class:
                    action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                      ? 'PRIORITY'
                      : 'STANDARD',
                };
              })
            : [];
        const deescalated: ActionSpecReport[] =
          queryParams.clinicianId && counter.counters.action?.deescalated
            ? counter.counters.action?.deescalated?.data.map((action) => {
                if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                  if (actionGroup[action.title]) {
                    actionGroup[action.title].deescalated += 1;
                  } else {
                    actionGroup[action.title] = {
                      remain_opened: 0,
                      closed: 0,
                      completed: 0,
                      expired: 0,
                      escalated: 0,
                      deescalated: 1,
                      reassigned: 0,
                    };
                  }
                }

                if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                  patientsSet.add(action.patientId);
                }

                return {
                  ...action,
                  status: 'DEESCALATED',
                  delta_time_formatted: action.delta_time ? formatSeconds(action.delta_time) : '',
                  timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                  statusAt: action.time_deescalated,
                  statusDate: action.time_deescalated
                    ? format(action.time_deescalated * 1000, ACTION_DATE_FORMAT)
                    : undefined,
                  class:
                    action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                      ? 'PRIORITY'
                      : 'STANDARD',
                };
              })
            : [];
        const reassigned: ActionSpecReport[] =
          queryParams.clinicianId && counter.counters.action?.reassigned
            ? counter.counters.action?.reassigned?.data.map((action) => {
                if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
                  if (actionGroup[action.title]) {
                    actionGroup[action.title].reassigned += 1;
                  } else {
                    actionGroup[action.title] = {
                      remain_opened: 0,
                      closed: 0,
                      completed: 0,
                      expired: 0,
                      escalated: 0,
                      deescalated: 0,
                      reassigned: 1,
                    };
                  }
                }

                if (queryParams.clinicianId && counter.user_id === queryParams.clinicianId) {
                  patientsSet.add(action.patientId);
                }

                return {
                  ...action,
                  status: 'REASSIGNED',
                  delta_time_formatted: action.delta_time ? formatSeconds(action.delta_time) : '',
                  timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
                  statusAt: action.time_reassigned,
                  statusDate: action.time_reassigned
                    ? format(action.time_reassigned * 1000, ACTION_DATE_FORMAT)
                    : undefined,
                  class:
                    action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                      ? 'PRIORITY'
                      : 'STANDARD',
                };
              })
            : [];

        if (!queryParams.clinicianId || counter.user_id === queryParams.clinicianId) {
          openedCount += counter.counters.action?.remain_opened?.count || 0;
          closedCount += counter.counters.action?.closed?.count || 0;
          completedCount += counter.counters.action?.completed?.count || 0;
          expiredCount += counter.counters.action?.expired?.count || 0;
          if (queryParams.clinicianId) {
            escalatedCount += counter.counters.action?.escalated?.count || 0;
            deescalatedCount += counter.counters.action?.deescalated?.count || 0;
            reassignedCount += counter.counters.action?.reassigned?.count || 0;
          }
        }

        return {
          uid: counter.user_id,
          firstName: counter.first_name,
          lastName: counter.last_name,
          insights: counter.counters?.insight?.interactions,
          surveys: counter.counters?.survey?.interactions,
          pledges: counter.counters?.pledge?.interactions,
          actionsOpened: counter.counters.action?.remain_opened?.count,
          actionsClosed: counter.counters.action?.closed?.count,
          actionsExpired: counter.counters.action?.expired?.count,
          actionsCompleted: counter.counters.action?.completed?.count,
          actionsEscalated: counter.counters.action?.escalated?.count,
          actionsReassigned: counter.counters.action?.reassigned?.count,
          actionDeescalated: counter.counters.action?.deescalated?.count,
          actions: [
            ...open,
            ...closed,
            ...completed,
            ...expired,
            ...escalated,
            ...reassigned,
            ...deescalated,
          ],
        };
      }),
      'uid'
    );
    const chartData = map(entries(actionGroup), ([title, values]) => ({
      title,
      ...values,
    }));

    const patientsAmount = queryParams?.clinicianId ? patientsSet.size : patientsListAmount;

    return {
      countersByUser,
      pdfData: {
        patientsAmount,
        openedCount,
        closedCount,
        completedCount,
        expiredCount,
        escalatedCount,
        deescalatedCount,
        reassignedCount,
        chartData,
      },
    };
  }

  return {
    countersByUser: {},
    pdfData: {
      patientsAmount: 0,
      openedCount: 0,
      closedCount: 0,
      completedCount: 0,
      expiredCount: 0,
      escalatedCount: 0,
      deescalatedCount: 0,
      reassignedCount: 0,
      chartData: [],
    },
  };
};

export default fetchFactuaryCounters;
