import { flatMap, isEmpty } from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';
import { createApi } from '@reduxjs/toolkit/query/react';

import { factsGranularity } from '@constants/generatedData';
import { apiBaseQuery } from 'services/base';
import { ActionEvidenceData } from 'models/action';
import { PanelPatientListResponse, PatientsByMeasureResponse } from 'models/panel';
import { DateRange } from 'models/generatedData';
import { getSleepFromSleepDepth } from 'utils/generatedData';
import { ChartCsvDataBuilder } from 'utils/chartCsvDataBuilder';

export interface QueryParams {
  userId: string;
  type?: string;
  table?: string;
  dateRange?: DateRange | 2;
  metrics?: string[];
  startTime?: number;
  endTime?: number;
  factsParams?: string;
  factsOverTimeParams?: string;
  measures?: string[];
  page?: number;
  pageSize?: number;
  tz?: string;
  smaMetrics?: any;
  evidenceData?: ActionEvidenceData[];
  cohortCode?: string;
}

const eventsProcessorAPI = 'events-processor/api/v1';

export const eventsProcessorApi = createApi({
  reducerPath: 'eventsProcessorApi',
  baseQuery: apiBaseQuery('/'),
  tagTypes: ['EventsProcessor'],
  endpoints(builder) {
    return {
      fetchTicksData: builder.query<any, QueryParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          let generalData: any = {};
          let stepsData: any = {};
          let rhrData: any = {};
          let mealsData: any = {};
          let sleepData: any = {};
          let sleepInfo: any = {};
          let resultData: any = {};
          let smaData: any = {};

          //Fetch sma data for baseline
          if (!isEmpty(queryParams?.smaMetrics)) {
            const smaFacts: any = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=${queryParams?.smaMetrics}&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
            smaData =
              smaFacts?.data.sort((a: any, b: any) => Number(a.timestamp) - Number(b.timestamp)) ||
              [];
          }

          //Fetch steps for 1 day range (differs from basic ticks request)
          if (queryParams?.metrics?.includes('steps')) {
            stepsData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=steps_sum&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
          }

          //Fetch rhr for 1 day range (differs from basic ticks request)
          if (queryParams?.metrics?.includes('rhr')) {
            rhrData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=rhr_mean&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
          }

          //Fetch meals data
          if (
            queryParams?.metrics?.includes('meals') &&
            queryParams?.startTime &&
            queryParams?.endTime
          ) {
            const startDate = formatInTimeZone(
              queryParams?.startTime * 1000,
              queryParams?.tz || '',
              'MM-dd-yyyy'
            );
            const endDate = formatInTimeZone(
              (queryParams?.endTime - 1000) * 1000,
              queryParams?.tz || '',
              'MM-dd-yyyy'
            );
            mealsData = await baseQuery({
              url: `/activities/api/v1/activities/${queryParams.userId}/?from=${startDate}&to=${endDate}&activityTypes=LOGGING_FOOD&size=${queryParams?.dateRange}`,
              method: 'GET',
            });
          }

          //Fetch sleep data
          if (queryParams?.metrics?.includes('sleep')) {
            const sleepEvents: any = await baseQuery({
              url: `${eventsProcessorAPI}/users/${queryParams?.userId}/sleeps?from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&tz=UTC`,
              method: 'GET',
            });
            sleepInfo = sleepEvents?.data[0];
            sleepData = flatMap(sleepEvents?.data?.map((item: any) => item.smoothed_stages)).map(
              (sleep: any) => {
                return [sleep[0], sleep[1], sleep[2] > 4 ? 4 : sleep[2]];
              }
            );
          }

          const filteredMetrics = queryParams?.metrics?.filter(
            (metric) => !['meals', 'sleep', 'steps', 'rhr'].includes(metric)
          );

          const finalMetrics = filteredMetrics?.includes('heart_rate')
            ? [...filteredMetrics, 'low_heart_rate']
            : filteredMetrics;

          if (!isEmpty(finalMetrics)) {
            generalData = await baseQuery({
              url:
                queryParams?.dateRange === 1 || queryParams?.dateRange === 2
                  ? `ticks-store/api/v1/users/${queryParams?.userId}/ticks?metrics=${finalMetrics}` +
                    `&start_time=${queryParams?.startTime}&end_time=${queryParams?.endTime}`
                  : `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=${filteredMetrics}&tz=America/Los_Angeles` +
                    `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=${
                      factsGranularity[queryParams?.dateRange || 7]
                    }`,
              method: 'GET',
            });
          }

          if (queryParams?.dateRange === 1 || queryParams.dateRange === 2) {
            resultData = {
              data: {
                ...generalData.data,
                ...(!isEmpty(stepsData) && { steps: stepsData.data }),
                ...(!isEmpty(rhrData) && { rhr: rhrData.data }),
                ...(!isEmpty(mealsData) && { meals: mealsData.data?.content }),
                ...(flatMap(queryParams?.metrics).includes('sleep') && {
                  sleep: sleepData,
                  sleepInfo,
                }),
                ...(!isEmpty(smaData) && { sma: smaData }),
              },
            };
          } else {
            resultData = {
              data: {
                general: generalData.data || [],
                meals: mealsData.data?.content || [],
              },
            };
          }

          return stepsData.error ||
            rhrData.error ||
            generalData.error ||
            mealsData.error ||
            smaData.error
            ? {
                error: {
                  data: {
                    ...generalData?.error?.data,
                    ...stepsData?.error?.data,
                    ...rhrData?.error?.data,
                    ...mealsData?.error?.data,
                    ...smaData?.error?.data,
                  },
                },
              }
            : resultData;
        },
        providesTags: ['EventsProcessor'],
      }),

      fetchEvidenceData: builder.query<any, QueryParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          let generalData: any = {};
          let stepsData: any = {};
          let rhrData: any = {};
          let mealsData: any = {};
          let sleepData: any = {};
          let sleepInfo: any = {};
          let resultData: any = {};
          let smaData: any = [];

          for (const group of queryParams?.evidenceData ?? []) {
            const startTime = group?.startTime;
            const endTime = group?.endTime;
            const days = Math.ceil(group?.hours / 24);
            const dateRange = group?.days;
            const smaMetrics = group.smaMetrics ?? [];
            const metrics = group.metricsArray ?? [];
            const filteredMetrics = metrics?.filter(
              (metric) =>
                !['meals', 'sleep'].includes(metric) &&
                !((metric === 'steps' || metric === 'rhr') && dateRange < 7)
            );
            const finalMetrics = filteredMetrics?.includes('heart_rate')
              ? [...filteredMetrics, 'low_heart_rate']
              : filteredMetrics;

            const [generalResponse, smaResponse, stepsResponse, rhrResponse, sleepResponse] =
              await Promise.all([
                !isEmpty(finalMetrics)
                  ? baseQuery({
                      url:
                        dateRange === 1 || dateRange === 2
                          ? `ticks-store/api/v1/users/${queryParams?.userId}/ticks?metrics=${finalMetrics}` +
                            `&start_time=${startTime}&end_time=${endTime}`
                          : `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=${filteredMetrics}&tz=America/Los_Angeles` +
                            `&from_ts=${startTime}&to_ts=${endTime}&granularity=${
                              factsGranularity[(dateRange as DateRange) || 7]
                            }`,
                      method: 'GET',
                    })
                  : Promise.resolve({ data: dateRange === 1 || dateRange === 2 ? {} : [] }),
                !isEmpty(smaMetrics)
                  ? baseQuery({
                      url:
                        `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=${smaMetrics}&tz=America/Los_Angeles` +
                        `&from_ts=${startTime}&to_ts=${endTime}&granularity=HOURLY`,
                      method: 'GET',
                    })
                  : Promise.resolve({ data: [] }),
                metrics?.includes('steps')
                  ? baseQuery({
                      url:
                        `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=steps_sum&tz=America/Los_Angeles` +
                        `&from_ts=${startTime}&to_ts=${endTime}&granularity=HOURLY`,
                      method: 'GET',
                    })
                  : Promise.resolve({ data: [] }),
                metrics?.includes('rhr')
                  ? baseQuery({
                      url:
                        `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=rhr_mean&tz=America/Los_Angeles` +
                        `&from_ts=${startTime}&to_ts=${endTime}&granularity=HOURLY`,
                      method: 'GET',
                    })
                  : Promise.resolve({ data: [] }),
                metrics?.includes('sleep')
                  ? baseQuery({
                      url: `${eventsProcessorAPI}/users/${queryParams?.userId}/sleeps?from_ts=${startTime}&to_ts=${endTime}&tz=UTC`,
                      method: 'GET',
                    })
                  : Promise.resolve({ data: [] }),
              ]);

            smaData =
              [
                ...smaData,
                ...(smaResponse?.data as any)?.sort(
                  (a: any, b: any) => Number(a.timestamp) - Number(b.timestamp)
                ),
              ] || [];

            stepsData = {
              data: [...(stepsData?.data ?? []), ...(stepsResponse?.data as any)],
            };
            rhrData = {
              data: [...(rhrData?.data ?? []), ...(rhrResponse?.data as any)],
            };

            sleepInfo = { ...sleepInfo, ...(sleepResponse?.data as any)?.[0] };
            sleepData = flatMap(
              (sleepResponse?.data as any)?.map((item: any) => item.smoothed_stages)
            ).map((sleep: any) => {
              return [sleep[0], sleep[1], sleep[2] > 4 ? 4 : sleep[2]];
            });

            generalData = generalResponse;

            //Fetch meals data
            if (metrics?.includes('meals') && startTime && endTime) {
              const startDate = formatInTimeZone(
                startTime * 1000,
                queryParams?.tz || '',
                'MM-dd-yyyy'
              );
              const endDate = formatInTimeZone(
                (endTime - 1000) * 1000,
                queryParams?.tz || '',
                'MM-dd-yyyy'
              );
              mealsData = await baseQuery({
                url: `/activities/api/v1/activities/${queryParams.userId}/?from=${startDate}&to=${endDate}&activityTypes=LOGGING_FOOD&size=${days}`,
                method: 'GET',
              });
            }

            if (dateRange === 1 || dateRange === 2) {
              resultData = {
                data: {
                  ...(resultData?.data || {}),
                  ...(generalData?.data || {}),
                  ...(!isEmpty(stepsData) && { steps: stepsData?.data }),
                  ...(!isEmpty(rhrData) && { rhr: rhrData?.data }),
                  ...(!isEmpty(mealsData) && { meals: mealsData?.data?.content }),
                  ...(!isEmpty(sleepData) && {
                    sleep: sleepData,
                    sleepInfo,
                  }),
                  ...(!isEmpty(smaData) && { sma: smaData }),
                },
              };
            } else {
              resultData = {
                data: {
                  ...(resultData?.data ?? {}),
                  general: [...(resultData?.data?.general || []), ...(generalData?.data || [])],
                  ...(!isEmpty(stepsData) && { steps: stepsData?.data }),
                  ...(!isEmpty(rhrData) && { rhr: rhrData?.data }),
                  ...(!isEmpty(mealsData) && { meals: mealsData?.data?.content }),
                  ...(!isEmpty(sleepData) && {
                    sleep: sleepData,
                    sleepInfo,
                  }),
                  ...(!isEmpty(smaData) && { sma: smaData }),
                },
              };
            }
          }

          return stepsData.error ||
            rhrData.error ||
            generalData.error ||
            mealsData.error ||
            smaData.error
            ? {
                error: {
                  data: {
                    ...generalData?.error?.data,
                    ...stepsData?.error?.data,
                    ...rhrData?.error?.data,
                    ...mealsData?.error?.data,
                    ...smaData?.error?.data,
                  },
                },
              }
            : resultData;
        },
        providesTags: ['EventsProcessor'],
      }),

      fetchEventsData: builder.query<any, QueryParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          let generalData: any = {};
          let stepsData: any = {};
          let caloriesData: any = {};
          let caloriesFactData: any = {};
          let insulinData: any = {};
          let rhrData: any = {};
          let mealsData: any = {};
          let medicationsData: any = {};
          let workoutsData: any = {};
          let sleepData: any = {};
          let resultData: any = {};
          let isRawData: boolean = false;

          let csvDataBuilder = new ChartCsvDataBuilder(
            queryParams.dateRange === 2 ? 1 : queryParams.dateRange || 1,
            queryParams.tz
          );

          //Fetch steps for 1 day range (differs from basic ticks request)
          if (queryParams?.metrics?.includes('steps')) {
            stepsData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=steps_sum&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
            csvDataBuilder.addFactsOverTime(['steps_sum'], stepsData.data);
          }

          //Fetch calories for 1 day range (differs from basic ticks request)
          if (queryParams?.metrics?.includes('calories')) {
            caloriesData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=calories_sum&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
            caloriesFactData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=calories_sum&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=DAILY`,
              method: 'GET',
            });
            csvDataBuilder.addFactsOverTime(['calories_sum'], caloriesData.data);
          }

          //Fetch insulin for 1 day range (differs from basic ticks request)
          if (queryParams?.metrics?.includes('basal') || queryParams?.metrics?.includes('bolus')) {
            insulinData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=basal_sum,bolus_sum&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
            csvDataBuilder.addFactsOverTime(['basal_sum', 'bolus_sum'], insulinData.data);
          }

          //Fetch rhr for 1 day range (differs from basic ticks request)
          if (queryParams?.metrics?.includes('rhr')) {
            rhrData = await baseQuery({
              url:
                `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=rhr_mean&tz=America/Los_Angeles` +
                `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=HOURLY`,
              method: 'GET',
            });
            csvDataBuilder.addFactsOverTime(['rhr_mean'], rhrData.data);
          }

          //Fetch logging data
          if (
            (queryParams?.metrics?.includes('meals') ||
              queryParams?.metrics?.includes('medications') ||
              queryParams?.metrics?.includes('workouts')) &&
            queryParams?.startTime &&
            queryParams?.endTime
          ) {
            const startDate = formatInTimeZone(
              queryParams?.startTime * 1000,
              queryParams?.tz || '',
              'MM-dd-yyyy'
            );
            const endDate = formatInTimeZone(
              (queryParams?.endTime - 1000) * 1000,
              queryParams?.tz || '',
              'MM-dd-yyyy'
            );
            if (queryParams?.metrics?.includes('meals')) {
              mealsData = await baseQuery({
                url: `/activities/api/v1/activities/${queryParams.userId}/?from=${startDate}&to=${endDate}&activityTypes=LOGGING_FOOD&size=${queryParams?.dateRange}`,
                method: 'GET',
              });
            }
            if (queryParams?.metrics?.includes('medications')) {
              medicationsData = await baseQuery({
                url: `/activities/api/v1/activities/${queryParams.userId}/?from=${startDate}&to=${endDate}&activityTypes=LOGGING_MEDICATION&size=${queryParams?.dateRange}`,
                method: 'GET',
              });
            }
            if (queryParams?.metrics?.includes('workouts')) {
              workoutsData = await baseQuery({
                url: `/activities/api/v1/activities/${queryParams.userId}/?from=${startDate}&to=${endDate}&activityTypes=MOVE&size=${queryParams?.dateRange}`,
                method: 'GET',
              });
            }
          }

          //Fetch sleep data
          if (queryParams?.metrics?.includes('sleep')) {
            const sleepEvents: any = await baseQuery({
              url: `${eventsProcessorAPI}/users/${queryParams?.userId}/sleeps?from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&tz=UTC`,
              method: 'GET',
            });
            sleepData = flatMap(sleepEvents?.data?.map((item: any) => item.smoothed_stages)).map(
              (sleep: any) => {
                return [sleep[0], sleep[1], sleep[2] > 4 ? 4 : sleep[2]];
              }
            );

            if (isEmpty(sleepData)) {
              //get data from 8pm previous day till 11am present day
              const startTime = (queryParams?.startTime || 0) - 14400;
              const endTime = (queryParams?.endTime || 0) - 46800;
              const sleepDepth: any = await baseQuery({
                url:
                  `ticks-store/api/v1/users/${queryParams?.userId}/ticks?metrics=sleep_depth` +
                  `&start_time=${startTime}&end_time=${endTime}`,
                method: 'GET',
              });

              sleepData = getSleepFromSleepDepth(sleepDepth?.data?.sleep_depth || []);
              isRawData = !isEmpty(sleepData);
            }

            if (Array.isArray(sleepEvents.data) && sleepEvents.data.length > 0) {
              csvDataBuilder.addSleepEvents(sleepEvents.data);
            }
            csvDataBuilder.addSleepStages(sleepData);
          }

          const filteredMetrics = queryParams?.metrics?.filter(
            (metric) =>
              ![
                'meals',
                'medications',
                'workouts',
                'sleep',
                'steps',
                'calories',
                'basal',
                'bolus',
                'rhr',
              ].includes(metric)
          );

          if (!isEmpty(filteredMetrics)) {
            if (queryParams?.dateRange === 1) {
              generalData = await baseQuery({
                url: `ticks-store/api/v1/users/${queryParams?.userId}/ticks?metrics=${filteredMetrics}&start_time=${queryParams?.startTime}&end_time=${queryParams?.endTime}`,
                method: 'GET',
              });
              csvDataBuilder.addTicks(generalData.data);
            } else {
              generalData = await baseQuery({
                url: `${eventsProcessorAPI}/users/${
                  queryParams?.userId
                }/facts-over-time?facts=${filteredMetrics}&tz=America/Los_Angeles&from_ts=${
                  queryParams?.startTime
                }&to_ts=${queryParams?.endTime}&granularity=${
                  factsGranularity[queryParams?.dateRange === 2 ? 1 : queryParams.dateRange || 7]
                }`,
                method: 'GET',
              });
              csvDataBuilder.addFactsOverTime(filteredMetrics!, generalData.data);
            }
          }

          if (queryParams?.dateRange === 1) {
            resultData = {
              data: {
                ...generalData.data,
                ...(!isEmpty(stepsData) && { steps: stepsData.data }),
                ...(!isEmpty(caloriesData) && { calories: caloriesData.data }),
                ...(!isEmpty(caloriesFactData) && { caloriesFactData: caloriesFactData.data }),
                ...(!isEmpty(insulinData) && { bolus: insulinData.data, basal: insulinData.data }),
                ...(!isEmpty(rhrData) && { rhr: rhrData.data }),
                ...(!isEmpty(mealsData) && { meals: mealsData.data?.content }),
                ...(!isEmpty(medicationsData) && { medications: medicationsData.data?.content }),
                ...(!isEmpty(workoutsData) && { workouts: workoutsData.data?.content }),
                ...(flatMap(queryParams?.metrics).includes('sleep') && {
                  sleep: sleepData,
                  isRawData,
                }),
              },
            };
          } else {
            resultData = {
              data: {
                general: generalData.data || [],
                meals: mealsData.data?.content || [],
                medications: medicationsData.data?.content || [],
                workouts: workoutsData.data?.content || [],
              },
            };
          }

          if (
            stepsData.error ||
            caloriesData.error ||
            insulinData.error ||
            rhrData.error ||
            generalData.error ||
            mealsData.error ||
            medicationsData.error ||
            workoutsData.error
          ) {
            return {
              error: {
                data: {
                  ...generalData?.error?.data,
                  ...stepsData?.error?.data,
                  ...caloriesData?.error?.data,
                  ...insulinData?.error?.data,
                  ...rhrData?.error?.data,
                  ...mealsData?.error?.data,
                  ...medicationsData?.error?.data,
                  ...workoutsData?.error?.data,
                },
              },
            };
          }

          return { data: { ...resultData.data, csvData: csvDataBuilder.getCsvData() } };
        },
        providesTags: ['EventsProcessor'],
      }),

      fetchFactsData: builder.query<any, QueryParams | void>({
        query: (queryParams) => ({
          url: `${eventsProcessorAPI}/users/${queryParams?.userId}/facts?facts=${queryParams?.factsParams}`,
          method: 'GET',
        }),
        providesTags: ['EventsProcessor'],
      }),

      fetchPatientsByMeasure: builder.query<PanelPatientListResponse, QueryParams | void>({
        query: (queryParams) => ({
          url: [
            `${eventsProcessorAPI}/user_facts/`,
            `?facts=firstName,lastName,middleName,title,photoUrl,${queryParams?.measures?.join(
              ','
            )}`,
            `${queryParams?.cohortCode ? `&cohort=${queryParams?.cohortCode}` : ''}`,
            `&page=${queryParams?.page}&size=${queryParams?.pageSize}&clinicianId=${queryParams?.userId}&teamType=PATIENT`,
            '&activeUserOnly=true',
          ].join(''),
          method: 'GET',
        }),
        transformResponse: (data: PatientsByMeasureResponse) => {
          return {
            totalElements: data.total_elements,
            totalPages: data.total_pages,
            items: data.items || data.content || [],
          };
        },
        providesTags: ['EventsProcessor'],
      }),

      fetchPatientAnomalyScore: builder.query<any, QueryParams>({
        query: (queryParams) => ({
          url:
            `${eventsProcessorAPI}/users/${queryParams?.userId}/facts-over-time?facts=` +
            `anomaly_score,anomaly_baseline_mean_background_hr,anomaly_baseline_mean_rmssd,anomaly_baseline_mean_resp_rate,anomaly_baseline_mean_skin_temp,` +
            `anomaly_baseline_std_skin_temp,anomaly_baseline_std_background_hr,anomaly_baseline_std_resp_rate,anomaly_baseline_std_rmssd,` +
            `rhr_mean,rmssd_median,resp_rate_median,skin_temp_mean` +
            `&from_ts=${queryParams?.startTime}&to_ts=${queryParams?.endTime}&granularity=DAILY`,
          method: 'GET',
        }),
      }),

      fetchPatientAnomalyTicks: builder.query<any, QueryParams>({
        query: (queryParams) => ({
          url: `ticks-store/api/v1/users/${queryParams?.userId}/ticks?metrics=background_hr,rmssd,resp_rate,skin_temp&start_time=${queryParams?.startTime}&end_time=${queryParams?.endTime}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {
  useFetchTicksDataQuery,
  useFetchEvidenceDataQuery,
  useFetchEventsDataQuery,
  useFetchFactsDataQuery,
  useFetchPatientsByMeasureQuery,
  useLazyFetchPatientAnomalyScoreQuery,
  useLazyFetchPatientAnomalyTicksQuery,
} = eventsProcessorApi;
