import React from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';

import useStyles from './styles';

export interface TooltipDetailsProps {
  title: string;
  body?: string;
  alert?: { title: string; icon: React.ReactNode };
  details?: { text: string; value: string }[];
  detailFooter?: { text: string; value: string }[];
}

const TooltipDetails = ({ title, body, alert, details, detailFooter }: TooltipDetailsProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography variant="header12" sx={{ pb: '6px' }}>
          {title}
        </Typography>
        {body && (
          <Typography variant="para11" sx={{ pb: '12px' }} color="custom.white60">
            {body}
          </Typography>
        )}
        {alert && (
          <div className={classes.alertContainer}>
            {alert.icon}
            {alert.title}
          </div>
        )}
        {details && (
          <>
            {details.map((detail, index) => (
              <div className={classes.detailContainer} key={index}>
                <Typography variant="para11" color="custom.white60">
                  {detail.text}
                </Typography>
                <Typography variant="para11">{detail.value}</Typography>
              </div>
            ))}
          </>
        )}
        {detailFooter && (
          <>
            {detailFooter.map((detail, index) => (
              <div className={cx(classes.detailContainer, classes.detailFooter)} key={index}>
                <Typography variant="para11" color="custom.white60">
                  {detail.text}
                </Typography>
                <Typography variant="para11">{detail.value}</Typography>
              </div>
            ))}
          </>
        )}
      </header>
    </div>
  );
};

export default TooltipDetails;
