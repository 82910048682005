import { LIGHT_MODE } from '@constants/global';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.customBackground.aside,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '43.2rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '12px',
    border: `2px solid ${
      theme.palette.mode === LIGHT_MODE ? theme.palette.custom.black20 : theme.palette.custom.black
    }`,
    padding: '9.6rem 4.8rem',
    gap: '48px',
  },
  logo: {
    width: '99px',
    height: '72px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
  },
  button: {
    height: '4.8rem',
  },
  azureContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '9px',
  },
  azureText: {
    color:
      theme.palette.mode === LIGHT_MODE
        ? theme.palette.custom.black80
        : theme.palette.custom.white80,
  },
  connectingContainer: {
    width: '100%',
    height: '4.8rem',
    borderRadius: '3px',
    backgroundColor: theme.palette.custom.black10,
    padding: '15px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '6px',
  },
  footerContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: '-80px',
  },
}));

export default useStyles;
