import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Sb from 'sendbird';
import format from 'date-fns/format';
import uniqBy from 'lodash/unionBy';

import { RootState } from 'store/rootReducer';
import { AppDispatch } from 'store/index';
import {
  HandleNewMessagesPayload,
  Message,
  ReactionPayload,
  SendBirdMessage,
  SendFilePayload,
} from 'models/messaging';
import { registryApi } from 'services/registry';

export interface InitialChannelStateProps {
  allMessages: Message[];
  currentChannel?: Sb.GroupChannel;
  error: string | null;
  isLoading: boolean;
  customMessage: string;
  clearText: boolean;
  hasPreviousMessages: boolean;
  hasNextMessages: boolean;
  isLoadingPrevious: boolean;
  isLoadingNext: boolean;
  initialSelectedMessageId: number | null;
}

const initialState: InitialChannelStateProps = {
  allMessages: [],
  error: null,
  isLoading: false,
  customMessage: '',
  clearText: false,
  hasPreviousMessages: false,
  hasNextMessages: false,
  isLoadingPrevious: false,
  isLoadingNext: false,
  initialSelectedMessageId: null,
};

const cloneMessage = (message: SendBirdMessage): Message => {
  const url = (message as Message)?.url;
  return {
    ...message,
    url,
  } as Message;
};

const setMessageDate = (message: SendBirdMessage) => {
  const newMessage = cloneMessage(message);
  newMessage.date = format(newMessage.createdAt, 'yyyy-MM-dd');
  return newMessage;
};

const handleNewMessages = createAsyncThunk(
  'HANDLE_NEW_MESSAGES',
  (payload: HandleNewMessagesPayload, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (!messaging?.sdk?.sendbird) {
      return;
    }

    const { messages, isNew = true } = payload;
    const { allMessages } = messaging.channel;
    const messagesWithDate = messages.map((m) => setMessageDate(m));
    let allMessagesConcatenated: Message[] = [];

    if (isNew) {
      allMessagesConcatenated = uniqBy([...allMessages, ...messagesWithDate], 'messageId');
    } else {
      allMessagesConcatenated = uniqBy([...messagesWithDate, ...allMessages], 'messageId');
    }

    thunkAPI.dispatch(setAllMessages(allMessagesConcatenated));
  }
);

export const getGroupChannel = createAsyncThunk(
  'sendbird/GET_GROUP_CHANNEL',
  (channelUrl: string, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (messaging?.sdk?.sendbird) {
      messaging.sdk.sendbird.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) {
          thunkAPI.dispatch(setError(error.message));
        } else {
          thunkAPI.dispatch(setChannel(groupChannel));
        }
      });
    }
  }
);

const createMessageListParams = (
  sendbird: Sb.SendBirdInstance,
  {
    lookupDirection,
    isInclusive,
  }: {
    lookupDirection: 'previous' | 'next' | 'both';
    isInclusive: boolean;
  }
) => {
  const limits = {
    previous: {
      prevResultSize: 20,
      nextResultSize: 0,
    },
    next: {
      prevResultSize: 0,
      nextResultSize: 20,
    },
    both: {
      prevResultSize: 10,
      nextResultSize: 10,
    },
  }[lookupDirection];

  const result = new sendbird.MessageListParams();
  result.prevResultSize = limits.prevResultSize;
  result.nextResultSize = limits.nextResultSize;
  result.isInclusive = isInclusive;
  result.reverse = false;
  result.includeThreadInfo = true;
  result.includeReactions = true;
  return result;
};

export const getMessages = createAsyncThunk('sendbird/GET_MESSAGES', async (_: void, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const dispatch = thunkAPI.dispatch;
  if (state.messaging.channel.initialSelectedMessageId) {
    await getMessagesByMessageId(
      state.messaging.channel.initialSelectedMessageId,
      state,
      dispatch
    ).finally(() => thunkAPI.dispatch(setInitialSelectedMessageId(null)));
  } else {
    await getLastMessages(state, dispatch);
  }
});

const getMessagesByMessageId = async (
  messageId: number,
  state: RootState,
  dispatch: AppDispatch
) => {
  const { messaging } = state;
  if (!(messaging?.channel?.currentChannel && messaging?.sdk?.sendbird)) {
    return;
  }

  const { currentChannel } = messaging.channel;
  const { sendbird } = messaging.sdk;
  const params = createMessageListParams(sendbird, {
    lookupDirection: 'both',
    isInclusive: true,
  });

  const messagesPromise = new Promise((resolve, reject) => {
    currentChannel.getMessagesByMessageId(messageId, params, (messages, error) => {
      if (error) {
        dispatch(setError(error.message));
        reject(error);
      } else if (messages.length) {
        const hasPreviousMessages = messages.findIndex((m) => m.messageId === messageId) > 0;
        const hasNextMessages =
          messages.findIndex((m) => m.messageId === messageId) < messages.length - 1;
        dispatch(
          handleNewMessages({
            messages: messages.map((m) => {
              if (m.messageId === messageId) {
                (m as Message).shouldScrollIntoView = true;
                (m as Message).shouldHighlight = true;
              }
              return m;
            }),
            isNew: true,
          }) as any
        );
        dispatch(setHasPreviousMessages(hasPreviousMessages));
        dispatch(setHasNextMessages(hasNextMessages));
        resolve(messages);
      } else {
        dispatch(setHasPreviousMessages(false));
        dispatch(setHasNextMessages(false));
        resolve([]);
      }
    });
  });
  return messagesPromise;
};

const getLastMessages = async (state: RootState, dispatch: AppDispatch) => {
  const { messaging } = state;

  if (!(messaging?.channel?.currentChannel && messaging?.sdk?.sendbird)) {
    return;
  }
  const { currentChannel } = messaging.channel;
  const { sendbird } = messaging.sdk;

  const getMessagesByTimestampPromise = new Promise((resolve, reject) => {
    currentChannel.getMessagesByTimestamp(
      Date.now(),
      createMessageListParams(sendbird, { lookupDirection: 'previous', isInclusive: true }),
      (messages, error) => {
        if (error) {
          dispatch(setError(error.message));
          reject(error);
        } else if (messages.length) {
          dispatch(
            handleNewMessages({
              messages,
              isNew: true,
            }) as any
          );
          dispatch(setHasPreviousMessages(true));
          dispatch(setHasNextMessages(false));
          resolve(messages);
        } else {
          dispatch(setHasPreviousMessages(false));
          dispatch(setHasNextMessages(false));
          resolve([]);
        }
      }
    );
  });

  return getMessagesByTimestampPromise;
};

export const getPreviousMessages = createAsyncThunk(
  'sendbird/GET_PREV_MESSAGES',
  async (_: void, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;

    if (
      !(
        messaging?.channel?.currentChannel &&
        messaging?.sdk?.sendbird &&
        messaging?.channel?.allMessages.length
      )
    ) {
      return;
    }
    const { currentChannel } = messaging.channel;
    const { sendbird } = messaging.sdk;
    const { createdAt: firstMessageTimestamp } = messaging.channel.allMessages[0];

    const getMessagesByTimestampPromise = new Promise((resolve, reject) => {
      currentChannel.getMessagesByTimestamp(
        firstMessageTimestamp,
        createMessageListParams(sendbird, { lookupDirection: 'previous', isInclusive: false }),
        (messages, error) => {
          if (error) {
            thunkAPI.dispatch(setError(error.message));
            reject(error);
          } else if (messages.length) {
            thunkAPI.dispatch(
              handleNewMessages({
                messages: messages,
                isNew: false,
              })
            );
            thunkAPI.dispatch(setHasPreviousMessages(true));
            resolve(messages);
          } else {
            thunkAPI.dispatch(setHasPreviousMessages(false));
            resolve([]);
          }
        }
      );
    });

    return getMessagesByTimestampPromise;
  }
);

export const getNextMessages = createAsyncThunk(
  'sendbird/GET_NEXT_MESSAGES',
  async (_: void, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;

    if (
      !(
        messaging?.channel?.currentChannel &&
        messaging?.sdk?.sendbird &&
        messaging?.channel?.allMessages.length
      )
    ) {
      return;
    }
    const { currentChannel } = messaging.channel;
    const { sendbird } = messaging.sdk;

    const { createdAt: lastMessageTimestamp } =
      messaging.channel.allMessages[messaging.channel.allMessages.length - 1];

    const getMessagesByTimestampPromise = new Promise((resolve, reject) => {
      currentChannel.getMessagesByTimestamp(
        lastMessageTimestamp,
        createMessageListParams(sendbird, { lookupDirection: 'next', isInclusive: false }),
        (messages, error) => {
          if (error) {
            thunkAPI.dispatch(setError(error.message));
            reject(error);
          } else if (messages.length) {
            thunkAPI.dispatch(
              handleNewMessages({
                messages,
                isNew: true,
              })
            );
            thunkAPI.dispatch(setHasNextMessages(true));
            resolve(messages);
          } else {
            thunkAPI.dispatch(setHasNextMessages(false));
            resolve([]);
          }
        }
      );
    });
    return getMessagesByTimestampPromise;
  }
);

export const sendMessage = createAsyncThunk(
  'sendbird/SEND_MESSAGE',
  (message: string, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (messaging?.sdk?.sendbird && messaging?.channel?.currentChannel) {
      const { currentChannel } = messaging.channel;
      const params = new messaging.sdk.sendbird.UserMessageParams();
      params.message = message;

      const sendUserMessagePromise = new Promise((resolve, reject) => {
        currentChannel.sendUserMessage(params, (message, error) => {
          currentChannel.markAsRead();
          if (!error) {
            thunkAPI.dispatch(
              handleNewMessages({
                messages: [message],
              })
            );
            resolve(message);
          } else {
            reject(error);
          }
        });
      });

      return sendUserMessagePromise;
    }
  }
);

export const sendFile = createAsyncThunk(
  'sendbird/SEND_FILE',
  (payload: SendFilePayload, thunkAPI) => {
    const { fileUrl, name, size, type } = payload;
    const { messaging } = thunkAPI.getState() as RootState;
    const sendFileMessagePromise = new Promise(async (resolve, reject) => {
      if (fileUrl && messaging?.sdk?.sendbird && messaging?.channel?.currentChannel) {
        const { currentChannel } = messaging.channel;
        const params = new messaging.sdk.sendbird.FileMessageParams();
        params.fileUrl = fileUrl;
        params.fileName = name;
        params.fileSize = size;
        params.mimeType = type;
        params.pushNotificationDeliveryOption = 'default';
        currentChannel.sendFileMessage(params, (message, error) => {
          currentChannel.markAsRead();
          if (!error) {
            thunkAPI.dispatch(
              handleNewMessages({
                messages: [message],
              })
            );
            resolve(message);
          } else {
            reject(error);
          }
        });
      } else {
        let errorMessage = '';
        if (!fileUrl) {
          errorMessage = 'File url is missing.';
        } else if (!messaging?.sdk?.sendbird) {
          errorMessage = 'Sendbird sdk is not initialized.';
        } else if (!messaging?.channel?.currentChannel) {
          errorMessage = "Chat channel doesn't exist";
        }
        reject(`Cannot send file - ${errorMessage}`);
      }
    });
    return sendFileMessagePromise;
  }
);

export const addReaction = createAsyncThunk(
  'sendbird/ADD_REACTION',
  ({ message, key }: ReactionPayload, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (messaging?.channel?.currentChannel && messaging?.sdk?.sendbird) {
      const { currentChannel } = messaging.channel;
      const params = new messaging.sdk.sendbird.MessageListParams();
      params.isInclusive = true;
      params.prevResultSize = 0;
      params.nextResultSize = 0;

      return currentChannel.getMessagesByMessageId(message.messageId, params, (messages, error) => {
        if (!error) {
          const currentMessage = messages[0];
          return currentChannel.addReaction(currentMessage, key);
        }
      });
    }
  }
);

export const deleteReaction = createAsyncThunk(
  'sendbird/DELETE_REACTION',
  ({ message, key }: ReactionPayload, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (messaging?.channel?.currentChannel && messaging?.sdk?.sendbird) {
      const { currentChannel } = messaging.channel;
      const params = new messaging.sdk.sendbird.MessageListParams();
      params.isInclusive = true;
      params.prevResultSize = 0;
      params.nextResultSize = 0;

      return currentChannel.getMessagesByMessageId(message.messageId, params, (messages, error) => {
        if (!error) {
          const currentMessage = messages[0];
          return currentChannel.deleteReaction(currentMessage, key);
        }
      });
    }
  }
);

export const onMessageReceived = createAsyncThunk(
  'sendbird/ON_MESSAGE_RECEIVED',
  async (message: SendBirdMessage, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { patient } = thunkAPI.getState() as RootState;

    const newMessage = {
      ...message,
      isUnread: true,
      url: (message as Message).url,
    } as SendBirdMessage;

    dispatch(
      handleNewMessages({
        messages: [newMessage],
      })
    );

    // Get Member profile for new clinician in chat
    const userId = (message as Sb.UserMessage)?.sender?.userId;
    if (userId && patient.chatMembersProfileById) {
      if (!(userId in patient.chatMembersProfileById) && userId !== 'all_health') {
        await dispatch(
          registryApi.endpoints.fetchChatMembersProfiles.initiate({ userIds: [userId] })
        );
      }
    }
  }
);

export const onMessageUpdated = createAsyncThunk(
  'sendbird/ON_MESSAGE_UPDATED',
  (message: Message, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (messaging?.channel && messaging?.sdk) {
      const { allMessages } = messaging.channel;
      const messageIndex = allMessages.findIndex((msg) => msg.messageId === message.messageId);
      if (messageIndex > -1) {
        const allMessagesUpdated = allMessages.map((msg) => {
          if (msg.messageId === message.messageId) {
            return {
              ...msg,
              ...cloneMessage(message),
            };
          }
          return msg;
        });
        thunkAPI.dispatch(setAllMessages(allMessagesUpdated));
      } else {
        thunkAPI.dispatch(
          handleNewMessages({
            messages: [message],
          })
        );
      }
    }
  }
);

export const onReactionUpdated = createAsyncThunk(
  'sendbird/ON_REACTION_UPDATED',
  (reactionEvent: Sb.ReactionEvent, thunkAPI) => {
    const { messaging } = thunkAPI.getState() as RootState;
    if (messaging?.channel?.allMessages.length && messaging?.sdk) {
      const { allMessages } = messaging.channel;
      const messageIndex = allMessages.findIndex(
        (msg) => msg.messageId === reactionEvent.messageId
      );

      if (messageIndex > -1) {
        let allMessagesUpdated = [...allMessages];
        let currentMessage = { ...allMessagesUpdated[messageIndex] };
        let reactions = [...currentMessage.reactions];
        const indexReaction = reactions.findIndex((reaction) => reaction.key === reactionEvent.key);
        const currentReaction = indexReaction > -1 ? reactions[indexReaction] : null;

        if (currentReaction) {
          const userIds = new Set(reactions[indexReaction].userIds);
          if (reactionEvent.operation === 'delete') {
            userIds.delete(reactionEvent.userId);
          } else {
            userIds.add(reactionEvent.userId);
          }
          reactions[indexReaction] = {
            ...reactions[indexReaction],
            userIds: Array.from(userIds),
          } as Sb.Reaction;
        } else {
          if (reactionEvent.operation === 'delete') {
            reactions = reactions.filter((reaction) => reaction.key !== reactionEvent.key);
          } else {
            reactions = [
              ...reactions,
              {
                key: reactionEvent.key,
                userIds: [reactionEvent.userId],
                updatedAt: reactionEvent.updatedAt,
              } as Sb.Reaction,
            ];
          }
        }

        reactions = reactions.filter((r) => !!r.userIds.length);

        allMessagesUpdated[messageIndex] = {
          ...allMessagesUpdated[messageIndex],
          reactions: [...reactions] || [],
        };

        thunkAPI.dispatch(setAllMessages([...allMessagesUpdated]));
      }
    }
  }
);

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    clearChannel: (state) => ({
      ...initialState,
      customMessage: state.customMessage,
      // initialSelectedMessageId is to be cleared explicitly after the
      // messages are loaded
      initialSelectedMessageId: state.initialSelectedMessageId,
    }),
    setError: (state, action: PayloadAction<string | null>) => ({
      ...state,
      currentChannel: undefined,
      error: action.payload,
      allMessages: [],
    }),
    setChannel: (state, action: PayloadAction<Sb.GroupChannel>) => ({
      ...state,
      currentChannel: action.payload,
      error: '',
      allMessages: [],
    }),
    setAllMessages: (state, action: PayloadAction<Message[]>) => ({
      ...state,
      allMessages: [...action.payload],
    }),
    setHasPreviousMessages: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasPreviousMessages: action.payload,
    }),
    setHasNextMessages: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasNextMessages: action.payload,
    }),
    setCustomMessage: (state, action: PayloadAction<string>) => ({
      ...state,
      customMessage: action.payload,
    }),
    setClearText: (state, action: PayloadAction<boolean>) => ({
      ...state,
      clearText: action.payload,
    }),
    markMessagesAsRead: (state) => {
      state.allMessages = state.allMessages.map((message) => ({ ...message, isUnread: false }));
    },
    markMessageAsScrolledIntoView: (state, action: PayloadAction<number>) => {
      const message = state.allMessages.find((m) => m.messageId === action.payload);
      if (message) {
        message.shouldScrollIntoView = false;
      }
    },
    markMessageAsHighlighted: (state, action: PayloadAction<number>) => {
      const message = state.allMessages.find((m) => m.messageId === action.payload);
      if (message) {
        message.shouldHighlight = false;
      }
    },
    setInitialSelectedMessageId: (state, action: PayloadAction<number | null>) => ({
      ...state,
      initialSelectedMessageId: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state) => ({
      ...state,
      isLoading: true,
    }));

    builder.addCase(getMessages.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));

    builder.addCase(getPreviousMessages.pending, (state) => ({
      ...state,
      isLoadingPrevious: true,
    }));

    builder.addCase(getPreviousMessages.fulfilled, (state) => ({
      ...state,
      isLoadingPrevious: false,
    }));

    builder.addCase(getNextMessages.pending, (state) => ({
      ...state,
      isLoadingNext: true,
    }));

    builder.addCase(getNextMessages.fulfilled, (state) => ({
      ...state,
      isLoadingNext: false,
    }));
  },
});

export const {
  clearChannel,
  setError,
  setChannel,
  setAllMessages,
  setHasPreviousMessages,
  setHasNextMessages,
  setCustomMessage,
  setClearText,
  markMessagesAsRead,
  markMessageAsScrolledIntoView,
  markMessageAsHighlighted,
  setInitialSelectedMessageId,
} = channelSlice.actions;
export const channelSelector = (state: RootState) => state.messaging.channel;
export const channelErrorSelector = (state: RootState) => state.messaging.channel.error;
export const currentChannelSelector = (state: RootState) => state.messaging.channel.currentChannel;
export const allMessagesSelector = (state: RootState) => state.messaging.channel.allMessages;
export const hasPreviousMessagesSelector = (state: RootState) =>
  state.messaging.channel.hasPreviousMessages;
export const hasNextMessagesSelector = (state: RootState) =>
  state.messaging.channel.hasNextMessages;
export const isLoadingSelector = (state: RootState) => state.messaging.channel.isLoading;
export const customMessageSelector = (state: RootState) => state.messaging.channel.customMessage;
export const clearTextSelector = (state: RootState) => state.messaging.channel.clearText;
export const isLoadingPreviousSelector = (state: RootState) =>
  state.messaging.channel.isLoadingPrevious;
export const isLoadingNextSelector = (state: RootState) => state.messaging.channel.isLoadingNext;

export default channelSlice.reducer;
