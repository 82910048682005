import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '43.2rem',
    minHeight: '43.7rem',
    backgroundColor: theme.palette.custom.greyB,
    borderRadius: '12px',
    padding: '7.2rem 4.8rem',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3.6rem',
  },
  text: {
    marginTop: '3.6rem',
  },
  logo: {
    width: '80px',
    height: '63px',
  },
  selector: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '36px',
    width: '100%',
  },
  button: {
    height: '4.8rem',
  },
}));

export default useStyles;
