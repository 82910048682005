import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import { ChevronRightIcon } from 'assets/icons';
import { CustomColors } from 'styles/theme';
import { styled } from '@mui/material/styles';

interface AccordionSummaryExtendedProps extends AccordionSummaryProps {
  color: CustomColors;
  isTitle?: boolean;
}

const StyledAccordionSummary = styled(({ isTitle, ...props }: AccordionSummaryExtendedProps) => (
  <MuiAccordionSummary expandIcon={<ChevronRightIcon />} {...props} />
))(({ theme, color, isTitle }) => ({
  padding: '1rem 2rem 1rem 0.4rem',
  ...(isTitle && {
    paddingTop: '3.5rem',
  }),
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.custom[color],
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .notificationIcon': {
    color: theme.palette.custom[color],
    marginRight: '1rem',
  },
  '& .MuiBadge-badge': {
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom[color],
    minWidth: '18px',
    width: '18px',
    height: '18px',
    right: '-20px',
  },
  '& .eventTitle': {
    color: theme.palette.custom[color],
  },
  '& .eventTime': {
    color: theme.palette.text.disabled,
    paddingLeft: '1rem',
  },
  '& .eventAction': {
    visibility: 'hidden',
    backgroundColor: theme.palette.custom[color],
  },
  '&:hover .eventAction': {
    visibility: 'visible',
  },
  '&:hover ': {
    backgroundColor: theme.palette.divider,
  },
}));

export default StyledAccordionSummary;
