import { createTheme, Theme } from '@mui/material/styles';
import CaveatTtf from '../assets/fonts/Caveat/Caveat-Regular.ttf';
import GothamSsmBoldWoff from '../assets/fonts/GothamSSm-Bold_Web.woff';
import GothamSsmBoldWoff2 from '../assets/fonts/GothamSSm-Bold_Web.woff2';
import GothamSsmMediumWoff from '../assets/fonts/GothamSSm-Medium_Web.woff';
import GothamSsmMediumWoff2 from '../assets/fonts/GothamSSm-Medium_Web.woff2';

export const configureMuiComponents = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Gotham SSm';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: url(${GothamSsmMediumWoff2}) format('woff2'), url(${GothamSsmMediumWoff}) format('woff');
          }

          @font-face {
            font-family: 'Gotham SSm';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: url(${GothamSsmBoldWoff2}) format('woff2'), url(${GothamSsmBoldWoff}) format('woff');
          }

          @font-face {
            font-family: 'Caveat';
            src: local('Caveat'), url(${CaveatTtf}) format('truetype');
          }

          ul {
            listStyle: none;
            margin: 0;
            padding: 0;
          }
          
          h1,h2,h3,h4,h5,h6: {
            margin: 0;
          }
        `,
      },
      MuiTabs: {
        variants: [
          {
            props: { variant: 'fullWidth' },
            style: {
              '& .MuiTab-root': {
                alignItems: 'start',
                borderBottom: `2px solid ${theme.palette.custom.greyD}`,
                color: theme.palette.custom.black40,
                padding: '1.2rem 0',
                textTransform: 'none',
              },
              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.custom.black,
              },
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.custom.black,
              },
            },
          },
        ],
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: theme.palette.text.primary,
          },
          track: {
            color: theme.palette.custom.greyE,
          },
          rail: {
            color: theme.palette.custom.greyE,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: theme.palette.divider,
            borderBottomWidth: '2px',
          },
        },
      },
      MuiYearPicker: {
        styleOverrides: {
          root: {
            '& .PrivatePickersYear-yearButton': {
              margin: '4px 0',
              fontSize: '1.2rem',

              '&.Mui-selected': {
                color: theme.palette.custom.white,
                backgroundColor: theme.palette.custom.cerulean,

                '&:hover,&:focus': {
                  backgroundColor: theme.palette.custom.cerulean,
                },
              },
            },
          },
        },
      },
      MuiMonthPicker: {
        styleOverrides: {
          root: {
            '& .PrivatePickersMonth-root': {
              margin: '4px 0',
              fontSize: '1.2rem',

              '&.Mui-selected': {
                color: theme.palette.custom.white,
                backgroundColor: theme.palette.custom.cerulean,

                '&:hover,&:focus': {
                  backgroundColor: theme.palette.custom.cerulean,
                },
              },
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            margin: '0 2px',

            '&.Mui-selected': {
              color: theme.palette.custom.white,
              backgroundColor: theme.palette.custom.cerulean,

              '&:hover,&:focus': {
                backgroundColor: theme.palette.custom.cerulean,
              },
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: 'inherit',
            '&.Mui-checked': {
              color: 'inherit',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'inherit',
            '&.Mui-checked': {
              color: 'inherit',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
  });
