import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    padding: 0,
    height: 'auto',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },
  cardActionArea: {
    padding: '1.5rem',
  },
  cardFileTitle: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardFileContent: {
    overflow: 'hidden',
  },
  documentViewerContainer: {
    width: '100%',
    height: '25vh',
  },
  documentViewer: {
    width: '100%',
    height: '25vh',
  },
}));

export default useStyles;
