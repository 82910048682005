import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { DARK_MODE } from '@constants/global';

export interface HeaderIconButtonProps extends IconButtonProps {
  dark?: boolean;
}

const HeaderIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop.toString() !== DARK_MODE,
})<HeaderIconButtonProps>(({ theme, dark }) => ({
  color: theme.palette.text.disabled,
  ...(dark && {
    color: theme.palette.text.secondary,
  }),
  '&:hover': {
    color: theme.palette.text.primary,
    background: theme.palette.action.selected,
  },
  '&:active': {
    color: theme.palette.text.primary,
  },
}));

export default HeaderIconButton;
