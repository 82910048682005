import { createContext, useContext } from 'react';
import { useTheme } from '@mui/material/styles';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export function useColorMode() {
  const theme = useTheme();
  const { toggleColorMode } = useContext(ColorModeContext);
  return [theme.palette.mode, toggleColorMode] as const;
}
