import React from 'react';
import { LogoAllIcon } from 'assets/icons';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useStyles from './styles';

const HeaderAuth = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="header">
      <AppBar className={classes.appBar} elevation={0} position="fixed">
        <Toolbar className={classes.toolbar} classes={{ root: classes.toolbarRoot }}>
          <div className={classes.linkPatientsContainer}>
            <div className={classes.leftSide} id="leftSide">
              <LogoAllIcon className={classes.icon} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderAuth;
