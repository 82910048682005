import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import { PatientTab } from 'models/patientTab';
import { RootState } from 'store/rootReducer';
import { loadState, saveState } from 'utils/localStorage';


const loadInitialState = () => {
  const patientTabsState = loadState('patientTabs');
  if (!patientTabsState) {
    return {
      tabs: []
    }
  }
  return {
    tabs: patientTabsState
  }
}

export interface InitialPatientTabsStateProps {
  tabs: PatientTab[];
}

const initialState: InitialPatientTabsStateProps = loadInitialState();

const patientTabsSlice = createSlice({
  name: 'patientTabs',
  initialState,
  reducers: {
    addTab: (state, action: PayloadAction<PatientTab>) => {
      const tabs = new Set([...state.tabs]);
      tabs.add(action.payload);
      const tabsState = [...uniqBy(Array.from(tabs), 'id')];
      saveState('patientTabs', tabsState);
      return {
        ...state,
        tabs: tabsState,
      }
    },
    removeTab: (state, action: PayloadAction<string>) => {
      const tabs = state?.tabs?.filter((tab) => tab.id !== action.payload);
      saveState('patientTabs', tabs);
      return {
        ...state,
        tabs,
      };
    },
  },
});

export const { addTab, removeTab } = patientTabsSlice.actions;
export const patientTabsSelector = (state: RootState) => state.patientTabs;

export default patientTabsSlice.reducer;
