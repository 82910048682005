import {
  CareTeamMember,
  FullName,
  Patient,
  PatientFacts,
  PatientResponse,
  User,
  UserRelation,
} from 'models/user';
import { KeycloakRole, Task } from 'models/timeline';
import { addActionsToNotificationsList, addMessageToNotificationsList } from 'store/notifications';
import { isEmpty, isNil, keyBy, uniq } from 'lodash';

import { Notification } from 'models/notification';
import { RadiusApiError, apiBaseQuery } from 'services/base';
import { createApi } from '@reduxjs/toolkit/query/react';
import { formatMainName } from 'utils/formatUserInfo';
import { fullName } from 'utils/formatUserInfo';
import { Compliance, ComplianceResponse } from 'models/compliance';
import { Activities, ActivityDay } from 'models/activities';
import { getGlobalTimezoneOffset } from 'utils/generatedData';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { endOfDay, getUnixTime, subDays } from 'date-fns';
import { BAND_STATUS } from '@constants/compliance';
import { setCurrentUnitSystem } from 'utils/staticUnitSystem';
import { UnitSystemType } from 'utils/unitSystem';
import { Dictionary } from '@reduxjs/toolkit';

export interface PatchUserProps {
  op: string;
  path: string;
  value: string | Object;
}
export interface QueryParams {
  userId?: string;
  email?: string;
  user?: User;
  userValues?: PatchUserProps[];
  task?: Partial<Task>;
  patientId?: string;
  teamTypes?: 'PRIMARY' | 'SUPPORT';
  startDate?: string;
  endDate?: string;
  tenantId?: string;
  userIds?: string[];
  notification?: Notification;
  notificationVariant?: 'ACTION' | 'MESSAGE';
  factsParams?: string;
}

export interface FetchUserByIdParams {
  userId: string;
}

export interface CareTeamQueryParams {
  currentClinicianId: string;
}

interface CareTeamMemberActionSummary {
  _id: string;
  count: number;
}

// ¯\_(ツ)_/¯
type UserWithIdThatIsAnObject = User & {
  id: {
    uuid: string;
    tenantId: string;
  };
};

interface CareTeamResponseData {
  clinician: UserWithIdThatIsAnObject;
  latestTask?: Task;
  patientFor?: {
    birthday: string;
    firstName: string;
    gender: string;
    sex: string;
    heightDisplayUnits: string[];
    lastName: string;
    middleName: string;
    photoUrl: string;
    signUrl: string | null;
    title: string;
    weightDisplayUnits: string[];
  };
}

interface FetchUserSuggestionsQueryParams {
  name: string;
  teamType?: string;
}

export const registryApi = createApi({
  reducerPath: 'registryApi',
  baseQuery: apiBaseQuery('/registry/api/v1'),
  tagTypes: [
    'User',
    'UserById',
    'Patient',
    'PatientTab',
    'CareTeamMember',
    'Timeline',
    'Clinician',
    'UserSuggestion',
    'PatientTeams',
    'PatientTeamMemberIds',
    'MemberList',
    'UserSubordinate',
  ],
  endpoints(builder) {
    return {
      // User-profile-controller
      fetchUserAccount: builder.query<User, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}`,
          method: 'GET',
        }),
        providesTags: ['User'],
        transformResponse: (response: User) => {
          setCurrentUnitSystem(
            (response?.portalSettings?.unitSystem || 'imperial')?.toLowerCase() as UnitSystemType
          );
          return response;
        },
      }),
      fetchUserPatient: builder.query<Patient, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.patientId}`,
          method: 'GET',
        }),
        providesTags: ['Patient'],
      }),
      fetchPatientTab: builder.query<PatientResponse, QueryParams | void>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const userResponse = await baseQuery({
            url: `/users/${queryParams?.patientId}`,
            method: 'GET',
          });

          if (userResponse.error) {
            return { error: userResponse.error };
          }

          const patientData = userResponse.data as User;

          const factsResponse = await apiBaseQuery('/events-processor/api/v1')(
            {
              url: [
                `/users/${queryParams?.patientId}/facts`,
                queryParams?.factsParams && `?facts=${queryParams?.factsParams}`,
              ].join(''),
              method: 'GET',
            },
            queryApi,
            extraOptions
          );

          if (factsResponse.error) {
            return { error: factsResponse.error };
          }

          const factsData = factsResponse.data as { data: PatientFacts };
          const accountAgeDay = !isNil(factsData?.data?.account_age_day)
            ? factsData?.data?.account_age_day + 1
            : 30;
          const activityDays = accountAgeDay > 28 ? 28 : accountAgeDay;
          const complianceDays = accountAgeDay > 7 ? 7 : accountAgeDay;

          const timeZone =
            patientData?.languageRegion?.timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/Los_Angeles';
          const timezonesOffset = getGlobalTimezoneOffset(timeZone);
          const today = endOfDay(utcToZonedTime(new Date(), timeZone));
          const startTime_28days = getUnixTime(subDays(today, activityDays)) - timezonesOffset;
          const startTimeYesterday = getUnixTime(subDays(today, 1)) - timezonesOffset;
          const startTime = getUnixTime(subDays(today, complianceDays)) - timezonesOffset;
          const endTime = getUnixTime(today) - timezonesOffset;
          const startDate_28days = formatInTimeZone(
            startTime_28days * 1000,
            timeZone,
            'MM-dd-yyyy'
          );
          const endDate = formatInTimeZone(endTime * 1000, timeZone, 'MM-dd-yyyy');

          const [complianceResponse, activityResponse, bandStatusResponse] = await Promise.all([
            apiBaseQuery('/events-processor/api/v1')(
              {
                url: `/users/${queryParams?.patientId}/facts?facts=band_compliance_from_${startTime}_to_${endTime},weight_compliance_from_${startTime_28days}_to_${endTime},app_compliance_from_${startTime}_to_${endTime}`,
                method: 'GET',
                transformResponse: (data: string) => {
                  const compliance = JSON.parse(data) as ComplianceResponse;
                  return {
                    bandCompliance:
                      compliance.data?.[`band_compliance_from_${startTime}_to_${endTime}`] || 0,
                    weightCompliance:
                      compliance.data?.[
                        `weight_compliance_from_${startTime_28days}_to_${endTime}`
                      ] || 0,
                    appCompliance:
                      compliance.data?.[`app_compliance_from_${startTime}_to_${endTime}`] || 0,
                  };
                },
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/activities/api/v1')(
              {
                url:
                  `/activities/${queryParams?.patientId}/?from=${startDate_28days}&to=${endDate}` +
                  `&activityTypes=LOGGING_MEDICATION,LOGGING_FOOD&size=${activityDays}`,
                method: 'GET',
                transformResponse: (data: string) => {
                  const activities = JSON.parse(data) as Activities;
                  return activities.content.filter((item: ActivityDay) => {
                    return !isEmpty(item.activities);
                  });
                },
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/ticks-store/api/v1')(
              {
                url:
                  `/users/${queryParams?.patientId}/ticks?metrics=band_removed` +
                  `&start_time=${startTimeYesterday}&end_time=${endTime}`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
          ]);

          if (complianceResponse.error || activityResponse.error) {
            return {
              error: (complianceResponse.error || activityResponse.error) as RadiusApiError,
            };
          }

          const bandData = (
            bandStatusResponse?.data as { band_removed: number[][] }
          )?.band_removed?.reverse();
          const bandStatus = bandData?.find(
            (item) => item[1] === 0 || item[1] === 1 || item[1] === 2
          )?.[1];
          const complianceData = {
            ...(complianceResponse.data as Compliance),
            bandStatus: !isNil(bandStatus) ? BAND_STATUS[bandStatus] : 'unknown',
          };
          const activitiesData = activityResponse.data as ActivityDay[];

          const data = {
            ...patientData,
            factsData: factsData.data,
            complianceData,
            activitiesData,
            activityDays,
            complianceDays,
          } as PatientResponse;

          return {
            data,
          };
        },
        providesTags: ['PatientTab'],
      }),
      fetchUserById: builder.query<User, FetchUserByIdParams>({
        query: (queryParams) => ({
          url: `/users/${queryParams.userId}`,
          method: 'GET',
        }),
        providesTags: ['UserById'],
      }),
      fetchUserPatientForNotifications: builder.query<Patient, QueryParams>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.patientId}`,
          method: 'GET',
        }),
        async onQueryStarted(queryParams: QueryParams, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            const newNotification = {
              ...queryParams.notification,
              title: formatMainName(data.personalInfo),
              image: `${data.personalInfo.photoUrl || null}`,
            };
            if (queryParams.notificationVariant === 'ACTION') {
              dispatch(addActionsToNotificationsList(newNotification));
            } else {
              dispatch(addMessageToNotificationsList(newNotification));
            }
          } catch (err) {
            console.error(err);
          }
        },
      }),
      fetchUserClinician: builder.query<User, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}`,
          method: 'GET',
        }),
        providesTags: ['Clinician'],
      }),
      fetchUserBasicInfo: builder.query<FullName, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}/basic_info`,
          method: 'GET',
        }),
        providesTags: ['Clinician'],
      }),
      putUserAccount: builder.mutation<User, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}`,
          method: 'put',
          data: queryParams?.user,
        }),
        invalidatesTags: ['User'],
      }),
      patchUserAccount: builder.mutation<User, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}`,
          method: 'PATCH',
          data: queryParams?.userValues,
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
        }),
        invalidatesTags: ['User', 'UserSuggestion'],
      }),
      fetchMultipleUserProfiles: builder.query<{ content: Patient[] }, QueryParams>({
        query: (queryParams) => ({
          url: [
            `/users/`,
            `?userIds=${queryParams.userIds?.join(',')}`,
            `&size=${queryParams.userIds?.length}`,
          ].join(''),
          method: 'GET',
        }),
      }),
      fetchChatMembersProfiles: builder.query<Dictionary<User>, QueryParams>({
        query: (queryParams) => ({
          url: [
            `/users/`,
            `?userIds=${queryParams.userIds?.join(',')}`,
            `&size=${queryParams.userIds?.length}`,
          ].join(''),
          method: 'GET',
        }),
        transformResponse: (response: { content: User[] }) => {
          const usersArray = response.content || [];
          return keyBy(usersArray, 'id');
        },
      }),
      // Relations-controller
      fetchPatientTeamsMembers: builder.query<
        { primaryTeam: User[]; supportTeam: User[] },
        QueryParams
      >({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          let clinicianIds: string[] = [];

          const primaryTeamResult = await baseQuery({
            url: `/users/${queryParams.patientId}/clinicians?teamTypes=PRIMARY`,
          });

          if (primaryTeamResult.error) {
            return { error: primaryTeamResult.error };
          }

          const primaryTeamData = primaryTeamResult.data as User[];
          primaryTeamData.forEach((user: User) => {
            user.id && clinicianIds.push(user.id);
          });

          const supportTeamResult = await baseQuery({
            url: `/users/${queryParams.patientId}/clinicians?teamTypes=SUPPORT`,
          });

          if (supportTeamResult.error) {
            return { error: supportTeamResult.error };
          }

          const supportTeamData = supportTeamResult.data as User[];
          supportTeamData.forEach((user: User) => {
            user.id && clinicianIds.push(user.id);
          });

          //this POST request returns roles for clinicians (semantically wrong, but batch api) :(
          const rolesResponse = await apiBaseQuery('/user/api/v3')(
            {
              url: `/users/clinicians/roles`,
              method: 'POST',
              data: {
                userIds: clinicianIds,
              },
            },
            queryApi,
            extraOptions
          );

          if (rolesResponse.error) {
            return { error: rolesResponse.error };
          }

          const rolesList = keyBy(rolesResponse.data as KeycloakRole[], 'userUuid');
          const primaryTeam = primaryTeamData.map((user: User) => {
            user.id && (user.roles = rolesList[user.id]?.roles);
            return user;
          });
          const supportTeam = supportTeamData.map((user: User) => {
            user.id && (user.roles = rolesList[user.id]?.roles);
            return user;
          });

          return {
            data: {
              primaryTeam: primaryTeam,
              supportTeam: supportTeam,
            } as { primaryTeam: User[]; supportTeam: User[] },
          };
        },
        providesTags: ['PatientTeams'],
      }),
      fetchPatientTeamMemberIds: builder.query<string[], { patientId: string }>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          let clinicianIds: string[] = [];

          const teamResult = await baseQuery({
            url: `/users/${queryParams.patientId}/clinicians`,
          });

          if (teamResult.error) {
            return { error: teamResult.error };
          }

          const teamData = teamResult.data as User[];
          teamData.forEach((user: User) => {
            user.id && clinicianIds.push(user.id);
          });

          return {
            data: clinicianIds,
          };
        },
        providesTags: ['PatientTeamMemberIds'],
      }),
      fetchPatientRelation: builder.query<UserRelation[], QueryParams | void>({
        query: (queryParams) => ({
          url: [
            `/users/${queryParams?.userId}/patients/${queryParams?.patientId}/teams`,
            queryParams?.teamTypes && `/clinicians?teamTypes=${queryParams.teamTypes}`,
          ].join(''),
          method: 'GET',
        }),
        providesTags: ['User'],
      }),
      fetchPatientsAssignToClinician: builder.query<Patient[], QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}/patients`,
          method: 'GET',
        }),
        providesTags: ['User'],
      }),
      fetchClinicians: builder.query<User[], QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}/patients`,
          method: 'GET',
        }),
        providesTags: ['User'],
      }),
      assignPatientToClinician: builder.mutation<
        Patient,
        Partial<Patient> & {
          clinicianId: string;
          teamType?: QueryParams['teamTypes'];
          payload?: any;
        }
      >({
        query: (params) => ({
          url: `/users/${params.clinicianId}/patients/${params.id}/full-assignment?teamType=${
            params.teamType || 'PRIMARY'
          }`,
          method: 'PUT',
          data: params.payload || {},
        }),
        invalidatesTags: ['User', 'PatientTeams', 'Timeline'],
      }),
      dischargePatientFromClinician: builder.mutation<UserRelation, QueryParams>({
        query: (params) => ({
          url: `/users/${params?.userId}/patients/${params?.patientId}?teamType=${
            params?.teamTypes || 'PRIMARY'
          }`,
          method: 'DELETE',
        }),
        invalidatesTags: ['User', 'PatientTeams'],
      }),
      //Timeline-controller
      fetchTimelineForPatient: builder.query<any, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const tasksResponse = await baseQuery({
            url: `/users/${queryParams?.patientId}/timeline?startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}`,
            method: 'GET',
          });

          if (tasksResponse.error) {
            return { error: tasksResponse.error };
          }

          const tasksData = tasksResponse.data as Task[];

          if (tasksData) {
            const clinicianIds = uniq(tasksData.map((task) => task.executedBy));

            //this POST request returns roles for clinicians (semantically wrong, but batch api)
            const rolesResponse = await apiBaseQuery('/user/api/v3')(
              {
                url: `/users/clinicians/roles`,
                method: 'POST',
                data: {
                  userIds: clinicianIds,
                },
              },
              queryApi,
              extraOptions
            );

            if (rolesResponse.error) {
              return { error: rolesResponse.error };
            }

            const rolesList = keyBy(rolesResponse.data as KeycloakRole[], 'userUuid');
            const tasks = tasksData.map((task) => ({
              ...task,
              roles: rolesList[task.executedBy]?.roles || [],
            }));

            return { data: tasks };
          }

          return { data: tasksData };
        },
        providesTags: ['Timeline'],
      }),
      addTimelineForPatient: builder.mutation<Task, QueryParams | void>({
        query: (queryParams) => ({
          url: `/users/${queryParams?.userId}/tasks`,
          method: 'POST',
          data: queryParams?.task,
        }),
        invalidatesTags: ['Timeline'],
      }),
      fetchCareTeam: builder.query<CareTeamMember[], CareTeamQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const actionsBaseQuery = apiBaseQuery('/factuary/api/v2/users/actions');
          const [careTeamResponse, caseloadResponse, allActionsResponse, closedActionsResponse] =
            await Promise.all([
              baseQuery({
                url: `clinicians/${queryParams.currentClinicianId}/team?activity=latest`,
                method: 'GET',
              }),
              baseQuery({
                url: '/users/clinicians/patients',
                method: 'GET',
              }),
              actionsBaseQuery(
                {
                  url: '/count',
                  method: 'GET',
                },
                queryApi,
                extraOptions
              ),
              actionsBaseQuery(
                {
                  url: '/count?status=COMPLETED|CLOSED',
                  method: 'GET',
                },
                queryApi,
                extraOptions
              ),
            ]);

          if (careTeamResponse.error) {
            return { error: careTeamResponse.error };
          }

          if (caseloadResponse.error) {
            return { error: caseloadResponse.error };
          }

          if (allActionsResponse.error) {
            console.error('Error retrieving care team actions', allActionsResponse.error);
          }
          if (closedActionsResponse.error) {
            console.error('Error retrieving care team closed actions', closedActionsResponse.error);
          }

          const allActions = (allActionsResponse.data || []) as CareTeamMemberActionSummary[];
          const closedActions = (closedActionsResponse.data || []) as CareTeamMemberActionSummary[];

          const userIdToAllActionsMap = keyBy(allActions, 'assignee_id');
          const userIdToClosedActionsMap = keyBy(closedActions, 'assignee_id');
          const caseloadByClinician = caseloadResponse.data as { [key: string]: number };

          const result: CareTeamMember[] = (careTeamResponse.data as CareTeamResponseData[]).map(
            (d) => {
              const roles = d.clinician?.roles?.filter((role) => role !== 'clinician') || [];
              const caseload = caseloadByClinician[d.clinician.id.uuid];
              return {
                ...{ ...d.clinician, id: d.clinician.id.uuid },
                roles,
                totalAssignedActions: userIdToAllActionsMap[d.clinician.id.uuid]?.count,
                totalClosedActions: userIdToClosedActionsMap[d.clinician.id.uuid]?.count,
                caseload,
                recentActivity: d.latestTask
                  ? {
                      performedAt: d.latestTask!.timestamp,
                      type: d.latestTask!.taskType,
                    }
                  : undefined,
              };
            }
          );

          return { data: result };
        },
        providesTags: ['CareTeamMember'],
      }),
      fetchUserSuggestions: builder.query<User[], FetchUserSuggestionsQueryParams>({
        query: (queryParams) => ({
          url: [
            `/users?partOfName=${queryParams.name}`,
            queryParams.teamType && `&teamType=${queryParams.teamType}`,
          ].join(''),
          method: 'GET',
        }),
        providesTags: ['UserSuggestion'],
        transformResponse: (response: { content: User[] }) =>
          (response.content || []).sort((a, b) =>
            fullName(a.personalInfo).localeCompare(fullName(b.personalInfo))
          ),
      }),
    };
  },
});

export const {
  useFetchUserAccountQuery,
  useFetchTimelineForPatientQuery,
  useFetchCareTeamQuery,
  useFetchUserPatientQuery,
  useFetchPatientRelationQuery,
  useLazyFetchPatientRelationQuery,
  useFetchUserClinicianQuery,
  useLazyFetchUserSuggestionsQuery,
  useLazyFetchMultipleUserProfilesQuery,
  useFetchUserPatientForNotificationsQuery,
  useFetchUserByIdQuery,
  useFetchPatientTeamsMembersQuery,
  usePutUserAccountMutation,
  useAddTimelineForPatientMutation,
  useAssignPatientToClinicianMutation,
  useDischargePatientFromClinicianMutation,
  usePatchUserAccountMutation,
  useFetchPatientTeamMemberIdsQuery,
  useLazyFetchPatientTabQuery,
} = registryApi;

export const selectUserSuggestionsQueryResult = (name: string, teamType?: string) =>
  registryApi.endpoints.fetchUserSuggestions.select({ name, teamType });
