import { MAX_FILE_SIZE_FOR_PREVIEW } from '@constants/messaging';

export const renameFile = (originalFile: File, newName: string) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
  });
};

export const urltoFile = (url: string, filename: string, mimeType: string) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const fetchJsonFile = (url: string) => {
  return fetch(url).then(function (res) {
    return res.json();
  });
};

export const acceptedFileTypeForPreviewer = [
  // Media-types reference: https://www.iana.org/assignments/media-types/media-types.xhtml#application
  // .doc
  'application/msword',
  'application/vnd.sealed.doc',
  // .docx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // xls
  'application/vnd.ms-excel',
  'application/vnd.sealed.xls',
  // .xlsx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // .ppt
  'application/vnd.ms-powerpoint',
  'application/vnd.sealed.ppt',
  // .pptx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // .txt
  'text/plain',
  // .pdf
  'application/vnd.sealedmedia.softseal.pdf',
  'application/pdf',
];

export const filterFilesByAcceptedFileTypeForPreviewer = (type: string) => {
  const isAcceptedFileType = acceptedFileTypeForPreviewer.includes(type);
  return isAcceptedFileType;
};

export const filterFilesBySizeForPreviewer = (fileSize: string) => {
  const sizeProps = fileSize?.split(' ');
  const isAcceptedFileSize = fileSize
    ? !(Number(sizeProps[0]) >= MAX_FILE_SIZE_FOR_PREVIEW && sizeProps[1] === 'MB')
    : true;
  return isAcceptedFileSize;
};
