import React, { useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ADMIN_ROUTE_ITEMS, CARE_ROUTE_ITEMS } from './ItemRoute/index';
import { AvatarIcon, MenuHamburgerIcon, XIcon } from 'assets/icons';
import { Box, IconButton, Menu, Skeleton, useMediaQuery, Divider, Typography } from '@mui/material';
import { DarkMode, LightMode, Logout } from '@mui/icons-material';
import { userAccount } from 'store/account';
import { selectIsAdmin, selectIsClinician } from 'store/auth';
import { disconnect, sdkSelector } from 'store/messaging/sdk';
import { useAppDispatch, useAppSelector } from 'store/index';

import Avatar from '@mui/material/Avatar';
import CustomMenuItem from './CustomMenuItem';
import { LIGHT_MODE } from '@constants/global';
import MenuRenderer from 'components/MenuRenderer';
import { SendBirdInstance } from 'sendbird';
import { User } from 'models/user';
import { clearSession } from 'msal';
import cx from 'classnames';
import { fullName } from 'utils/formatUserInfo';
import { setTenant } from 'store/account';
import { useColorMode } from 'styles/color-mode';
import { useSbCalls } from 'hooks/sbCalls';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { useUserAnalytics } from 'hooks/useUserAnalytics';

const UserDropdown = () => {
  const theme = useTheme();
  const classes = useStyles();
  const matchesDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const user: User = useAppSelector(userAccount);
  const messagingSdk = useAppSelector(sdkSelector);
  const sbCalls = useSbCalls();
  const { onCreateEvent } = useUserAnalytics();
  const [currentMode, toggle] = useColorMode();
  const rootDivRef = useRef<HTMLDivElement>(null);
  const isAdmin = useAppSelector(selectIsAdmin);
  const isClinician = useAppSelector(selectIsClinician);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isDashboardView = location.pathname.includes('dashboard');
  const open = Boolean(anchorEl);

  const userRouteGroups = useMemo(() => {
    const result = [];
    if (isClinician) {
      result.push(CARE_ROUTE_ITEMS);
    }
    if (isAdmin) {
      result.push(ADMIN_ROUTE_ITEMS);
    }
    return result;
  }, [isAdmin, isClinician]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onCreateEvent(event, '<UserDropdown />', '<Header />');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event: React.MouseEvent) => {
    onCreateEvent(event, '<Logout />', '<UserDropdown />');
    // Handle Sendbird Disconnection
    dispatch(disconnect(messagingSdk as SendBirdInstance) as any);
    // Handle Sendbird Calls Disconnection
    sbCalls.deauth();

    dispatch(setTenant(undefined));
    clearSession();
    instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin + '/',
    });
  };

  const handleModeToggle = (event: React.MouseEvent<HTMLElement>) => {
    onCreateEvent(event, '<ThemeModeToggler />', '<Header />');
    toggle();
  };

  const handleNavigation = (component: string) => (event: React.SyntheticEvent) => {
    onCreateEvent(event, `<${component} />`, '<UserDropdown />');
  };

  return (
    <>
      <MenuRenderer
        placement="right-end"
        trigger={() => (
          <div
            className={cx(classes.root, { active: isDashboardView })}
            role="button"
            ref={rootDivRef}
          >
            <Link to="/dashboard/today" className={classes.link}>
              {!matchesDownSM && user.personalInfo.firstName ? (
                <Typography className={classes.userName}>{fullName(user.personalInfo)}</Typography>
              ) : (
                !matchesDownSM && <Skeleton variant="text" sx={{ width: '12rem' }} />
              )}

              {user?.personalInfo?.photoUrl ? (
                <Avatar
                  alt={fullName(user?.personalInfo, '-')}
                  src={user?.personalInfo?.photoUrl}
                  className={classes.avatar}
                />
              ) : (
                <AvatarIcon className={classes.avatarIcon} />
              )}
            </Link>
            <IconButton className={classes.menuIcon} onClick={handleClick}>
              {open ? <XIcon /> : <MenuHamburgerIcon />}
            </IconButton>
          </div>
        )}
      >
        {() => (
          <>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              className={classes.menu}
              PaperProps={{
                elevation: 1,
                className: classes.paper,
              }}
              sx={{
                '& .MuiPaper-root': {
                  minWidth:
                    rootDivRef.current?.offsetWidth &&
                    `${(rootDivRef.current?.offsetWidth - 1) / 10}rem`,
                },
              }}
            >
              <Box>
                {userRouteGroups.map((routeGroup, index) => (
                  <React.Fragment key={index}>
                    {routeGroup.map((route) => (
                      <CustomMenuItem
                        key={route.text}
                        text={route.text}
                        route={route.link}
                        action={handleNavigation(route.text)}
                      />
                    ))}
                    <Divider />
                  </React.Fragment>
                ))}
                <CustomMenuItem
                  icon={
                    currentMode === LIGHT_MODE ? (
                      <DarkMode fontSize="small" sx={{ color: theme.palette.text.primary }} />
                    ) : (
                      <LightMode fontSize="small" sx={{ color: theme.palette.text.primary }} />
                    )
                  }
                  text={currentMode === LIGHT_MODE ? 'Dark Mode' : 'Light Mode'}
                  action={handleModeToggle}
                />
                <CustomMenuItem
                  icon={<Logout fontSize="small" sx={{ color: theme.palette.text.primary }} />}
                  text="Sign Out"
                  action={handleLogout}
                  isLast
                />
              </Box>
            </Menu>
          </>
        )}
      </MenuRenderer>
    </>
  );
};

export default UserDropdown;
