import { isArray } from 'lodash';

import {
  BandStatusReportRawData,
  ClinicalReportPatientData,
  EngagementReportRawData,
  PeriodItem,
  ReportResponse,
  ReportTick,
} from 'models/reports';
import { userReportsApi } from '.';
import {
  getClinicalFacts,
  getTimezoneMonthDate,
  queryParamsReportsByPeriod,
  reportFromFactsData,
} from 'utils/reports';
import { RadiusApiError, apiBaseQuery } from 'services/base';
import { User } from 'models/user';
import { bandStatusReportMock, engagementReportMock } from 'mocks/reports';
import {
  CLINICAL_FACTS,
  CLINICAL_MONTH_METRICS,
  CLINICAL_SLEEP_FACTS,
} from '@constants/reportClinical';
import { UnitSystem } from 'utils/unitSystem';
import { DATA_CLINICAL_MONTH_METRICS } from '@constants/reportDataClinical';
import { ProgramDetails } from 'models/program';

export interface MemberReportParams {
  period: PeriodItem;
  patientId: string;
  clinicianId?: string;
  unitSystem?: UnitSystem;
}

const eventsProcessorAPI = 'events-processor/api/v1';
const ticksStoreAPI = 'ticks-store/api/v1';

export const memberReportsApi = userReportsApi.injectEndpoints({
  endpoints(builder) {
    return {
      fetchMemberBandStatusReport: builder.query<BandStatusReportRawData[], MemberReportParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          const params = queryParamsReportsByPeriod(queryParams.period);
          params.append('patientIds', queryParams.patientId);

          const reportsResponse = await baseQuery({
            url: `/datalake-service/v1/reports/engagement-band/${
              queryParams.period.period
            }?${params.toString()}`,
            method: 'GET',
          });

          if (reportsResponse.error) {
            return { error: reportsResponse.error };
          }

          const responseData = reportsResponse.data as ReportResponse;
          const reportList =
            ((responseData?._embedded?.engagementBandDailyReportList ||
              responseData?._embedded?.billingCareMemberMonthlyReportList ||
              responseData?._embedded
                ?.billingCareMemberWeeklyReportList) as BandStatusReportRawData[]) ||
            bandStatusReportMock;
          const reportsData: BandStatusReportRawData[] =
            isArray(reportList) && reportList.length > 0
              ? (reportList as BandStatusReportRawData[])
              : [];

          return { data: reportsData };
        },
        providesTags: ['MemberReports'],
      }),
      fetchMemberEngagementReport: builder.query<EngagementReportRawData[], MemberReportParams>({
        async queryFn(queryParams, _, __, baseQuery) {
          const params = queryParamsReportsByPeriod(queryParams.period);
          params.append('patientIds', queryParams.patientId);

          const reportsResponse = await baseQuery({
            url: `/datalake-service/v1/reports/engagement-app?${params.toString()}`,
            method: 'GET',
          });

          if (reportsResponse.error) {
            return { error: reportsResponse.error };
          }

          const responseData = reportsResponse.data as ReportResponse;
          const reportList =
            responseData?._embedded?.engagementAppDailyReportList ||
            responseData?._embedded?.engagementAppMonthlyReportList ||
            responseData?._embedded?.engagementAppWeeklyReportList ||
            engagementReportMock;
          const reportsData: EngagementReportRawData[] =
            isArray(reportList) && reportList.length > 0
              ? (reportList as EngagementReportRawData[])
              : [];

          return { data: reportsData };
        },
        providesTags: ['MemberReports'],
      }),
      fetchMemberSummaryReport: builder.query<ClinicalReportPatientData, MemberReportParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const [patientResponse, patientFhirResponse] = await Promise.all([
            baseQuery({
              url: `/registry/api/v1/users/${queryParams.patientId}`,
              method: 'GET',
            }),
            baseQuery({
              url: `/fhirservice/api/v1.0/patients/${queryParams.patientId}`,
              method: 'GET',
            }),
          ]);

          if (patientResponse.error) {
            return { error: patientResponse.error };
          }

          const patient = patientResponse.data as User;
          const fhirData =
            (patientFhirResponse?.data as { identifier: { system: string; value: string }[] }) ||
            null;
          const mrn =
            fhirData?.identifier?.find((item) => item?.system?.includes('urn:epic'))?.value || null;

          const tz =
            patient.languageRegion?.timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/Los_Angeles';

          const { startTime, endTime } = getTimezoneMonthDate(queryParams.period.value, tz);

          const facts = getClinicalFacts(CLINICAL_FACTS, startTime, endTime);
          const sleepFacts = getClinicalFacts(CLINICAL_SLEEP_FACTS, startTime, endTime);
          const monthMetrics = CLINICAL_MONTH_METRICS;

          const [
            factsResponse,
            sleepFactsResponse,
            monthFactsResponse,
            dailyFactsResponse,
            aggHistResponse,
            primaryTeamResponse,
            supportTeamResponse,
          ] = await Promise.all([
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts?facts=${facts},height`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts?facts=${sleepFacts}`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=MONTHLY`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=DAILY`,
              method: 'GET',
            }),
            baseQuery({
              url: `${ticksStoreAPI}/users/${patient.id}/aggregate?start_time=${startTime}&end_time=${endTime}&metrics=heart_rate&reduce=hist`,
              method: 'GET',
            }),
            apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${patient.id}/clinicians?teamTypes=PRIMARY`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${patient.id}/clinicians?teamTypes=SUPPORT`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
          ]);

          if (
            factsResponse.error ||
            sleepFactsResponse.error ||
            monthFactsResponse.error ||
            dailyFactsResponse.error ||
            aggHistResponse.error ||
            primaryTeamResponse.error ||
            supportTeamResponse.error
          ) {
            return {
              error: (factsResponse.error ||
                sleepFactsResponse.error ||
                monthFactsResponse.error ||
                dailyFactsResponse.error ||
                aggHistResponse.error ||
                primaryTeamResponse.error ||
                supportTeamResponse.error) as RadiusApiError,
            };
          }

          const teams = ((primaryTeamResponse.data ?? []) as User[]).concat(
            (supportTeamResponse.data ?? []) as User[]
          );

          const reportData = reportFromFactsData(
            patient,
            tz,
            startTime,
            endTime,
            {
              ...((factsResponse.data as any)?.data || {}),
              ...((sleepFactsResponse.data as any)?.data || {}),
            },
            monthFactsResponse.data,
            [],
            dailyFactsResponse.data,
            aggHistResponse.data,
            teams,
            queryParams.unitSystem,
            {},
            mrn
          );

          return { data: reportData };
        },
        providesTags: ['MemberReports'],
      }),
      fetchDataSummaryReport: builder.query<ClinicalReportPatientData, MemberReportParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const [patientResponse, patientFhirResponse] = await Promise.all([
            baseQuery({
              url: `/registry/api/v1/users/${queryParams.patientId}`,
              method: 'GET',
            }),
            baseQuery({
              url: `/fhirservice/api/v1.0/patients/${queryParams.patientId}`,
              method: 'GET',
            }),
          ]);

          if (patientResponse.error) {
            return { error: patientResponse.error };
          }

          const patient = patientResponse.data as User;
          const fhirData =
            (patientFhirResponse?.data as { identifier: { system: string; value: string }[] }) ||
            null;
          const mrn =
            fhirData?.identifier?.find((item) => item?.system?.includes('urn:epic'))?.value || null;

          const tz =
            patient.languageRegion?.timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/Los_Angeles';

          const { startTime, endTime } = getTimezoneMonthDate(queryParams.period.value, tz);
          const monthMetrics = DATA_CLINICAL_MONTH_METRICS;

          const [
            monthFactsResponse,
            dailyFactsResponse,
            primaryTeamResponse,
            supportTeamResponse,
            diagnosesResponse,
            programDetailResponse,
            ticksResponse,
          ] = await Promise.all([
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=MONTHLY`,
              method: 'GET',
            }),
            baseQuery({
              url: `${eventsProcessorAPI}/users/${patient.id}/facts-over-time?facts=${monthMetrics}&tz=America/Los_Angeles&from_ts=${startTime}&to_ts=${endTime}&granularity=DAILY`,
              method: 'GET',
            }),
            apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${patient.id}/clinicians?teamTypes=PRIMARY`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/registry/api/v1')(
              {
                url: `/users/${patient.id}/clinicians?teamTypes=SUPPORT`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/factuary/api/v1')(
              {
                url: `/screener/${patient.id}/screen?deduplicate=true`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
            apiBaseQuery('/factuary/api/v2')(
              {
                url: `/users/${patient.id}/programs/detail`,
                method: 'GET',
              },
              queryApi,
              extraOptions
            ),
            //TODO: add request for ticks from startTime to endTime for those metrics which should show all readings per day
            //ex: https://radius-dev.all.health/ticks-store/api/v1/users/9cc472f2-578b-48b6-be28-7d46166339ec/ticks?metrics=weight%3D-1,weight_conf&start_time=1710129600&end_time=1710215999'
            //also if metric should show only values that are electronically transmitted - add device_type to the request and filter those ticks that have device_type (should be checked again with Tim and Anton)
            baseQuery({
              url: `${ticksStoreAPI}/users/${patient.id}/ticks?start_time=${startTime}&end_time=${endTime}&metrics=weight%3D-1%2Cweight_conf%2Csystolic%2Cdiastolic`,
              method: 'GET',
            }),
          ]);

          if (
            monthFactsResponse.error ||
            dailyFactsResponse.error ||
            primaryTeamResponse.error ||
            supportTeamResponse.error ||
            ticksResponse.error
          ) {
            return {
              error: (monthFactsResponse.error ||
                dailyFactsResponse.error ||
                primaryTeamResponse.error ||
                supportTeamResponse.error ||
                ticksResponse.error) as RadiusApiError,
            };
          }

          const teams = ((primaryTeamResponse.data ?? []) as User[]).concat(
            (supportTeamResponse.data ?? []) as User[]
          );

          const ticks = ticksResponse.data as { [key: string]: Array<ReportTick> };
          const diagnosesData: any = diagnosesResponse?.data || [];
          let diagnosesSet: Set<string> = new Set();
          for (const patientAnswers of diagnosesData) {
            for (const answers of patientAnswers.patient) {
              const answerValues = answers.answer_text.split(', ');
              answerValues.forEach(diagnosesSet.add, diagnosesSet);
            }
          }
          const diagnosesArray = [...Array.from(diagnosesSet)].sort();
          const detailsData = programDetailResponse.data as { data: ProgramDetails[] };
          const programsData =
            detailsData.data
              ?.filter((program) => program?.program_status !== 'canceled')
              .map((program) => program?.program_name) || [];

          const reportData = reportFromFactsData(
            patient,
            tz,
            startTime,
            endTime,
            {},
            monthFactsResponse.data,
            [],
            dailyFactsResponse.data,
            {},
            teams,
            queryParams.unitSystem,
            ticks,
            mrn
          );

          return { data: { ...reportData, diagnoses: diagnosesArray, programs: programsData } };
        },
        providesTags: ['MemberReports'],
      }),
    };
  },
});

export const {
  useLazyFetchMemberEngagementReportQuery,
  useLazyFetchMemberSummaryReportQuery,
  useLazyFetchDataSummaryReportQuery,
  useLazyFetchMemberBandStatusReportQuery,
} = memberReportsApi;
