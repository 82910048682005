import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BandStatusReportRawData,
  BillingCareMemberRawData,
  EngagementReportRawData,
  PeriodItem,
} from 'models/reports';
import { adminReportsApi } from 'services/userReports/adminReports';
import { RootState } from 'store/rootReducer';

interface ReportsState {
  openSummary: boolean;
  periodSelected?: PeriodItem;
  careTimeTracking: {
    items: BillingCareMemberRawData[];
    totalRecords: number;
    ctmTotalSecondsSpent: number;
    careTeam: BillingCareMemberRawData[];
  };
  populationEngagement: {
    items: EngagementReportRawData[];
    totalRecords: number;
  };
  populationBandStatus: {
    items: BandStatusReportRawData[];
    totalRecords: number;
  };
}

const initialState: ReportsState = {
  openSummary: false,
  careTimeTracking: {
    items: [],
    totalRecords: 0,
    ctmTotalSecondsSpent: 0,
    careTeam: [],
  },
  populationEngagement: {
    items: [],
    totalRecords: 0,
  },
  populationBandStatus: {
    items: [],
    totalRecords: 0,
  },
};

const adminReportsSlice = createSlice({
  name: 'adminReports',
  initialState,
  reducers: {
    setAdminOpenSummary: (state, { payload }: PayloadAction<boolean>) => {
      state.openSummary = payload;
    },
    setAdminReportPeriod: (state, { payload }: PayloadAction<PeriodItem | undefined>) => {
      state.periodSelected = payload;
    },
    clearAdminReport: (state) => {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminReportsApi.endpoints.fetchCareTeamTimeTrackingReport.matchFulfilled,
      (state, { payload }) => {
        state.careTimeTracking.items = payload.groupedData;
        state.careTimeTracking.totalRecords = payload.groupedData.length;
        state.careTimeTracking.ctmTotalSecondsSpent = payload.groupedData.reduce(
          (sum, record) =>
            record.careMemberTotalSecondsSpent ? sum + record.careMemberTotalSecondsSpent : sum,
          0
        );
        state.careTimeTracking.careTeam = payload.groupedData.filter(
          (report) => !!report.careMemberTotalSecondsSpent
        );
      }
    );
    builder.addMatcher(
      adminReportsApi.endpoints.fetchEngagementReport.matchFulfilled,
      (state, { payload }) => {
        state.populationEngagement.items = payload;
        state.populationEngagement.totalRecords = payload?.length;
      }
    );
    builder.addMatcher(
      adminReportsApi.endpoints.fetchAdminBandStatusReport.matchFulfilled,
      (state, { payload }) => {
        state.populationBandStatus.items = payload;
        state.populationBandStatus.totalRecords = payload.length;
      }
    );
  },
});

export const { setAdminReportPeriod, setAdminOpenSummary, clearAdminReport } =
  adminReportsSlice.actions;

export const adminOpenSummaryReportSelector = (state: RootState) => state.admin.reports.openSummary;
export const adminPeriodReportSelector = (state: RootState) => state.admin.reports.periodSelected;
export const adminCareTimeTrackingReportSelector = (state: RootState) =>
  state.admin.reports.careTimeTracking;
export const adminEngagementReportSelector = (state: RootState) =>
  state.admin.reports.populationEngagement;

export default adminReportsSlice.reducer;
