import { format } from 'date-fns';
import { ErrorMessages } from 'models/error';

const ERROR_MESSAGES: ErrorMessages = {
  409: 'This user already exists.',
};

export function getCreateUserMessageError(errorCode: number) {
  let errorMessage = ERROR_MESSAGES[errorCode];

  if (!errorMessage) {
    return `Something went wrong. Please reload the page and try again!`;
  }

  return errorMessage;
}

export const formatDobFromCSV = (dob: string) => {
  const dateOfBirth = format(new Date(dob), 'yyyy-MM-dd');
  return dateOfBirth;
};
