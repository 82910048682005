import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    color: theme.palette.custom.white40,
    overflow: 'auto',
    display: 'flex',
  },
  collapse: {
    overflow: 'hidden',
  },
  link: {
    display: 'block',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.custom.black,
      color: theme.palette.custom.greyE,
    },
    '&.active': {
      backgroundColor: theme.palette.custom.greyD,
      color: theme.palette.custom.blackFill,
    },
  },
  linkDropDownMenu: {
    width: '100%',
  },
  closeDropDownMenu: {
    marginLeft: 'auto',
  },
  tab: {
    alignItems: 'center',
    display: 'flex',
    height: '48px',
    padding: '1rem',
  },
  avatar: {
    fontSize: '2.4rem',
    marginRight: '1rem',
  },
  close: {
    color: 'inherit',
    visibility: 'hidden',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&.visible': {
      visibility: 'visible',
    },
  },
}));

export default useStyles;
