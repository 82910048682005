import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    height: '100%',
    overflow: 'hidden',
    paddingBottom: '56.25%',
    paddingTop: '30px',
    position: 'relative',
    cursor: 'pointer',
  },
  video: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    backgroundColor: 'black',
  },
  card: {
    width: 'auto',
    height: 'auto',
    padding: '1.2rem',
    borderRadius: 0,
  },
  avatar: {
    bgColor: 'gray',
    height: '3.4rem',
    width: '3.4rem',
  },
  cardActions: {
    marginTop: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
