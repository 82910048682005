import React from 'react';
import Box from '@mui/material/Box';
import type { CustomColors } from 'styles/theme';
import type { DefinitionValueProps } from '../DefinitionValue';
import SimpleTooltip from 'components/Tooltips/SimpleTooltip';
import TooltipDetails from 'components/Tooltips/TooltipDetails';
import { Typography } from '@mui/material';
import cx from 'classnames';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export interface DefinitionProps {
  name?: string | React.ReactNode;
  children: React.ReactElement<DefinitionValueProps> | React.ReactElement<DefinitionValueProps>[];
  isAccented?: boolean;
  color?: CustomColors;
  isBgAccented?: boolean;
  tooltipData?: {
    title: string;
    body?: string;
    details?: { text: string; value: string }[];
    alert?: { title: string; icon: React.ReactNode };
    detailFooter?: { text: string; value: string }[];
  };
  valueClassName?: string;
  titleClassName?: string;
  hasHover?: boolean;
  hasValueHover?: boolean;
  link?: string;
}

export function Definition(props: DefinitionProps) {
  const classes = useStyles();
  const theme = useTheme();
  let titleColor: string;
  if (props.isAccented) {
    titleColor = theme.palette.custom.red;
  } else if (props.color) {
    titleColor = theme.palette.custom[props.color];
  } else {
    titleColor = theme.palette.text.disabled;
  }

  const renderBox = () =>
    props.link ? (
      <Link
        to={props.link}
        className={cx(classes.row, {
          bgAccented: props.isBgAccented,
          [classes.rowGrid]: !!props.name,
          [classes.grid]: !props.name && props.link,
          hover: props.hasHover,
        })}
      >
        {props.name && (
          <dt className={cx(classes.name, props.titleClassName)}>
            <Typography variant="para12" sx={{ color: titleColor }}>
              {props.name}
            </Typography>
          </dt>
        )}
        <dd className={cx(classes.value, props.valueClassName, { hover: props.hasValueHover })}>
          {props.children}
        </dd>
      </Link>
    ) : (
      <Box
        className={cx(classes.row, {
          bgAccented: props.isBgAccented,
          [classes.rowGrid]: !!props.name,
          hover: props.hasHover,
        })}
      >
        {props.name && (
          <dt className={cx(classes.name, props.titleClassName)}>
            <Typography variant="para12" sx={{ color: titleColor }}>
              {props.name}
            </Typography>
          </dt>
        )}
        <dd className={cx(classes.value, props.valueClassName, { hover: props.hasValueHover })}>
          {props.children}
        </dd>
      </Box>
    );

  return (
    <>
      {!props.tooltipData ? (
        renderBox()
      ) : (
        <SimpleTooltip
          title={
            <TooltipDetails
              title={props.tooltipData?.title ?? ''}
              body={props.tooltipData?.body}
              details={props.tooltipData?.details}
              alert={props.tooltipData?.alert}
              detailFooter={props.tooltipData?.detailFooter}
            />
          }
          isWithArrow={true}
          placement="top"
          color="black"
        >
          {renderBox()}
        </SimpleTooltip>
      )}
    </>
  );
}
