import { AnalyticsEvent } from 'lib/analytics/models/analytics';
import { UserInfo } from 'lib/analytics/models/userInfo';

export const postUserPageUsageEvents = async (userInfo: UserInfo, events: AnalyticsEvent[]) => {
  try {
    const analyticsApiResponse = await fetch('/analytics-service/v1/analytics', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.userToken}`,
        tenantId: userInfo.tenantId,
      },
      body: JSON.stringify(events),
    });

    const analyticsEvents = await analyticsApiResponse.json();

    return { data: analyticsEvents };
  } catch (error) {
    return { error: error };
  }
};
