import type { DirectCall } from 'sendbird-calls';

import type { StatefulDirectCall } from 'models/calls';

import { updateCall } from 'store/calls';

const registerDirectCallListeners = (
  call: DirectCall,
  dispatchUpdate: (part: Partial<StatefulDirectCall>) => void,
) => {
  call.onEstablished = (call: DirectCall) => {
    dispatchUpdate({ callState: 'established' });
  };
  call.onConnected = (call: DirectCall) => {
    dispatchUpdate({ callState: 'connected' });
  };
  call.onReconnected = (call: DirectCall) => {
    dispatchUpdate({ callState: 'reconnected' });
  };
  call.onReconnecting = (call: DirectCall) => {
    dispatchUpdate({ callState: 'reconnecting' });
  };
  call.onEnded = async (call: DirectCall) => {
    dispatchUpdate({
      callState: 'ended',
      endResult: call.endResult,
      endedBy: call.endedBy,
      callLog: call.callLog,
    });
  };
  call.onRemoteAudioSettingsChanged = (call: DirectCall) => {
    dispatchUpdate({ isRemoteAudioEnabled: call.isRemoteAudioEnabled });
  };
  call.onRemoteVideoSettingsChanged = (call: DirectCall) => {
    dispatchUpdate({ isRemoteVideoEnabled: call.isRemoteVideoEnabled });
  };
};

export const statefyDirectCall = (
  call: DirectCall,
  dispatch: React.Dispatch<any>,
  registerListener: boolean = true,
): StatefulDirectCall => {
  const dispatchUpdate = (part: Partial<StatefulDirectCall>) => {
    const payload = {
      callId: call.callId,
      ...part,
    };
    dispatch(updateCall(payload));
  };

  if (registerListener) {
    registerDirectCallListeners(call, dispatchUpdate);
  }

  return {
    callState: (call.localUser.userId === call.caller.userId) ? 'dialing' : 'ringing',
    callId: call.callId,
    caller: call.caller,
    callee: call.callee,
    isVideoCall: call.isVideoCall,
    localUser: call.localUser,
    remoteUser: call.remoteUser,
    isLocalAudioEnabled: call.isLocalAudioEnabled,
    isRemoteAudioEnabled: call.isRemoteAudioEnabled,
    isLocalVideoEnabled: call.isLocalVideoEnabled,
    isRemoteVideoEnabled: call.isRemoteVideoEnabled,
    myRole: call.myRole,
    isOngoing: call.isOngoing,
    endedBy: call.endedBy,
    isEnded: call.isEnded,
    endResult: call.endResult,
    localMediaView: call.localMediaView,
    remoteMediaView: call.remoteMediaView,
    callLog: call.callLog,

    setLocalMediaView(mediaView) {
      dispatchUpdate({ localMediaView: mediaView });
      return call.setLocalMediaView(mediaView);
    },
    setRemoteMediaView(mediaView) {
      dispatchUpdate({ remoteMediaView: mediaView });
      return call.setRemoteMediaView(mediaView);
    },

    stopVideo() {
      dispatchUpdate({ isLocalVideoEnabled: false });
      return call.stopVideo();
    },
    startVideo() {
      dispatchUpdate({ isLocalVideoEnabled: true });
      return call.startVideo();
    },
    getDuration() {
      return call.getDuration();
    },
    accept(params) {
      return call.accept(params);
    },
    end() {
      return call.end();
    },

    muteMicrophone() {
      dispatchUpdate({ isLocalAudioEnabled: false });
      return call.muteMicrophone();
    },
    unmuteMicrophone() {
      dispatchUpdate({ isLocalAudioEnabled: true });
      return call.unmuteMicrophone();
    },
  };
};
