import React, { useState } from 'react';

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import { DownloadIcon, Expand2Icon, XIcon } from 'assets/icons';
import { useUserAnalytics } from 'hooks/useUserAnalytics';

interface ImagePreviewProps {
  url: string;
  name?: string;
}

const ImagePreview = ({ url, name }: ImagePreviewProps) => {
  const { onCreateEvent } = useUserAnalytics();
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.stopPropagation();
    setShowModal(true);
    onCreateEvent(ev, '<CardActionArea />', '<LinkPreview />');
  };

  const handleClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.SyntheticEvent<{}, Event>
  ) => {
    e.stopPropagation();
    setShowModal(false);
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    window.location.href = url;
  };

  return (
    <>
      <Card
        sx={{ width: '100%', p: 0, height: 'auto' }}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <CardActionArea onClick={(ev) => handleClick(ev)}>
          <CardMedia component="img" image={url} alt="url" />
        </CardActionArea>
        <Collapse
          in={showActions}
          timeout={{ enter: 1000, exit: 1000, appear: 1000 }}
          unmountOnExit
        >
          <CardActions sx={{ flexDirection: 'column' }}>
            <Button
              size="medium"
              fullWidth
              startIcon={<Expand2Icon />}
              onClick={(e) => handleClick(e)}
            >
              FULL SCREEN
            </Button>
            <Button
              size="medium"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={(e) => handleDownload(e)}
            >
              DOWNLOAD
            </Button>
          </CardActions>
        </Collapse>
      </Card>
      <Dialog
        open={showModal}
        onBackdropClick={(e) => handleClose(e)}
        maxWidth="md"
        sx={{ zIndex: 1500 }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="header15">{name ?? 'Image'}</Typography>
          <IconButton onClick={(e) => handleClose(e)}>
            <XIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <CardMedia component="img" image={url} alt="url" />
        </DialogContent>
        <DialogActions sx={{ px: '24px', py: '10px' }}>
          <Button onClick={(e) => handleDownload(e)} variant="cta" color="black">
            DOWNLOAD
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImagePreview;
