import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { AzureIcon, LogoAllIcon } from 'assets/icons';
import Footer from 'components/Footer';
import HeaderAuth from 'components/HeaderAuth';
import Loader from '../Loader';
import useStyles from './styles';

interface AuthPageProps {
  isConnecting: boolean;
  onSignIn: () => void;
}

const AuthPage = ({ isConnecting, onSignIn }: AuthPageProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <HeaderAuth />
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <LogoAllIcon className={classes.logo} />
          <Box className={classes.buttonContainer}>
            {!isConnecting ? (
              <>
                <Typography variant="header19">sign in to your account</Typography>
                <Button
                  onClick={onSignIn}
                  variant="cta"
                  color="black"
                  className={classes.button}
                  fullWidth
                >
                  Sign In
                </Button>
              </>
            ) : (
              <>
                <Box sx={{ height: '24px' }}></Box>
                <Box className={classes.connectingContainer}>
                  <Typography variant="header12">{'Connecting..'}</Typography>
                  <Loader />
                </Box>
              </>
            )}
          </Box>
          <Box className={classes.azureContainer}>
            <AzureIcon />
            <Typography variant="para12" className={classes.azureText}>
              Secured by Microsoft Azure
            </Typography>
          </Box>
        </Box>
        <Box className={classes.footerContainer}>
          <Box className={classes.footer}>
            <Footer isWhiteBackground />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthPage;
