import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Program } from 'models/program';
import { factuaryApi } from 'services/factuary';
import { RootState } from 'store/rootReducer';

export interface InitialProgramsStateProps {
  assigned: Program[];
  all: Program[];
  nextPage: number | null;
}

const initialState: InitialProgramsStateProps = {
  assigned: [],
  all: [],
  nextPage: null,
};

const programsSlice: Slice<
  InitialProgramsStateProps,
  SliceCaseReducers<InitialProgramsStateProps>,
  'programs'
> = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(factuaryApi.endpoints.fetchPrograms.matchFulfilled, (state, { payload }) => {
      state.all = payload.all;
      state.assigned = payload.assigned;
      state.nextPage = payload.nextPage;
    });

    builder.addMatcher(
      factuaryApi.endpoints.fetchUnassignedPrograms.matchFulfilled,
      (state, { payload }) => {
        state.all = [...state.all, ...payload.programs];
        state.nextPage = payload.nextPage;
      }
    );
  },
});

export const assignedProgramsSelector = (state: RootState) => state.programs.assigned;
export const allProgramsSelector = (state: RootState) => state.programs.all;
export const programsNextPageSelector = (state: RootState) => state.programs.nextPage;

export default programsSlice.reducer;
