export const formatTimer = (timer: number, includeHours = true, includeSeconds = true) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${Number(minutes) % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  if (!includeHours) {
    return `${getMinutes} : ${getSeconds}`;
  }

  if (!includeSeconds) {
    return `${getHours} : ${getMinutes}`;
  }

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const parseDuration = (duration: number) => {
  const hours = 0 | (duration / 1000 / 3600);
  const minutes = 0 | (duration / 1000 / 60);
  const seconds = 0 | (duration / 1000) % 60;

  return `${hours}h ${minutes}m ${seconds}s`;
};

export const formatTime = (
  seconds: number,
  includeHours = false,
  includeMinutes = false,
  padMinutes = false
) => {
  const hours = Math.trunc(seconds / 3600);
  const minutes = Math.trunc((seconds % 3600) / 60);

  return hours || includeHours
    ? minutes || includeMinutes
      ? `${hours}h ${padMinutes ? String(minutes).padStart(2, '0') : minutes}m`
      : `${hours}h`
    : `${padMinutes ? String(minutes).padStart(2, '0') : minutes}m`;
};
