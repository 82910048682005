import { createApi } from '@reduxjs/toolkit/query/react';
import { TenantAPI } from './../../models/user';
import { apiBaseQuery } from 'services/base';

interface GetUserTenant {
  userId: string;
  type: 'clinicians' | 'patients';
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: apiBaseQuery('/user/api/v3'),
  tagTypes: ['User'],
  endpoints(builder) {
    return {
      fetchUserTenant: builder.query<TenantAPI[], GetUserTenant>({
        query: (queryParams) => ({
          url: `/users/${queryParams.type}/${queryParams.userId}/tenants`,
          method: 'GET',
        }),
        providesTags: ['User'],
      }),
    };
  },
});

export const { useFetchUserTenantQuery } = userApi;
