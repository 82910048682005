import { useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import reactStringReplace from 'react-string-replace';
import sanitizeHtml from 'sanitize-html';
import { Link, useTheme } from '@mui/material';

const useChatMessageFormatter = () => {
  const theme = useTheme();

  return useCallback(
    (message: string) => {
      return reactStringReplace(sanitizeHtml(message), /(https?:\/\/[^\s<]+)/g, (match) =>
        renderToString(
          <Link
            href={match}
            target="_blank"
            rel="noreferrer"
            color={theme.palette.custom.cyan}
            sx={{ '&:hover': { opacity: 0.7 } }}
          >
            {match}
          </Link>
        )
      ).join('');
    },
    [theme.palette.custom.cyan]
  );
};

export default useChatMessageFormatter;
