export const CALL_END_STATUS = {
  no_answer: 'No Answer',
  canceled: 'Canceled',
  declined: 'Declined',
  other_device_accepted: 'Other Device Accepted',
  completed: 'Completed',
  connection_lost: 'Connection Lost',
  timed_out: 'Timed Out',
  dial_failed: 'Dial Failed',
  accept_failed: 'Accept Failed',
  unknown: 'Unknown',
};

export const MAX_FILE_SIZE_FOR_PREVIEW = 8;
export const ALL_HEALTH_METADATA_TITLE = 'all.health';
export const ALL_HEALTH_METADATA_DESCRIPTION =
  'We detect lifestyle diseases early and help members manage existing conditons to influence health outcomes. Sign up to learn more.';
