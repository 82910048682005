import React, { ReactNode } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel, Typography } from '@mui/material';
import { Tenant, TenantAPI } from 'models/user';
import useStyles from './styles';

interface SelectComponentProps {
  value: Tenant | null;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  options: TenantAPI[];
  label: string;
}

const SelectInput = ({ value, onChange, options, label }: SelectComponentProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      {!value?.tenant && (
        <InputLabel id="select-label" variant="outlined">
          {label}
        </InputLabel>
      )}

      <Select value={value?.tenant} onChange={onChange} label={!value?.tenant ? label : undefined}>
        {options.map((option: TenantAPI) => {
          return (
            <MenuItem value={option.tenantName} key={option.tenantName}>
              <Typography variant="para12">
                {option.tenantDisplayName || option.tenantName.toUpperCase()}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
