import {
  BandFillIcon,
  BloodGlucoseIcon,
  BloodPressureGaugeIcon,
  BloodSp02Icon,
  EmojiIcon,
  HeartHrvIcon,
  HeartWBeatIcon,
  LungsIcon,
  MealsIcon,
  MoonIcon,
  MovementIcon,
  ScaleIcon,
  SyringeIcon,
  TemperatureIcon,
} from 'assets/icons';
import { BandRemovedTypes, DateRange, MetricChartType, SleepDepthTypes } from 'models/charts';
import { getCurrentUnitSystem } from 'utils/staticUnitSystem';

export const GENERAL_LAYOUT_CHART_HEIGHT = 200;
export const WEIGHT_CONFIDENCE_THRESHOLD = 80;

export const CHART_ICON: any = {
  sleep: <MoonIcon />,
  steps: <MovementIcon />,
  heart_rate: <HeartWBeatIcon />,
  rhr: <HeartWBeatIcon />,
  rmssd: <HeartHrvIcon />,
  resp_rate: <LungsIcon />,
  glucose: <BloodGlucoseIcon />,
  spo2: <BloodSp02Icon />,
  meals: <MealsIcon />,
  blood_pressure: <BloodPressureGaugeIcon />,
  temp: <TemperatureIcon />,
  weight: <ScaleIcon />,
  gsr: <EmojiIcon />,
  band_removed: <BandFillIcon />,
  insulin: <SyringeIcon />,
};

export const composedCharts: any = {
  temp: ['skin_temp'],
  insulin: ['bolus', 'basal'],
  band_removed: ['band_removed', 'band_charge', 'band_wearing'],
};

export const complexCharts: any = {
  blood_pressure: ['systolic', 'diastolic'],
  blood_pressure_mean: ['systolic_mean', 'diastolic_mean'],
};

export const parentMetric: any = {
  ambient_temp: 'temp',
  skin_temp: 'temp',
  bolus: 'insulin',
  basal: 'insulin',
  band_removed: 'band_removed',
  band_wearing: 'band_removed',
  band_charge: 'band_removed',
};

export const requestMetrics: any = {
  sleep: ['sleep'],
  steps: ['steps_sum'],
  heart_rate: ['heart_rate_mean', 'heart_rate_min', 'heart_rate_max'],
  rhr: ['rhr_mean'],
  rmssd: ['rmssd_mean', 'rmssd_min', 'rmssd_max'],
  resp_rate: ['resp_rate_mean', 'resp_rate_min', 'resp_rate_max'],
  ambient_temp: ['ambient_temp_mean', 'ambient_temp_min', 'ambient_temp_max'],
  skin_temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  glucose: ['glucose_mean', 'glucose_min', 'glucose_max'],
  weight: ['weight_mean', 'weight_min', 'weight_max'],
  spo2: ['spo2_mean', 'spo2_min', 'spo2_max'],
  gsr: ['gsr_mean', 'gsr_min', 'gsr_max'],
  band_removed: ['band_removed_time'],
  band_charge: ['band_charge_time'],
  band_wearing: ['band_wearing_time'],
  bolus: ['bolus_sum'],
  basal: ['basal_sum'],
  systolic: ['systolic_mean', 'systolic_min', 'systolic_max'],
  diastolic: ['diastolic_mean', 'diastolic_min', 'diastolic_max'],
  meals: ['meals'],
};

export const metricName: Record<string, string> = {
  sleep: 'Sleep',
  steps: 'Movement',
  heart_rate: 'Heart Rate',
  rhr: 'RHR',
  rmssd: 'HRV',
  resp_rate: 'Respiration rate',
  ambient_temp: 'Ambient temp',
  skin_temp: 'Skin temp',
  glucose: 'Glucose',
  weight: 'Weight',
  spo2: 'SpO2',
  gsr: 'GSR',
  band_removed: 'Removed',
  band_wearing: 'Wearing',
  band_charge: 'Charging',
  bolus: 'Bolus',
  basal: 'Basal',
  systolic: 'Systolic',
  diastolic: 'Diastolic',
};

export const metricChartType: MetricChartType = {
  sleep: 'sleepDepth',
  steps: 'basicBar',
  heart_rate: 'basicLine',
  rhr: 'basicLine',
  rmssd: 'basicLine',
  resp_rate: 'basicLine',
  ambient_temp: 'composedLine',
  skin_temp: 'composedLine',
  glucose: 'basicLine',
  weight: 'basicLine',
  spo2: 'basicLine',
  gsr: 'basicLine',
  battery_level: 'basicLine',
  band_removed: 'bandLine',
  band_charge: 'composedBar',
  band_wearing: 'composedBar',
  bolus: 'composedBar',
  basal: 'composedBar',
  blood_pressure: 'bloodPressure',
  meals: 'mealsDots',
  temp: 'composedLine',
  insulin: 'composedBar',
  medications: 'logDots',
  workouts: 'logDots',
  calories: 'basicBar',
  sleep_efficiency: 'basicLine',
  sleep_duration: 'basicLine',
};

export const metricBorderRadius: any = {
  steps: [5, 5, 0, 0],
  bolus: 0,
  basal: 0,
  band_removed: 0,
  band_charge: 0,
  band_wearing: 0,
};

export const metricStack: any = {
  steps: 'steps',
  bolus: 'insulin',
  basal: 'insulin',
  band_removed: 'band_removed',
  band_charge: 'band_removed',
  band_wearing: 'band_removed',
};

export const metricTriggerItem: any = {
  basicBar: true,
  mealsDots: true,
  sleepDepth: true,
};

export const metricsColor: any = {
  sleep: {
    0: '#3882C7',
    1: '#389EC7',
    2: '#10B7C9',
    3: '#FAC919',
  },
  steps: '#FC9C42',
  heart_rate: '#dd439a',
  rmssd: '#f31f68',
  resp_rate: '#aa7eff',
  rhr: '#A04DDB',
  ambient_temp: '#b6a24f',
  skin_temp: '#e4cb63',
  glucose: '#d578ff',
  weight: '#009688',
  spo2: '#26745b',
  gsr: '#4594AD',
  band_removed: '#ffa8a7',
  band_charge: '#ffa8a766',
  band_wearing: '#ffa8a799',
  bolus: '#307bf6',
  basal: '#8e8e8e',
  blood_pressure: '#198dd0',
  meals: '#ffcf66',
  sleep_efficiency: '#3889C7',
};

export const tooltipSleepStagesColors: any = {
  1: '#FAC919',
  2: '#389EC7',
  3: '#10B7C9',
  4: '#3882C7',
};

export const bandRemovedColors: any = {
  1: '#E66067',
  2: '#F0A0CC',
  3: '#00C4BB',
};

export const bandStates: BandRemovedTypes = {
  1: 'Removed',
  2: 'Charging',
  3: 'Wearing',
};

export const rawBandStates: BandRemovedTypes = {
  1: 'Wearing',
  2: 'Removed',
  3: 'Charging',
};

export const sleepDepthLevels: SleepDepthTypes = {
  1: 'Awake',
  2: 'Light',
  3: 'REM',
  4: 'Deep',
};

export const getMetricsUnits = (): Record<string, string> => {
  const unitSystem = getCurrentUnitSystem();
  return {
    sleep: '',
    steps: 'steps',
    heart_rate: 'BPM',
    rhr: 'BPM',
    rmssd: 'ms',
    resp_rate: 'BPM',
    ambient_temp: unitSystem.temperatureSymbol,
    skin_temp: unitSystem.temperatureSymbol,
    temp: unitSystem.temperatureSymbol,
    glucose: 'mg/dL',
    weight: unitSystem.weightSymbol,
    spo2: '%',
    gsr: 'µS',
    band_removed: '',
    band_charge: '',
    band_wearing: '',
    bolus: 'U',
    basal: 'U',
    blood_pressure: 'mmHg',
    meals: 'meals',
  };
};

export const factsMetrics: Record<string, string[]> = {
  sleep: ['sleep_efficiency_mean'],
  steps: ['steps_sum', 'active_time_mean'],
  heart_rate: ['heart_rate_mean', 'heart_rate_min', 'heart_rate_max'],
  rhr: ['rhr_mean', 'rhr_min', 'rhr_max'],
  rmssd: ['rmssd_mean', 'rmssd_min', 'rmssd_max'],
  resp_rate: ['resp_rate_mean', 'resp_rate_min', 'resp_rate_max'],
  temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  ambient_temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  skin_temp: ['skin_temp_mean', 'skin_temp_min', 'skin_temp_max'],
  glucose: ['glucose_mean', 'glucose_min', 'glucose_max'],
  weight: ['weight_mean', 'weight_min', 'weight_max'],
  spo2: ['spo2_mean', 'spo2_min', 'spo2_max'],
  gsr: ['gsr_mean', 'gsr_min', 'gsr_max'],
  insulin: ['bolus_sum', 'basal_sum'],
  blood_pressure: ['systolic_mean', 'diastolic_mean'],
  systolic: ['systolic_mean'],
  diastolic: ['diastolic_mean'],
};

export const factsMeanMetrics: Record<string, string> = {
  sleep: 'sleep_efficiency_mean',
  steps: 'steps_sum',
  heart_rate: 'heart_rate_mean',
  rhr: 'rhr_mean',
  rmssd: 'rmssd_mean',
  resp_rate: 'resp_rate_mean',
  temp: 'skin_temp_mean',
  ambient_temp: 'ambient_temp_mean',
  skin_temp: 'skin_temp_mean',
  glucose: 'glucose_mean',
  weight: 'weight_mean',
  spo2: 'spo2_mean',
  gsr: 'gsr_mean',
  systolic: 'systolic_mean',
  diastolic: 'diastolic_mean',
};

export const xAxisLabelFormat: Record<DateRange, string> = {
  1: 'haaa',
  2: 'haaa',
  7: 'MMM dd',
  14: 'MMM dd',
  21: 'MMM dd',
  28: 'MMM dd',
};
