import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme.palette.customBackground.aside,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '4px',
  },
  button: {
    padding: '8px',
  },
  timeContainer: {
    color: theme.palette.text.secondary,
  },
  time: {
    flex: 'none',
    width: '24px',
    minWidth: 0,
  },
  slider: {
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
    height: '4px',
    margin: '0 8px',
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&:before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
      },
      '&:after': {
        width: '32px',
        height: '32px',
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${
          theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 16%)' : 'rgb(0 0 0 / 16%)'
        }`,
      },
      '&.Mui-active': {
        width: 16,
        height: 16,
      },
    },
    '& .MuiSlider-rail': {
      opacity: 0.28,
    },
  },
  speakerIcon: {
    fontSize: '1.4rem',
  },
}));

export default useStyles;
