import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.custom.greyD,
    display: 'flex',
    height: '48px',
    paddingLeft: '1.2rem',
    paddingRight: '0.7rem',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.custom.black,
    },
    '&.active': {
      backgroundColor: theme.palette.customBackground.input,
      color: theme.palette.text.primary,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '7px',
  },
  avatar: {
    width: 24,
    height: 24,
    marginLeft: '1.2rem',
    fontSize: '1rem',
  },
  avatarIcon: {
    color: 'inherit',
    display: 'flex',
    fontSize: '2.4rem',
    marginLeft: '1.2rem',
  },
  menuIcon: {
    color: 'inherit',
  },
  menu: {
    zIndex: theme.zIndex.snackbar,
    '& .MuiPaper-root': {
      padding: '0px',
      borderRadius: '0px 0px 12px 12px',
    },
    '& .MuiMenuItem-root': {
      height: '3.6rem',
    },
  },
  paper: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginLeft: '1.5rem',
    marginTop: '0.7rem',
    '& .MuiListItemIcon-root': {
      justifyContent: 'center',
    },
    '& .MuiTypography-root': {
      marginLeft: '1rem',
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 18,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  userName: {
    maxWidth: '15rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
