import { UserRole } from 'models/user';

export const USER_ROLE: Record<UserRole, string> = {
  md: 'Provider',
  nurse: 'Nurse',
  coach: 'Coach',
  specialist: 'Specialist',
  support: 'Support',
  tenant_admin: 'Tenant Admin',
  clinician: 'Clinician',
  patient: 'Patient',
  observer: 'Observer',
};
