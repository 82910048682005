import React from 'react';

import FeatureFlagsContext, { FeatureFlags, FeatureGroup } from './context';
import useFeatureFlags from './useFeatureFlags';

function FeatureFlagProvider({
  features = {},
  children,
}: {
  features?: FeatureFlags;
  children: React.ReactNode;
}) {
  const currentFeatures = useFeatureFlags();
  return (
    <FeatureFlagsContext.Provider
      value={mergeFeatures(transformFlags(currentFeatures), transformFlags(features))}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}

function transformFlags(features: FeatureFlags) {
  if (!Array.isArray(features)) return features;
  return Object.fromEntries(features.map((feature) => [feature, true]));
}

function mergeFeatures(a: FeatureGroup, b: FeatureGroup): FeatureGroup {
  return { ...a, ...b };
}

export default FeatureFlagProvider;
