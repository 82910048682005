import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    color: theme.palette.custom.white40,
    overflow: 'auto',
    display: 'flex',
  },
  link: {
    display: 'block',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.custom.black,
      color: theme.palette.custom.greyE,
    },
    '&.active': {
      backgroundColor: theme.palette.customBackground.input,
      color: theme.palette.text.primary,
    },
  },
  linkDropDownMenu: {
    width: '100%',
  },
  closeDropDownMenu: {
    marginLeft: 'auto',
  },
  tab: {
    alignItems: 'center',
    display: 'flex',
    width: '20rem',
    height: '4.8rem',
    transition: 'width 1s',
    padding: '1.2rem 1rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1.2rem',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '15rem',
    },
  },
  name: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
  },
  avatar: {
    fontSize: '2.4rem',
    marginRight: '1rem',
  },
  close: {
    color: 'inherit',
    visibility: 'hidden',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&.visible': {
      visibility: 'visible',
    },
  },
}));

export default useStyles;
