import { KG_TO_POUND } from '@constants/global';

export function kgToLbs(kg: number | null) {
  return Number(kg) * KG_TO_POUND;
}

export function lbsToKg(lbs: number | null) {
  return Number(lbs) / KG_TO_POUND;
}

export function cmToFtIn(cm: number | null): Array<number | null> {
  if (typeof cm !== 'number') {
    return [null, null];
  }
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  return [feet, inches];
}

export function ftInToCm([feet, inches]: Array<number | null>) {
  if (feet === null && inches === null) {
    return null;
  }

  const totalInches = Number(feet) * 12 + Number(inches);
  return totalInches * 2.54;
}

export function feetToFeetAndInches(feet: number) {
  const inches = (feet % 1) * 12;
  return [Math.floor(feet), inches];
}

export function metersToFeet(m: number | null) {
  return Number(m) * 3.28084;
}

export function celsiusToFahrenheit(value: number) {
  return (Number(value) * 9) / 5 + 32;
}

export function fahrenheitToCelsius(value: number) {
  return ((Number(value) - 32) * 5) / 9;
}
