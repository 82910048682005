import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { User as SbUser } from 'sendbird-calls';

import { RootState } from 'store/rootReducer';
import {
  AudioInputDeviceInfo,
  AudioOutputDeviceInfo,
  StatefulDirectCall,
  VideoInputDeviceInfo,
} from 'models/calls';

export type CallState = {
  readonly user?: SbUser;
  readonly calls: StatefulDirectCall[];
  readonly audioInputDeviceInfo: AudioInputDeviceInfo;
  readonly audioOutputDeviceInfo: AudioOutputDeviceInfo;
  readonly videoInputDeviceInfo: VideoInputDeviceInfo;
  isVideoExpanded: boolean;
};

export const initialState: CallState = {
  user: undefined,
  calls: [],
  audioInputDeviceInfo: { current: undefined, available: [] },
  audioOutputDeviceInfo: { current: undefined, available: [] },
  videoInputDeviceInfo: { current: undefined, available: [] },
  isVideoExpanded: false,
};

const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    auth: (state, action: PayloadAction<SbUser | undefined>) => ({
      ...state,
      user: action.payload,
    }),
    deauth: () => ({
      ...initialState,
    }),
    addCall: (state, action) => {
      state.calls.push(action.payload);
    },
    updateCall: (state, action) => {
      const callIndex = state.calls.findIndex((call) => call.callId === action.payload.callId);
      if (callIndex === -1) {
        return;
      }
      state.calls[callIndex] = {
        ...state.calls[callIndex],
        ...action.payload,
      };
    },
    clearCalls: (state) => ({
      ...state,
      calls: [],
    }),
    clearCallsAndDevices: (state) => ({
      ...state,
      calls: [],
      audioInputDeviceInfo: { current: undefined, available: [] },
      audioOutputDeviceInfo: { current: undefined, available: [] },
      videoInputDeviceInfo: { current: undefined, available: [] },
      isVideoExpanded: false,
    }),
    updateAudioInputDeviceInfo: (state, action) => ({
      ...state,
      audioInputDeviceInfo: {
        ...state.audioInputDeviceInfo,
        ...action.payload,
      },
    }),
    updateAudioOutputDeviceInfo: (state, action) => ({
      ...state,
      audioOutputDeviceInfo: {
        ...state.audioOutputDeviceInfo,
        ...action.payload,
      },
    }),
    updateVideoInputDeviceInfo: (state, action) => ({
      ...state,
      videoInputDeviceInfo: {
        ...state.videoInputDeviceInfo,
        ...action.payload,
      },
    }),
    setVideoExpanded: (state, action) => ({
      ...state,
      isVideoExpanded: action.payload,
    }),
  },
});

export const {
  auth,
  deauth,
  addCall,
  updateCall,
  clearCalls,
  clearCallsAndDevices,
  updateAudioInputDeviceInfo,
  updateAudioOutputDeviceInfo,
  updateVideoInputDeviceInfo,
  setVideoExpanded,
} = callsSlice.actions;
export const callsStateSelector = (state: RootState) => state.calls;
export const callsSelector = (state: RootState) => state.calls.calls;
export const isVideoExpandedSelector = (state: RootState) => state.calls.isVideoExpanded;

export default callsSlice.reducer;
