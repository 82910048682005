import React, { MouseEvent, useMemo, useState } from 'react';
import { generalBadgeSelector, notificationListSelector } from 'store/notifications';

import { BellIcon } from 'assets/icons';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import NotificationListItems from './components/NotificationListItems';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import cx from 'classnames';
import { useAppSelector } from 'store/index';
import useStyles from './styles';
import { useUserAnalytics } from 'hooks/useUserAnalytics';
import StyledBadge from './styledComponents/StyledBadge';
import { chatConfigSelector } from 'store/account';
import { NotificationList } from 'models/notification';

const Notifications = () => {
  const classes = useStyles();
  const notifications = useAppSelector(notificationListSelector);
  const generalBadge = useAppSelector(generalBadgeSelector);
  const chatConfig = useAppSelector(chatConfigSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onCreateEvent } = useUserAnalytics();
  const notificationList = useMemo(
    () => notifications.filter((item: any) => chatConfig !== 'OFF' || item.type !== 'messages'),
    [notifications, chatConfig]
  );

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    onCreateEvent(event, '<Notifications />', '<Header />');
  };

  const handleClose = () => {
    setIsOpen(false);
    setExpanded(false);
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <IconButton
        classes={{ root: cx(classes.notificationButton, { active: isOpen }) }}
        aria-describedby="notification"
        onClick={handleOpen}
        size="large"
      >
        <StyledBadge variant={generalBadge ? 'standard' : 'dot'} badgeContent={generalBadge}>
          <BellIcon />
        </StyledBadge>
      </IconButton>
      <Popper
        id="notification"
        anchorEl={anchorEl}
        open={isOpen}
        placement="bottom-end"
        transition
        className={classes.popper}
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: classes.notificationContainer }}>
              <span className={classes.arrow} ref={setArrowRef} />
              <ClickAwayListener onClickAway={handleClose}>
                <List dense disablePadding>
                  {notificationList?.map((notifications: NotificationList) => (
                    <NotificationListItems
                      key={notifications.id}
                      badge={notifications.badge}
                      type={notifications.type}
                      title={notifications.title}
                      list={notifications.list}
                      handleClose={handleClose}
                      handleChange={handleChange}
                      expanded={expanded}
                    />
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default Notifications;
