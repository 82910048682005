import { BandStatusReportRawData } from 'models/reports';

export const reportFromBandData = (
  rawData: BandStatusReportRawData[]
): BandStatusReportRawData[] => {
  return rawData.map((item: BandStatusReportRawData) => ({
    ...item,
    memberName: `${item.familyName || ''}, ${item.givenName || ''} `,
  }));
};
