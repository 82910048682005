import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Cohort } from 'models/cohorts';

export interface CohortsState {
  userCohorts: Cohort[];
  selectedCohort: Cohort | null;
  editedCohort: Cohort | null;
  totalPopulation: number;
}

const initialState = {
  userCohorts: [],
  selectedCohort: null,
  editedCohort: null,
  totalPopulation: 0,
};

const cohortSlice = createSlice<CohortsState, SliceCaseReducers<CohortsState>, 'cohorts'>({
  name: 'cohorts',
  initialState,
  reducers: {
    setTotalPopulation: (state, action: PayloadAction<number>) => {
      state.totalPopulation = action.payload;
    },
    setUserCohorts: (state, action: PayloadAction<Cohort[]>) => {
      state.userCohorts = action.payload;
    },
    setSelectedCohort: (state, action: PayloadAction<Cohort>) => {
      state.selectedCohort = action.payload;
    },
    setEditedCohort: (state, action: PayloadAction<Cohort | null>) => {
      state.editedCohort = action.payload;
    },
    addUserCohort: (state, action: PayloadAction<Cohort>) => {
      state.userCohorts.push(action.payload);
    },
    editUserCohort: (state, action: PayloadAction<Cohort>) => {
      state.userCohorts = state.userCohorts.map((cohort) => {
        return {
          ...(cohort.id === action.payload.id ? action.payload : cohort),
        };
      });
    },
    deleteUserCohort: (state, action: PayloadAction<string>) => {
      state.userCohorts = state.userCohorts.filter((cohort) => cohort.id !== action.payload);
    },
  },
});

export const { actions } = cohortSlice;

export default cohortSlice.reducer;
