import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SystemSurvey } from 'models/systemSurvey';
import { adminMembersApi } from 'services/registry/adminMembers';
import { adminApi } from 'services/admin';
import type { RootState } from 'store/rootReducer';

interface AccountActionsState {
  permanentlyDeleteProgress: number;
  dischargeProgress: number;
  holdProgress: number;
  sendMessageProgress: number;
  reassignClinicianProgress: number;
  intakeSurveys: SystemSurvey[];
}

const initialState: AccountActionsState = {
  permanentlyDeleteProgress: 0,
  dischargeProgress: 0,
  holdProgress: 0,
  sendMessageProgress: 0,
  reassignClinicianProgress: 0,
  intakeSurveys: [],
};

const accountActionsSlice = createSlice({
  name: 'accountActions',
  initialState,
  reducers: {
    setPermanentlyDeleteProgress: (state, action: PayloadAction<number>) => {
      state.permanentlyDeleteProgress = action.payload;
    },
    setHoldProgress: (state, action: PayloadAction<number>) => {
      state.holdProgress = action.payload;
    },
    setReassignClinicianProgress: (state, action: PayloadAction<number>) => {
      state.reassignClinicianProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(adminMembersApi.endpoints.sendMessageToMember.matchPending, (state) => {
      state.sendMessageProgress = 50;
    });

    builder.addMatcher(adminMembersApi.endpoints.sendMessageToMember.matchRejected, (state) => {
      state.sendMessageProgress = 100;
    });

    builder.addMatcher(adminMembersApi.endpoints.sendMessageToMember.matchFulfilled, (state) => {
      state.sendMessageProgress = 100;
    });

    builder.addMatcher(adminMembersApi.endpoints.dischargeRestoreMembers.matchPending, (state) => {
      state.dischargeProgress = 50;
    });

    builder.addMatcher(adminMembersApi.endpoints.dischargeRestoreMembers.matchRejected, (state) => {
      state.dischargeProgress = 100;
    });

    builder.addMatcher(
      adminMembersApi.endpoints.dischargeRestoreMembers.matchFulfilled,
      (state) => {
        state.dischargeProgress = 100;
      }
    );

    builder.addMatcher(adminApi.endpoints.getIntakeSurveys.matchFulfilled, (state, { payload }) => {
      state.intakeSurveys = payload;
    });
  },
});

export const selectPermanentlyProgressState = (state: RootState) =>
  state.admin.accountActions.permanentlyDeleteProgress;
export const selectDischargeProgressState = (state: RootState) =>
  state.admin.accountActions.dischargeProgress;
export const selectHoldProgressState = (state: RootState) =>
  state.admin.accountActions.holdProgress;
export const selectSendMessageProgressState = (state: RootState) =>
  state.admin.accountActions.sendMessageProgress;
export const selectReassignClinicianProgressState = (state: RootState) =>
  state.admin.accountActions.reassignClinicianProgress;
export const selectIntakeSurveys = (state: RootState) => state.admin.accountActions.intakeSurveys;
export const { setPermanentlyDeleteProgress, setHoldProgress, setReassignClinicianProgress } =
  accountActionsSlice.actions;
export default accountActionsSlice.reducer;
