import { Cohort, CohortDisplayFact } from 'models/cohorts';

export const BASIC_COLUMNS: CohortDisplayFact[] = [
  {
    base: 'age',
    name: 'age',
    display_name: 'Age',
  },
  {
    base: 'sex',
    name: 'sex',
    display_name: 'Sex',
  },
];
export const COMPLIANCE_COLUMNS: CohortDisplayFact[] = [
  {
    base: 'sync_last',
    name: 'sync_last',
    display_name: 'Last Sync',
  },
  {
    base: 'band_compliance',
    name: 'band_compliance',
    display_name: 'Band',
  },
];
export const TOTAL_POPULATION_COLUMNS: CohortDisplayFact[] = [
  {
    base: 'bmi',
    name: 'bmi',
    display_name: 'BMI',
  },
  {
    base: 'steps',
    name: 'steps_sum_1d',
    display_name: 'Steps',
  },
  {
    base: 'sleep_duration',
    name: 'sleep_duration_mean_1d',
    display_name: 'Sleep',
  },
];

export const DRAFT_COHORT: Cohort = {
  title: '',
  description: '',
  display_facts: [...BASIC_COLUMNS, ...COMPLIANCE_COLUMNS],
  filters: [],
  json_conditions: [],
  isDraft: true,
};

export const TOTAL_POPULATION: Cohort = {
  id: '__TOTAL_POPULATION_COHORT__',
  title: 'Total Population',
  description: '',
  display_facts: [...BASIC_COLUMNS, ...TOTAL_POPULATION_COLUMNS, ...COMPLIANCE_COLUMNS],
  filters: [],
  json_conditions: [],
  type: 'shared',
  status: 'live',
};
