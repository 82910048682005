export const getHost = () => {
  let host = window.location.host;
  if (host.includes('localhost')) {
    host = 'radius-dev.all.health';
  }

  const type = host.split('.')[0];
  if (type.includes('radius')) {
    return host;
  }
  return host.replace(`${type}.`, '');
};

export const getBaseUrl = () => {
  let origin = window.location.origin;
  let host = window.location.host;
  const type = host.split('.')[0];
  if (host.includes('localhost') || type.includes('radius')) {
    return origin;
  }
  return origin.replace(`${type}.`, '');
};
