import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSbCalls } from 'hooks/sbCalls';
import { StatefulDirectCall } from 'models/calls';
import { useMediaPortalContext } from './MediaPortalContext';

const LocalVideoPortal: React.FC = () => {
  const { localVideoPortalDestination } = useMediaPortalContext();
  const { calls } = useSbCalls();
  const ongoingCall = calls.find((call) => call.isOngoing) as StatefulDirectCall | undefined;

  const localVideoRef = useRef<HTMLVideoElement>(null);

  const portalDestination =
    localVideoPortalDestination || document.getElementById('default-local-video-container');

  useEffect(() => {
    if (ongoingCall) {
      if (ongoingCall.callState === 'ended') {
        ongoingCall.setLocalMediaView(null as any);
      } else {
        ongoingCall.setLocalMediaView(localVideoRef.current as HTMLVideoElement);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingCall?.callId, portalDestination]);

  if (!ongoingCall || !ongoingCall.isVideoCall) {
    return null;
  }
  if (!portalDestination) return null;

  return ReactDOM.createPortal(
    <video ref={localVideoRef} autoPlay playsInline muted style={{ display: 'block' }} />,
    portalDestination
  );
};

export default LocalVideoPortal;
