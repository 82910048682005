import React, { MouseEvent, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { AvatarIcon, XIcon } from 'assets/icons';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { PatientTab as PatientTabModel } from 'models/patientTab';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { removeTab } from 'store/patientTabs';
import { useAppDispatch } from 'store/index';
import { useUserAnalytics } from 'hooks/useUserAnalytics';
import useStyles from './styles';

interface PatientTabProps {
  tab: PatientTabModel;
  tabs: PatientTabModel[];
  isDropDownMenu: boolean;
}

const PatientTab = (props: PatientTabProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { tab, tabs, isDropDownMenu } = props;
  const [isHovering, setIsHovering] = useState(false);
  const path = `/patient/${tab.id}`;
  const active = pathname.includes(path);
  const navigate = useNavigate();
  const { onCreateEvent } = useUserAnalytics();

  const { patientId } = useParams<{ patientId: string }>();

  const handleClose = (tabId: string) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch(removeTab(tabId));
    if (pathname.includes('patient')) {
      const isTabsEmpty = !tabs.filter((tab) => tab.id !== tabId).length;
      if (isTabsEmpty) {
        navigate('/dashboard/today');
      } else {
        const currentIndex = tabs.findIndex((tab) => tab.id === tabId);
        const indexTabtoNavigate = currentIndex === 0 ? currentIndex + 1 : currentIndex - 1;
        const nextTab = tabs[indexTabtoNavigate];
        if (patientId !== nextTab.id) {
          navigate(`/patient/${nextTab.id}/summary`);
        }
      }
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Link
      to={`${path}/summary`}
      className={cx(classes.link, { active, [classes.linkDropDownMenu]: isDropDownMenu })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={(ev) => onCreateEvent(ev, `<PatientTab tab=${tab.name} />`, '<PatientTabs />')}
    >
      <Box className={classes.tab}>
        {tab.image ? (
          <Avatar
            alt={tab.name}
            src={tab.image}
            sx={{ width: 24, height: 24, marginRight: '1.2rem', fontSize: '1rem' }}
          />
        ) : (
          <AvatarIcon className={classes.avatar} />
        )}
        <Typography noWrap className={classes.name} variant="body2">
          {tab.name}
        </Typography>
        <IconButton
          size="small"
          classes={{
            root: cx(classes.close, {
              visible: isHovering,
              [classes.closeDropDownMenu]: isDropDownMenu,
            }),
          }}
          onClick={handleClose(tab.id)}
        >
          <XIcon />
        </IconButton>
      </Box>
    </Link>
  );
};

export default PatientTab;
