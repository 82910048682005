import React from 'react';
import { Typography } from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';
import cx from 'classnames';

import useStyles from './styles';

export type DefinitionVariantType = 'error' | 'success' | 'warning' | 'stale';

export interface DefinitionValueProps extends CommonProps {
  label?: string;
  children: React.ReactNode;
  variant?: DefinitionVariantType;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  onClick?: any;
  hasHover?: boolean;
  hasComplexChildren?: boolean;
}

export function DefinitionValue(props: DefinitionValueProps) {
  const classes = useStyles();
  return (
    <div
      className={cx(
        {
          [classes.error]: props.variant === 'error',
          [classes.success]: props.variant === 'success',
          [classes.warning]: props.variant === 'warning',
          [classes.stale]: props.variant === 'stale',
          [classes.hover]: props.hasHover,
        },
        classes.value,
        props.className
      )}
      onClick={props.onClick}
    >
      {props.prefixIcon ? <span className={classes.valueIcon}>{props.prefixIcon}</span> : null}
      <Typography
        component={props.hasComplexChildren ? 'div' : 'span'}
        sx={{ width: props.hasComplexChildren ? '100%' : 'auto' }}
        variant="para12"
      >
        {props.children}
      </Typography>
      {props.label ? (
        <Typography variant="para12" className={classes.label}>
          {props.label}
        </Typography>
      ) : null}
      {props.suffixIcon ? props.suffixIcon : null}
    </div>
  );
}
