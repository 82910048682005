import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { adminApi } from 'services/admin';
import { analyticsApi } from 'services/analytics';
import { calendarApi } from 'services/calendar';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { eventsProcessorApi } from 'services/eventsProcessor';
import { factuaryApi } from 'services/factuary';
import { filesApi } from 'services/files';
import { foodloggingApi } from 'services/foodlogging';
import { loginApi } from 'services/login';
import { notesApi } from 'services/notes';
import { panelApi } from 'services/panel';
import { registryApi } from 'services/registry';
import rootReducer, { RootState } from './rootReducer';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { configuratorApi } from 'services/configurator';
import { activitiesApi } from 'services/activities';
import { userApi } from 'services/user';
import { userReportsApi } from 'services/userReports';
import { cohortApi } from 'services/cohorts';
import { setSnackbarMessage } from './snackbar';
import { fhirApi } from 'services/fhir';

import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

const serverErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload?.status >= 500 || action.payload?.status === 400) {
      api.dispatch(
        setSnackbarMessage({
          text: 'Something went wrong. Please refresh the page.',
          variant: 'error',
        })
      );
    }

    if (
      action.payload?.status === 403 &&
      ['fetchPatientTeamsMembers', 'fetchUserPatient', 'fetchPatientTab'].includes(
        action.meta?.arg?.endpointName
      )
    ) {
      api.dispatch(
        setSnackbarMessage({
          text: "You don't have access to this user.",
          variant: 'error',
          withRedirect: true,
        })
      );
    }
  }

  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(serverErrorLogger)
      .concat(calendarApi.middleware)
      .concat(eventsProcessorApi.middleware)
      .concat(filesApi.middleware)
      .concat(foodloggingApi.middleware)
      .concat(activitiesApi.middleware)
      .concat(notesApi.middleware)
      .concat(panelApi.middleware)
      .concat(registryApi.middleware)
      .concat(adminApi.middleware)
      .concat(loginApi.middleware)
      .concat(analyticsApi.middleware)
      .concat(factuaryApi.middleware)
      .concat(cohortApi.middleware)
      .concat(configuratorApi.middleware)
      .concat(userReportsApi.middleware)
      .concat(userApi.middleware)
      .concat(fhirApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
