import React, { useMemo, useEffect } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import createAppTheme from './theme';
import { ColorModeContext } from './color-mode';
import { COLOR_MODE_LOCAL_STORAGE_KEY } from '@constants/global';
import useUserSettings from 'hooks/useUserSettings';

export default function AppThemeProvider({ children }: React.PropsWithChildren<{}>) {
  const [{ colorMode }, updateSettings] = useUserSettings();
  const contextValue = useMemo(
    () => ({
      toggleColorMode: () => {
        updateSettings({ colorMode: colorMode === 'light' ? 'dark' : 'light' });
      },
    }),
    [colorMode, updateSettings]
  );

  const theme = useMemo(() => createAppTheme(colorMode), [colorMode]);

  // Save color mode in localStorage so that it can be applied even before the
  // user preferences are loaded
  useEffect(() => {
    localStorage.setItem(COLOR_MODE_LOCAL_STORAGE_KEY, colorMode);
  }, [colorMode]);

  return (
    <ColorModeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </ColorModeContext.Provider>
  );
}
