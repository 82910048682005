import React, { MouseEvent, ReactEventHandler, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FilesPreview from 'components/FilesPreview';
import { Message } from 'models/messaging';
import Typography from '@mui/material/Typography';
import VideoPlayer from 'components/VideoPlayer';
import isEmpty from 'lodash/isEmpty';
import useStyles from './styles';
import { useUserAnalytics } from 'hooks/useUserAnalytics';
import ImagePreview from 'components/ImagePreview';
import AudioPlayer from 'components/AudioPlayer';
import { ALL_HEALTH_METADATA_DESCRIPTION, ALL_HEALTH_METADATA_TITLE } from '@constants/messaging';

interface LinkPreviewProps {
  url: string;
  message: Partial<Message>;
}

const LinkPreview = (props: LinkPreviewProps) => {
  const classes = useStyles();
  const { onCreateEvent } = useUserAnalytics();
  const {
    url,
    message,
    message: { type, ogMetaData, name, size },
  } = props;
  const [fileSize, setFileSize] = useState<string>('');

  const onAudioPlay: ReactEventHandler<HTMLAudioElement> = (event) => {
    const audioMessages = document.getElementsByClassName('chat-audio-message');
    for (let i = 0; i < audioMessages.length; i++) {
      const audioMessage = audioMessages[i] as HTMLAudioElement;
      if (audioMessage !== event.target) {
        audioMessage.pause();
      }
    }

    // Stop all videos
    const videoMessages = document.getElementsByClassName('chat-video-message');
    for (let i = 0; i < videoMessages.length; i++) {
      const videoMessage = videoMessages[i] as HTMLVideoElement;
      videoMessage.pause();
    }

    handleAudioActions('onPlay')(event);
  };

  useEffect(() => {
    if (size) {
      if (size >= 0 && size < 1000) {
        setFileSize(`${size} bytes`);
      } else if (size >= 1000 && size < 1000000) {
        const totalSize = Math.round(size / 1000);
        setFileSize(`${totalSize} KB`);
      } else if (size >= 1000000) {
        const totalSize = Math.round(size / 1000000);
        setFileSize(`${totalSize} MB`);
      }
    }
  }, [size]);

  const handleOpenLink = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onCreateEvent(event, '<CardActionArea />', '<LinkPreview />');
    window.open(ogMetaData!.url, '_blank', 'noreferrer,noopener');
  };

  const handleAudioActions =
    (type: string): ReactEventHandler<HTMLAudioElement> =>
    (event) => {
      onCreateEvent(event, `<ReactAudioPlayer type="${type}" />`, '<LinkPreview />');
    };

  if (type?.includes('video')) {
    return <VideoPlayer url={url} message={message} />;
  }

  if (type?.includes('audio')) {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <AudioPlayer
          className="chat-audio-message"
          onPlay={onAudioPlay}
          onPause={handleAudioActions('onPause')}
          onEnded={handleAudioActions('onEnded')}
          onSeeked={handleAudioActions('onSeeked')}
          src={url}
        />
      </div>
    );
  }

  if (type?.includes('image')) {
    return <ImagePreview url={url} name={message.name} />;
  }

  if (!isEmpty(ogMetaData)) {
    //to support old messages
    const isAllHealthLink = ogMetaData?.url === 'https://all.health';
    const title = isAllHealthLink ? ALL_HEALTH_METADATA_TITLE : ogMetaData?.title;
    const description = isAllHealthLink ? ALL_HEALTH_METADATA_DESCRIPTION : ogMetaData?.description;
    return (
      <Card sx={{ maxWidth: 250, p: 0, height: 'auto' }}>
        <CardActionArea onClick={handleOpenLink}>
          {ogMetaData?.defaultImage?.url && (
            <CardMedia component="img" image={ogMetaData?.defaultImage?.url} alt="url" />
          )}
          <CardContent>
            <Typography
              classes={{ root: classes.ellipsis }}
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={{ m: 0, p: '0 1rem' }}
            >
              {title}
            </Typography>
            <Typography
              classes={{ root: classes.ellipsis }}
              variant="body2"
              color="text.secondary"
              sx={{ m: 0, p: '0 1rem 1rem' }}
            >
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <FilesPreview
      name={name}
      url={url}
      fileSize={fileSize}
      classes={classes}
      action={onCreateEvent}
      type={type || ''}
    />
  );
};

export default LinkPreview;
