import React, { MouseEvent, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogoAllIcon, PhoneIcon } from 'assets/icons';

import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Notifications from './components/Notifications';
import PatientTabs from './components/PatientTabs';
import ROUTES from '@constants/routes';
import Toolbar from '@mui/material/Toolbar';
import UserDropdown from './components/UserDropdown';
import { useSbCalls } from 'hooks/sbCalls';
import useStyles from './styles';
import { useUserAnalytics } from 'hooks/useUserAnalytics';

interface HeaderProps {
  hideNotifications: boolean;
}

const Header = ({ hideNotifications }: HeaderProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { calls } = useSbCalls();
  const { onCreateEvent } = useUserAnalytics();

  const onCall = useMemo(() => {
    return calls.find((call) => call.isOngoing);
  }, [calls]);

  const goToPatient = (ev: MouseEvent<HTMLButtonElement>) => {
    if (onCall) {
      onCreateEvent(ev, `<PhoneIcon onCall.callee=${onCall.callee.nickname} />`, '<Header />');
      navigate(`/patient/${onCall.callee.userId}/summary`);
    }
  };

  return (
    <div className={classes.root} id="header">
      <AppBar className={classes.appBar} elevation={0} position="fixed">
        <Toolbar className={classes.toolbar} classes={{ root: classes.toolbarRoot }}>
          <div className={classes.linkPatientsContainer}>
            <div className={classes.leftSide} id="leftSide">
              <Link
                to={ROUTES.main}
                className={classes.link}
                onClick={(ev) => onCreateEvent(ev, '<Link />', '<Header />')}
              >
                <LogoAllIcon />
              </Link>
              {/* TODO: add global search  */}
              {/* <GlobalSearch /> */}
            </div>
          </div>

          <PatientTabs />

          <div className={classes.actions} id="actions">
            {onCall && onCall.callState && onCall.callState !== 'ended' && (
              <IconButton aria-label="notification" onClick={goToPatient}>
                <Badge
                  classes={{ badge: classes.phoneBadge, root: classes.phoneIcon }}
                  variant="dot"
                >
                  <PhoneIcon />
                </Badge>
              </IconButton>
            )}
            {!hideNotifications ? <Notifications /> : null}
            <UserDropdown />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
