import { differenceInDays, format, isWithinInterval } from 'date-fns';
import {
  EngagementReportRawData,
  EngagementSummaryTotals,
  ExternalIdColumn,
  Period,
  PeriodItem,
} from 'models/reports';
import { groupBy, isEmpty, orderBy, round, sumBy } from 'lodash';

interface ReportFromEngagementData {
  rawData: EngagementReportRawData[];
  period: PeriodItem;
}

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
  period?: Period;
}

interface AccountSummary {
  reports: EngagementReportRawData[];
  period: PeriodItem;
}

export const reportFromEngagementData = ({
  rawData,
  period,
}: ReportFromEngagementData): EngagementReportRawData[] => {
  return orderBy(
    rawData.map((item: any) => {
      return {
        ...item,
        ...(period.period === 'monthly'
          ? { month: period.periodNumber }
          : { week: period.periodNumber }),
        year: period.year,
        givenName: item.first_name ?? '',
        familyName: item.last_name ?? '',
        uid: item.user_id ?? '',
        mrn: item.mrn ?? null,
        externalMrn: item.mrn ?? null,
        externalEmployeeId: item.employee_id || null,
        memberStatus: item.member_status ?? null,
        memberCreatedDate: item.account_created
          ? format(new Date(item.account_created * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberOnboardedDate: item.member_since
          ? format(new Date(item.member_since * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberDaysSinceEnrolled: item?.member_seconds_enrolled
          ? Math.ceil(item.member_seconds_enrolled / 86400)
          : null,
        criteriaMet:
          period?.period === 'monthly'
            ? item?.total_days_band_synced && item.total_days_band_synced >= 16
              ? 'Yes'
              : 'No'
            : null,
        numberVisitsAverage: item.daily_avg ? round(item.daily_avg, 2) : null,
        numberVisits: item.total ?? null,
        memberCreatedAt: item.account_created || null,
        memberOnboardedTimestamp: item.member_since ?? null,
        totalDaysBandSynced: Number(item.total_days_band_synced)
          ? item.total_days_band_synced
          : null,
        averageInteractions: item.band_compliance_avg
          ? round(item.band_compliance_avg * 100, 2)
          : null,
        pledgesInteractions: Number(item.pledges_interactions) ? item.pledges_interactions : null,
        insightsInteractions: Number(item.insights_interactions)
          ? item.insights_interactions
          : null,
        surveysInteractions: Number(item.surveys_interactions) ? item.surveys_interactions : null,
      };
    }),
    [(report) => report.givenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );
};

export const getBandComplianceData = (reports: EngagementReportRawData[], period?: PeriodItem) => {
  const byTotalDaysBandSynced = groupBy(
    reports.filter((item) => !!item.totalDaysBandSynced),
    'totalDaysBandSynced'
  );

  const totalActiveAccounts = sumBy(reports, (report) =>
    report.memberStatus === 'ACTIVE' ? 1 : 0
  );

  const periodDays = period ? differenceInDays(period?.value.end, period?.value.start) + 1 : 7;
  const days = Array.from({ length: periodDays }, (v, k) => k + 1);

  const chartData = !isEmpty(byTotalDaysBandSynced)
    ? days.map((day) => ({
        value: byTotalDaysBandSynced[day]
          ? round((byTotalDaysBandSynced[day].length / totalActiveAccounts) * 100, 1)
          : 0,
        day,
      }))
    : [];

  return chartData;
};

export const getCsvHeaders = ({ externalIdColumns, period }: CsvHeaderProps) => {
  return [
    ...(period === 'monthly'
      ? [{ label: 'Reporting Month', key: 'month' }]
      : [{ label: 'Reporting Week', key: 'week' }]),
    { label: 'Reporting Year', key: 'year' },
    { label: 'First Name', key: 'givenName' },
    { label: 'Last Name', key: 'familyName' },
    { label: 'User ID', key: 'uid' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'memberStatus' },
    { label: 'Account Created', key: 'memberCreatedDate' },
    { label: 'Member Since', key: 'memberOnboardedDate' },
    { label: 'Account Age (d)', key: 'memberDaysSinceEnrolled' },
    ...(period === 'monthly' ? [{ label: '16d Req', key: 'criteriaMet' }] : []),
    { label: 'App Visits Daily Average', key: 'numberVisitsAverage' },
    { label: 'App Visits Total', key: 'numberVisits' },
    { label: 'Surveys', key: 'surveysInteractions' },
    { label: 'Insights', key: 'insightsInteractions' },
    { label: 'Pledges', key: 'pledgesInteractions' },
    { label: 'Band Compliance (d)', key: 'totalDaysBandSynced' },
    { label: 'Band Compliance (%)', key: 'averageInteractions' },
  ];
};

export const getEngagementAccountSummary = ({
  reports,
  period,
}: AccountSummary): EngagementSummaryTotals => {
  const totalAccounts = reports.length || 0;
  const totalAccountsCreated = sumBy(reports, (report) =>
    report.memberCreatedDate &&
    period &&
    isWithinInterval(new Date(report.memberCreatedDate), period?.value)
      ? 1
      : 0
  );
  const totalActiveAccounts = sumBy(reports, (report) =>
    report.memberStatus === 'ACTIVE' ? 1 : 0
  );

  const accountsActivations = sumBy(reports, (report) =>
    report.memberOnboardedDate &&
    period &&
    isWithinInterval(new Date(report.memberOnboardedDate), period?.value)
      ? 1
      : 0
  );

  return {
    totalAccounts,
    totalAccountsCreated,
    totalActiveAccounts,
    accountsActivations,
  };
};
