import Badge, { BadgeProps } from '@mui/material/Badge';

import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: theme.palette.custom.gradientRed,
    borderRadius: '50%',
    right: 4,
    top: 0,
    border: `2px solid ${theme.palette.custom.blackFill}`,
    padding: '4px',
  },
}));

export default StyledBadge;
