import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '4.8rem',
    backgroundColor: theme.palette.customBackground.aside,
    borderRadius: '6px',
    padding: '9px 15px',
    display: 'flex',
  },
  heartIcon: {
    color: theme.palette.custom.red,
    margin: '0 6px',
  },
  whiteBackground: {
    backgroundColor: theme.palette.custom.white,
  },
}));

export default useStyles;
