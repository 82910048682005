import flatMap from 'lodash/flatMap';

export const CLINICAL_REPORT_DATA = [
  {
    title: 'Heart Rate',
    tableTitle: 'Heart Rate Data Table',
    description:
      "The report below helps visualize your heart rate over time. It's beneficial for monitoring conditions such as atrial fibrillation or heart failure.\nHeart rate = the number of times your heart beats per minute",
    charts: [
      {
        name: 'Heart Rate Daily Average',
        key: 'heart_rate',
        y: 'heart_rate_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Minimum',
            key: 'heart_rate_min',
          },
          {
            name: 'Maximum',
            key: 'heart_rate_max',
          },
          {
            name: 'Mean',
            key: 'heart_rate_mean',
          },
        ],
      },
      {
        name: 'Measurement Distribution by BPM',
        key: 'heart_rate_dist',
        y: 'heart_rate_dist',
        type: 'dist',
        isSecondary: true,
        summary: [
          {
            name: 'Total Measurements',
            key: 'heart_rate_count',
            hideUnit: true,
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'heart_rate_min',
        label: 'Minimum HR',
      },
      {
        key: 'heart_rate_max',
        label: 'Maximum HR',
      },
      {
        key: 'heart_rate_mean',
        label: 'Mean HR',
      },
    ],
  },
  {
    title: 'Respiratory Rate',
    tableTitle: 'Respiratory Rate Data Table',
    description:
      "The report below helps visualize your respiratory rate over time. It's beneficial for monitoring conditions such as COPD. \nRespiratory Rate = the number of times you breathe per minute",
    charts: [
      {
        name: 'Respiratory Rate Daily Average',
        key: 'resp_rate',
        y: 'resp_rate_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Minimum',
            key: 'resp_rate_min',
          },
          {
            name: 'Maximum',
            key: 'resp_rate_max',
          },
          {
            name: 'Mean',
            key: 'resp_rate_mean',
          },
          {
            name: 'Total Measurements',
            key: 'resp_rate_count',
            hideUnit: true,
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'resp_rate_min',
        label: 'Minimum RR',
      },
      {
        key: 'resp_rate_max',
        label: 'Maximum RR',
      },
      {
        key: 'resp_rate_mean',
        label: 'Mean RR',
      },
    ],
  },
  {
    title: 'SpO2',
    tableTitle: 'SpO2 Data Table',
    description:
      "The report below helps visualize your oxygen saturation over time. It's beneficial for monitoring conditions such as COPD.\nOxygen saturation = the amount of oxygen in your red blood cells",
    charts: [
      {
        name: 'SpO2 Daily Average',
        key: 'spo2',
        y: 'spo2_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Minimum',
            key: 'spo2_min',
          },
          {
            name: 'Maximum',
            key: 'spo2_max',
          },
          {
            name: 'Mean',
            key: 'spo2_mean',
          },
          {
            name: 'Total Measurements',
            key: 'spo2_count',
            hideUnit: true,
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'spo2_min',
        label: 'Minimum SpO2',
      },
      {
        key: 'spo2_max',
        label: 'Maximum SpO2',
      },
      {
        key: 'spo2_mean',
        label: 'Mean SpO2',
      },
    ],
  },
  {
    title: 'Activity',
    tableTitle: 'Activity Data Table',
    description:
      'Your daily step count is visualized below to help you understand how your feet move on a given day.',
    charts: [
      {
        name: 'Total Daily Steps',
        key: 'steps',
        y: 'steps_sum',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Minimum',
            key: 'steps_min',
          },
          {
            name: 'Maximum',
            key: 'steps_max',
          },
          {
            name: 'Mean',
            key: 'steps_mean',
          },
          {
            name: 'Total Steps',
            key: 'steps_sum',
          },
        ],
      },
      {
        name: 'Step Averages by Day of Week',
        key: 'steps',
        y: 'steps_mean',
        type: 'weekly',
        isSecondary: true,
        summary: [
          {
            name: 'Minimum',
            key: 'steps_min_week',
          },
          {
            name: 'Maximum',
            key: 'steps_max_week',
          },
          {
            name: 'Mean',
            key: 'steps_mean_week',
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'steps_sum',
        label: 'Total Steps',
      },
    ],
  },
  {
    title: 'Sleep',
    tableTitle: 'Sleep Data Table',
    description:
      "The report below helps you understand your sleep habits. It's beneficial for monitoring conditions such as sleep apnea.\nSleep duration = total time spent asleep, including awake periods\nSleep consistency = regularity of your sleep schedule\nSleep efficiency = percentage of time spent actively sleeping",
    charts: [
      {
        name: 'Sleep Duration',
        key: 'sleep_duration',
        y: 'sleep_duration',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Minimum',
            key: 'sleep_duration_min',
          },
          {
            name: 'Maximum',
            key: 'sleep_duration_max',
          },
          {
            name: 'Mean',
            key: 'sleep_duration_mean',
          },
          {
            name: 'Deep',
            key: 'sleep_deep_mean',
            hasDot: true,
          },
          {
            name: 'REM',
            key: 'sleep_rem_mean',
            hasDot: true,
          },
          {
            name: 'Light',
            key: 'sleep_light_mean',
            hasDot: true,
          },
          {
            name: 'Awake',
            key: 'sleep_awake_mean',
            hasDot: true,
          },
        ],
      },
      {
        name: 'Duration Averages by Day of Week',
        key: 'sleep_duration',
        y: 'sleep_duration',
        type: 'weekly',
        isSecondary: true,
        summary: [],
      },
      {
        name: 'Sleep Consistency',
        key: 'sleep_consistency',
        y: 'sleep_time_onset',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Bedtime Min',
            key: 'sleep_time_onset_min',
          },
          {
            name: 'Bedtime Max',
            key: 'sleep_time_onset_max',
          },
          {
            name: 'Wake Time Min',
            key: 'sleep_time_final_awekening_min',
          },
          {
            name: 'Wake Time Max',
            key: 'sleep_time_final_awekening_max',
          },
          {
            name: 'Avg Awakenings',
            key: 'sleep_awakenings_mean',
          },
        ],
      },
      {
        name: 'Consistency Averages by Day of Week',
        key: 'sleep_consistency',
        y: 'sleep_time_onset',
        type: 'weekly',
        isSecondary: true,
        summary: [
          {
            name: 'Bedtime',
            key: 'sleep_time_onset_mean_week',
          },
          {
            name: 'Wake Time',
            key: 'sleep_time_final_awekening_mean_week',
          },
          {
            name: 'Latency',
            key: 'sleep_timetosleep_mean_week',
          },
        ],
      },
      {
        name: 'Sleep Efficiency',
        key: 'sleep_efficiency',
        y: 'sleep_efficiency_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Minimum',
            key: 'sleep_efficiency_min',
          },
          {
            name: 'Maximum',
            key: 'sleep_efficiency_max',
          },
          {
            name: 'Mean',
            key: 'sleep_efficiency_mean',
          },
        ],
      },
      {
        name: 'Efficiency by Day of Week',
        key: 'sleep_efficiency',
        y: 'sleep_efficiency_mean',
        type: 'weekly',
        isSecondary: true,
        summary: [
          {
            name: 'Minimum',
            key: 'sleep_efficiency_min_week',
          },
          {
            name: 'Maximum',
            key: 'sleep_efficiency_max_week',
          },
          {
            name: 'Mean',
            key: 'sleep_efficiency_mean_week',
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'sleep_duration',
        label: 'Total',
      },
      {
        key: 'sleep_light',
        label: 'Light',
      },
      {
        key: 'sleep_rem',
        label: 'REM',
      },
      {
        key: 'sleep_deep',
        label: 'Deep',
      },
      {
        key: 'sleep_awake',
        label: 'Awake',
      },
      {
        key: 'sleep_num_intra_sleep_awakenings',
        label: 'Awakenings',
      },
      {
        key: 'sleep_time_onset',
        label: 'Bedtime',
      },
      {
        key: 'sleep_time_final_awekening',
        label: 'Wake Time',
      },
      {
        key: 'sleep_efficiency_mean',
        label: 'Efficiency',
      },
    ],
  },
  {
    title: 'Weight',
    tableTitle: 'Weight Data Table',
    description:
      "The report below visualizes your weight logs over time. It's beneficial for observing any weight fluctuations, especially when adopting a new diet plan.",
    charts: [
      {
        name: 'Weight',
        key: 'weight',
        y: 'weight_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Min',
            key: 'weight_min',
          },
          {
            name: 'Max',
            key: 'weight_max',
          },
          {
            name: 'Mean',
            key: 'weight_mean',
          },
        ],
      },
      {
        name: 'BMI (Body Mass Index)',
        key: 'bmi',
        y: 'bmi',
        type: 'daily',
        isSecondary: true,
        summary: [
          {
            name: 'Min',
            key: 'bmi_min',
          },
          {
            name: 'Max',
            key: 'bmi_max',
          },
          {
            name: 'Mean',
            key: 'bmi_mean',
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'weight_mean',
        label: 'Weight',
      },
      {
        key: 'bmi',
        label: 'BMI',
      },
    ],
  },
  {
    title: 'Blood Pressure',
    tableTitle: 'Blood Pressure Data Table',
    description:
      "The report below helps visualize your blood pressure over time. It's beneficial for monitoring conditions such as hypertension.",
    charts: [
      {
        name: 'Blood Pressure',
        key: 'blood_pressure',
        y: 'systolic_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'SYS Min',
            key: 'systolic_min',
          },
          {
            name: 'SYS Max',
            key: 'systolic_max',
          },
          {
            name: 'SYS Mean',
            key: 'systolic_mean',
          },

          {
            name: 'DIAS Min',
            key: 'diastolic_min',
          },
          {
            name: 'DIAS Max',
            key: 'diastolic_max',
          },
          {
            name: 'DIAS Mean',
            key: 'diastolic_mean',
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'systolic_min',
        label: 'Minimum SYS',
      },
      {
        key: 'systolic_max',
        label: 'Maximum SYS',
      },
      {
        key: 'systolic_mean',
        label: 'Mean SYS',
      },
      {
        key: 'diastolic_min',
        label: 'Minimum DIAS',
      },
      {
        key: 'diastolic_max',
        label: 'Maximum DIAS',
      },
      {
        key: 'diastolic_mean',
        label: 'Mean DIAS',
      },
    ],
  },
  {
    title: 'Glucose',
    tableTitle: 'Glucose Data Table',
    description:
      "The report below helps visualize your glucose levels over time. It's beneficial for observing your sugar intake and monitoring conditions such as diabetes.",
    charts: [
      {
        name: 'Glucose',
        key: 'glucose',
        y: 'glucose_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Min',
            key: 'glucose_min',
          },
          {
            name: 'Max',
            key: 'glucose_max',
          },
          {
            name: 'Mean',
            key: 'glucose_mean',
          },
          {
            name: 'Total Measurements',
            key: 'glucose_count',
            hideUnit: true,
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'glucose_min',
        label: 'Minimum Glucose',
      },
      {
        key: 'glucose_max',
        label: 'Maximum Glucose',
      },
      {
        key: 'glucose_mean',
        label: 'Mean Glucose',
      },
    ],
  },
  {
    title: 'Band Compliance',
    tableTitle: 'Band Compliance Data Table',
    description: 'The report below shows how much of the day you wear the band.',
    charts: [
      {
        name: 'Band On Wrist',
        key: 'band_removed',
        y: 'band_removed_time_mean',
        type: 'daily',
        isSecondary: false,
        summary: [
          {
            name: 'Wearing',
            key: 'band_wearing_time_mean',
          },
          {
            name: 'Charging',
            key: 'band_charge_time_mean',
          },
          {
            name: 'Removed',
            key: 'band_removed_time_mean',
          },
        ],
      },
      {
        name: 'Compliance Averages by Day of Week',
        key: 'band_removed',
        y: 'band_removed_time',
        type: 'weekly',
        isSecondary: true,
        summary: [
          {
            name: 'Wearing',
            key: 'band_wearing_time_mean_week',
          },
          {
            name: 'Charging',
            key: 'band_charge_time_mean_week',
          },
          {
            name: 'Removed',
            key: 'band_removed_time_mean_week',
          },
        ],
      },
    ],
    tableHeader: [
      {
        key: 'band_wearing_time',
        label: 'Wearing',
      },
      {
        key: 'band_charge_time',
        label: 'Charging',
      },
      {
        key: 'band_removed_time',
        label: 'Removed',
      },
    ],
  },
];

export const CLINICAL_FACTS = [
  //HEART RATE
  'heart_rate_max',
  'heart_rate_min',
  'heart_rate_mean',
  'heart_rate_count',
  //RESPIRATORY RATE
  'resp_rate_max',
  'resp_rate_min',
  'resp_rate_mean',
  'resp_rate_count',
  //SPO2
  'spo2_max',
  'spo2_min',
  'spo2_mean',
  'spo2_count',
  //STEPS
  'steps_sum',
  'steps_mean',
  'steps_max',
  'steps_min',
  'steps_mean_mon',
  'steps_mean_tue',
  'steps_mean_wed',
  'steps_mean_thu',
  'steps_mean_fri',
  'steps_mean_sat',
  'steps_mean_sun',
  //WEIGHT
  'weight_mean',
  'weight_max',
  'weight_min',
  'weight_last',
  'bmi_min',
  'bmi_max',
  'bmi_mean',
  //BLOOD PREASURE
  'systolic_mean',
  'systolic_min',
  'systolic_max',
  'diastolic_mean',
  'diastolic_min',
  'diastolic_max',
  //GLUCOSE
  'glucose_mean',
  'glucose_max',
  'glucose_min',
  'glucose_count',
  //BAND COMPLIANCE
  'band_removed_time_mean',
  'band_wearing_time_mean',
  'band_charge_time_mean',
  'band_removed_time_mean_mon',
  'band_removed_time_mean_tue',
  'band_removed_time_mean_wed',
  'band_removed_time_mean_thu',
  'band_removed_time_mean_fri',
  'band_removed_time_mean_sat',
  'band_removed_time_mean_sun',
  'band_charge_time_mean_mon',
  'band_charge_time_mean_tue',
  'band_charge_time_mean_wed',
  'band_charge_time_mean_thu',
  'band_charge_time_mean_fri',
  'band_charge_time_mean_sat',
  'band_charge_time_mean_sun',
  'band_wearing_time_mean_mon',
  'band_wearing_time_mean_tue',
  'band_wearing_time_mean_wed',
  'band_wearing_time_mean_thu',
  'band_wearing_time_mean_fri',
  'band_wearing_time_mean_sat',
  'band_wearing_time_mean_sun',
];

export const CLINICAL_SLEEP_FACTS = [
  'sleep_duration_min',
  'sleep_duration_max',
  'sleep_duration_mean',
  'sleep_rem_mean',
  'sleep_light_mean',
  'sleep_deep_mean',
  'sleep_awake_mean',
  'sleep_time_onset',
  'sleep_time_onset_min',
  'sleep_time_onset_max',
  'sleep_time_final_awekening',
  'sleep_time_final_awekening_min',
  'sleep_time_final_awekening_max',
  'sleep_timetosleep_mean',
  'sleep_awakenings_mean',
  'sleep_duration_mean_mon',
  'sleep_duration_mean_tue',
  'sleep_duration_mean_wed',
  'sleep_duration_mean_thu',
  'sleep_duration_mean_fri',
  'sleep_duration_mean_sat',
  'sleep_duration_mean_sun',
  'sleep_deep_mean_mon',
  'sleep_deep_mean_tue',
  'sleep_deep_mean_wed',
  'sleep_deep_mean_thu',
  'sleep_deep_mean_fri',
  'sleep_deep_mean_sat',
  'sleep_deep_mean_sun',
  'sleep_rem_mean_mon',
  'sleep_rem_mean_tue',
  'sleep_rem_mean_wed',
  'sleep_rem_mean_thu',
  'sleep_rem_mean_fri',
  'sleep_rem_mean_sat',
  'sleep_rem_mean_sun',
  'sleep_light_mean_mon',
  'sleep_light_mean_tue',
  'sleep_light_mean_wed',
  'sleep_light_mean_thu',
  'sleep_light_mean_fri',
  'sleep_light_mean_sat',
  'sleep_light_mean_sun',
  'sleep_awake_mean_mon',
  'sleep_awake_mean_tue',
  'sleep_awake_mean_wed',
  'sleep_awake_mean_thu',
  'sleep_awake_mean_fri',
  'sleep_awake_mean_sat',
  'sleep_awake_mean_sun',
  'sleep_timetosleep_mean_mon',
  'sleep_timetosleep_mean_tue',
  'sleep_timetosleep_mean_wed',
  'sleep_timetosleep_mean_thu',
  'sleep_timetosleep_mean_fri',
  'sleep_timetosleep_mean_sat',
  'sleep_timetosleep_mean_sun',
  'sleep_time_onset_mean_mon',
  'sleep_time_onset_mean_tue',
  'sleep_time_onset_mean_wed',
  'sleep_time_onset_mean_thu',
  'sleep_time_onset_mean_fri',
  'sleep_time_onset_mean_sat',
  'sleep_time_onset_mean_sun',
  'sleep_time_final_awekening_mean_mon',
  'sleep_time_final_awekening_mean_tue',
  'sleep_time_final_awekening_mean_wed',
  'sleep_time_final_awekening_mean_thu',
  'sleep_time_final_awekening_mean_fri',
  'sleep_time_final_awekening_mean_sat',
  'sleep_time_final_awekening_mean_sun',
  'sleep_num_intra_sleep_awakenings_mean_mon',
  'sleep_num_intra_sleep_awakenings_mean_tue',
  'sleep_num_intra_sleep_awakenings_mean_wed',
  'sleep_num_intra_sleep_awakenings_mean_thu',
  'sleep_num_intra_sleep_awakenings_mean_fri',
  'sleep_num_intra_sleep_awakenings_mean_sat',
  'sleep_num_intra_sleep_awakenings_mean_sun',
  'sleep_efficiency_mean_mon',
  'sleep_efficiency_mean_tue',
  'sleep_efficiency_mean_wed',
  'sleep_efficiency_mean_thu',
  'sleep_efficiency_mean_fri',
  'sleep_efficiency_mean_sat',
  'sleep_efficiency_mean_sun',
  'sleep_efficiency_mean',
  'sleep_efficiency_max',
  'sleep_efficiency_min',
];

export const CLINICAL_MONTH_METRICS = [
  'heart_rate_mean',
  'heart_rate_min',
  'heart_rate_max',
  'resp_rate_mean',
  'resp_rate_min',
  'resp_rate_max',
  'spo2_mean',
  'spo2_min',
  'spo2_max',
  'steps_mean',
  'steps_sum',
  'sleep_duration',
  'sleep_rem',
  'sleep_light',
  'sleep_deep',
  'sleep_awake',
  'sleep_time_onset',
  'sleep_time_final_awekening',
  'sleep_time_to_sleep',
  'sleep_num_intra_sleep_awakenings',
  'sleep_efficiency_mean',
  'weight_mean',
  'bmi',
  'systolic_mean',
  'systolic_min',
  'systolic_max',
  'diastolic_mean',
  'diastolic_min',
  'diastolic_max',
  'glucose_min',
  'glucose_max',
  'glucose_mean',
  'band_removed_time_mean',
  'band_charge_time_mean',
  'band_wearing_time_mean',
];

export const CLINICAL_WEEK_METRICS = ['active_time_sum'];

export const CLINICAL_REPORT_HEADERS = flatMap([
  { key: 'csvDate', label: 'Date' },
  ...CLINICAL_REPORT_DATA.map((item) => item.tableHeader),
]);

export const CLINICAL_DEPRECATED_FACTS = [
  //STEPS
  'steps_mean_30d',
  'steps_mean_3m',
  'steps_mean_mon_30d',
  'steps_mean_tue_30d',
  'steps_mean_wed_30d',
  'steps_mean_thu_30d',
  'steps_mean_fri_30d',
  'steps_mean_sat_30d',
  'steps_mean_sun_30d',
  //CALORIES
  'calories_mean_30d',
  'calories_mean_3m',
  //LONGEST IDLE TIME
  'longest_idle_30d',
  'longest_idle_3m',
  //ACTIVE TIME
  'active_time_sum_30d',
  'active_time_mean_3m',
  //SLEEP
  'sleep_time_onset_30d',
  'sleep_time_onset_3m',
  'sleep_time_final_awekening_30d',
  'sleep_time_final_awekening_3m',
  'sleep_time_to_sleep_mean_30d',
  'sleep_time_to_sleep_mean_3m',
  'sleep_duration_mean_30d',
  'sleep_duration_mean_3m',
  'sleep_duration_mean_mon_30d',
  'sleep_duration_mean_tue_30d',
  'sleep_duration_mean_wed_30d',
  'sleep_duration_mean_thu_30d',
  'sleep_duration_mean_fri_30d',
  'sleep_duration_mean_sat_30d',
  'sleep_duration_mean_sun_30d',
  'sleep_efficiency_mean_30d',
  'sleep_awakenings_mean_30d',
  'sleep_rem_mean_30d',
  'sleep_light_mean_30d',
  'sleep_deep_mean_30d',
  'sleep_awake_mean_30d',
  //BIOMETRICS
  'heart_rate_max_30d',
  'heart_rate_min_30d',
  'heart_rate_mean_30d',
  'rmssd_max_30d',
  'rmssd_min_30d',
  'rmssd_mean_30d',
  'resp_rate_max_30d',
  'resp_rate_min_30d',
  'resp_rate_mean_30d',
  'spo2_max_30d',
  'spo2_min_30d',
  'spo2_mean_30d',
];

export const CLINICAL_DEPRECATED_MONTH_METRICS = [
  'steps_mean',
  'calories_mean',
  'idle_time_mean',
  'heart_rate_mean',
  'rmssd_mean',
  'resp_rate_mean',
  'spo2_mean',

  'sleep_time_to_sleep',
  'sleep_duration',
  'sleep_rem',
  'sleep_light',
  'sleep_deep',
  'sleep_awake',
];
