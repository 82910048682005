export interface BrowserNotification {
  title: string;
  body: string;
  icon?: string;
}

export function showBrowserNotification({ title, body, icon }: BrowserNotification) {
  askBrowserNotificationPemission().then((granted) => {
    if (granted) {
      new window.Notification(title, { body, icon });
    }
  });
}

export async function askBrowserNotificationPemission() {
  if (!('Notification' in window)) {
    // The user's browser does not support desktop notification
    return false;
  }
  if (window.Notification.permission === 'granted') {
    // Notification permissions have already been granted
    return true;
  }
  if (window.Notification.permission === 'denied') {
    return false;
  }

  const permission = await window.Notification.requestPermission();
  return permission === 'granted';
}
