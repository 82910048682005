export const EVENT_VERSION: string = '1';
export const EVENT_NAME: any = {
  pageViewStart: 'page_view_start',
  pageViewEnd: 'page_view_end',
};

export const EVENT_DATA_SOURCE: string = 'web';
export const EVENT_CATEGORY: string = 'Patient experience & Engagement';
export const EVENT_SUBCATEGORY: string = 'Patient Adherence';
export const EVENT_APP_NAME: string = 'Clinical Portal';
