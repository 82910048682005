import { ActionCategoryType } from 'models/action';

export const ACTION_TASKS_CONTENT: Record<ActionCategoryType, any> = {
  NEW_PATIENT: {
    NEW_MEMBER_MESSAGE: 'Generate an editable introduction message to the member',
    REMINDER: 'Add interview appointment reminder to calendar',
  },
  PANEL_PATIENT_FOLLOWUP: {
    WELFARE_CHECK_MESSAGE: 'Generate an editable welfare check message to the member',
    REMINDER: 'Add appointment reminder to calendar',
  },
  PATIENT_FOLLOWUP: {
    WELFARE_CHECK_MESSAGE: 'Generate an editable welfare check message to the member',
    REMINDER: 'Add appointment reminder to calendar',
  },
  BIOMETRICS: {
    WELFARE_CHECK_MESSAGE: 'Generate an editable welfare check message to the member',
    REMINDER: 'Add appointment reminder to calendar',
  },
  HF_BIOMETRICS: {
    WELFARE_CHECK_MESSAGE: 'Generate an editable welfare check message to the member',
    REMINDER: 'Add appointment reminder to calendar',
  },
};

export const ACTION_MESSAGE_LABEL: Record<ActionCategoryType, string> = {
  NEW_PATIENT: 'Generate an editable introduction message to the member',
  PANEL_PATIENT_FOLLOWUP: 'Generate an editable welfare check message to the member',
  PATIENT_FOLLOWUP: 'Generate an editable welfare check message to the member',
  BIOMETRICS: 'Generate an editable welfare check message to the member',
  HF_BIOMETRICS: 'Generate an editable welfare check message to the member',
};

export const ACTION_EVIDENCE_METRICS: any = {
  BG: 'glucose',
  MEALS: 'meals',
  SLEEP: 'sleep',
  BP: 'blood_pressure',
  TEMP: 'temp',
  HR: 'heart_rate',
  RR: 'resp_rate',
  SpO2: 'spo2',
  STEPS: 'steps',
  HRV: 'rmssd',
  GSR: 'gsr',
  WEIGHT: 'weight',
  MOVEMENT: 'steps',
  RHR: 'rhr',
  BAND_STATUS: 'band_removed',

  //TODO: change it to existing metric
  TIME_IN_RANGE: 'heart_rate',
  ACTIVITY: 'steps',
  STRESS: 'gsr',
};

export const ACTION_METRICS_ABOVE_BASELINE: any = {
  glucose: '#e65f67',
  blood_pressure: '#e65f67',
  systolic: '#e65f67',
  diastolic: '#e65f67',
  temp: '#e65f67',
  skin_temp: '#e65f67',
  heart_rate: '#e65f67',
  resp_rate: '#e65f67',
  spo2: '#15bccf',
  steps: '#15bccf',
  rmssd: '#e65f67',
  gsr: '#e65f67',
  weight: '#e65f67',
  rhr: '#e65f67',
  band_removed: '#e65f67',
};

export const ACTION_METRICS_UNDER_BASELINE: any = {
  glucose: '#15bccf',
  blood_pressure: '#15bccf',
  systolic: '#15bccf',
  diastolic: '#15bccf',
  temp: '#15bccf',
  skin_temp: '#15bccf',
  heart_rate: '#15bccf',
  resp_rate: '#15bccf',
  spo2: '#e65f67',
  steps: '#e65f67',
  rmssd: '#15bccf',
  gsr: '#15bccf',
  weight: '#15bccf',
  rhr: '#15bccf',
  band_removed: '#15bccf',
};

export const ACTION_EVIDENCE_NAME: Record<string, string> = {
  sleep: 'Sleep',
  steps: 'Movement',
  heart_rate: 'Heart Rate',
  rhr: 'Resting Heart Rate',
  rmssd: 'HRV',
  resp_rate: 'Respiration Rate',
  glucose: 'Blood Glucose',
  spo2: 'SpO2',
  meals: 'Meals',
  blood_pressure: 'Blood Pressure',
  temp: 'Temperature',
  gsr: 'GSR',
  weight: 'Weight',
  band_removed: 'Band Status',
};

export const CLOSED_STATUS: Record<string, string> = {
  HF_BIOMETRICS: 'INTERVENED',
  BIOMETRICS: 'INTERVENED',
  PANEL_PATIENT_FOLLOWUP: 'INTERVENED',
  PATIENT_FOLLOWUP: 'INTERVENED',
  NEW_PATIENT: 'CONFIRMED',
};
