import { createContext } from 'react';
import SendbirdCall from 'sendbird-calls';
import type { AuthOption, DialParams } from 'sendbird-calls';

import { CallState, initialState } from 'store/calls';
import type { StatefulDirectCall } from 'models/calls';

/** Context */
export type ContextType = CallState & {
  isAuthenticated: boolean;
  init: (appId: string) => void;
  auth: (authOption: AuthOption) => Promise<SendbirdCall.User | undefined>;
  deauth: () => void;

  // Media Device Control
  updateMediaDevices: (constraints: { audio: boolean; video: boolean }) => void;
  selectAudioInputDevice: (deviceInfo: MediaDeviceInfo) => void;
  selectAudioOutputDevice: (deviceInfo: MediaDeviceInfo) => void;
  selectVideoInputDevice: (deviceInfo: MediaDeviceInfo) => void;

  // Direct Calls
  isBusy: boolean;
  currentCall?: StatefulDirectCall;
  dial: (params: DialParams) => Promise<StatefulDirectCall>;
  addDirectCallSound: typeof SendbirdCall.addDirectCallSound;
  clearCalls: () => void;
};

const stub = (): never => {
  throw new Error('Authentication error.');
};

export const initialContext: ContextType = {
  ...initialState,
  isAuthenticated: false,
  init: stub,
  auth: stub,
  deauth: stub,

  // Media Device Control
  updateMediaDevices: stub,
  selectAudioInputDevice: stub,
  selectAudioOutputDevice: stub,
  selectVideoInputDevice: stub,

  // Direct Calls
  isBusy: false,
  currentCall: undefined,
  dial: stub,
  addDirectCallSound: stub,
  clearCalls: stub,
};

const CallContext = createContext<ContextType>(initialContext);

export default CallContext;
