import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { AlertIcon } from 'assets/icons';
import { DefinitionProps } from './Definition';

import useStyles from './styles';

export { Definition } from './Definition';
export { DefinitionValue } from './DefinitionValue';

export interface DefinitionListProps {
  title?: string;
  children: React.ReactElement<DefinitionProps> | React.ReactElement<DefinitionProps>[];
  isAccented?: boolean;
  showAlertIcon?: boolean;
  variant: 'two-columns' | 'single-column';
  footer?: React.ReactElement;
}

function DefinitionList(props: DefinitionListProps) {
  const classes = useStyles();

  const renderTwoColumns = () => {
    const children = React.Children.toArray(props.children);
    const middle = Math.ceil(children.length / 2);
    const firstColumn = children.slice(0, middle);
    const secondColumn = children.slice(middle);

    return (
      <>
        <div className={classes.column}>{firstColumn}</div>
        <div className={classes.column}>{secondColumn}</div>
      </>
    );
  };

  const renderSingleColumn = () => {
    return <div className={classes.column}>{props.children}</div>;
  };

  const renderItems = {
    'two-columns': renderTwoColumns,
    'single-column': renderSingleColumn,
  }[props.variant];

  const listLayoutClass = {
    'two-columns': classes.listTwoColumns,
    'single-column': classes.listSingleColumn,
  }[props.variant];

  return (
    <div>
      {props?.title && (
        <header className={cx(classes.header, props.isAccented ? classes.headerAccented : '')}>
          <Typography variant="header12">{props.title}</Typography>
          {props.showAlertIcon ? <AlertIcon className={classes.headerAlertIcon} /> : null}
        </header>
      )}
      <dl className={cx(classes.list, listLayoutClass)}>{renderItems()}</dl>
      {props.footer}
    </div>
  );
}

DefinitionList.defaultProps = {
  variant: 'two-columns',
};

export default DefinitionList;
