import makeStyles from '@mui/styles/makeStyles';
import { LIGHT_MODE } from '@constants/global';

export default makeStyles((theme) => ({
  container: {},
  trigger: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '9px 12px',
    borderRadius: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  triggerOpen: {
    background: theme.palette.action.selected,
  },
  triggerDense: {
    padding: '6px 9px',
  },
  triggerIcon: {
    marginLeft: '10px',
    transition: 'transform 128ms ease',
  },
  triggerIconOpen: {
    transform: 'rotate(180deg)',
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  optionsList: {
    listStyle: 'none',
    margin: 0,
    background: theme.palette.background.paper,
    borderRadius: '6px',
    boxShadow: `0px 18px 24px -6px ${
      theme.palette.mode === LIGHT_MODE ? 'rgba(80, 86, 97, 0.2)' : 'rgba(0, 0, 0, .4)'
    }`,
    padding: '12px',
    maxHeight: '240px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  option: {
    padding: '9px 12px',
    borderRadius: '3px',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.hover,
      color: theme.palette.text.primary,
    },
  },
  selectedOption: {
    background: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },
}));
