import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SystemSurveyAnswer, SystemSurveyAnswers } from 'models/systemSurvey';
import type { RootState } from 'store/rootReducer';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

interface MemberInvitationState {
  intakeSurveyAnswers: {
    [key: string]: SystemSurveyAnswers;
  };
  isIntakeSurveyOpen: boolean;
}

const initialState: MemberInvitationState = {
  intakeSurveyAnswers: {},
  isIntakeSurveyOpen: false,
};

const invitationsSlice = createSlice({
  name: 'memberInvitation',
  initialState,
  reducers: {
    setIntakeSurveyAnswers: (
      state,
      action: PayloadAction<{ [key: string]: SystemSurveyAnswers }>
    ) => {
      state.intakeSurveyAnswers = action.payload;
    },
    initIntakeSurvey: (state) => {
      state.isIntakeSurveyOpen = true;
    },
    discardIntakeSurvey: (state) => {
      state.isIntakeSurveyOpen = false;
      for (const surveyId of Object.keys(state.intakeSurveyAnswers)) {
        state.intakeSurveyAnswers[surveyId] = {};
      }
    },
    answerIntakeSurveyQuestion: (
      state,
      action: PayloadAction<{ questionName: string; answer: SystemSurveyAnswer; surveyId: string }>
    ) => {
      const { questionName, answer, surveyId } = action.payload;
      if (isEmpty(answer)) {
        state.intakeSurveyAnswers[surveyId] = omit(
          state.intakeSurveyAnswers[surveyId],
          questionName
        );
      } else {
        state.intakeSurveyAnswers[surveyId] = {
          ...state.intakeSurveyAnswers[surveyId],
          [questionName]: answer,
        };
      }
    },
  },
});

export const selectMemberInvitationState = (state: RootState) => state.admin.memberInvitation;

export const {
  setIntakeSurveyAnswers,
  initIntakeSurvey,
  discardIntakeSurvey,
  answerIntakeSurveyQuestion,
} = invitationsSlice.actions;

export default invitationsSlice.reducer;
