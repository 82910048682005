import { createApi } from '@reduxjs/toolkit/query/react';

import { PanelSet } from 'models/panel';
import { apiBaseQuery } from 'services/base';

export interface QueryParams {
  userId?: string;
  panel?: PanelSet;
  panelSetId?: string;
}

export const panelApi = createApi({
  reducerPath: 'panelApi',
  baseQuery: apiBaseQuery('/metaresources/api/v1/panelSet/users'),
  tagTypes: ['Panel'],
  endpoints(builder) {
    return {
      createPanelSet: builder.mutation<PanelSet, QueryParams | void>({
        query: queryParams => ({
          url: `/${queryParams?.userId}`,
          method: 'POST',
          data: queryParams?.panel,
        }),
        invalidatesTags: ['Panel'],
      }),
      updatePanelSet: builder.mutation<PanelSet, QueryParams | void>({
        query: queryParams => ({
          url: `/${queryParams?.userId}`,
          method: 'POST',
          data: queryParams?.panel,
        }),
        invalidatesTags: ['Panel'],
      }),
      getAllPanelSets: builder.query<PanelSet[], QueryParams | void>({
        query: queryParams => ({
          url: `/${queryParams?.userId}/panelSets`,
          method: 'GET',
        }),
        providesTags: ['Panel'],
      }),
      deletePanelSet: builder.mutation<PanelSet, QueryParams | void>({
        query: queryParams => ({
          url: `/${queryParams?.userId}/panelSets/${queryParams?.panelSetId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Panel'],
      }),
    };
  },
});

export const {
  useCreatePanelSetMutation,
  useUpdatePanelSetMutation,
  useGetAllPanelSetsQuery,
  useDeletePanelSetMutation,
} = panelApi;
