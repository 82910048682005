import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

interface SnackbarState {
  message: SnackbarMessage | null;
}

interface SnackbarMessage {
  text: string;
  variant?: 'error' | 'success' | 'info' | 'warning';
  action?: React.ReactNode;
  dismissAfterMs?: number;
  withRedirect?: boolean;
}

const MESSAGE_DEFAULTS: Partial<SnackbarMessage> = {
  variant: 'info',
  dismissAfterMs: 10000,
  withRedirect: false,
};

const initialState: SnackbarState = {
  message: null,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbarMessage: (state, action: PayloadAction<SnackbarMessage>) => {
      state.message = { ...MESSAGE_DEFAULTS, ...action.payload };
    },
    clearSnackbarMessage: (state, _action: PayloadAction<void>) => {
      state.message = null;
    },
  },
});

export const selectSnackbarMessage = (state: RootState) => state.snackbar.message;

export const { setSnackbarMessage, clearSnackbarMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;
