import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import PatientTab from 'components/Header/components/PatientTab';
import PatientsMenu from 'components/Header/components/PatientsMenu';
import { patientTabsSelector } from 'store/patientTabs';
import { useAppSelector } from 'store/index';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import { PatientTab as PatientTabModel } from 'models/patientTab';

const PatientTabs = () => {
  const classes = useStyles();
  const { tabs } = useAppSelector(patientTabsSelector);

  const theme = useTheme();
  const matchesUpLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesMDLG = useMediaQuery(
    theme.breakpoints.between(
      Number(theme.breakpoints.values['md']),
      Number(theme.breakpoints.values['lg'])
    )
  );
  const matchesSMMD = useMediaQuery(
    theme.breakpoints.between(
      Number(theme.breakpoints.values['sm']),
      Number(theme.breakpoints.values['md'])
    )
  );

  return (matchesDownMD && tabs.length > 0) ||
    (matchesSMMD && tabs.length > 1) ||
    (matchesMDLG && tabs.length > 2) ||
    (matchesUpLG && tabs.length > 4) ? (
    <PatientsMenu tabs={tabs} />
  ) : (
    <TransitionGroup className={classes.root} id="patientTabs">
      {tabs?.map((tab: PatientTabModel) => (
        <Collapse className={classes.collapse} key={tab.id} orientation="horizontal">
          <PatientTab tab={tab} tabs={tabs} isDropDownMenu={false} />
        </Collapse>
      ))}
    </TransitionGroup>
  );
};

export default PatientTabs;
