import { Palette, PaletteMode, createTheme } from '@mui/material';

import { createAppPalette } from './palette';
import { configureButtons } from './buttons';
import { configureCards } from './cards';
import { configureMenus } from './menus';
import { configureTables } from './tables';
import { configureMuiComponents } from './mui-components';
import { configureAlerts } from './alerts';
import { LIGHT_MODE } from '@constants/global';

export type CustomColors = keyof Palette['custom'];

const createAppTheme = (mode: PaletteMode = LIGHT_MODE) => {
  const theme = createTheme({
    typography: {
      htmlFontSize: 10,
      fontFamily: "'Gotham SSm', 'Helvetica Neue', Arial, Helvetica, sans-serif",
      header72: { fontSize: '7.2rem', fontWeight: 500, lineHeight: 1.16666667 },
      header56: { fontSize: '5.6rem', fontWeight: 500, lineHeight: 1.28571429 },
      header48: { fontSize: '4.8rem', fontWeight: 500, lineHeight: 1.25 },
      header36: { fontSize: '3.6rem', fontWeight: 500, lineHeight: 1.33333333 },
      header30: { fontSize: '3rem', fontWeight: 500, lineHeight: 1.2 },
      header24: { fontSize: '2.4rem', fontWeight: 700, lineHeight: 1.25 },
      header19: { fontSize: '1.9rem', fontWeight: 700, lineHeight: 1.26315789 },
      header15: { fontSize: '1.5rem', fontWeight: 700, lineHeight: 1.6 },
      header12: { fontSize: '1.2rem', fontWeight: 700, lineHeight: 1.5 },
      header11: { fontSize: '1.1rem', fontWeight: 700, lineHeight: 1.63636364 },
      header10: { fontSize: '1.0rem', fontWeight: 700, lineHeight: 1.5 },
      para12: { fontSize: '1.2rem', fontWeight: 500, lineHeight: 1.5 },
      para11: { fontSize: '1.1rem', fontWeight: 500, lineHeight: 1.63636364 },
      para10: { fontSize: '1rem', fontWeight: 500, lineHeight: 1.5 },
      icon: { fontSize: '1.8rem' },
      body1: { fontSize: '1.2rem' },
      body2: { fontSize: '1.2rem' },
    },
    palette: createAppPalette(mode),
    breakpoints: {
      values: {
        xs: 400,
        sm: 600,
        sm70: 700,
        md: 900,
        lg: 1200,
        lg23: 1230,
        lg80: 1380,
        xl: 1536,
      },
    },
  });

  return configureCards(
    configureTables(
      configureMenus(configureButtons(configureMuiComponents(configureAlerts(theme))))
    )
  );
};

export default createAppTheme;
