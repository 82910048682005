import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
  },
  headerAccented: {
    color: theme.palette.custom.red,
  },
  headerMenuButton: {
    marginLeft: 'auto',
    color: theme.palette.text.disabled,
  },
  headerAlertIcon: {
    color: theme.palette.custom.red,
    fontSize: '1.6rem',
    marginLeft: '6px',
  },
  list: {
    margin: 0,
  },
  listTwoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
    '--definition-list-row-template-columns': '1fr 1fr',
  },
  listSingleColumn: {
    '--definition-list-row-template-columns': '1fr 2fr',
  },
  column: {
    borderTop: `2px solid ${theme.palette.divider}`,
  },
}));

export default useStyles;
