import React, { MouseEventHandler, ReactChild } from 'react';
import { Link } from 'react-router-dom';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';

export interface CustomMenuItemProps {
  icon?: ReactChild;
  text: string;
  route?: string | undefined;
  isLast?: boolean;
  action?: MouseEventHandler<HTMLLIElement> | undefined;
}

const CustomMenuItem = ({ icon, text, route, action, isLast }: CustomMenuItemProps) => {
  const renderMenuItem = () => (
    <MenuItem
      onClick={action}
      style={{
        borderRadius: isLast ? '0 0 1.2rem 1.2rem' : '0rem',
        paddingLeft: '1.8rem',
      }}
    >
      {icon && (
        <ListItemIcon style={{ minWidth: '12px', marginRight: '0.9rem' }}>{icon}</ListItemIcon>
      )}
      <Typography variant="header12" style={{ display: 'flex', marginLeft: 0 }}>
        {text}
      </Typography>
    </MenuItem>
  );

  return <>{route ? <Link to={route}>{renderMenuItem()}</Link> : <>{renderMenuItem()}</>}</>;
};

export default CustomMenuItem;
