import { round } from 'lodash';
import { kgToLbs, metersToFeet, celsiusToFahrenheit, feetToFeetAndInches } from './conversions';

export type UnitSystemType = 'imperial' | 'metric';

export interface UnitSystem {
  readonly systemName: UnitSystemType;
  readonly temperatureSymbol: string;
  readonly weightSymbol: string;

  localeWeight(valueKg: number): number;
  localeHeight(valueMeters: number): number;
  localeTemperature(valueCelsius: number): number;

  formatLocaleWeight(localeValue: number, format?: 'long' | 'short' | 'narrow'): string;
  formatLocaleHeight(localeValue: number, format?: 'long' | 'short' | 'narrow'): string;
  formatLocaleTemperature(localeValue: number, format?: 'long' | 'short' | 'narrow'): string;
}

export class MetricUnitSystem implements UnitSystem {
  get systemName() {
    return 'metric' as UnitSystemType;
  }

  get temperatureSymbol() {
    return '°C';
  }

  get weightSymbol() {
    return 'kg';
  }

  localeWeight(valueKg: number) {
    return valueKg;
  }

  localeHeight(valueMeters: number) {
    return valueMeters;
  }

  localeTemperature(valueCelsius: number) {
    return valueCelsius;
  }

  formatLocaleHeight(localeValue: number, format: 'long' | 'short' | 'narrow' = 'short') {
    return Intl.NumberFormat(navigator.language, {
      style: 'unit',
      unit: 'centimeter',
      unitDisplay: format,
    }).format(round(localeValue * 100));
  }

  formatLocaleWeight(localeValue: number, format: 'long' | 'short' | 'narrow' = 'short') {
    return Intl.NumberFormat(navigator.language, {
      style: 'unit',
      unit: 'kilogram',
      unitDisplay: format,
    }).format(round(localeValue, 1));
  }

  formatLocaleTemperature(localeValue: number, format: 'long' | 'short' | 'narrow' = 'short') {
    return Intl.NumberFormat(navigator.language, {
      style: 'unit',
      unit: 'celsius',
      unitDisplay: format,
    }).format(round(localeValue, 2));
  }
}

export class ImperialUnitSystem implements UnitSystem {
  get systemName() {
    return 'imperial' as UnitSystemType;
  }

  get temperatureSymbol() {
    return '°F';
  }

  get weightSymbol() {
    return 'lbs';
  }

  localeWeight(valueKg: number) {
    return kgToLbs(valueKg);
  }

  localeHeight(valueMeters: number) {
    return metersToFeet(valueMeters);
  }

  localeTemperature(valueCelsius: number) {
    return celsiusToFahrenheit(valueCelsius);
  }

  formatLocaleHeight(localeValue: number, format: 'long' | 'short' | 'narrow' = 'narrow') {
    const [feet, inches] = feetToFeetAndInches(localeValue);

    return `${Intl.NumberFormat(navigator.language, {
      style: 'unit',
      unit: 'foot',
      unitDisplay: format,
    }).format(round(feet))} ${Intl.NumberFormat(navigator.language, {
      style: 'unit',
      unit: 'inch',
      unitDisplay: format,
    }).format(round(inches))}`;
  }

  formatLocaleWeight(localeValue: number, format: 'long' | 'short' | 'narrow' = 'short') {
    return `${new Intl.NumberFormat(navigator.language).format(round(localeValue, 1))}${
      format === 'narrow' ? '' : ' '
    }${format === 'long' ? 'pounds' : 'lbs'}`;
  }

  formatLocaleTemperature(localeValue: number, format: 'long' | 'short' | 'narrow' = 'short') {
    return Intl.NumberFormat(navigator.language, {
      style: 'unit',
      unit: 'fahrenheit',
      unitDisplay: format,
    }).format(round(localeValue, 2));
  }
}

export function defaultUnitSystemType() {
  return new Intl.Locale(navigator.language).region === 'US' ? 'imperial' : 'metric';
}
