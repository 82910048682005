import { PayloadAction, Slice, SliceCaseReducers, createSlice } from '@reduxjs/toolkit';

import { Note } from 'models/note';
import { RootState } from 'store/rootReducer';
import { notesApi } from 'services/notes';

export interface NotesStateProps {
  notes: Note[];
  viewFilterOptions: string[];
  sortByOptions: string[];
  categories: string[];
  defaultNote: string;
  defaultNoteFile: string;
  scrollToTop: boolean;
}

const initialState: NotesStateProps = {
  notes: [],
  viewFilterOptions: ['All', 'Last'],
  sortByOptions: ['Recent', 'Oldest'],
  categories: ['Messaging'],
  defaultNote: '',
  defaultNoteFile: '',
  scrollToTop: false,
};

const notesSlice: Slice<NotesStateProps, SliceCaseReducers<NotesStateProps>, 'notes'> = createSlice(
  {
    name: 'notes',
    initialState,
    reducers: {
      setNotes: (state, action: PayloadAction<Note[]>) => {
        if (action.payload) {
          return {
            ...state,
            notes: [...action.payload],
          };
        }
      },
      setDefaultNote: (state, action: PayloadAction<string>) => ({
        ...state,
        defaultNote: action.payload,
      }),
      setDefaultNoteFile: (state, action: PayloadAction<string>) => ({
        ...state,
        defaultNoteFile: action.payload,
      }),
      setNotesScrollToTop: (state, action: PayloadAction<boolean>) => {
        state.scrollToTop = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addMatcher(
        notesApi.endpoints.fetchLastNoteInstance.matchFulfilled,
        (state, { payload }) => {
          state.notes = [{ ...payload }];
        }
      );
    },
  }
);

export const { setNotes, setDefaultNote, setDefaultNoteFile, setNotesScrollToTop } =
  notesSlice.actions;
export const notesSelector = (state: RootState) => state.notes.notes;
export const sortByOptionsSelector = (state: RootState) => state.notes.sortByOptions;
export const viewFilterOptionsSelector = (state: RootState) => state.notes.viewFilterOptions;
export const categoriesSelector = (state: RootState) => state.notes.categories;
export const defaultNoteSelector = (state: RootState) => state.notes.defaultNote;
export const defaultNoteFileSelector = (state: RootState) => state.notes.defaultNoteFile;
export const noteScrollToTopSelector = (state: RootState) => state.notes.scrollToTop;

export default notesSlice.reducer;
