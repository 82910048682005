import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormikProps } from 'formik';
import type { RootState } from 'store/rootReducer';

interface AccountInvitationState {
  formStates: { [formKey: string]: FormikProps<any> };
}

interface SetFormikStateActionPayload {
  formKey: string;
  formState: FormikProps<any>;
}

const initialState: AccountInvitationState = {
  formStates: {},
};

const invitationsSlice = createSlice({
  name: 'accountInvitation',
  initialState,
  reducers: {
    setFormState: (
      state: AccountInvitationState,
      action: PayloadAction<SetFormikStateActionPayload>
    ) => {
      const { formKey, formState } = action.payload;
      state.formStates[formKey] = formState;
    },
  },
});

export const selectFormState = (formKey: string) => (state: RootState) =>
  state.admin.accountInvitation.formStates[formKey];

export const { setFormState } = invitationsSlice.actions;

export default invitationsSlice.reducer;
