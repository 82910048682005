/**
 * This module is created becuase it's really hard to pass a dynamic unit system
 * into various charts and generated-data helper functions that need it.
 *
 * If you need unit system in a react component, please use `useUnitSystem` hook.
 */
import {
  UnitSystem,
  MetricUnitSystem,
  ImperialUnitSystem,
  defaultUnitSystemType,
  UnitSystemType,
} from './unitSystem';

let current: UnitSystem;

const savedUnitSystemType = localStorage.getItem('unitSystemType');

// Set the initial unit system based on local storage or use the default
setCurrentUnitSystem((savedUnitSystemType as UnitSystemType) || defaultUnitSystemType());

export function setCurrentUnitSystem(type: UnitSystemType) {
  if (type === 'imperial') {
    current = new ImperialUnitSystem();
  } else if (type === 'metric') {
    current = new MetricUnitSystem();
  } else {
    throw new TypeError(`Unexpected unit system type ${type}`);
  }

  localStorage.setItem('unitSystemType', type);
}

export function getCurrentUnitSystem() {
  return current;
}
