import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { apiBaseQuery } from 'services/base';

const INITIAL_REQUEST_SIZE = 1000;

interface FactItem {
  data: {
    timestamp_first_tick_millis?: number;
  };
  tenant_id: string;
  user_id: string;
  timestamp_modified: number;
  tz?: string;
}

interface FactsResponse {
  total_elements: number;
  total_pages: number;
  items: FactItem[];
}

const fetchOnboardedDates = async ({
  queryApi,
  extraOptions,
}: {
  queryApi: BaseQueryApi;
  extraOptions: {};
}) => {
  const params = new URLSearchParams();
  params.append('teamType', 'PATIENT');
  params.append('size', `${INITIAL_REQUEST_SIZE}`);
  params.append('activeUserOnly', 'true');
  params.append('page', '0');
  params.append('facts', 'timestamp_first_tick_millis');

  const factsResponse = await apiBaseQuery('/events-processor/api/v1')(
    {
      url: `user_facts/?${params.toString()}`,
      method: 'GET',
    },
    queryApi,
    extraOptions
  );

  if (factsResponse?.data) {
    const facts = factsResponse?.data as FactsResponse;
    if (facts?.total_elements > INITIAL_REQUEST_SIZE) {
      params.delete('size');
      params.append('size', `${facts.total_elements}`);

      const allFactsResponse = await apiBaseQuery('/events-processor/api/v1')(
        {
          url: `user_facts/?${params.toString()}`,
          method: 'GET',
        },
        queryApi,
        extraOptions
      );

      if (allFactsResponse?.data) {
        const allFacts = allFactsResponse?.data as FactsResponse;
        return getOnboardedDatesByUser(allFacts.items);
      }
      return {};
    }
    return getOnboardedDatesByUser(facts.items);
  }
  return {};
};

const getOnboardedDatesByUser = (items: FactItem[]) => {
  const onboardedDateByUser: Record<string, string> = items.reduce((prev, current) => {
    if (current.data.timestamp_first_tick_millis) {
      return {
        ...prev,
        [current.user_id]: new Date(current.data.timestamp_first_tick_millis).toISOString(),
      };
    }
    return prev;
  }, {});
  return onboardedDateByUser;
};

export default fetchOnboardedDates;
