import React, { useRef, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import { ChevronDownBigIcon } from 'assets/icons';

import useStyles from './styles';

export interface DropdownOption<T> {
  value: T;
  text: string;
}

export interface DropdownProps<T> {
  options: DropdownOption<T>[];
  value?: T;
  placeholder?: string;
  onChange: (newValue: T) => void;
  placement?: PopperPlacementType;
  variant?: 'standard' | 'header';
  disabled?: boolean;
}

function Dropdown<T>({
  options,
  value,
  placeholder,
  onChange,
  placement,
  variant,
  disabled,
}: DropdownProps<T>) {
  const classes = useStyles();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = useMemo(() => options.find(option => option.value === value), [
    options,
    value,
  ]);

  const toggleIsOpen = useCallback(() => setIsOpen(curIsOpen => !curIsOpen), [setIsOpen]);

  const handleOptionClick = useCallback(
    (option: DropdownOption<T>) => {
      onChange(option.value);
      setIsOpen(false);
    },
    [onChange]
  );

  const dropdownId = useUniqueId();

  return (
    <div className={classes.container}>
      <button
        ref={triggerRef}
        disabled={disabled}
        className={classNames(classes.trigger, {
          [classes.triggerOpen]: isOpen,
          [classes.triggerDense]: variant === 'header',
        })}
        onClick={toggleIsOpen}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        id={`${dropdownId}_button`}
      >
        <Typography variant="header12">{selectedOption?.text || placeholder}</Typography>
        <ChevronDownBigIcon
          role="presentation"
          className={classNames(classes.triggerIcon, {
            [classes.triggerIconOpen]: isOpen,
          })}
        />
      </button>
      <Popper
        className={classes.popper}
        open={isOpen}
        anchorEl={triggerRef.current}
        placement={placement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={toggleIsOpen}>
          <ul
            id={`${dropdownId}_list`}
            className={classes.optionsList}
            tabIndex={-1}
            role="listbox"
            // aria-activedescendant={selectedOption ? `${dropdownId}_option_${selectedOption.value}` : null}
          >
            {options.map(option => (
              <li
                key={String(option.value)}
                id={`${dropdownId}_option_${option.value}`}
                role="option"
                aria-selected={option === selectedOption}
                className={classNames({
                  [classes.option]: true,
                  [classes.selectedOption]: option === selectedOption,
                })}
                onClick={() => handleOptionClick(option)}
              >
                <Typography variant="para12">{option.text}</Typography>
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

function useUniqueId() {
  const [id] = useState(
    Math.random()
      .toString(36)
      .substr(2)
  );
  return id;
}

export default Dropdown;
