import { createTheme, Theme } from '@mui/material/styles';

export const configureCards = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.customBackground.aside,
            borderRadius: 6,
            cursor: 'pointer',
            height: '100%',
            padding: '3.6rem 2.4rem',
            '& .MuiCardHeader-root': {
              padding: 0,
            },
            '& .MuiCardContent-root': {
              padding: '1rem 0 0 0',
            },
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
              margin: 0,
            },
            '& .MuiCardActions-root': {
              padding: 0,
            },
          },
        },
      },
    },
  });
