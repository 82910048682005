import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getMsalInstance } from 'msal';

import type { RootState } from 'store/rootReducer';
import { getBaseUrl } from 'utils/host';

export interface RadiusApiError {
  status?: number;
  data?: unknown;
}

const axiosBaseQuery =
  (
    { baseURL }: { baseURL: string } = {
      baseURL: '',
    }
  ): AxiosBaseQueryFn =>
  async (requestOpts, queryApi) => {
    const state = queryApi.getState() as RootState;
    const tenant = state.auth.tenant;
    const token = state.auth.token;
    const host = getBaseUrl();

    const config = {
      ...requestOpts,
      baseURL: host + baseURL,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        ...requestOpts.headers,
        tenantId: tenant?.tenant_ID,
        tenantCode: tenant?.tenant,
      },
    };

    try {
      const result = await axios({ ...config });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (Number(err?.response?.status) === 401) {
        const msalInstance = getMsalInstance();
        if (msalInstance) {
          // TODO: uncomment when all services will be migrated to Azure AD
          // msalInstance.logoutRedirect({
          //   postLogoutRedirectUri: window.location.origin + '/', // Redirect after logout
          // });
        } else {
          console.error('MSAL instance is not available.');
        }
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiBaseQuery = (apiPath: string): AxiosBaseQueryFn =>
  axiosBaseQuery({ baseURL: apiPath });

export type AxiosBaseQueryFn = BaseQueryFn<AxiosRequestConfig, unknown, RadiusApiError, {}, {}>;
