import Sb from 'sendbird';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

interface ConnectionProps {
  userId: string;
  appId: string;
  nickname?: string;
  profileUrl?: string;
  accessToken?: string;
}

export interface InitialMessagingStateProps {
  sendbird?: Sb.SendBirdInstance;
  error: string;
  emojiCategories: Sb.EmojiCategory[];
}

export const disconnect = createAsyncThunk(
  'sendbird/DISCONNECT',
  async (sendbird: Sb.SendBirdInstance) => {
    if (sendbird && sendbird.disconnect) {
      const isDisconnected = await sendbird.disconnect();
      return isDisconnected;
    }
    return true;
  }
);

export const handleConnection = createAsyncThunk(
  'sendbird/HANDLE_CONNECTION',
  async (props: ConnectionProps, thunkAPI) => {
    const { accessToken, userId, appId } = props;
    const { messaging } = thunkAPI.getState() as RootState;
    const { payload: isDisconnected } = await thunkAPI.dispatch(
      disconnect(messaging.sdk.sendbird || ({} as Sb.SendBirdInstance))
    );
    if (isDisconnected) {
      if (userId && appId) {
        const newSdk = new Sb({ appId, localCacheEnabled: true });

        const connectCbSucess = (res: Sb.User) => {
          thunkAPI.dispatch(initSdk(newSdk));
        };

        const connectCbError = (err: Error) => {
          thunkAPI.dispatch(setError(err.message));
        };

        if (accessToken) {
          newSdk
            .connect(userId, accessToken)
            .then((res) => connectCbSucess(res))
            .catch(connectCbError);
        } else {
          newSdk
            .connect(userId)
            .then((res) => connectCbSucess(res))
            .catch(connectCbError);
        }
      } else {
        thunkAPI.dispatch(setError('UserId or appId missing'));
      }
    }
  }
);

const initialState: InitialMessagingStateProps = {
  error: '',
  emojiCategories: [],
};

const sdkSlice = createSlice({
  name: 'sdk',
  initialState,
  reducers: {
    initSdk: (state, action: PayloadAction<Sb.SendBirdInstance>) => ({
      ...state,
      sendbird: action.payload,
    }),
    resetSdk: () => ({
      ...initialState,
    }),
    setError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
    }),
    setEmojiCategories: (state, action: PayloadAction<Sb.EmojiCategory[]>) => ({
      ...state,
      emojiCategories: action.payload,
    }),
  },
});

export const { initSdk, resetSdk, setError, setEmojiCategories } = sdkSlice.actions;
export const sdkSelector = (state: RootState) => state.messaging.sdk.sendbird;
export const emojiCategoriesSelector = (state: RootState) => state.messaging.sdk.emojiCategories;

export default sdkSlice.reducer;
