import React from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import useStyles from './styles';
import { useMsal } from '@azure/msal-react';
import { clearSession } from 'msal';

interface ErrorPageProps {
  title?: string;
  description?: string;
  isSignInError?: boolean;
}

const ErrorPage = ({ title, description, isSignInError }: ErrorPageProps) => {
  const classes = useStyles();
  const { instance } = useMsal();
  const defaultTitle =
    "We're sorry, but the application failed to start due to a configuration error";
  const defaultDescription = ' Please contact your system administrator to resolve this issue';

  const handleLogout = (event: React.MouseEvent) => {
    clearSession();
    instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin + '/sysadmin-portal/',
    });
  };

  return (
    <div className={classes.root}>
      <Box className={classes.errorContainer}>
        <Typography variant="header24">{title ?? defaultTitle}</Typography>
        <Typography variant="header15" sx={{ fontWeight: 500 }}>
          {description ?? defaultDescription}
        </Typography>
        {isSignInError ? (
          <Button
            variant="cta"
            color="black"
            sx={{ height: '4.8rem' }}
            fullWidth
            onClick={handleLogout}
          >
            Sign in
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

export default ErrorPage;
