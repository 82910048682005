import { combineReducers } from '@reduxjs/toolkit';
import accountInvitationReducer from './accountInvitation';
import memberInvitationReducer from './memberInvitation';
import accountImportReducer from './accountImport';
import accountActionsReducer from './accountActions';
import reportsReducer from './reports';

export default combineReducers({
  accountInvitation: accountInvitationReducer,
  memberInvitation: memberInvitationReducer,
  accountImport: accountImportReducer,
  accountActions: accountActionsReducer,
  reports: reportsReducer,
});
