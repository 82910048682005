import { createTheme, Theme } from '@mui/material/styles';

export const configureButtons = (theme: Theme) =>
  createTheme(theme, {
    typography: {
      button: {
        ...theme.typography.header12,
        lineHeight: 1.5,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'text' },
            style: {
              textTransform: 'uppercase',
              padding: '9px 18px',
              transition: 'none',
              color: theme.palette.text.primary,

              '&.Mui-disabled': {
                background: theme.palette.action.disabledBackground,
                boxShadow: 'none',
                color: theme.palette.text.disabled,
              },
            },
          },
          {
            props: { variant: 'cta' },
            style: {
              textTransform: 'none',
              boxShadow: '0px 15px 18px -11px rgba(80, 86, 97, 0.2)',
              borderRadius: '3px',
              padding: '9px 18px',
              transition: 'none',
              // below is a trick to transition solid color to a gradient
              // https://css-tricks.com/transitioning-gradients/
              position: 'relative',
              zIndex: 0,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '3px',
                opacity: 0,
                transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                zIndex: -1,
              },
              '&:hover': {
                '&.MuiButton-root': {},
                '&::before': {
                  opacity: 1,
                },
              },

              '&.Mui-disabled': {
                background: theme.palette.action.disabledBackground,
                color: theme.palette.action.disabled,
                boxShadow: 'none',
              },
            },
          },
          {
            props: { variant: 'cta', color: 'red' },
            style: {
              color: theme.palette.custom.white,
              background: theme.palette.custom.red,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.custom.red,
                },
              },
              '&::before': {
                background: theme.palette.custom.gradientRed,
              },
            },
          },
          {
            props: { variant: 'cta', color: 'cyan' },
            style: {
              color: theme.palette.custom.white,
              background: theme.palette.custom.cyan,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.custom.cyan,
                },
              },
              '&::before': {
                background: theme.palette.custom.gradientCyan,
              },
              '&.Mui-disabled': {
                background: 'transparent',
              },
            },
          },
          {
            props: { variant: 'cta', color: 'black' },
            style: {
              color: theme.palette.custom.white,
              background: theme.palette.custom.black,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.custom.black,
                },
              },
              '&::before': {
                background: theme.palette.custom.gradientBlack,
              },
            },
          },
          {
            props: { variant: 'cta', color: 'white' },
            style: {
              outline: `1px solid ${theme.palette.divider}`,
              background: theme.palette.background.default,
              boxShadow: 'none',
              color: theme.palette.text.primary,
              transition: 'outline-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.action.hover,
                  outlineColor: theme.palette.customBackground.input,
                },
              },
            },
          },
          {
            props: { variant: 'cta', color: 'grey' },
            style: {
              background: theme.palette.customBackground.input,
              boxShadow: 'none',
              color: theme.palette.text.primary,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.action.hover,
                },
              },
              '&.Mui-disabled': {
                color: theme.palette.text.primary,
                background: 'none',
              },
            },
          },
          {
            props: { variant: 'cta', color: 'cerulean' },
            style: {
              color: theme.palette.custom.white,
              background: theme.palette.custom.cerulean,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.custom.cerulean,
                },
              },
              '&::before': {
                background: theme.palette.custom.cerulean,
              },
            },
          },
          {
            props: { variant: 'cta', color: 'green' },
            style: {
              color: theme.palette.custom.white,
              background: theme.palette.custom.green,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.custom.green,
                },
              },
              '&::before': {
                background: theme.palette.custom.green,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            color: theme.palette.custom.black,
            '.MuiButton-startIcon svg,.MuiButton-endIcon svg': {
              fontSize: '16px',
            },

            '.MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorStart': {
              left: '18px',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '&.Mui-disabled': {
              color: theme.palette.text.disabled,
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            color: theme.palette.custom.white,
            boxShadow: '0px 18px 18px -9px rgba(80, 86, 97, 0.2)',
            transition: 'transform 128ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              transform: 'scale(1.08333333)',
            },
            '&.Mui-disabled': {
              color: theme.palette.custom.black40,
              background: theme.palette.custom.greyB,
            },
          },
        },
        variants: [
          {
            props: { color: 'cyan' },
            style: {
              background: theme.palette.custom.gradientCyan,
            },
          },
          {
            props: { color: 'gold' },
            style: {
              background: theme.palette.custom.gradientGold,
            },
          },
          {
            props: { color: 'black' },
            style: {
              background: theme.palette.custom.black,
              '&:hover': {
                background: theme.palette.custom.blackFill,
              },
            },
          },
          {
            props: { color: 'green' },
            style: {
              background: theme.palette.custom.green,
              '&:hover': {
                background: theme.palette.custom.green,
              },
            },
          },
        ],
      },
    },
  });
