import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSbCalls } from 'hooks/sbCalls';
import { StatefulDirectCall } from 'models/calls';
import { useMediaPortalContext } from './MediaPortalContext';

const RemoteVideoPortal: React.FC = () => {
  const { remoteVideoPortalDestination } = useMediaPortalContext();
  const { calls } = useSbCalls();
  const ongoingCall = calls.find((call) => call.isOngoing) as StatefulDirectCall | undefined;

  const remoteVideoRef = useRef<HTMLVideoElement>(null);

  const portalDestination =
    remoteVideoPortalDestination || document.getElementById('default-remote-video-container');

  useEffect(() => {
    if (ongoingCall) {
      ongoingCall.setRemoteMediaView(remoteVideoRef.current as HTMLVideoElement);
    }

    return () => {
      if (ongoingCall) {
        ongoingCall.setRemoteMediaView(null as any);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingCall?.callId, portalDestination]);

  if (!ongoingCall || !ongoingCall.isVideoCall) {
    return null;
  }

  if (!portalDestination) return null;

  return ReactDOM.createPortal(
    <video
      ref={remoteVideoRef}
      autoPlay
      playsInline
      muted={false}
      style={{ display: 'block' }}
      className={remoteVideoPortalDestination ? 'videoRemote' : ''}
    />,
    portalDestination
  );
};

export default RemoteVideoPortal;
