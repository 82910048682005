import React from 'react';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import useStyles from './styles';

const CircularProgressComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color="inherit" />
      </Box>
    </div>
  );
};

export default CircularProgressComponent;
