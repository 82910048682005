import round from 'lodash/round';
import { getMetricsUnits } from '@constants/charts';
import {
  Action,
  ActionCategoryType,
  ActionContent,
  ActionEvidence,
  ActionEvidenceData,
  ActionStatusType,
} from 'models/action';
import { DateRange, Metric } from 'models/charts';
import { CustomColors } from 'styles/theme';
import { getConvertedValue } from './generatedData';
import { formatTime } from './formatTimer';
import { UserRole } from 'models/user';
import { ACTION_EVIDENCE_METRICS, CLOSED_STATUS } from '@constants/actions';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getUnixTime,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subHours,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { getMetricArray, getSmaMetrics } from './chartTransform';
import { getTimezoneOffset } from 'date-fns-tz';
import { ActionAssignmentType } from 'models/actionAssignment';
import { REASSIGNED_STATUS } from '@constants/actionAssignment';

export const getActionColor = (actionCategory: ActionCategoryType): CustomColors => {
  switch (actionCategory) {
    case 'HF_BIOMETRICS':
    case 'BIOMETRICS':
      return 'red';
    default:
      return 'cyan';
  }
};

export const getTriggeredValue = (
  content: ActionContent | undefined,
  evidence: ActionEvidence[] | undefined,
  withMetric: boolean = false
): string => {
  const primaryMetric =
    evidence?.find((item) => item.order.toLowerCase() === 'primary')?.metric || '';
  const metric = ACTION_EVIDENCE_METRICS[primaryMetric] || '';
  const metricsUnits = getMetricsUnits();

  if (metric === 'band_removed') {
    const triggeredKey = Object.keys(content?.triggered_values || {}).find((key) =>
      key.includes('band')
    );

    if (content?.triggered_values && triggeredKey) {
      const value = content?.triggered_values[triggeredKey];

      return formatTime(value);
    }

    return '';
  }

  const triggeredKey = Object.keys(content?.triggered_values || {}).find((key) =>
    key.includes(metric)
  );

  if (content?.triggered_values && triggeredKey) {
    const value = content?.triggered_values[triggeredKey];
    const convertedValue =
      metric === 'weight' ||
      metric === 'heart_rate' ||
      metric === 'spo2' ||
      metric === 'resp_rate' ||
      metric === 'rmssd' ||
      metric === 'rhr'
        ? metric === 'weight'
          ? getRoundedValue(metric, value)
          : getRoundedValue(metric, value, false)
        : getConvertedValue(value, metric);

    return withMetric
      ? `${convertedValue}${metricsUnits[metric] || ''}`
      : `${convertedValue}${metric === 'spo2' ? metricsUnits.spo2 : ''}`;
  }

  return '';
};

export const getRoundedValue = (metric: Metric, value: number, rounding: boolean = true) =>
  value ? round(getConvertedValue(value, metric), rounding ? 2 : 0) : '';

export const getUserRole = (roles?: UserRole[]) =>
  roles?.find((role: UserRole) => role !== 'clinician' && role !== 'tenant_admin') || 'md';

export const getHeaderLabel = (period: string, createdDate: Date) => {
  let periodData;
  if (period.includes('S')) {
    periodData = parseShiftedPeriod(period, createdDate);
  } else {
    periodData = parseSimplePeriod(period, createdDate);
  }

  const { startTime, endTime } = periodData;
  if (isSameDay(startTime, endTime)) {
    return format(startTime, 'M/d');
  } else {
    return `${format(startTime, 'M/d')}-${format(endTime, 'M/d')}`;
  }
};

export const getEvidenceData = (
  { evidence }: Action,
  createdDate: Date,
  tz: string
): ActionEvidenceData[] =>
  evidence?.map((item) => {
    const metric = ACTION_EVIDENCE_METRICS[item.metric];
    const order = item.order;
    const key = `${metric}-${order}`;
    let evidencePeriod;
    let timeAvg;
    let timeBaseline;

    if (order.toLowerCase() === 'secondary') {
      const primaryItem = evidence?.find((item) => item.order.toLowerCase() === 'primary');
      timeAvg = primaryItem?.timeframe_average || item?.timeframe_average || '24h';
      timeBaseline = primaryItem?.timeframe_baseline || item?.timeframe_baseline || '7d';
      evidencePeriod = getEvidencePeriod(timeAvg, createdDate);
    } else {
      timeAvg = item?.timeframe_average || '24h';
      timeBaseline = item?.timeframe_baseline || '7d';
      evidencePeriod = getEvidencePeriod(timeAvg, createdDate);
    }
    const timezonesOffset = new Date().getTimezoneOffset() * 60 + getTimezoneOffset(tz) / 1000;
    const startTime = getUnixTime(evidencePeriod.startTime) - timezonesOffset;
    const endTime = getUnixTime(evidencePeriod.endTime) - timezonesOffset;
    const metricsArray = getMetricArray(metric, evidencePeriod.days as DateRange);
    const smaMetrics = getSmaMetrics(metricsArray);

    return {
      metric,
      metricsArray,
      order,
      key,
      timeAvg,
      timeBaseline,
      smaMetrics,
      ...evidencePeriod,
      startTime,
      endTime,
    };
  }) || [];

export const getEvidencePeriod = (time: string, createdDate: Date) => {
  if (time.includes('S')) {
    return parseShiftedPeriod(time, createdDate);
  } else {
    return parseSimplePeriod(time, createdDate);
  }
};

const parseSimplePeriod = (period: string, createdDate: Date) => {
  const regex = /(\d+)([hdwmy])/;
  const matches = period.match(regex);

  if (matches && matches[1] && matches[2]) {
    const value = parseInt(matches[1], 10);
    const unit = matches[2].toLowerCase();

    const { startTime, endTime } = getTime(createdDate, value, unit);

    const hours = calculateHours(value, unit);
    const days = Math.ceil(hours / 24);
    const chartDays = days >= 7 ? 7 : days <= 1 ? 1 : 2;

    return {
      hours,
      days: chartDays,
      unit,
      period: matches[0],
      startTime,
      endTime,
      shiftHours: 0,
      shiftUnit: 'h',
    };
  }

  return {
    hours: 24,
    days: 1,
    unit: 'h',
    period: '24h',
    startTime: subHours(createdDate, 24),
    endTime: createdDate,
    shiftHours: 0,
    shiftUnit: 'h',
  };
};

const parseShiftedPeriod = (period: string, createdDate: Date) => {
  const shiftRegex = /(\d+)([hdwmy])S(\d+)([hdwmy])/;
  const shiftMatches = period.match(shiftRegex);

  if (shiftMatches && shiftMatches[1] && shiftMatches[2] && shiftMatches[3] && shiftMatches[4]) {
    const value = parseInt(shiftMatches[1], 10);
    const unit = shiftMatches[2].toLowerCase();
    const shiftValue = parseInt(shiftMatches[3], 10) ?? 0;
    const shiftUnit = shiftMatches[4].toLowerCase() ?? 'h';

    const { startTime, endTime } = getTime(createdDate, value, unit);

    const hours = calculateHours(value, unit);
    const days = Math.ceil(hours / 24);
    const chartDays = days >= 7 ? 7 : days === 1 ? 1 : 2;
    const { shiftedStartTime, shiftedEndTime } = getShiftValues(
      startTime,
      endTime,
      shiftValue,
      shiftUnit
    );

    return {
      hours,
      days: chartDays,
      unit,
      period: shiftMatches[0],
      startTime: shiftedStartTime,
      endTime: shiftedEndTime,
      shiftHours: calculateHours(shiftValue, shiftUnit),
      shiftUnit,
    };
  }

  return {
    hours: 24,
    days: 1,
    unit: 'h',
    period: '24h',
    startTime: subHours(createdDate, 24),
    endTime: createdDate,
    shiftHours: 0,
    shiftUnit: 'h',
  };
};

const getTime = (createdDate: Date, value: number, unit: string) => {
  let startTime, endTime;

  switch (unit.toLowerCase()) {
    case 'h':
      startTime = subHours(createdDate, value);
      endTime = createdDate;
      break;
    case 'd':
      startTime = startOfDay(subDays(createdDate, value - 1));
      endTime = endOfDay(createdDate);
      break;
    case 'w':
      startTime = startOfWeek(subDays(createdDate, (value - 1) * 7), { weekStartsOn: 1 });
      endTime = endOfDay(createdDate);
      break;
    case 'm':
      startTime = startOfMonth(subMonths(createdDate, value - 1));
      endTime = endOfDay(createdDate);
      break;
    case 'y':
      startTime = startOfYear(subYears(createdDate, value - 1));
      endTime = endOfDay(createdDate);
      break;
    default:
      startTime = subHours(createdDate, 24);
      endTime = createdDate;
      break;
  }
  return { startTime, endTime };
};

const getShiftValues = (startTime: Date, endTime: Date, shiftValue: number, shiftUnit: string) => {
  let shiftedStartTime, shiftedEndTime;

  switch (shiftUnit.toLowerCase()) {
    case 'h':
      shiftedStartTime = subHours(startTime, shiftValue);
      shiftedEndTime = subHours(endTime, shiftValue);
      break;
    case 'd':
      shiftedStartTime = subDays(startTime, shiftValue);
      shiftedEndTime = subDays(endTime, shiftValue);
      break;
    case 'w':
      shiftedStartTime = subWeeks(startTime, shiftValue);
      shiftedEndTime = endOfWeek(subWeeks(endTime, shiftValue), { weekStartsOn: 1 });
      break;
    case 'm':
      shiftedStartTime = subMonths(startTime, shiftValue);
      shiftedEndTime = endOfMonth(subMonths(endTime, shiftValue));
      break;
    case 'y':
      shiftedStartTime = subYears(startTime, shiftValue);
      shiftedEndTime = endOfYear(subYears(endTime, shiftValue));
      break;
    default:
      shiftedStartTime = startTime;
      shiftedEndTime = endTime;
      break;
  }

  return { shiftedStartTime, shiftedEndTime };
};

const calculateHours = (value: number, unit: string) => {
  switch (unit.toLowerCase()) {
    case 'h':
      return value;
    case 'd':
      return value * 24;
    case 'w':
      return value * 24 * 7;
    case 'm':
      return value * 24 * 30;
    case 'y':
      return value * 24 * 365;
    default:
      return 24;
  }
};

export const getActionStatuses = (action: Action): ActionStatusType[] => {
  if (action.status.status === 'COMPLETED') {
    return [CLOSED_STATUS[action.action_category] as ActionStatusType];
  }

  if (REASSIGNED_STATUS.includes(action.status.status) && action.status.status_original) {
    return [action.status.status, action.status.status_original];
  }
  return [action.status.status];
};

export const getActionAssignmentType = (
  currentAssigneeRole: UserRole,
  newAssigneeRole: UserRole
): ActionAssignmentType => {
  if (
    (currentAssigneeRole === 'md' && ['nurse', 'coach'].includes(newAssigneeRole)) ||
    (currentAssigneeRole === 'nurse' && ['coach'].includes(newAssigneeRole))
  ) {
    return 'deescalate';
  }
  if (
    (currentAssigneeRole === 'nurse' && ['md'].includes(newAssigneeRole)) ||
    (currentAssigneeRole === 'coach' && ['md', 'nurse'].includes(newAssigneeRole))
  ) {
    return 'escalate';
  }
  return 'reassign';
};
