import { createApi } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';
import { apiBaseQuery } from 'services/base';
import {
  Cohort,
  CohortFilter,
  CohortJsonCondition,
  CohortMeta,
  CohortStatus,
  CohortType,
  Measure,
} from 'models/cohorts';
import { getFactByName } from 'utils/facts';
import { TOTAL_POPULATION } from '@constants/cohort';
import { celsiusToFahrenheit, fahrenheitToCelsius, kgToLbs, lbsToKg } from 'utils/conversions';
import { UnitSystem } from 'utils/unitSystem';

export const cohortApi = createApi({
  reducerPath: 'cohortApi',
  baseQuery: apiBaseQuery('/factuary/api/v2'),
  tagTypes: ['Cohort'],
  endpoints(builder) {
    return {
      fetchCohorts: builder.query<Cohort[], { unitSystem: UnitSystem }>({
        async queryFn(queryParams, _, __, baseQuery) {
          const cohortsResult = await baseQuery({
            url: `/cohorts`,
          });

          if (cohortsResult.error) {
            return { error: cohortsResult.error };
          }

          const cohortsData = cohortsResult.data as { cohorts: Cohort[] };
          const cohorts = cohortsData.cohorts.map((cohort: Cohort) => ({
            ...cohort,
            filters: filtersFromJsonConditions(cohort.json_conditions, queryParams.unitSystem),
          }));
          return {
            data: [TOTAL_POPULATION, ...cohorts] as Cohort[],
          };
        },
      }),
      fetchCohortById: builder.query<Cohort, any>({
        async queryFn(queryParams, _, __, baseQuery) {
          const cohortResult = await baseQuery({
            url: `/cohorts/${queryParams.id}`,
          });

          if (cohortResult.error) {
            return { error: cohortResult.error };
          }

          const cohortData = cohortResult.data as { cohort: Cohort; meta: CohortMeta };
          const cohort = {
            ...cohortData.cohort,
            filters: filtersFromJsonConditions(
              cohortData.cohort?.json_conditions,
              queryParams.unitSystem
            ),
            meta: cohortData.meta,
          };
          return {
            data: cohort as Cohort,
          };
        },
      }),
      createCohort: builder.mutation<Cohort, { cohort: Cohort; unitSystem: UnitSystem }>({
        async queryFn(queryParams, _, __, baseQuery) {
          const cohortBody = cohortObjectFromData(queryParams.cohort);
          const cohortResult = await baseQuery({
            url: '/cohorts',
            method: 'POST',
            data: cohortBody,
          });

          if (cohortResult.error) {
            return { error: cohortResult.error };
          }

          const cohortData = cohortResult.data as { cohort: Cohort; meta: CohortMeta };
          const cohort = {
            ...cohortData.cohort,
            filters: filtersFromJsonConditions(
              cohortData.cohort?.json_conditions,
              queryParams.unitSystem
            ),
            meta: cohortData.meta,
          };

          return {
            data: cohort as Cohort,
          };
        },
      }),

      deleteCohort: builder.mutation<void, any>({
        query: (id) => ({
          url: `/cohorts/${id}`,
          method: 'DELETE',
        }),
      }),
      fetchCohortPreview: builder.query<any, Partial<Cohort>>({
        query: (params) => {
          const cohort = {
            tenant_id: params.tenantId,
            type: 'custom',
            json_conditions: jsonConditionsFromFilters(params.filters || []),
          };
          return {
            url: '/cohorts/preview',
            method: 'POST',
            data: cohort,
          };
        },
      }),
    };
  },
});

export const {
  useLazyFetchCohortsQuery,
  useFetchCohortsQuery,
  useFetchCohortByIdQuery,
  useLazyFetchCohortByIdQuery,
  useCreateCohortMutation,
  useDeleteCohortMutation,
  useFetchCohortPreviewQuery,
} = cohortApi;

export const cohortObjectFromData = (data: Cohort) => ({
  ...(data.id ? { id: data.id } : {}),
  tenant_id: data.tenantId,
  title: data.title,
  description: data.description,
  json_conditions: jsonConditionsFromFilters(data.filters),
  display_facts: data.display_facts,
  status: 'live' as CohortStatus,
  type: 'custom' as CohortType,
});

export const jsonConditionsFromFilters = (filters: CohortFilter[]) =>
  filters.map((filter: CohortFilter) => {
    if (filter.type === 'measure') {
      if (filter.base === 'weight') {
        return {
          fact: filter.name,
          gte:
            filter.value &&
            (filter.system === 'metric' ? filter.value[0] : lbsToKg(filter.value[0])),
          lte:
            filter.value &&
            (filter.system === 'metric' ? filter.value[1] : lbsToKg(filter.value[1])),
        };
      }
      if (filter.base === 'skin_temp') {
        return {
          fact: filter.name,
          gte:
            filter.value &&
            (filter.system === 'metric' ? filter.value[0] : fahrenheitToCelsius(filter.value[0])),
          lte:
            filter.value &&
            (filter.system === 'metric' ? filter.value[1] : fahrenheitToCelsius(filter.value[1])),
        };
      }
      return {
        fact: filter.name,
        gte: filter.value && filter.value[0],
        lte: filter.value && filter.value[1],
      };
    }

    return {
      fact: filter.name,
      one_of: [filter.value],
    };
  });

export const filtersFromJsonConditions = (
  json_conditions: CohortJsonCondition[],
  unitSystem: UnitSystem
) =>
  json_conditions
    .map((condition: CohortJsonCondition) => {
      const fact = getFactByName(condition, unitSystem) as Measure;
      if (!fact) {
        return {};
      } else if ('one_of' in condition) {
        return {
          ...fact,
          value: condition.one_of && condition.one_of[0],
        };
      } else {
        if (fact.base === 'weight') {
          return {
            ...fact,
            value: [
              getFilterValue(fact, condition.gte || condition.gt, fact.range[0], kgToLbs),
              getFilterValue(fact, condition.lte || condition.lt, fact.range[1], kgToLbs),
            ],
          };
        }
        if (fact.base === 'skin_temp') {
          return {
            ...fact,
            value: [
              getFilterValue(
                fact,
                condition.gte || condition.gt,
                fact.range[0],
                celsiusToFahrenheit
              ),
              getFilterValue(
                fact,
                condition.lte || condition.lt,
                fact.range[1],
                celsiusToFahrenheit
              ),
            ],
          };
        }
        return {
          ...fact,
          value: [
            condition.gte || condition.gt || fact.range[0],
            condition.lte || condition.lt || fact.range[1],
          ],
        };
      }
    })
    .filter((filter) => !isEmpty(filter));

const getFilterValue = (
  fact: Measure,
  conditionValue: number | null = null,
  rangeValue: number,
  func: any
) =>
  conditionValue
    ? fact.system === 'metric'
      ? conditionValue
      : func(conditionValue || null)
    : rangeValue;
