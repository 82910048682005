import { createApi } from '@reduxjs/toolkit/query/react';

import { setProfilePhoto, setSignature } from 'store/account';

import { apiBaseQuery, RadiusApiError } from 'services/base';
import { sendFile } from 'store/messaging/channel';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

interface QueryParams {
  tenantId: string;
  userId: string;
  file: FormData;
  fileName: string;
  fileSize: number;
  fileType: string;
}

interface UploadProfileFilesMutationParams extends QueryParams {
  onUploadProgress?: (event: ProgressEvent) => void;
}

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: apiBaseQuery('/blob'),
  tagTypes: ['File'],
  endpoints(builder) {
    return {
      uploadChatFile: builder.mutation<string, QueryParams>({
        async queryFn(queryParams, { dispatch }, extraOptions, baseQuery) {
          const blobStoreResponse = await baseQuery({
            url: `/tenant/${queryParams.tenantId}/user/${queryParams.userId}/upload?path=chat&overwrite=true`,
            method: 'POST',
            data: queryParams.file,
          });

          if (blobStoreResponse.error) {
            return {
              error: blobStoreResponse.error,
            };
          }

          const fileUrl = blobStoreResponse.data as string;

          dispatch(
            sendFile({
              fileUrl,
              name: queryParams.fileName,
              size: queryParams.fileSize,
              type: queryParams.fileType,
            })
          );

          return { data: fileUrl };
        },
      }),
      uploadProfileFiles: builder.mutation<string, UploadProfileFilesMutationParams>({
        queryFn(params, _queryApi, _extraOptions, baseQuery) {
          return baseQuery({
            url: `/tenant/${params.tenantId}/user/${params.userId}/upload?path=profile&overwrite=true`,
            method: 'POST',
            data: params.file,
            onUploadProgress: params.onUploadProgress,
          }) as Promise<QueryReturnValue<string, RadiusApiError>>;
        },
        invalidatesTags: ['File'],
        async onCacheEntryAdded(queryParams, { dispatch, cacheDataLoaded, getCacheEntry }) {
          try {
            await cacheDataLoaded;
            const { data } = getCacheEntry();
            if (data) {
              dispatch(setProfilePhoto(data));
            }
          } catch (error) {
            console.error(error);
          }
        },
      }),
      uploadSignature: builder.mutation<string, QueryParams>({
        query: (queryParams: QueryParams) => ({
          url: `/tenant/${queryParams.tenantId}/user/${queryParams.userId}/upload?path=profile&overwrite=true`,
          method: 'POST',
          data: queryParams.file,
          responseHandler: (response: Response) => response.text(),
        }),
        invalidatesTags: ['File'],
        async onCacheEntryAdded(queryParams, { dispatch, cacheDataLoaded, getCacheEntry }) {
          try {
            await cacheDataLoaded;
            const { data } = getCacheEntry();
            data && dispatch(setSignature(data));
          } catch (error) {
            console.error(error);
          }
        },
      }),
    };
  },
});

export const {
  useUploadChatFileMutation,
  useUploadProfileFilesMutation,
  useUploadSignatureMutation,
} = filesApi;
