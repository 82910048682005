import { AdminClinicianRoleType } from './admin';

export type Sex = 'female' | 'intersex' | 'male';
export type Gender = 'female' | 'intersex' | 'male' | 'non-binary' | 'transgender' | 'unknown';

export type AccountType = 'member' | 'staff';

export type AccountInvitationModel = MemberInvitationModel | StaffInvitationModel;

export interface MemberInvitationModel extends AccountInvitationCommon {
  height: number | null | Array<number | null>;
  weight: number | null;
  heightDisplayUnits: 'ft/in' | 'cm';
  weightDisplayUnits: 'lbs' | 'kg';
}

export interface StaffInvitationModel extends AccountInvitationCommon {
  role: AdminClinicianRoleType | null;
  isAdmin: boolean;
  qhcp: boolean;
}

interface AccountInvitationCommon {
  // Required Info
  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  // Personal Info
  photo: File | null;
  dateOfBirth: Date | null;
  sex: Sex | null;
  gender: Gender | null;

  // Mailing Address
  street: string | null;
  city: string | null;
  stateOrRegion: string | null;
  country: { code: string; name: string } | null;
  zip: string | null;
}

export function isStaffInvitationModel(
  invitationModel: AccountInvitationModel
): invitationModel is StaffInvitationModel {
  return 'role' in invitationModel;
}

export function isMemberInvitationModel(
  invitationModel: AccountInvitationModel
): invitationModel is MemberInvitationModel {
  return !isStaffInvitationModel(invitationModel);
}

export const StaffRoles: { value: AdminClinicianRoleType; label: string }[] = [
  {
    value: 'observer',
    label: 'Observer',
  },
  {
    value: 'md',
    label: 'Provider',
  },
  {
    value: 'nurse',
    label: 'Nurse',
  },
  {
    value: 'coach',
    label: 'Coach',
  },
  {
    value: 'specialist',
    label: 'Specialist',
  },
  {
    value: 'support',
    label: 'Support',
  },
];

export type AccountStatusType = 'ALL' | 'DISCHARGED' | 'ON_HOLD' | 'LIVE';
