import { createTheme, Theme } from '@mui/material/styles';

export const configureTables = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiTableContainer: {
        styleOverrides: {
          root: {
            // Firefox support
            scrollbarColor: `${theme.palette.text.secondary} ${theme.palette.divider}`,
            // Other browsers
            '&::-webkit-scrollbar': {
              width: 10,
              height: 10,
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: 5,
              '&:hover': {
                backgroundColor: theme.palette.divider,
              },
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.text.secondary,
              borderRadius: 5,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: '48px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.para12.fontSize,
            fontWeight: theme.typography.para12.fontWeight,
            padding: '0 16px',
            borderWidth: '0 0 2px 0',
            borderStyle: 'solid',
            borderColor: theme.palette.divider,

            '&:first-of-type': {
              paddingLeft: 0,
            },
          },
          body: {
            borderLeftWidth: '1px',
            '&:first-of-type': {
              borderLeftWidth: 0,
              paddingLeft: '9px',
            },
          },
          head: {
            position: 'relative',
            whiteSpace: 'nowrap',

            '&:before': {
              content: '""',
              position: 'absolute',
              top: '16px',
              left: 0,
              width: '1px',
              height: '18px',
              background: theme.palette.divider,
            },

            '&:first-of-type': {
              '&:before': {
                content: 'unset',
              },
            },
          },
          stickyHeader: {
            position: 'sticky',
          },
        },
      },
    },
  });
