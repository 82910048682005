import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  row: {
    [theme.breakpoints.only('md')]: {
      display: 'inherit',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '&.bgAccented': {
      backgroundColor: theme.palette.customBackground.aside,
    },
    '&.hover:hover': {
      backgroundColor: theme.palette.divider,
      cursor: 'pointer',
    },
  },
  rowGrid: {
    display: 'grid',
    alignItems: 'baseline',
    // this variable is defined in DefinitionList/styles.ts
    gridTemplateColumns: 'var(--definition-list-row-template-columns)',
  },
  name: {
    color: theme.palette.text.disabled,
    padding: '.5rem 0',
    paddingRight: '1rem',
    '& svg': {
      verticalAlign: 'middle',
    },
    '& sub': {
      fontSize: '0.7em',
    },
  },
  value: {
    padding: '.5rem 0',
    margin: 0,
    position: 'relative',
    wordBreak: 'break-all',

    '&.hover:hover': {
      backgroundColor: theme.palette.divider,
      cursor: 'pointer',
    },
  },
  grid: {
    display: 'grid',
  },
}));

export default useStyles;
