import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { HeartIcon } from 'assets/icons';
import useStyles from './styles';
import cx from 'classnames';

interface FooterProps {
  sx?: object;
  isWhiteBackground?: boolean;
}

const Footer = ({ sx, isWhiteBackground }: FooterProps) => {
  const classes = useStyles();

  return (
    <Box
      className={cx(classes.root, { [classes.whiteBackground]: isWhiteBackground })}
      sx={sx}
      id="footer"
    >
      <Typography variant="para12">Made with</Typography>
      <HeartIcon className={classes.heartIcon} />
      <Typography variant="para12">by the all.health team</Typography>
    </Box>
  );
};

export default Footer;
